import { createBreakpoint } from 'react-use';

function useBreakpointValue<T>(values: { [breakpoint: string]: T }) {
  //
  const breakpoints = {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1440,
    '3xl': 1920, //fhd
    '4xl': 2560, //2k
    '5xl': 3840, //4k
  };

  const useBreakpoint = createBreakpoint(breakpoints);

  const breakpoint = useBreakpoint();

  return {
    value: values[breakpoint],
    breakpoint,
  };
}

export { useBreakpointValue };
