import { mode } from '@chakra-ui/theme-tools';

export const secondary = (props) => {
  return {
    position: 'relative',
    backgroundClip: 'text',
    border: 0,
    borderRadius: '10px',
    color: mode('black', 'white')(props),
    fontWeight: 600,
    boxShadow: '0px 0px 0px 4px rgba(214, 74, 165, 0)',
    transition: 'all 200ms linear',

    _before: {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      padding: '1px',
      background: 'purpleGradient',
      borderRadius: 'inherit',
      WebkitMask:
        'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
      WebkitMaskComposite: 'xor',
      maskComposite: 'exclude',
    },

    _after: {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: 'purpleGradient',
      borderRadius: 'inherit',
      opacity: '0%',
      zIndex: -1,
      transition: 'opacity 200ms linear',
    },

    _hover: {
      boxShadow: '0px 0px 10px rgba(158, 132, 255, 0.4)',

      _after: {
        opacity: '10%',
      },
    },

    _active: {
      boxShadow: '0px 0px 15px rgba(158, 132, 255, 0.6)',

      _after: {
        opacity: '10%',
      },
    },

    _focus: {
      boxShadow: '0px 0px 0px 4px rgba(214, 74, 165, 0.15)',
    },

    _disabled: {
      backgroundColor: 'transparent',
      bgClip: 'border-box',
      border: '1px solid',
      borderColor: 'blueDisabled',
      color: mode('darkFlat.40', 'whiteFlat.40')(props),
      opacity: '40%',

      _before: {
        display: 'none',
      },

      _after: {
        display: 'none',
      },

      _hover: {
        boxShadow: 'none',
        color: mode('darkFlat.40', 'whiteFlat.40')(props),
      },

      _active: {
        boxShadow: 'none',
        backgroundColor: 'transparent',
        color: mode('darkFlat.40', 'whiteFlat.40')(props),
      },
    },
  };
};
