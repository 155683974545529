import { Box, Flex, Link, Text } from '@chakra-ui/react';
import cn from 'classnames';
import React from 'react';

import { Icon, IconNames } from '@/components/ui';
import { formatAddress } from '@/utils';

import { ETHERSCAN_LINK } from '@/constants';

import dayjs from 'dayjs';
import s from './nft-history.module.sass';

export type NFTHistoryType = 'transfer' | 'mint' | 'sale' | 'list' | 'delist';

interface INFTHistoryProps {
  type: NFTHistoryType;
  tx: string;
  price?: [number, string];
  from: string;
  to?: string;
  timestamp: number;
}

const labels: Record<NFTHistoryType, [string, IconNames, string]> = {
  delist: [s.TypeLabel_delist, 'delist', 'Delist'],
  list: [s.TypeLabel_list, 'listing', 'List'],
  mint: [s.TypeLabel_mint, 'mint', 'Mint'],
  sale: [s.TypeLabel_sale, 'sale', 'Sale'],
  transfer: [s.TypeLabel_transfer, 'transfer', 'Transfer'],
};

export const NFTHistory: React.FC<INFTHistoryProps> = (props) => {
  const { type, tx, price = [], from, to, timestamp } = props;

  const [priceValue, token] = price;

  const [labelStyle, labelIcon, labelName] = labels?.[type] ?? [];

  return !labelName ? null : (
    <Box className={s.Wrapper}>
      <Flex alignItems={'center'} justifyContent={'space-between'}>
        <Box className={cn(s.TypeLabel, labelStyle)}>
          <Icon icon={labelIcon} />
          <Text>{labelName}</Text>
        </Box>
        <Text className={s.Price}>
          {!['sale', 'list'].includes(type) ? '-' : `${priceValue} ${token}`}
        </Text>
      </Flex>
      <Flex alignItems={'center'} justifyContent={'space-between'}>
        <Flex className={s.Address}>
          <Text>{formatAddress(from, 2, 4)}</Text>
          {!to ? null : (
            <>
              <Icon
                icon={'chevronUp'}
                transform={'rotate(90deg)'}
                opacity={0.6}
              />
              <Text>{formatAddress(to, 2, 4)}</Text>
            </>
          )}
        </Flex>
        <Flex className={s.Time}>
          <Text>{dayjs().to(dayjs(timestamp * 1000))}</Text>
          {!tx ? null : (
            <Link isExternal href={`${ETHERSCAN_LINK.hash}/${tx}`}>
              <Icon icon={'externalLink'} boxSize={'16px'} />
            </Link>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};
