import { useTheme } from '@/hooks';
import { Icon, IconProps } from '@chakra-ui/react';

export const ArrowLeft = (props: IconProps) => {
  //
  const { isLight } = useTheme();
  const fill = isLight ? 'black' : 'white';

  return (
    <>
      <Icon
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 16 16"
        fill="none"
        display="block"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.90237 3.09763C6.77219 2.96746 6.56114 2.96746 6.43096 3.09763L1.7643 7.7643C1.63412 7.89447 1.63412 8.10553 1.7643 8.2357L6.43096 12.9024C6.56114 13.0325 6.77219 13.0325 6.90237 12.9024C7.03254 12.7722 7.03254 12.5611 6.90237 12.431L2.4714 8L6.90237 3.56904C7.03254 3.43886 7.03254 3.22781 6.90237 3.09763Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.18945 7.99992C2.18945 8.18401 2.33869 8.33325 2.52279 8.33325L13.1895 8.33325C13.3735 8.33325 13.5228 8.18401 13.5228 7.99992C13.5228 7.81582 13.3735 7.66659 13.1895 7.66659L2.52279 7.66659C2.33869 7.66659 2.18945 7.81582 2.18945 7.99992Z"
          fill={fill}
        />
      </Icon>
    </>
  );
};
