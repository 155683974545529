import NextImage, { ImageLoader, ImageLoaderProps } from 'next/future/image';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { AspectRatio, Image as ChakraImage } from '@chakra-ui/react';

import { imageRemotePatterns } from '@/configs/image-remote-patterns';
import { RemotePattern } from 'next/dist/shared/lib/image-config';
import { matchRemotePattern } from 'next/dist/shared/lib/match-remote-pattern';

import { NftAssetError } from '@/components/common/nft-card/components/nft-asset/components';
import { NftImageLoading } from '../../../nft-image-loading';
import { NftAssetImageErrorBoundary } from './components';

type AssetType = {
  url: string;
  type?: 'image' | 'video' | 'audio' | 'unknown';
};

type ImageLoadingType = 'skeleton' | 'spinner';

interface INftAssetImageProps {
  asset: AssetType;
  alt: string;
  imageLoadingType?: ImageLoadingType;
  renderLoading?: () => React.ReactNode;
  renderError?: () => React.ReactNode;
  onError?: () => void;
  onLoaded?: () => void;
}

const contentfulImageLoader: ImageLoader = ({
  src,
  width,
}: ImageLoaderProps) => {
  return `${src}?w=${width}`;
};

export const NftAssetImage: React.FC<INftAssetImageProps> = (props) => {
  //
  const {
    asset,
    imageLoadingType = 'skeleton',
    alt,
    renderLoading = () => <NftImageLoading type={imageLoadingType} />,
    renderError = () => <NftAssetError />,
    onError,
    onLoaded,
  } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const handleLoadingComplete = useCallback(() => {
    setIsLoading(false);
    onLoaded?.();
  }, [onLoaded]);

  const handleLoadingError = useCallback(() => {
    setIsError(true);
    setIsLoading(false);
    onError?.();
  }, [onError]);

  const assetUrl = useMemo(() => asset.url, [asset]);

  useEffect(() => {
    if (!assetUrl) {
      handleLoadingError();
    }
  }, [assetUrl]);

  const isWhiteListURL = useMemo(() => {
    if (assetUrl) {
      try {
        return imageRemotePatterns.some((pattern) =>
          matchRemotePattern(pattern as RemotePattern, new URL(assetUrl))
        );
      } catch (e: unknown) {}
    }

    return false;
  }, [assetUrl]);

  useEffect(() => {
    if (!isWhiteListURL && assetUrl) {
      const img = new Image();
      img.src = assetUrl;
      img.onload = () => handleLoadingComplete();
    }
  }, [isWhiteListURL, assetUrl]);

  return (
    <>
      {isLoading && renderLoading()}

      {isError ? (
        renderError()
      ) : (
        <AspectRatio ratio={1} pos={'relative'}>
          <NftAssetImageErrorBoundary>
            {isWhiteListURL ? (
              <NextImage
                loader={contentfulImageLoader}
                src={assetUrl}
                alt={alt}
                width={500}
                height={500}
                onLoadingComplete={handleLoadingComplete}
                onError={handleLoadingError}
                loading="lazy"
              />
            ) : (
              <>
                {assetUrl && (
                  <ChakraImage
                    src={assetUrl}
                    alt={alt}
                    width={500}
                    height={500}
                    onError={handleLoadingError}
                    onLoad={handleLoadingComplete}
                    loading="lazy"
                  />
                )}
              </>
            )}
          </NftAssetImageErrorBoundary>
        </AspectRatio>
      )}
    </>
  );
};
