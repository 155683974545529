import {
  Box,
  Grid,
  GridItem,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
} from '@chakra-ui/react';
import { useToast } from '@chakra-ui/toast';
import React, { useEffect, useState } from 'react';
import { useDebounce, useStateWithHistory } from 'react-use';

import { getCollections } from '@/api/playlists';
import {
  CollectionCard,
  CollectionCardSkeleton,
  CollectionsNotFound,
  INFTListProps,
  NFTList,
  Pagination,
  usePaginatedQuery,
} from '@/components/common';
import { Icon, Toast } from '@/components/ui';
import { PAGE_SIZES } from '@/constants';
import { useAddress } from '@/hooks';
import { ICollection } from '@/types';
import { trackSearch } from '@/utils/amplitude';

import styles from './collection-list.module.sass';

interface ICollectionListProps {
  containerOffset?: number;
  fixedPagination?: boolean;
  NFTListProps?: INFTListProps;
  onTotalChanged: (_total: number | undefined) => void;
}

export const CollectionList: React.FC<ICollectionListProps> = (props) => {
  const {
    containerOffset = 0,
    NFTListProps = {},
    fixedPagination = true,
    onTotalChanged,
  } = props;

  const toast = useToast();

  const ownerAddress = useAddress();
  const [selectedCollection, setSelectedCollection] = useState<ICollection>();

  const [search, setSearch, searchHistory] = useStateWithHistory('');
  const [debouncedSearch, setDebouncedSearch] = React.useState('');

  const { paginationInfo, queryData, pageParams, setPaginationParams } =
    usePaginatedQuery({
      fetch: (params) =>
        getCollections(params, {
          impersonateUser: ownerAddress,
          searchQuery: debouncedSearch,
        }),
      queryKey: `user/collections?search=${debouncedSearch}`,
      defaultPageSize: PAGE_SIZES[0],
    });

  useDebounce(
    () => {
      if (search) {
        trackSearch(search);
      }

      setDebouncedSearch(search);
      setPaginationParams({ ...pageParams, page: 1 });
    },
    500,
    [search]
  );

  useEffect(() => {
    onTotalChanged(paginationInfo?.total);
  }, [paginationInfo, onTotalChanged]);

  useEffect(() => {
    window?.scrollTo(0, 0);
  }, [paginationInfo?.currentPage]);

  if (selectedCollection) {
    return (
      <>
        <Link
          href={'#'}
          alignItems={'center'}
          display={'inline-flex'}
          mt={6}
          mb={'16px'}
          gap={'8px'}
          fontSize={'14px'}
          _hover={{
            color: 'purpleLight',
            textDecoration: 'none',
          }}
          onClick={(e) => {
            e.preventDefault();
            setSelectedCollection(undefined);
          }}
        >
          <Icon icon={'arrowLeft'} />
          Go back
        </Link>
        <Heading fontSize={'32px'}>{selectedCollection.name}</Heading>
        <NFTList
          fixed
          containerOffset={47}
          collectionAddress={selectedCollection.contractAddress}
          {...NFTListProps}
        />
      </>
    );
  }

  return (
    <Box pt={'32px'}>
      {!queryData?.data?.length &&
      !search.trim() &&
      searchHistory.position === 0 ? null : (
        <InputGroup size={'lg'} mb={'32px'}>
          <InputLeftElement pointerEvents="none">
            <Icon icon={'search'} opacity={0.4} />
          </InputLeftElement>
          <Input
            placeholder={'Search'}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </InputGroup>
      )}

      {queryData.isLoading || queryData.isFetching ? (
        <Grid gap={6} className={styles.Grid} mb={6} mt={6}>
          {new Array(4).fill(null).map((_, i) => (
            <CollectionCardSkeleton key={i} />
          ))}
        </Grid>
      ) : (
        <>
          {queryData.isFetched && (
            <>
              {queryData.data.length ? (
                <Box pb={'75px'}>
                  <Grid gap={6} className={styles.Grid}>
                    {queryData.data.map((collection, i) => (
                      <GridItem w="100%" key={i}>
                        <CollectionCard
                          collection={collection}
                          onClick={() => {
                            if (!collection.contractAddress) {
                              return toast({
                                position: 'bottom-right',
                                duration: 6000,
                                render: ({ onClose }) => (
                                  <Toast
                                    status="error"
                                    title={
                                      "Collection doesn't have a contractAddress..."
                                    }
                                    onClose={onClose}
                                  />
                                ),
                              });
                            }
                            setSelectedCollection(collection);
                          }}
                        />
                      </GridItem>
                    ))}
                  </Grid>
                  <Pagination
                    pageParams={pageParams}
                    setPaginationParams={setPaginationParams}
                    pageSizes={PAGE_SIZES}
                    paginationInfo={paginationInfo}
                    fixed={fixedPagination}
                  />
                </Box>
              ) : (
                <CollectionsNotFound
                  minH={`calc(100vh - ${styles.headerHeight} - 64px - ${containerOffset}px - 32px - 48px - 32px - 80px)`}
                />
              )}
            </>
          )}
        </>
      )}
    </Box>
  );
};
