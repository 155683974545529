import React, { useCallback, useContext, useEffect } from 'react';

import {
  Box,
  Center,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  SimpleGrid,
  useBreakpointValue,
} from '@chakra-ui/react';

import {
  NftCard,
  NftCardSkeleton,
  NFTsNotFound,
  Pagination,
  usePaginatedQuery,
} from '@/components/common';

import { Icon } from '@/components/ui';

import { useAddress } from '@/hooks';
import { useDebounce, useStateWithHistory } from 'react-use';

import { fetchUserNFTs } from '@/api/nfts';
import { PAGE_SIZES } from '@/constants';
import { PaginationInfo } from '@/types';
import { trackSearch } from '@/utils/amplitude';

import { NftCardProps } from '@/components/common/nft-card';
import { PlayerContext } from '@/components/common/player';

import { configs } from '@/configs';
import cn from 'classnames';
import styles from './nft-list.module.sass';

export interface INFTListProps {
  collectionAddress?: string;
  selectable?: boolean;
  selectedKeys?: string[];
  maxSelectedItems?: number;
  fixed?: boolean;
  hideSinglePager?: boolean;
  containerOffset?: number;
  nftCardProps?: NftCardProps;
  //defaultSelectedKeys?: string[];
  onSelect?(item): void;
  onDeselect?(item): void;
  onPaginationChanges?: (pagination: PaginationInfo) => void;
}

export const NFTList: React.FC<INFTListProps> = (props) => {
  //
  const {
    collectionAddress,
    selectable = false,
    selectedKeys,
    maxSelectedItems,
    fixed,
    containerOffset = 0,
    nftCardProps = {},
    onSelect,
    onDeselect,
    hideSinglePager,
    onPaginationChanges,
  } = props;

  const ownerAddress = useAddress();

  const getSelectedIndex = useCallback(
    (nft) => {
      const id = `${nft.contractAddress}#${nft.tokenId}`;
      return (selectedKeys || []).findIndex((key) => key === id);
    },
    [selectedKeys]
  );

  const columns = {
    '5xl': 8,
    '4xl': 7,
    '3xl': 6,
    xxl: 4,
    xl: 3,
    md: 2,
    sm: 1,
  };

  const value = useBreakpointValue(columns);

  const playerContext = useContext(PlayerContext);

  const [search, setSearch, searchHistory] = useStateWithHistory('');
  const [debouncedSearch, setDebouncedSearch] = React.useState('');

  const { paginationInfo, queryData, pageParams, setPaginationParams } =
    usePaginatedQuery({
      fetch: (params) =>
        fetchUserNFTs(params, {
          impersonateUser: ownerAddress,
          searchQuery: debouncedSearch,
          contractAddress: collectionAddress,
          includeCollection: true,
        }),
      queryKey: `playlists-${ownerAddress}?search=${debouncedSearch}`,
      defaultPageSize: PAGE_SIZES[0],
    });

  useDebounce(
    () => {
      if (search) {
        trackSearch(search);
      }
      setDebouncedSearch(search);
      setPaginationParams({ ...pageParams, page: 1 });
    },
    500,
    [search]
  );

  useEffect(() => {
    onPaginationChanges?.(paginationInfo);
  }, [paginationInfo, onPaginationChanges]);

  useEffect(() => {
    window?.scrollTo(0, 0);
  }, [paginationInfo?.currentPage]);

  useEffect(() => {
    playerContext?.resetList();
  }, [queryData.data]);

  const renderPagination = () => (
    <Pagination
      pageParams={pageParams}
      setPaginationParams={setPaginationParams}
      pageSizes={PAGE_SIZES}
      paginationInfo={paginationInfo}
      fixed={fixed}
      hideSingle={hideSinglePager}
    />
  );

  return (
    <Box className={styles.Wrapper}>
      {!queryData?.data?.length &&
      !search.trim() &&
      searchHistory.position === 0 ? null : (
        <InputGroup size={'lg'} mb={'32px'}>
          <InputLeftElement pointerEvents="none">
            <Icon icon={'search'} opacity={0.4} />
          </InputLeftElement>
          <Input
            placeholder={'Search'}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </InputGroup>
      )}
      {queryData.isLoading || queryData.isFetching ? (
        <Box pb="75px">
          <SimpleGrid columns={columns} spacing="16px">
            {Array(value)
              .fill(null)
              .map((_, i) => (
                <NftCardSkeleton key={i} />
              ))}
          </SimpleGrid>
          {renderPagination()}
        </Box>
      ) : (
        queryData.isFetched && (
          <>
            {queryData.isError ? (
              <>
                <Center className={cn(styles.ErrorWrapper)}>
                  <Box textAlign={'center'}>
                    <Icon
                      icon={'exclamationMark'}
                      className={cn(styles.ErrorIcon)}
                    />
                    <Heading fontSize={'24px'}>
                      Something went wrong, please continue to the next page.
                    </Heading>
                  </Box>
                </Center>
                {renderPagination()}
              </>
            ) : (
              <>
                {queryData.data?.length ? (
                  <>
                    <SimpleGrid columns={columns} spacing="16px">
                      {queryData.data.map((nft, index: number) => {
                        const selectedIndex = getSelectedIndex(nft);
                        const isSelected = selectedIndex !== -1;

                        return (
                          <NftCard
                            key={index}
                            nft={nft}
                            selectable={selectable}
                            isSelected={isSelected}
                            selectedLabel={
                              configs.featuresFlags.enableProPlanSubscription
                                ? isSelected
                                  ? `${selectedIndex + 1} / ${maxSelectedItems}`
                                  : undefined
                                : `${selectedIndex + 1}`
                            }
                            onSelect={onSelect}
                            onDeselect={onDeselect}
                            {...nftCardProps}
                          />
                        );
                      })}
                    </SimpleGrid>
                    {renderPagination()}
                  </>
                ) : (
                  <NFTsNotFound
                    minH={`calc(100vh - ${styles.headerHeight} - 64px - ${containerOffset}px - 32px - 48px - 32px - 80px)`}
                  />
                )}
              </>
            )}
          </>
        )
      )}
    </Box>
  );
};
