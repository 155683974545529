import { useRouter } from 'next/router';
import { createContext, useEffect, useState } from 'react';

export const SelectedNftsContext = createContext(null);

export const SelectedNftsProvider = ({ children }) => {

  const [maxSelectedAssets] = useState(3);
  const [assets, setAssets] = useState([]);

  const value = {
    maxSelectedAssets,
    assets,
    setAssets,
  };

  const router = useRouter();

  useEffect(() => {
    if (assets.length > 0) {
      const handleRouteChange = () => {
        // clear state when route is changed
        setAssets([]);
      };

      router.events.on('routeChangeComplete', handleRouteChange);

      // If the component is unmounted, unsubscribe
      // from the event with the `off` method:
      return () => {
        router.events.off('routeChangeComplete', handleRouteChange);
      };
    }
  }, [router]);

  return (
    <SelectedNftsContext.Provider value={value}>
      {children}
    </SelectedNftsContext.Provider>
  );
};
