import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useMemo } from 'react';
import querystring from 'query-string';

import { TOKENS_MAP } from '@/constants';
import { IToken, TokenTicker } from '@/types';

export function useTokensUSDPrice(tokens: Array<TokenTicker | undefined>, defaultValue: number | undefined = 0) {
  const { data } = useQuery<Record<TokenTicker, number | undefined>>({
    retry: false,
    queryKey: ['tokens-usd-price'],
    queryFn: async () => {
      const tokens = Object.keys(TokenTicker).reduce<IToken[]>((acc, ticker: TokenTicker) => {
        if (!!TOKENS_MAP?.[ticker]?.address) {
          acc.push(TOKENS_MAP?.[ticker]);
        }
        return acc;
      }, []);

      const tokensCoinGeckoIds = tokens.map((token) => token.coinGeckoId);

      const url = 'https://api.coingecko.com/api/v3/simple/price';

      const queryParams = querystring.stringify({
        ids: Array.from(new Set(tokensCoinGeckoIds)).join(','),
        vs_currencies: 'usd',
      });

      const { data } = await axios.get(`${url}?${queryParams}`);

      return tokens.reduce((acc, token) => {
        acc[token.ticker] = data[token.coinGeckoId]?.usd;
        return acc;
      }, {} as Record<TokenTicker, number>);
    },
  });

  return useMemo(() => {
    return tokens.map((token) => data?.[token] ?? defaultValue);
  }, [data, tokens, defaultValue]);
}