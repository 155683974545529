import Block from 'react-blockies';
import { Box } from '@chakra-ui/react';

import { useAuth } from '@/hooks';

interface BlockiesProps {
  seed: string;
  size: number;
  scale?: number;
}

const SCALE = 4;

export const Blockies = (props: BlockiesProps) => {
  const { seed = '', size } = props;

  const { avatar } = useAuth();

  return avatar ? (
    <Box boxSize={`${size * SCALE}px`} overflow={'hidden'}>
      <img src={avatar} alt=""/>
    </Box>
    ) : (
    !!seed ? <Block seed={seed} size={size} scale={SCALE} /> : null
  );
};
