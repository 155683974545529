import { mode } from '@chakra-ui/theme-tools';

export const Dropdown = {
  // The styles all Cards have in common
  parts: ['wrapper', 'arrow', 'popover', 'content'],

  baseStyle: (props) => ({
    popover: {},

    wrapper: {
      position: 'relative',
    },

    content: {
      width: 'auto',
      minWidth: '100%',
      borderRadius: '10px',
      border: '1px solid',
      borderColor: mode('blackTransparent.10', 'whiteTransparent.10')(props),
      backgroundColor: mode('white', 'dark')(props),
      boxShadow: '0px 0px 25px rgba(61, 30, 98, 0.15)',
    },

    arrow: {
      marginLeft: '8px',

      svg: {
        transition: 'transform 200ms linear',
      },

      path: {
        fill: mode('dark', 'white')(props),
      },
    },
  }),
  sizes: {
    md: {
      trigger: {
        height: '40px',
        paddingLeft: '14px',
        paddingRight: '14px',
      },

      arrow: {
        width: '16px',
        height: '16px',
      },
    },

    lg: {
      trigger: {
        height: '48px',
        paddingLeft: '16px',
        paddingRight: '16px',
      },
    },
  },
  // Two variants: rounded and smooth
  variants: {},
  // The default variant value
  defaultProps: {
    variant: 'ghost',
    size: 'md',
  },
};
