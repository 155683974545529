import { Box, BoxProps, HStack } from '@chakra-ui/react';
import { Text } from '@chakra-ui/layout';
import cn from 'classnames';
import React from 'react';

import { useClipboard } from '@/hooks';
import { Tooltip } from '@/components/ui/tooltip';
import { Button } from '@/components/ui/button';
import { Icon } from '@/components/ui';

import s from './nft-details.module.sass';

export const NFTDetails: React.FC<BoxProps> = (props) => {
  const { className, ...rest } = props;
  return (
    <Box className={cn(s.Wrapper, className)} {...rest} />
  );
};

export interface INFTDetailsItemProps {
  label: string;
  value: string | number;
  copyData?: string;
}

export const NFTDetailsItem: React.FC<INFTDetailsItemProps> = (props) => {
  const { label, value, copyData } = props;

  const { hasCopied, handleCopy } = useClipboard();

  return (
    <Box>
      <Text className={s.Label}>{label}</Text>
      <HStack spacing={'4px'}>
        <Text className={s.Value}>{value}</Text>
        {!!copyData && (
          <Tooltip
            hasArrow
            placement={'top'}
            label={hasCopied ? 'Copied' : 'Copy'}
            fontSize={'12px'}
          >
            <Button className={s.CopyButton} onClick={() => handleCopy(copyData)}>
              <Icon icon={'copySmall'} boxSize={'12px'} />
            </Button>
          </Tooltip>
        )}
      </HStack>
    </Box>
  );
};