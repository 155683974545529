import { IAlchemyNft, NetworkID, PaginatedData, PaginationQueryParams } from '@/types';
import { DisplayAPI } from '@/utils/axios';

import { transformAlchemyToUniverseNftData } from '@/utils/transformAlchemyToUniverseData';
import { NETWORK_CHAIN_ID } from '@/constants';

const NETWORK: Record<NetworkID, string> = {
  1: 'mainnet',
  5: 'goerli',
};

interface IFetchUserNFTsQuery {
  impersonateUser: string;
  searchQuery?: string;
  contractAddress?: string;
  includeCollection?: boolean;
}

export const fetchUserNFTs = async (pagination: PaginationQueryParams, query: IFetchUserNFTsQuery) => {
  const { data } = await DisplayAPI.get<PaginatedData<IAlchemyNft>>('/nfts', {
    params: {
      page: pagination.page,
      size: pagination.size,
      network: NETWORK[NETWORK_CHAIN_ID],
      ...query,
    },
  });

  return {
    ...data,
    data: data.data.map(transformAlchemyToUniverseNftData)
  };
}