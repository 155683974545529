import { ButtonProps } from '@chakra-ui/button';
import { MenuProps } from '@chakra-ui/menu';
import { Menu, MenuButton, MenuItem, MenuList, Portal } from '@chakra-ui/react';
import React from 'react';

import { Button, Icon } from '@/components/ui';

export interface IPlaylistCardMenuProps extends Omit<MenuProps, 'children'> {
  buttonProps?: ButtonProps;
  showEdit?: boolean;
  showDelete?: boolean;
  onEdit?: () => void;
  onDelete?: () => void;
}

export const PlaylistCardMenu: React.FC<IPlaylistCardMenuProps> = (props) => {
  const {
    buttonProps = {},
    showEdit = true,
    showDelete = true,
    onEdit,
    onDelete,
    ...rest
  } = props;

  return (
    <Menu placement={'bottom-end'} {...rest}>
      <MenuButton as={Button} {...buttonProps}>
        <Icon icon="dots" />
      </MenuButton>
      <Portal>
        <MenuList minW={'128px'} zIndex={100}>
          {showEdit && <MenuItem onClick={onEdit}>Edit</MenuItem>}
          {showDelete && (
            <MenuItem onClick={onDelete} color={'red'}>
              Delete
            </MenuItem>
          )}
        </MenuList>
      </Portal>
    </Menu>
  );
};
