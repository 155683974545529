import { Box, BoxProps } from '@chakra-ui/react';

export const Content = (props: BoxProps) => {
  //
  const { children, ...rest } = props;

  return (
    <Box py={'32px'} px={[0, null, '32px']} {...rest}>
      {children}
    </Box>
  );
};
