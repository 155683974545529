import { PaginationInfo, PaginationQueryParams } from '@/types';
import { Box, Flex } from '@chakra-ui/react';
import { Pages } from './pages';
import { SizeSelector } from './size-selector';

interface IPaginatedControlsProps {
  onParamsChange: (page: Partial<PaginationQueryParams>) => void;
  pageParams: PaginationQueryParams;
  pagination?: PaginationInfo;
  pageSizes: number[];
}

export const PaginatedControls: React.FC<IPaginatedControlsProps> = (props) => {
  const {
    pageSizes,
    pageParams,
    pagination,
    onParamsChange,
  } = props;

  const current = pagination?.currentPage ?? 1;
  const total = pagination?.lastPage ?? 1;

  return (
    <Flex
      alignItems="center"
      justifyContent={['center', null, 'space-between']}
      height="100%"
    >
      <Pages
        current={current}
        onChange={(page) => onParamsChange({ page })}
        total={total}
      />
      <Box display={['none', null, 'block']}>
        <SizeSelector
          current={pageParams.size}
          onSizeChange={(size) => onParamsChange({ size, page: 1 })}
          pageSizes={pageSizes}
        />
      </Box>
    </Flex>
  );
}
