import { useMutation } from '@tanstack/react-query';
import { useWeb3React } from '@web3-react/core';
import { utils } from 'ethers';

export const useSignMessage = () => {
  const { account, provider } = useWeb3React();

  type IMutatePayload = {
    message: string;
  };

  const { mutateAsync: signMessage, ...rest } = useMutation(
    async (payload: IMutatePayload) => {
      const data = utils.toUtf8Bytes(payload.message);
      const hexiflied = utils.hexlify(data);

      return await provider.send('personal_sign', [
        hexiflied,
        account.toLowerCase(),
      ]);
    }
  );

  return { signMessage, ...rest };
};
