import axios from 'axios';
import { utils } from 'ethers';
import BigNumber from 'bignumber.js';
import querystring from 'query-string';

import { IOrder, IToken, NFT } from '@/types';

import { prepareReservoirOrderSignature, convertReservoirToUniverseOrder } from './reservoir-utils';
import { ReservoirClient } from '@/api/reservoir/reservoir.client';
import { configs } from '@/configs';

export const SALE_SPLIT_MAX_BPS = 1000;
export const SALE_SPLIT_MAX_PERCENT = SALE_SPLIT_MAX_BPS / 10;

export const ORDERBOOK = {
  'OpenSea': 'opensea',
  'LooksRare': 'looks-rare',
  'Reservoir': 'reservoir',
  'X2Y2':  'x2y2',
  'Universe': 'universe',
}

export const ORDERKIND = {
  'LooksRare': 'looks-rare',
  'Ox':  'zeroex-v4',
  'OpenSea': 'seaport',
  'X2Y2': 'x2y2',
  'Universe': 'universe',
}

export const ORDERSOURCE = {
  'Universe': 'universe.xyz'
}

const API = axios.create({
  baseURL: configs.reservoirApiUrl,
});

API.interceptors.request.use(
  (config) => {
    config.headers['x-api-key'] = `${configs.reservoirApiKey}`;
    return config;
  },
  (error) => Promise.reject(error)
);

interface IGetTokenActivityArgs {
  collectionAddress: string;
  tokenId: string | number;
  limit?: number;
  nextCursor?: string;
}

export type IReservoirActivityType = 'sale' | 'ask' | 'transfer' | 'mint' | 'bid' | 'bid_cancel' | 'ask_cancel';

interface IReservoirActivity {
  type: IReservoirActivityType;
  fromAddress: string;
  toAddress: string;
  price: number;
  amount: number;
  timestamp: number;
  createdAt: string;
  contract: string;
  token: {
    tokenId: string;
    tokenName: string;
    tokenImage: string;
  },
  collection: {
    collectionId: string;
    collectionImage: string;
    collectionName: string;
  },
  txHash: string;
  logIndex: number;
  batchIndex: number;
}

export class ReservoirAPI {

  static async createListing(
    signer: any,
    makerAddress: string,
    NFT: {
      contractAddress: string;
      tokenId: string | number;
    },
    price: string,
    token: IToken,
    saleSplits: [[string, string]] | [],
    startTime: number,
    expirationTime: number,
    amount = 1,
  ) {
    const totalPrice = new BigNumber(price).multipliedBy(amount).toString();
    const paymentAmount = utils.parseUnits(totalPrice, token?.decimals).toString();
    const { revenueSplits, splitSum } = ReservoirAPI.prepareRoyalties(saleSplits);

    if (splitSum > SALE_SPLIT_MAX_BPS) {
      throw new Error(`Sale splits should be less than ${SALE_SPLIT_MAX_PERCENT}%`);
    }

    const result = await ReservoirClient?.actions.listToken({
      listings: [{
        token: `${NFT.contractAddress}:${NFT.tokenId}`,
        weiPrice: `${paymentAmount}`,
        orderbook: ORDERBOOK.Universe as any,
        orderKind: ORDERKIND.Universe as any,
        expirationTime: expirationTime.toString(),
        currency: token.address,

        automatedRoyalties: true,
        fees: revenueSplits,
        quantity: amount,
      }],
      signer,
    });

    return result;

    // const body = {
    //   params: [
    //     {
    //       orderKind: ORDERKIND.Universe,
    //       orderbook: ORDERBOOK.Universe,
    //       automatedRoyalties: true,
    //       fees: revenueSplits,
    //       currency: token.address,
    //       listingTime: startTime.toFixed(),
    //       expirationTime: expirationTime.toString(),
    //       token: `${NFT.contractAddress}:${NFT.tokenId}`,
    //       weiPrice: paymentAmount,
    //       quantity: amount
    //     }
    //   ],
    //   maker: makerAddress,
    //   source: ORDERSOURCE.Universe
    // }
    // const { data } = await API.post('/execute/list/v4', body);
    //
    // const { signature, post} = await prepareReservoirOrderSignature('Authorize listing', data, signer)
    // const order = await ReservoirAPI.postReservoirOrder(signature, post)
    //
    // return order;
  }


  static async postReservoirOrder(signature: string, postData: any) {
    const url = `${postData.endpoint}?signature=${signature}`

    const { data } = await API.post(
      url,
      JSON.stringify(postData.body),
      {
        method: postData.method,
        headers: {
          'Content-Type': 'application/json',
        }
      }
    );

    return data
  }

  static prepareRoyalties(saleSplits: [[string, string]] | []) {
    let splitSum = 0;
    const revenueSplits: string[] = [];

    saleSplits.forEach((split) => {
      const splitVal = Number(split[1]);
      if (!isNaN(splitVal)) {
        const value = Number((splitVal * 100).toFixed(0));
        const account = split[0];
        revenueSplits.push(`${account}:${value}`);
        splitSum += splitVal;
      }
    });

    return { revenueSplits, splitSum };
  }

  static async getListingsReservoir(address: string, tokenId?: string | number): Promise<IOrder[]> {
    const url = !tokenId
      ? `/orders/asks/v3?&contracts=${address}&includeRawData=true&status=active&sortBy=price&limit=1000`
      : `/orders/asks/v3?&token=${address}%3A${tokenId}&includeRawData=true&status=active&sortBy=price&limit=1000`;

    const { data } = await API.get<{ orders: IOrder[] }>(url);
    const { orders } = data;

    if (!orders.length) {
      return [];
    } else {
      return orders.map((order) => convertReservoirToUniverseOrder(order));
    }
  }

  static async prepareReservoirCancelOfferData(orderId: string, maker: string) {
    const { data } = await API.get(`/execute/cancel/v2?id=${orderId}&maker=${maker}`);
    return data?.steps?.[0]?.items?.[0]?.data;
  }

  static async prepareReservoirBuyOrderData(
    walletAddress: string,
    hash: string,
    listingAmount: string,
    currency: string,
    tokenType: string,
    partial: boolean
  ) {
    const response = await API.post(
      '/execute/buy/v4',
      {
        orderIds: [hash], // to checkout more than 1 edition we can set array of [...Array(parseInt(listingAmount))].map((_, i) => hash)
        onlyPath: false,
        partial,
        skipBalanceCheck: false,
        taker: walletAddress,
        ...(tokenType.toLowerCase() === "erc1155" && { quantity: listingAmount }),
        currency,
      }
    );

    // response.data.steps[1].items[0].data ==> { data, from, to, value }
    return response.data.steps[1].items[0].data;
  }

  static async getTokenActivity(args: IGetTokenActivityArgs) {
    const { collectionAddress, tokenId } = args;

    type IResponse = {
      activities: IReservoirActivity[];
      continuation?: string;
    };

    const { data } = await API.get<IResponse>(`/tokens/${collectionAddress}:${tokenId}/activity/v4?${querystring.stringify({
      limit: args.limit ?? 10,
      continuation: args.nextCursor,
    })}`);

    return data;
  }

}
