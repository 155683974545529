import { SelectedNftsContext } from '@/context';
import { useCallback, useContext } from 'react';

export const useSelectedNfts = () => {
  const context = useContext(SelectedNftsContext);

  if (context === undefined) {
    throw new Error(
      'useSelectedNfts must be used within a SelectedNftsProvider'
    );
  }

  const { maxSelectedAssets, assets, setAssets } = context;

  const onSelect = useCallback((value) => {
    setAssets((assets) => [
      ...assets,
      {
        id: value.id,
        url: value.asset.url,
        previewUrl: value.asset.preview,
      },
    ]);
  }, []);

  const onDeselect = useCallback((value) => {
    setAssets((assets) => assets.filter((asset) => asset.id !== value.id));
  }, []);

  return {
    maxSelectedAssets,
    assets,
    setAssets,
    onSelect: onSelect,
    onDeselect: onDeselect,
  };
};
