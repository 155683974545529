import React, { useCallback, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';

import { Mute, Play, ProgressBar, usePlayerState } from '@/components/common';
import { Box, Flex } from '@chakra-ui/react';

import { useNftCardContext } from '@/components/common/nft-card/context';

import { NftImageLoading } from '../../../nft-image-loading';
import { NftAssetImage } from '../nft-asset-image';

import cn from 'classnames';
import styles from '../../nft-asset.module.sass';

type Asset = {
  url: string;
  type?: string;
};

type ImageLoadingType = 'skeleton' | 'spinner';
interface Props {
  asset: Asset;
  preview?: string;
  alt?: string;
  imageLoadingType?: ImageLoadingType;
  isShowProgressBar?: boolean;
  onError?: () => void;
}

export const NftAssetAudio = (props: Props) => {
  //
  const {
    asset,
    preview,
    alt,
    onError,
    imageLoadingType,
    isShowProgressBar = false,
  } = props;

  const player = useRef(null);

  const {
    buffer,
    playing,
    played,
    seeking,
    paused,
    muted,
    loaded,
    togglePlaying,
    toggleSound,
    onEnded,
    onLoaded,
    onBuffer,
    onBufferEnd,
    onPlayedChange,
    onSeekingStart,
    onSeekingEnd,
    onClickPreview,
  } = usePlayerState({ player });

  const { setIsPlaying } = useNftCardContext();

  const handleTogglePlaying = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      togglePlaying();
    },
    [togglePlaying]
  );

  const handleToggleSound = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      toggleSound();
    },
    [toggleSound]
  );

  const handleClickPreview = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      onClickPreview();
    },
    [onClickPreview]
  );

  useEffect(() => {
    //
    setIsPlaying(playing);
  }, [playing]);

  return (
    <>
      <Box className={styles.Wrapper}>
        {buffer && <NftImageLoading />}

        {loaded ? (
          <Flex
            className={cn(
              styles.Controls,
              loaded && styles.isLoaded,
              playing && styles.isPlaying
              //paused && styles.isPaused
            )}
            align="center"
            justify="space-between"
            data-class="controls"
            gap="8px"
          >
            <Play
              playing={playing}
              onClick={handleTogglePlaying}
              isLightMode={true}
            />

            {isShowProgressBar && (
              <ProgressBar
                played={played}
                seeking={seeking}
                onSeekingStart={onSeekingStart}
                onSeekingEnd={onSeekingEnd}
                isLightMode={true}
                isWrap={true}
              />
            )}

            <Mute
              muted={muted}
              onClick={handleToggleSound}
              isLightMode={true}
            />
          </Flex>
        ) : (
          <Box className={styles.Play} data-class="play">
            <Play
              playing={playing}
              onClick={handleClickPreview}
              isLightMode={true}
            />
          </Box>
        )}

        <NftAssetImage
          alt={alt}
          asset={{ url: preview }}
          imageLoadingType={imageLoadingType}
        />

        <Box className={styles.Player}>
          <ReactPlayer
            ref={player}
            url={asset.url}
            width="100%"
            height="100%"
            muted={muted}
            playing={playing}
            loop={true}
            onProgress={onPlayedChange}
            onReady={onLoaded}
            onEnded={onEnded}
            onError={onError}
            onBuffer={onBuffer}
            onBufferEnd={onBufferEnd}
            light={preview}
            playIcon={<></>}
            style={{ width: '0px', height: '0px', display: 'none' }}
          />
        </Box>
      </Box>
    </>
  );
};
