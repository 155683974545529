import axios from 'axios';
import { configs } from '@/configs';

export const DisplayAPI = axios.create({
  baseURL: configs.NFTDisplayApiUrl,
});

DisplayAPI.interceptors.request.use(
  (config) => {
    const authWhiteList = ['/auth/challenge', '/auth/login'];

    if (!authWhiteList.includes(config.url)) {
      config.headers.Authorization = `Bearer ${JSON.parse(
        localStorage.getItem('graviton_access_token')
      )}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

// NFT Torrent

export const TorrentAPI = axios.create({
  baseURL: configs.NFTTorrentApiUrl,
});

TorrentAPI.interceptors.request.use(
  (config) => {
    //
    const authWhiteList = ['/auth/challenge', '/auth/login'];

    if (!authWhiteList.includes(config.url)) {
      config.headers.Authorization = `Bearer ${JSON.parse(
        localStorage.getItem('graviton_access_token')
      )}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

// Reservoir

export const ReservoirAPI = axios.create({
  baseURL: configs.reservoirApiUrl,
});

ReservoirAPI.interceptors.request.use(
  (config) => {
    //
    config.headers['x-api-key'] = configs.reservoirApiKey;

    return config;
  },
  (error) => Promise.reject(error)
);
