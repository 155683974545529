import { Box, HStack, VStack } from '@chakra-ui/react';
import Image from 'next/image';
import { useState } from 'react';

import baby from './images/baby.png';
import rocket from './images/rocket.png';

import styles from './user-plan.module.sass';

export const UserPlan = () => {
  //
  const [isProPlan, setIsProPlan] = useState(false);

  return (
    <>
      <Box className={styles.Wrapper}>
        <HStack spacing="10px">
          <Image
            src={isProPlan ? rocket : baby}
            width={20}
            height={20}
            alt="User Plan Image"
            quality={100}
          />
          <VStack align="flex-start" spacing={0}>
            <Box className={styles.Text}>
              {isProPlan ? 'Pro plan activated' : 'Free plan activated'}{' '}
            </Box>

            {isProPlan ? (
              <HStack spacing="6px">
                <Box className={styles.Date}>23.03.2023</Box>
                <Box className={styles.DaysLeft}>300 days left</Box>
              </HStack>
            ) : (
              <Box
                as="a"
                onClick={() => setIsProPlan(true)}
                className={styles.Link}
              >
                Get Pro plan now
              </Box>
            )}
          </VStack>
        </HStack>
      </Box>
    </>
  );
};
