import { ComponentStyleConfig } from '@chakra-ui/react';

export const Text: ComponentStyleConfig = {
  variants: {
    bold: {
      fontWeight: 700,
    },
    'semi-bold': {
      fontWeight: 600,
    },
    medium: {
      fontWeight: 500,
    },
    regular: {
      fontWeight: 300,
    }
  },
  defaultProps:{
  },
};