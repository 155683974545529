import { Icon, IconProps } from '@chakra-ui/react';

export const PlusIcon = (props: IconProps) => {
  //
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="12px"
      height="12px"
      viewBox="0 0 12 12"
      fill="none"
      display="block"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.75 -3.57628e-07C6.16421 -3.57628e-07 6.5 0.335786 6.5 0.75L6.5 4.99995L10.75 4.99995C11.1642 4.99995 11.5 5.33574 11.5 5.74995C11.5 6.16417 11.1642 6.49995 10.75 6.49995L6.5 6.49995V10.75C6.5 11.1642 6.16421 11.5 5.75 11.5C5.33579 11.5 5 11.1642 5 10.75V6.49995L0.75 6.49995C0.335786 6.49995 -5.96046e-08 6.16416 0 5.74995C5.96046e-08 5.33574 0.335787 4.99995 0.75 4.99995L5 4.99995L5 0.75C5 0.335786 5.33579 -3.57628e-07 5.75 -3.57628e-07Z"
        fill="black"
      />
    </Icon>
  );
};
