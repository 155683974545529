import { useEffect, useState } from 'react';
import { useFirstMountState } from 'react-use';

import { Icon, IconType } from '@/components/ui';
import { Box, HStack } from '@chakra-ui/react';

import cn from 'classnames';
import styles from './activity-filters.module.sass';

type TransactionType = 'sale' | 'list' | 'delist' | 'transfer' | 'mint';
type ReservoirValue = 'sale' | 'ask' | 'ask_cancel' | 'transfer' | 'mint';

type Value = {
  key: TransactionType;
  label: string;
  icon: IconType;
  value: ReservoirValue;
};

type ValueType = {
  [key in TransactionType]: Value;
};

interface Props {
  //
  filters?: TransactionType[];
  onChange?: (filters: TransactionType[]) => void;
}

const values: ValueType = {
  mint: {
    key: 'mint',
    value: 'mint',
    label: 'Mint',
    icon: { name: 'transaction-mint' },
  },
  sale: {
    key: 'sale',
    value: 'sale',
    label: 'Sale',
    icon: { name: 'transaction-sale' },
  },
  transfer: {
    key: 'transfer',
    value: 'transfer',
    label: 'Transfer',
    icon: { name: 'transaction-transfer' },
  },
  list: {
    key: 'list',
    value: 'ask',
    label: 'List',
    icon: { name: 'transaction-list' },
  },
  delist: {
    key: 'delist',
    value: 'ask_cancel',
    label: 'Delist',
    icon: { name: 'transaction-delist' },
  },
};

const defaultFilters: TransactionType[] = [
  'sale',
  'list',
  'delist',
  'transfer',
  'mint',
];

export const ActivityFilers = (props: Props) => {
  //
  const { filters = defaultFilters, onChange } = props;

  const isFirstMount = useFirstMountState();

  const [renderItems, setRenderItems] = useState([]);
  const [activeFilers, setActiveFilters] = useState([]);

  useEffect(() => {
    getRenderItems();
  }, []);

  useEffect(() => {
    //
    if (!isFirstMount) {
      onInternalChange(activeFilers);
    }
  }, [activeFilers]);

  const getRenderItems = (): void => {
    //
    const items = [];

    Object.entries(values).forEach(([key, value]: [keyof ValueType, Value]) => {
      if (filters.includes(key)) {
        items.push(value);
      }
    });

    setRenderItems(items);
  };

  const toggleFilter = (item: Value) => {
    //
    const index = activeFilers.indexOf(item.value);

    setActiveFilters((prev) => {
      if (index !== -1) {
        prev.splice(index, 1);
        return [...prev];
      } else {
        return [...prev, item.value];
      }
    });
  };

  const onInternalChange = (values) => {
    //
    onChange && onChange(values);
  };

  return (
    <HStack spacing="8px" className={styles.Wrapper}>
      {renderItems.map((item, index) => {
        //
        return (
          <Box
            className={cn(
              styles.Button,
              activeFilers.includes(item.value) && styles['Button--Active']
            )}
            key={index}
            onClick={() => toggleFilter(item)}
          >
            <HStack spacing="8px">
              <Box>
                <Icon icon={item.icon} size="md" />
              </Box>
              <Box>{item.label}</Box>
            </HStack>
          </Box>
        );
      })}
    </HStack>
  );
};
