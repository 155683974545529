import { useMutation } from '@tanstack/react-query';

import type {
  ICollection,
  ICurrency,
  ISubscriptionOrder,
  ISubscriptionPlan,
  PaginatedData,
  PaginationQueryParams,
  Playlist,
} from '@/types';
import { DisplayAPI } from '@/utils/axios';

export type IGetPlaylistsParamsSortBy = keyof Pick<
  Playlist,
  '_id' | 'title' | 'createdAt' | 'updatedAt'
>;

export interface IGetPlaylistsParams extends PaginationQueryParams {
  query?: string;
  sort?: IGetPlaylistsParamsSortBy;
  sortOrder?: 1 | -1;
  limitAssets?: number;
}

export const getPlaylists = async (params: IGetPlaylistsParams) => {
  const { data } = await DisplayAPI.get<PaginatedData<Playlist>>('/playlists', {
    params,
  });
  return data;
};

export const getAllPlaylists = async () => {
  const perPage = 100;

  const response = await getPlaylists({
    page: 1,
    size: perPage,
  });

  const otherPages = new Array(response.pagination.lastPage - 1)
    .fill(null)
    .map((_, i) =>
      getPlaylists({
        page: i + 2,
        size: perPage,
      })
    );

  const otherPagesResponses = await Promise.all(otherPages);

  return [response, ...otherPagesResponses].reduce(
    (acc, { data }) => [...acc, ...data],
    []
  );
};

export const getPlaylistById = async (id) => {
  const { data } = await DisplayAPI.get(`/playlists/${id}`);
  return data;
};

export const createPlaylist = async (
  data: Omit<
    Playlist,
    '_id' | 'displayCode' | 'owner' | 'createdAt' | 'updatedAt'
  >
) => {
  const { data: response } = await DisplayAPI.post(`/playlists`, data);
  return response;
};

export const updatePlaylist = async (variables: {
  id: string;
  data: Playlist;
}) => {
  const { id, data } = variables;
  const { data: response } = await DisplayAPI.put(`/playlists/${id}`, data);
  return response;
};

export const batchUpdatePlaylists = async (playlists: Playlist[]) => {
  const requests = playlists.map((playlist) =>
    updatePlaylist({
      id: playlist._id,
      data: playlist,
    })
  );

  return await Promise.all(requests);
};

export const deletePlaylist = async (id: string) => {
  const { data } = await DisplayAPI.delete(`/playlists/${id}`);
  return data;
};

export const patchPlaylist = async (playlistId: string, playlist: Playlist) => {
  const { data } = await DisplayAPI.patch(`/playlists/${playlistId}`, playlist);
  return data;
};

export const getSubscriptionPlans = async () => {
  const { data } = await DisplayAPI.get<ISubscriptionPlan>(
    `/subscriptions/plans`
  );
  return data;
};

export const getSubscriptionCurrencies = async () => {
  const { data } = await DisplayAPI.get<ICurrency[]>(
    `/subscriptions/currencies`
  );
  return data;
};

export const getSubscriptionPlanEstimate = async (
  id: string,
  currency: string
) => {
  const { data } = await DisplayAPI.get(
    `/subscriptions/plans/${id}/estimate?currencyCode=${currency}`
  );
  return data;
};

export const createSubscriptionOrder = async (
  planId: string,
  currencyCode: string
) => {
  const { data } = await DisplayAPI.post<ISubscriptionOrder>(
    `/subscriptions/orders`,
    { planId, currencyCode }
  );
  return data;
};

export const getSubscriptionOrderEstimate = async (id: string) => {
  const { data } = await DisplayAPI.get<ISubscriptionOrder>(
    `/subscriptions/orders/${id}/estimate`
  );
  return data;
};

export const getSubscriptionOrder = async (id: string) => {
  const { data } = await DisplayAPI.get<ISubscriptionOrder>(
    `/subscriptions/orders/${id}`
  );
  return data;
};

interface IGetCollectionsQuery {
  searchQuery?: string;
  impersonateUser?: string;
}

export const getCollections = async (
  pagination: PaginationQueryParams,
  query: IGetCollectionsQuery
) => {
  const { data } = await DisplayAPI.get<PaginatedData<ICollection>>(
    '/collections',
    {
      params: {
        ...query,
        ...pagination,
      },
    }
  );
  return data;
};

export const getCollectionByAddress = async (address: string) => {
  const { data } = await DisplayAPI.get<ICollection>(`/collections/${address}`);
  return data;
};

export const useDeletePlaylist = () => {
  return useMutation((id: string) => deletePlaylist(id));
};

export const useCreatePlaylist = () => {
  //

  return useMutation(
    (
      playlist: Omit<
        Playlist,
        '_id' | 'displayCode' | 'owner' | 'createdAt' | 'updatedAt'
      >
    ) => createPlaylist(playlist)
  );
};

export const useUpdatePlaylist = () => {
  //

  return useMutation((variables: { id: string; data: Playlist }) =>
    updatePlaylist(variables)
  );
};
