import { AspectRatio, Box, Text } from '@chakra-ui/react';

import { Icon } from '@/components/ui';

import styles from './playlist-card-asset-error.module.sass';

export const PlaylistCardAssetError = () => {
  //
  return (
    <AspectRatio ratio={1}>
      <Box className={styles.Wrapper}>
        <Icon icon="cogs" className={styles.Icon} />
        <Text className={styles.Text}>
          Visual cannot be loaded.
          <br />
          Check back later.
        </Text>
      </Box>
    </AspectRatio>
  );
};
