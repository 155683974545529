import { init, track as baseTrack } from '@amplitude/analytics-browser';

import { ConnectorName } from '@/components/modules/home';
import { configs } from '@/configs';
import { detectBrowser, getMobileOperatingSystem } from '@/utils/system';
import { Playlist } from '@/types';
import { DELAY_TYPES } from '@/modules/display/constants';

interface IPlaylist extends Playlist {
  fadeType: string;
}

export type IStakeMethod = 'ENOUGH_TO_VALIDATE' | 'MAX' | 'MANUAL';

enum Events {
  INITIATE_CONNECT_WALLET = 'INITIATE_CONNECT_WALLET',
  CONNECT_WALLET = 'CONNECT_WALLET',
  SEARCH = 'SEARCH',
  INITIATE_CREATE_PLAYLIST = 'INITIATE_CREATE_PLAYLIST',
  CREATE_PLAYLIST = 'CREATE_PLAYLIST',
  DELETE_PLAYLIST = 'DELETE_PLAYLIST',
  VIEW_PLAYLIST = 'VIEW_PLAYLIST',
  INITIATE_EDIT_PLAYLIST = 'INITIATE_EDIT_PLAYLIST',
  EDIT_PLAYLIST = 'EDIT_PLAYLIST',
  TOGGLE_THEME = 'TOGGLE_THEME',
  INITIATE_GET_MOBILE_APP = 'INITIATE_GET_MOBILE_APP',
  PAGE_VIEW = 'PAGE_VIEW',
  INITIATE_CREATE_TNFT_SINGLE = 'INITIATE_CREATE_TNFT_SINGLE',
  INITIATE_CREATE_TNFT_COLLECTION = 'INITIATE_CREATE_TNFT_COLLECTION',
  INITIATE_FILE_UPLOAD = 'INITIATE_FILE_UPLOAD',
  TOGGLE_TNFT_PROPERTIES = 'TOGGLE_TNFT_PROPERTIES',
  TOGGLE_TNFT_REVENUE_SPLITS = 'TOGGLE_TNFT_REVENUE_SPLITS',
  TOGGLE_TNFT_MINT_TO_OTHER_WALLET = 'TOGGLE_TNFT_MINT_TO_OTHER_WALLET',
  TOGGLE_TNFT_COLLECTION_REVENUE_SPLITS = 'TOGGLE_TNFT_COLLECTION_REVENUE_SPLITS',
  CREATE_TNFT_SINGLE = 'CREATE_TNFT_SINGLE',
  CREATE_TNFT_COLLECTION = 'CREATE_TNFT_COLLECTION',
  CANCEL_CREATE_TNFT_SINGLE = 'CANCEL_CREATE_TNFT_SINGLE',
  CANCEL_CREATE_TNFT_COLLECTION = 'CANCEL_CREATE_TNFT_COLLECTION',
  INITIATE_GENERATE_OTP = 'INITIATE_GENERATE_OTP',
  GENERATE_OTP_TRANSACTION_PROMPT = 'GENERATE_OTP_TRANSACTION_PROMPT',
  INITIATE_UNLINK_DEVICE = 'INITIATE_UNLINK_DEVICE',
  TORRENT_MODAL_VIEW = 'TORRENT_MODAL_VIEW',
  APPLY_BROWSE_FILTERS = 'APPLY_BROWSE_FILTERS',
  REWARDS_TAB = 'REWARDS_TAB',
  INITIATE_REWARDS_CLAIM = 'INITIATE_REWARDS_CLAIM',
  INITIATE_STAKE = 'INITIATE_STAKE',
  TOGGLE_ENABLE_TOKEN = 'TOGGLE_ENABLE_TOKEN',
  INITIATE_WITHDRAW = 'INITIATE_WITHDRAW',
  INITIATE_SWAP = 'INITIATE_SWAP',
}

init(configs.amplitudeApiKey);

export const track = (eventName: string, params = {}) => {
  baseTrack(eventName, {
    ...params,
    PLATFORM: getMobileOperatingSystem(),
    BROWSER: detectBrowser(),
  });
};

export const trackInitiateConnectWallet = () => {
  track(Events.INITIATE_CONNECT_WALLET);
};

export const trackConnectWallet = (connectorName: ConnectorName) => {
  track(Events.CONNECT_WALLET, {
    WalletType: connectorName,
  });
};

export const trackSearch = (query: string) => {
  track(Events.SEARCH, {
    QUERY: query,
  });
};

export const trackInitiateCreatePlaylist = () => {
  track(Events.INITIATE_CREATE_PLAYLIST);
};

export const trackCreatePlaylist = (playlist: IPlaylist) => {
  track(Events.CREATE_PLAYLIST, {
    PLAYLIST_NAME: playlist.title,
    NFT_NAME_VISIBLE: playlist.showPoweredBy,
    DELAY: playlist.delay,
    FADE_TYPE: playlist.fadeType,
    DELAY_TYPE: DELAY_TYPES.find(type => +type.value === +playlist.delayType),
    NFT_COUNT: playlist.assets.length,
    PLAYLIST_ID: playlist._id,
  });
};

export const trackDeletePlaylist = (playlistName: string) => {
  track(Events.DELETE_PLAYLIST, {
    PLAYLIST_NAME: playlistName,
  });
};

export const trackInitiateEditPlaylist = () => {
  track(Events.INITIATE_EDIT_PLAYLIST);
};

export const trackEditPlaylist = (playlist: IPlaylist) => {
  track(Events.EDIT_PLAYLIST, {
    PLAYLIST_NAME: playlist.title,
    DELAY: playlist.delay,
    FADE_TYPE: playlist.fadeType,
    DELAY_TYPE: DELAY_TYPES.find(type => +type.value === +playlist.delayType),
    NFT_COUNT: playlist.assets.length,
    PLAYLIST_ID: playlist._id,
  });
};

export const trackToggleTheme = (colorMode: 'dark' | 'light') => {
  track(Events.TOGGLE_THEME, {
    MODE: colorMode,
  });
};

export const trackInitiateGetMobileApp = (applicationName: string) => {
  track(Events.INITIATE_GET_MOBILE_APP, {
    APP: applicationName,
  });
};

export const trackPage = (page: string) => {
  track(Events.PAGE_VIEW, { TITLE: page });
};

export const trackInitiateCreateTnftSingle = () => {
  track(Events.INITIATE_CREATE_TNFT_SINGLE);
};

export const trackInitiateCreateTnftCollection = () => {
  track(Events.INITIATE_CREATE_TNFT_COLLECTION);
};

export const trackInitiateFileUpload = (type: 'TNFT_FILE' | 'TNFT_PREVIEW_IMAGE' | 'TNFT_COLLECTION_LOGO' | 'TNFT_COLLECTION_BANNER') => {
  track(Events.INITIATE_FILE_UPLOAD, {
    TYPE: type,
  });
};

export const trackToggleTnftProperties = (showProperties: boolean) => {
  track(Events.TOGGLE_TNFT_PROPERTIES, {
    MODE: showProperties ? 'ON' : 'OFF',
  });
};

export const trackToggleTnftRevenueSplits = (showRevenueSplits: 'ON' | 'OFF') => {
  track(Events.TOGGLE_TNFT_REVENUE_SPLITS, {
    MODE: showRevenueSplits,
  });
};

export const trackToggleTnftMintToOtherWallet = (showMintToOtherWallet: 'ON' | 'OFF') => {
  track(Events.TOGGLE_TNFT_MINT_TO_OTHER_WALLET, {
    MODE: showMintToOtherWallet,
  });
};

export const trackToggleTnftCollectionRevenueSplits = () => {
  track(Events.TOGGLE_TNFT_COLLECTION_REVENUE_SPLITS);
};

export const trackCreateTnftSingle = (success: boolean) => {
  track(Events.CREATE_TNFT_SINGLE, {
    SUCCESS: success,
  });
};

export const trackCreateTnftCollection = (success: boolean) => {
  track(Events.CREATE_TNFT_COLLECTION, {
    SUCCESS: success,
  });
};

export const trackCancelCreateTnftSingle = (decision: 'CONFIRM' | 'REJECT') => {
  track(Events.CANCEL_CREATE_TNFT_SINGLE, {
    DECISION: decision,
  });
};

export const trackCancelCreateTnftCollection = (decision: 'CONFIRM' | 'REJECT') => {
  track(Events.CANCEL_CREATE_TNFT_COLLECTION, {
    DECISION: decision,
  });
};

export const trackInitiateGenerateOtp = () => {
  track(Events.INITIATE_GENERATE_OTP);
};

export const trackGenerateOtpTransactionPrompt = (decision: 'CONFIRM' | 'REJECT') => {
  track(Events.GENERATE_OTP_TRANSACTION_PROMPT, {
    DECISION: decision,
  });
};

export const trackInitiateUnlinkDevice = () => {
  track(Events.INITIATE_UNLINK_DEVICE);
};

export const trackTorrentModalView = () => {
  track(Events.TORRENT_MODAL_VIEW);
};

export const trackApplyBrowseFilters = (filters: string) => {
  track(Events.APPLY_BROWSE_FILTERS, {
    FILTERS: filters,
  });
};

export const trackRewardsTab = (tab: 'CLAIM' | 'STAKE' | 'WITHDRAW' | 'SWAP') => {
  track(Events.REWARDS_TAB, {
    tab,
  });
};
export const trackInitiateRewardsClaim = () => {
  track(Events.INITIATE_REWARDS_CLAIM);
};
export const trackInitiateStake = (amount: string, method: IStakeMethod) => {
  track(Events.INITIATE_STAKE, {
    AMOUNT: amount,
    METHOD: method,
  });
};
export const trackToggleEnableToken = () => {
  track(Events.TOGGLE_ENABLE_TOKEN);
};
export const trackInitiateWithdraw = (amount: string, method: IStakeMethod) => {
  track(Events.INITIATE_WITHDRAW, {
    AMOUNT: amount,
    METHOD: method,
  });
};
export const trackInitiateSwap = () => {
  track(Events.INITIATE_SWAP);
};

