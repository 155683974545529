export enum Marketplaces {
  OpenSea = "OpenSea",
  LooksRare = "LooksRare",
  X2Y2 = "X2Y2",
  Universe = "Universe XYZ - The NFT Universe Built on Ethereum",
}

export enum OrderAssetClass {
  ERC721 = 'ERC721',
  ERC1155 = 'ERC1155',
  ERC721_BUNDLE = 'ERC721_BUNDLE',
  ERC20 = 'ERC20',
  ETH = 'ETH',
}

export interface IERC721AssetType {
  assetClass: OrderAssetClass.ERC721;
  contract: string;
  tokenId: number;
}

export interface IERC1155AssetType {
  assetClass: OrderAssetClass.ERC1155;
  contract: string;
  tokenId: number;
}
export interface IERC20AssetType {
  assetClass: OrderAssetClass.ERC20;
  contract: string;
}

export interface IERC721BundleAssetType {
  assetClass: OrderAssetClass.ERC721_BUNDLE;
  contracts: string[];
  tokenIds: Array<Array<number>>;
  bundleName: string;
  bundleDescription: string;
}

export interface IOrder {
  blockNum?: number;
  cancelledTxHash: null | string;
  createdAt: Date;
  data: {
    dataType: 'ORDER_DATA';
    revenueSplits: Array<{
      account: string;
      value: number;
    }>;
  };
  end: number;
  feeBps: number;
  feeBreakdown: [
    {
      bps: number;
      recipient: string;
    }
  ],
  fill: string;
  from: string;
  hash: string;
  id: string;
  make: {
    assetType: IERC721AssetType | IERC721BundleAssetType | IERC20AssetType | IERC1155AssetType;
    value: string;
  };
  makeBalance: string;
  makeStock: string;
  maker: string;
  salt: number;
  side: number;
  signature: string;
  start: number;
  status: number;
  source: {
    name: Marketplaces;
  },
  take: {
    assetType: {
      assetClass: string;
      type?: string;
      contract?: string;
    };
    value: string;
  };
  taker: string;
  type: string;
  to: string;
  updatedAt: Date;
}