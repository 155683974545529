import axios from 'axios';
import { configs } from '@/configs';

export const displaySetChallenge = async (
  challenge: string
): Promise<{ uuid: string }> => {
  //
  const url = `${configs.NFTDisplayApiUrl}/auth/challenge`;

  const body = {
    challenge: challenge,
  };

  const { data: response } = await axios.post(url, body);

  return response;
};

export interface IDisplayUserAuthenticateData {
  address: string;
  signature: string;
  uuid: string;
}

export const displayUserAuthenticate = async (data: IDisplayUserAuthenticateData) => {
  //
  const { address, signature, uuid } = data;

  const url = `${configs.NFTDisplayApiUrl}/auth/login`;

  const body = {
    address: address,
    signature: signature,
    uuid: uuid,
  };

  const { data: response } = await axios.post(url, body);

  return response;
};

export const getDisplayUser = async () => {
  //
  const url = `${configs.NFTDisplayApiUrl}/auth/me`;
  const token = JSON.parse(localStorage.getItem('graviton_access_token'));

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const { data: response } = await axios.get(url, config);

  return response;
};

export const getTorrentUser = async () => {
  //
  const url = `${configs.NFTTorrentApiUrl}/auth/me`;
  const token = JSON.parse(localStorage.getItem('graviton_access_token'));

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const { data: response } = await axios.get(url, config);

  return response;
};
