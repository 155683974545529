import { Icon, Tooltip } from '@/components/ui';
import { Box } from '@chakra-ui/react';

import cn from 'classnames';
import React from 'react';
import styles from '../controls.module.sass';

interface IPlayProps {
  playing: boolean;
  isLightMode?: boolean;
  isShowTitle?: boolean;
  playTitle?: string;
  pauseTitle?: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

export const Play: React.FC<IPlayProps> = (props) => {
  //
  const {
    playing = false,
    playTitle = 'Play',
    pauseTitle = 'Pause',
    isShowTitle = true,
    isLightMode = false,
    onClick,
  } = props;

  return (
    <Tooltip
      label={playing ? pauseTitle : playTitle}
      isDisabled={!isShowTitle}
      variant={isLightMode && 'white'}
    >
      <Box
        className={cn(styles.Button, isLightMode && styles.isLightMode)}
        onClick={onClick}
      >
        <Icon icon={playing ? 'pause' : 'play'} className={styles.Icon} />
      </Box>
    </Tooltip>
  );
};
