import { CenterProps, HStack } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { useRouter } from 'next/router';

import { ReactComponent as NoFoundDark } from '@/assets/images/nfts-not-found-dark.svg';
import { ReactComponent as NoFoundLight } from '@/assets/images/nfts-not-found-light.svg';
import { DataNotFound } from '@/components/common';
import { Button, Icon } from '@/components/ui';
import { useTheme } from '@/hooks';
import { stringToBoolean } from '@/utils';
import { trackInitiateCreateTnftSingle } from '@/utils/amplitude';
import { configs } from '@/configs';

export interface INFTsNotFoundProps extends CenterProps {
  tNFTs?: boolean;
}

export const NFTsNotFound: React.FC<INFTsNotFoundProps> = (props) => {
  const { tNFTs = false, ...rest } = props;

  const router = useRouter();

  const { isLight } = useTheme();

  const [title, description, buttonText] = useMemo(() => {
    if (tNFTs) {
      return [
        'No tNFTs Found',
        'Click below to mint your first tNFT',
        'Mint tNFT',
      ];
    }

    return [
      'No NFTs Found',
      'Click below to mint your first NFT',
      'Mint NFT',
    ];
  }, [tNFTs]);

  return (
    <DataNotFound
      title={title}
      description={configs.featuresFlags.enableNFTTorrent ? description : ''}
      renderImage={() => isLight ? <NoFoundLight /> : <NoFoundDark />}
      {...rest}
    >
      {configs.featuresFlags.enableNFTTorrent && (
        <HStack spacing={'20px'}>
          <Button
            variant="secondary"
            size="lg"
            onClick={() => {
              trackInitiateCreateTnftSingle();
              router.push(`/torrent/mint/tnft`);
            }}
          >
            {buttonText}
          </Button>
        </HStack>
      )}
    </DataNotFound>
  );
};