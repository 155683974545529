import { ChevronLeftIcon, ChevronRightIcon } from '@/components/icons';
import { Button, IconButton } from '@/components/ui';
import { clamp } from '@/utils';
import { HStack, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

interface Props {
  total: number;
  current: number;
  onChange: (page: number) => void;
}

export function Pages({ total, current, onChange }: Props) {
  const [pointer, setPointer] = useState<number | undefined>();

  useEffect(() => setPointer(undefined), [total, current]);

  const renderButton = (page: number) => {
    const isActive = page === current;
    return (
      <Button
        key={page}
        size="sm"
        onClick={() => onChange(page)}
        variant={isActive ? 'secondary' : undefined}
        transition="none"
      >
        <Text fontSize={'14px'} fontWeight={'bold'}>
          {page}
        </Text>
      </Button>
    );
  };

  const renderMore = (from: number, direction: number) => {
    const nextPointer = clamp(from, 3, total - 2);
    return (
      <Button
        size="sm"
        onClick={() => setPointer(nextPointer)}
        transition="none"
        key={`more-${direction}`}
      >
        <Text fontSize={'14px'} fontWeight={'bold'}>
          ...
        </Text>
      </Button>
    );
  };

  const renderAll = () =>
    Array(total ?? 0)
      .fill(null)
      .map((_, i) => renderButton(i + 1));

  const renderEdges = () => {
    return [
      renderButton(1),
      renderButton(2),
      current > 3 ? renderMore(current - 3, -1) : renderMore(3, 1),
      renderButton(total - 1),
      renderButton(total),
    ];
  };

  const renderCenter = (from: number) => {
    return [
      renderButton(1),
      from > 3 ? renderMore(from - 3, 1) : undefined,
      renderButton(from - 1),
      renderButton(from),
      renderButton(from + 1),
      from < total - 2 ? renderMore(from + 3, -1) : undefined,
      renderButton(total),
    ];
  };

  const buttons = (() => {
    if (total <= 5) return renderAll();
    if (pointer) return renderCenter(pointer);
    if (current < 3 || current > total - 2) return renderEdges();
    return renderCenter(current);
  })();

  return (
    <HStack>
      <IconButton
        aria-label="Prev"
        onClick={() => onChange(current - 1)}
        size="sm"
        icon={<ChevronLeftIcon width="12px" />}
        disabled={current <= 1}
      />
      {buttons}
      <IconButton
        aria-label="Prev"
        onClick={() => onChange(current + 1)}
        size="sm"
        icon={<ChevronRightIcon width="12px" />}
        disabled={current >= total}
      />
    </HStack>
  );
}
