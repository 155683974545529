import { Icon, IconProps } from '@chakra-ui/react';

export const SuccessIcon = (props: IconProps) => {
  //
  return (
    <>
      <Icon
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 35 34"
        fill="none"
        display="block"
        {...props}
      >
        <path
          d="M23.2396 13.1085C23.5324 13.4014 23.5324 13.8763 23.2396 14.1692L16.5161 20.8927C16.2232 21.1855 15.7484 21.1856 15.4555 20.8928L12.0923 17.531C11.7994 17.2382 11.7993 16.7633 12.0921 16.4704C12.3849 16.1774 12.8598 16.1773 13.1528 16.4701L15.9856 19.3018L22.1789 13.1085C22.4718 12.8156 22.9467 12.8156 23.2396 13.1085Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.89042 5.43626C7.25255 3.97492 9.19975 3.14648 11.5254 3.14648H23.8065C26.138 3.14648 28.086 3.97459 29.4472 5.43654C30.8029 6.89246 31.5208 8.91596 31.5208 11.215V22.7863C31.5208 25.0853 30.8029 27.1089 29.447 28.5648C28.0855 30.0268 26.1373 30.8548 23.8051 30.8548H11.5254C9.19386 30.8548 7.24625 30.0267 5.88531 28.5647C4.53001 27.1087 3.8125 25.0852 3.8125 22.7863V11.215C3.8125 8.91513 4.53375 6.89176 5.89042 5.43626ZM6.98768 6.45902C5.93283 7.59071 5.3125 9.22659 5.3125 11.215V22.7863C5.3125 24.7757 5.9302 26.4114 6.98324 27.5426C8.03062 28.6678 9.56447 29.3548 11.5254 29.3548H23.8051C25.7668 29.3548 27.3014 28.6678 28.3493 27.5425C29.4028 26.4113 30.0208 24.7755 30.0208 22.7863V11.215C30.0208 9.22576 29.4028 7.59001 28.3495 6.45874C27.3017 5.3335 25.7675 4.64648 23.8065 4.64648H11.5254C9.57133 4.64648 8.03708 5.33318 6.98768 6.45902Z"
          fill="black"
        />
      </Icon>
    </>
  );
};
