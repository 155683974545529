import { Icon, IconProps } from '@chakra-ui/react';

export const MyNftsIcon = (props: IconProps) => {
  //
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 3.35016C4.98416 3.35016 3.35 4.98432 3.35 7.00016V17.0002C3.35 19.016 4.98416 20.6502 7 20.6502H17C19.0158 20.6502 20.65 19.016 20.65 17.0002V7.00016C20.65 4.98432 19.0158 3.35016 17 3.35016H7ZM4.65 7.00016C4.65 5.70229 5.70213 4.65016 7 4.65016H17C18.2979 4.65016 19.35 5.70229 19.35 7.00016V17.0002C19.35 18.298 18.2979 19.3502 17 19.3502H7C5.70213 19.3502 4.65 18.298 4.65 17.0002V7.00016ZM9.52998 8.32253C9.04439 8.32253 8.65073 8.71618 8.65073 9.20177C8.65073 9.68737 9.04439 10.081 9.52998 10.081C10.0156 10.081 10.4092 9.68737 10.4092 9.20177C10.4092 8.71618 10.0156 8.32253 9.52998 8.32253ZM7.35073 9.20177C7.35073 7.99821 8.32642 7.02253 9.52998 7.02253C10.7335 7.02253 11.7092 7.99821 11.7092 9.20177C11.7092 10.4053 10.7335 11.381 9.52998 11.381C8.32642 11.381 7.35073 10.4053 7.35073 9.20177ZM16.0589 13.0347C15.5062 12.4616 14.5802 12.4887 14.0621 13.0932L9.49352 18.4232C9.25989 18.6957 8.84955 18.7273 8.57699 18.4937C8.30442 18.2601 8.27286 17.8497 8.50648 17.5771L13.0751 12.2472C14.0921 11.0606 15.9099 11.0073 16.9947 12.1323L18.4679 13.6601C18.7171 13.9185 18.7096 14.33 18.4512 14.5792C18.1928 14.8284 17.7813 14.8209 17.5321 14.5625L16.0589 13.0347Z"
        fill="black"
      />
      <defs>
        <linearGradient
          id="gradient"
          x1="2.323"
          y1="3.6129"
          x2="21.4124"
          y2="15.5111"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF7F69" />
          <stop offset="0.299586" stopColor="#FF2D83" />
          <stop offset="1" stopColor="#6695FF" />
        </linearGradient>
      </defs>
    </Icon>
  );
};
