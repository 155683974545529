import { mode } from '@chakra-ui/theme-tools';

const baseStyleWrapper = (props) => {
  //
  const { color } = props;

  return {
    fontFamily: 'Space Grotesk, serif',
    position: 'relative',
    backgroundColor: mode('white', 'dark')(props),
    border: '1px solid',
    borderColor: mode('blackTransparent.10', 'whiteTransparent.10')(props),
    boxShadow: mode(
      '0px 10px 33px rgba(170, 103, 200, 0.16)',
      '-10px 10px 40px rgba(0, 0, 0, 0.5)'
    )(props),
    borderRadius: '4px',
    paddingRight: '68px',
    paddingLeft: '24px',
    paddingTop: '15px',
    paddingBottom: '15px',
    maxWidth: '430px',

    _before: {
      content: '""',
      display: 'block',
      height: '100%',
      width: '4px',
      position: 'absolute',
      left: 0,
      top: 0,
      borderTopLeftRadius: '4px',
      borderBottomLeftRadius: '4px',
      backgroundColor: color,
    },
  };
};

const baseStyleIcon = (props) => {
  //
  const { color } = props;

  return {
    width: '34px',
    height: '34px',
    minW: '34px',

    svg: {
      width: 'inherit',
      height: 'inherit',
    },

    path: {
      fill: color,
    },
  };
};

const baseStyleTitle = (props) => {
  return {
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '20px',
  };
};

const baseStyleDescription = (props) => {
  return {
    fontSize: '14px',
    fontWeight: '300',
    lineHeight: '18px',
    color: mode('darkFlat.60', 'whiteFlat.60')(props),
  };
};

const baseStyleCloseButton = (props) => {
  return {
    position: 'absolute',
    right: '20px',
    top: '50%',
    transform: 'translateY(-50%)',
  };
};

const baseStyle = (props) => ({
  wrapper: baseStyleWrapper(props),
  title: baseStyleTitle(props),
  description: baseStyleDescription(props),
  icon: baseStyleIcon(props),
  closeButton: baseStyleCloseButton(props),
});

export const Toast = {
  //
  parts: ['wrapper', 'title', 'description', 'icon', 'closeButton'],

  baseStyle: baseStyle,

  sizes: {
    //md: {},
    //lg: {},
  },

  variants: {
    //gradient: (props) => ({}),
  },
  // The default variant value
  defaultProps: {
    //variant: 'gradient',
    //size: 'md',
  },
};
