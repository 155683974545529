import { mode } from '@chakra-ui/theme-tools';

export const ghost = (props) => {
  const { colorScheme } = props;

  const baseStyles = {
    fontWeight: 400,
    border: '1px solid',
    borderRadius: '10px',
    background: 'transparent',
    boxShadow: mode(
      '0px 0px 0px 4px transparent',
      '0px 0px 0px 4px transparent'
    )(props),
    outline: 'none',
    transition:
      'border-color 200ms linear, background-color 200ms linear, box-shadow 200ms linear',

    _hover: {
      background: 'transparent',
    },

    _active: {
      background: 'transparent',
    },
  };

  const colorSchemeStyles = {
    default: {
      borderColor: mode('blackTransparent.10', 'whiteTransparent.10')(props),
      color: mode('black', 'white')(props),

      _hover: {
        borderColor: mode('blackTransparent.40', 'whiteTransparent.40')(props),
      },

      _active: {
        background: mode('blackTransparent.5', 'whiteTransparent.5')(props),
      },

      _focus: {
        boxShadow: mode(
          '0px 0px 0px 4px rgba(0 0 0 / 10%)',
          '0px 0px 0px 4px rgba(255 255 255 / 10%)'
        )(props),
        borderColor: mode('blackTransparent.40', 'whiteTransparent.40')(props),
      },

      _expanded: {
        boxShadow: mode(
          '0px 0px 0px 4px rgba(0 0 0 / 10%)',
          '0px 0px 0px 4px rgba(255 255 255 / 10%)'
        )(props),
        borderColor: mode('blackTransparent.40', 'whiteTransparent.40')(props),
      },
    },
    black: {
      color: mode('black', 'white')(props),
      borderColor: mode('black', 'white')(props),

      path: {
        fill: mode('black', 'white')(props),
      },

      _hover: {
        borderColor: mode('black', 'white')(props),
        backgroundColor: mode(
          'blackTransparent.10',
          'whiteTransparent.10'
        )(props),
      },

      _active: {},
    },
    red: {
      color: 'red',
      borderColor: 'red',

      path: {
        fill: 'red',
      },

      _hover: {
        borderColor: 'red',
        backgroundColor: 'redTransparent.10',
      },

      _active: {},
    },
    green: {
      color: 'green',
      borderColor: 'green',

      path: {
        fill: 'green',
      },

      _hover: {
        borderColor: 'green',
        backgroundColor: 'greenTransparent.10',
      },

      _active: {},
    },
  };

  const styles = colorSchemeStyles[colorScheme] || colorSchemeStyles.default;

  return {
    ...baseStyles,
    ...styles,
  };
};
