import { Network, Alchemy } from 'alchemy-sdk';
import { configs } from '@/configs';
import { NETWORK_CHAIN_ID } from '@/constants';
import { NetworkID } from '@/types';

export const NETWORKS: Record<NetworkID, Network> = {
  [NetworkID.MAINNET]: Network.ETH_MAINNET,
  [NetworkID.GOERLI]: Network.ETH_GOERLI,
};

const settings = {
  apiKey: configs.alchemyApiKey,
  network: NETWORKS[NETWORK_CHAIN_ID] ?? Network.ETH_MAINNET,
};

export const AlchemyAPI = new Alchemy(settings);
