import { Blockies } from '@/components/common';
import { CopyIcon, RightFromBracketIcon } from '@/components/icons';
import { Tooltip } from '@/components/ui/';
import { useAuth, useTheme } from '@/hooks';
import { truncateEthAddress } from '@/utils';
import { configs } from '@/configs';
import { Box, Flex, HStack, useClipboard } from '@chakra-ui/react';
import { useWeb3React } from '@web3-react/core';
import { UserPlan } from '../user-plan';

import cn from 'classnames';
import styles from '../../wallet-dropdown.module.sass';

export const DropdownContent = () => {
  //
  const { account } = useWeb3React();
  const { logout } = useAuth();
  const { themeClass } = useTheme();
  const { hasCopied, onCopy } = useClipboard(account);

  const address = truncateEthAddress(account, 3);

  return (
    <Box w="240px" className={cn(styles.Wrapper, styles[themeClass])}>
      <Box className={cn(styles.Content)}>
        <Flex align="center" justify="space-between">
          <HStack spacing="12px">
            <Box className={cn(styles.WalletIcon, styles.WalletIconDropdown)}>
              <Blockies seed={account} size={9} />
            </Box>
            <Box as="address" title={account} className={styles.Address}>
              {address}
            </Box>
          </HStack>
          <Tooltip label={hasCopied ? 'Copied' : 'Copy'}>
            <Box
              as="button"
              aria-label="Copy to clipboard"
              className={styles.CopyIcon}
              onClick={() => onCopy()}
            >
              <CopyIcon />
            </Box>
          </Tooltip>
        </Flex>

        {configs.featuresFlags.enableProPlanSubscription && (
          <UserPlan />
        )}
      </Box>

      <Box className={styles.Footer}>
        <HStack
          as="button"
          title="Sign out"
          spacing="10px"
          className={styles.SignOutButton}
          onClick={() => logout()}
        >
          <Box>
            <RightFromBracketIcon />
          </Box>
          <Box>Sign out</Box>
        </HStack>
      </Box>
    </Box>
  );
};
