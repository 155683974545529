import { WETH } from '@graviton-inc/simple-sushiswap-sdk';
import { constants } from 'ethers';

import { NETWORK_CHAIN_ID } from '@/constants';
import { IToken, NetworkID, TokenTicker } from '@/types';

const tokensContractAddress: Record<TokenTicker, Partial<Record<NetworkID, string>>> = {
  [TokenTicker.ETH]: {
    [NetworkID.MAINNET]: constants.AddressZero,
    [NetworkID.GOERLI]: constants.AddressZero,
  },
  [TokenTicker.WETH]: {
    [NetworkID.MAINNET]: WETH.MAINNET().contractAddress,
    [NetworkID.GOERLI]: WETH.GORLI().contractAddress,
  },
  [TokenTicker.USDT]: {
    [NetworkID.MAINNET]: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    [NetworkID.GOERLI]: '0x509ee0d083ddf8ac028f2a56731412edd63223b9',
  },
  [TokenTicker.USDC]: {
    [NetworkID.MAINNET]: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    [NetworkID.GOERLI]: '0x2f3A40A3db8a7e3D09B0adfEfbCe4f6F81927557',
  },
  [TokenTicker.BUSD]: {
    [NetworkID.MAINNET]: '0x4fabb145d64652a948d72533023f6e7a623c7c53',
    [NetworkID.GOERLI]: '0xb809b9B2dc5e93CB863176Ea2D565425B03c0540',
  },
  [TokenTicker.DAI]: {
    [NetworkID.MAINNET]: '0x6b175474e89094c44da98b954eedeac495271d0f',
    [NetworkID.GOERLI]: '0x73967c6a0904aA032C103b4104747E88c566B1A2',
  },
  [TokenTicker.UNI]: {
    [NetworkID.MAINNET]: '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984',
    [NetworkID.GOERLI]: '0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984',
  },
  [TokenTicker.LINK]: {
    [NetworkID.MAINNET]: '0x514910771af9ca656af840dff83e8264ecf986ca',
  },
  [TokenTicker.WBTC]: {
    [NetworkID.MAINNET]: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
  },
  [TokenTicker.MATIC]: {
    [NetworkID.MAINNET]: '0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0',
  },
  [TokenTicker.APE]: {
    [NetworkID.MAINNET]: '0x4d224452801aced8b2f0aebe155379bb5d594381',
  },
  [TokenTicker.AAVE]: {
    [NetworkID.MAINNET]: '0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9',
  },
  [TokenTicker.SNX]: {
    [NetworkID.MAINNET]: '0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f',
  },
  [TokenTicker.CRV]: {
    [NetworkID.MAINNET]: '0xd533a949740bb3306d119cc777fa900ba034cd52',
  },
  [TokenTicker.LPT]: {
    [NetworkID.MAINNET]: '0x58b6a8a3302369daec383334672404ee733ab239',
  },
  [TokenTicker.XYZ]: {
    [NetworkID.MAINNET]: '0x618679df9efcd19694bb1daa8d00718eacfa2883',
    [NetworkID.GOERLI]: '0x3294D582Cba736657a63cF0a0B7523839A232f3d',
  },
  [TokenTicker.ASTO]: {
    [NetworkID.MAINNET]: '0x823556202e86763853b40e9cde725f412e294689',
  },
  [TokenTicker.ILV]: {
    [NetworkID.MAINNET]: '0x767fe9edc9e0df98e07454847909b5e959d7ca0e',
  },
  [TokenTicker.BOND]: {
    [NetworkID.MAINNET]: '0x0391d2021f89dc339f60fff84546ea23e337750f',
  },
  [TokenTicker.LEAG]: {
    [NetworkID.MAINNET]: '0x7b39917f9562c8bc83c7a6c2950ff571375d505d',
  },
  [TokenTicker.SAND]: {
    [NetworkID.MAINNET]: '0x3845badade8e6dff049820680d1f14bd3903a5d0',
  },
  [TokenTicker.MANA]: {
    [NetworkID.MAINNET]: '0x0f5d2fb29fb7d3cfee444a200298f468908cc942',
  },
  [TokenTicker.SYLO]: {
    [NetworkID.MAINNET]: '0xf293d23bf2cdc05411ca0eddd588eb1977e8dcd4',
  },
  [TokenTicker.AXS]: {
    [NetworkID.MAINNET]: '0xbb0e17ef65f82ab018d8edd776e8dd940327b28b',
  },
  [TokenTicker.ENTR]: {
    [NetworkID.MAINNET]: '0xd779eea9936b4e323cddff2529eb6f13d0a4d66e',
  },
  [TokenTicker.FDT]: {
    [NetworkID.MAINNET]: '0xed1480d12be41d92f36f5f7bdd88212e381a3677',
  },
  [TokenTicker.CHARLES]: {
    [NetworkID.GOERLI]: '0xfe26d62cfa9ad3ef40f437ba0302e708b593f3bc',
  },
};

const tokensContractDecimals: Partial<Record<TokenTicker, Partial<Record<NetworkID, number>>>> = {
  WETH: {
    [NetworkID.MAINNET]: WETH.MAINNET().decimals,
    [NetworkID.GOERLI]: WETH.GORLI().decimals,
  },
};

type DefineTokenType = <T extends TokenTicker = TokenTicker>(_tokenData: Omit<IToken<T>, 'address'>) => IToken<T>;

const defineToken: DefineTokenType = (tokenData) => ({
  ...tokenData,
  address: tokensContractAddress[tokenData.ticker][NETWORK_CHAIN_ID],
  decimals: tokensContractDecimals?.[tokenData.ticker]?.[NETWORK_CHAIN_ID] ?? tokenData.decimals,
});

type ITokensMap = {
  [T in TokenTicker]: IToken<T>;
};

export const TOKENS_MAP: ITokensMap = {
  [TokenTicker.ETH]: defineToken({
    ticker: TokenTicker.ETH,
    image: 'https://assets.coingecko.com/coins/images/279/large/ethereum.png?1595348880',
    name: 'Ethereum',
    decimals: 18,
    coinGeckoId: 'ethereum',
  }),
  [TokenTicker.WETH]: defineToken({
    ticker: TokenTicker.WETH,
    image: 'https://assets.coingecko.com/coins/images/2518/large/weth.png?1628852295',
    name: 'Wrapped Ethereum',
    decimals: WETH.MAINNET().decimals,
    coinGeckoId: 'weth',
  }),
  [TokenTicker.USDT]: defineToken({
    ticker: TokenTicker.USDT,
    image: 'https://assets.coingecko.com/coins/images/325/large/Tether.png?1668148663',
    name: 'Tether USD',
    decimals: 6,
    coinGeckoId: 'tether',
  }),
  [TokenTicker.USDC]: defineToken({
    ticker: TokenTicker.USDC,
    image: 'https://assets.coingecko.com/coins/images/6319/large/USD_Coin_icon.png?1547042389',
    name: 'USD Coin',
    decimals: 6,
    coinGeckoId: 'usd-coin',
  }),
  [TokenTicker.BUSD]: defineToken({
    ticker: TokenTicker.BUSD,
    image: 'https://assets.coingecko.com/coins/images/9576/large/BUSD.png?1568947766',
    name: 'Binance USD',
    decimals: 18,
    coinGeckoId: 'binance-usd',
  }),
  [TokenTicker.DAI]: defineToken({
    ticker: TokenTicker.DAI,
    image: 'https://assets.coingecko.com/coins/images/9956/large/4943.png?1636636734',
    name: 'Dai Stablecoin',
    decimals: 18,
    coinGeckoId: 'dai',
  }),
  [TokenTicker.UNI]: defineToken({
    ticker: TokenTicker.UNI,
    image: 'https://assets.coingecko.com/coins/images/12504/large/uniswap-uni.png?1600306604',
    name: 'Uniswap',
    decimals: 18,
    coinGeckoId: 'uniswap',
  }),
  [TokenTicker.LINK]: defineToken({
    ticker: TokenTicker.LINK,
    image: 'https://assets.coingecko.com/coins/images/877/large/chainlink-new-logo.png?1547034700',
    name: 'ChainLink Token',
    decimals: 18,
    coinGeckoId: 'chainlink',
  }),
  [TokenTicker.WBTC]: defineToken({
    ticker: TokenTicker.WBTC,
    image: 'https://assets.coingecko.com/coins/images/7598/large/wrapped_bitcoin_wbtc.png?1548822744',
    name: 'Wrapped BTC',
    decimals: 8,
    coinGeckoId: 'wrapped-bitcoin',
  }),
  [TokenTicker.MATIC]: defineToken({
    ticker: TokenTicker.MATIC,
    image: 'https://assets.coingecko.com/coins/images/4713/large/matic-token-icon.png?1624446912',
    name: 'Matic Token',
    decimals: 18,
    coinGeckoId: 'matic-network',
  }),
  [TokenTicker.APE]: defineToken({
    ticker: TokenTicker.APE,
    image: 'https://assets.coingecko.com/coins/images/24383/large/apecoin.jpg?1647476455',
    name: 'ApeCoin',
    decimals: 18,
    coinGeckoId: 'apecoin',
  }),
  [TokenTicker.AAVE]: defineToken({
    ticker: TokenTicker.AAVE,
    image: 'https://assets.coingecko.com/coins/images/12645/large/AAVE.png?1601374110',
    name: 'Aave Token',
    decimals: 18,
    coinGeckoId: 'aave',
  }),
  [TokenTicker.SNX]: defineToken({
    ticker: TokenTicker.SNX,
    image: 'https://assets.coingecko.com/coins/images/3406/large/SNX.png?1598631139',
    name: 'Synthetix Network Token',
    decimals: 18,
    coinGeckoId: 'havven',
  }),
  [TokenTicker.CRV]: defineToken({
    ticker: TokenTicker.CRV,
    image: 'https://assets.coingecko.com/coins/images/12124/large/Curve.png?1597369484',
    name: 'Curve DAO Token',
    decimals: 18,
    coinGeckoId: 'curve-dao-token',
  }),
  [TokenTicker.LPT]: defineToken({
    ticker: TokenTicker.LPT,
    image: 'https://assets.coingecko.com/coins/images/7137/large/logo-circle-green.png?1619593365',
    name: 'Livepeer Token',
    decimals: 18,
    coinGeckoId: 'livepeer',
  }),
  [TokenTicker.XYZ]: defineToken({
    ticker: TokenTicker.XYZ,
    image: 'https://assets.coingecko.com/coins/images/15809/large/universexyz.png?1621950483',
    name: 'XYZ Governance Token',
    decimals: 18,
    coinGeckoId: 'universe-xyz',
  }),
  [TokenTicker.ASTO]: defineToken({
    ticker: TokenTicker.ASTO,
    image: 'https://assets.coingecko.com/coins/images/24893/large/ASTO.png?1649303806',
    name: 'Altered State Machine Utility Token',
    decimals: 18,
    coinGeckoId: 'altered-state-token',
  }),
  [TokenTicker.ILV]: defineToken({
    ticker: TokenTicker.ILV,
    image: 'https://assets.coingecko.com/coins/images/14468/large/ILV.JPG?1617182121',
    name: 'Illuvium',
    decimals: 18,
    coinGeckoId: 'illuvium',
  }),
  [TokenTicker.BOND]: defineToken({
    ticker: TokenTicker.BOND,
    image: 'https://assets.coingecko.com/coins/images/12811/large/barnbridge.jpg?1602728853',
    name: 'BarnBridge Governance Token',
    decimals: 18,
    coinGeckoId: 'barnbridge',
  }),
  [TokenTicker.LEAG]: defineToken({
    ticker: TokenTicker.LEAG,
    image: 'https://assets.coingecko.com/coins/images/19130/large/leag_token_icon.png?1634523084',
    name: 'LeagueDAO Governance Token',
    decimals: 18,
    coinGeckoId: 'leaguedao-governance-token',
  }),
  [TokenTicker.SAND]: defineToken({
    ticker: TokenTicker.SAND,
    image: 'https://assets.coingecko.com/coins/images/12129/large/sandbox_logo.jpg?1597397942',
    name: 'SAND',
    decimals: 18,
    coinGeckoId: 'the-sandbox',
  }),
  [TokenTicker.MANA]: defineToken({
    ticker: TokenTicker.MANA,
    image: 'https://assets.coingecko.com/coins/images/878/large/decentraland-mana.png?1550108745',
    name: 'Decentraland',
    decimals: 18,
    coinGeckoId: 'decentraland',
  }),
  [TokenTicker.SYLO]: defineToken({
    ticker: TokenTicker.SYLO,
    image: 'https://assets.coingecko.com/coins/images/6430/large/SYLO.svg?1589527756',
    name: 'Sylo',
    decimals: 18,
    coinGeckoId: 'sylo',
  }),
  [TokenTicker.AXS]: defineToken({
    ticker: TokenTicker.AXS,
    image: 'https://assets.coingecko.com/coins/images/13029/large/axie_infinity_logo.png?1604471082',
    name: 'Axie Infinity Shard',
    decimals: 18,
    coinGeckoId: 'axie-infinity',
  }),
  [TokenTicker.ENTR]: defineToken({
    ticker: TokenTicker.ENTR,
    image: 'https://assets.coingecko.com/coins/images/18684/large/entr.png?1635826690',
    name: 'ENTER Governance Token',
    decimals: 18,
    coinGeckoId: 'centrifuge',
  }),
  [TokenTicker.FDT]: defineToken({
    ticker: TokenTicker.FDT,
    image: 'https://assets.coingecko.com/coins/images/20388/large/MLamPGZI_400x400.jpg?1636960965',
    name: 'FIAT DAO Token',
    decimals: 18,
    coinGeckoId: 'fiat-dao-token',
  }),
  [TokenTicker.CHARLES]: defineToken({
    ticker: TokenTicker.CHARLES,
    image: 'https://assets.coingecko.com/coins/images/9956/large/4943.png?1636636734',
    name: 'Charles Token',
    decimals: 18,
    coinGeckoId: 'universe-xyz',
  }),
};

export const ETH_TOKEN = TOKENS_MAP.ETH;
export const WETH_TOKEN = TOKENS_MAP.WETH;
export const XYZ_TOKEN = TOKENS_MAP.XYZ;
