import { useObjectState } from '@/hooks';
import { PaginatedData, PaginationInfo, PaginationQueryParams } from '@/types';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';

interface Props<T> {
  /** @tanstack/react-query queryKey value */
  queryKey: string;
  defaultPageSize?: number;
  /** Function to retrieve the paginated data */
  fetch: (params: PaginationQueryParams) => Promise<PaginatedData<T>>;
}

export function usePaginatedQuery<T>({
  queryKey,
  defaultPageSize,
  fetch,
}: Props<T>) {
  const [pageParams, setPaginationParams] =
    useObjectState<PaginationQueryParams>({
      page: 1,
      size: defaultPageSize ?? 12,
    });

  const [paginationInfo, setPaginationInfo] = useState<
    PaginationInfo | undefined
  >();
  const { page, size } = pageParams;

  const onQuery = async () => {
    setPaginationInfo({
      ...paginationInfo,
      currentPage: pageParams.page,
    });
    const res = await fetch(pageParams);
    setPaginationInfo(res.pagination);
    return res.data;
  };

  const pageQueryKey = [queryKey, page, size];

  const queryData = useQuery(pageQueryKey, onQuery, {
    keepPreviousData: true,
    refetchOnMount: true,
  });

  return {
    setPaginationParams,
    pageParams,
    paginationInfo,
    queryData,
    pageQueryKey,
  };
}
