import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { createContext, useCallback, useEffect, useState } from 'react';

import { useWeb3React } from '@web3-react/core';
import { useRouter } from 'next/router';
import { useLocalStorage, useMount } from 'react-use';

import { useContracts } from '@/hooks';
import { useEnsAvatar, useSignMessage } from './hooks';

import { getChallengeMessage } from '@/utils';

import {
  displaySetChallenge,
  displayUserAuthenticate,
  getDisplayUser,
  getTorrentUser,
} from '@/api/auth';
import { WrongNetwork } from '@/components/modules/home';
import { Toast } from '@/components/ui';
import { NETWORK_CHAIN_ID } from '@/constants';
import { useDisclosure, useToast } from '@chakra-ui/react';
import { Connector, ProviderConnectInfo } from '@web3-react/types';

export interface AuthContextData {
  user: {
    display: any;
    torrent: any;
  };
  address: string;
  signer: any;
  provider: any;
  isAuthenticated: boolean;
  isLoading: boolean;
  isWaitingForSignMessage: boolean;
  isWaitingForWalletActivation: boolean;
  avatar?: string;
  login: (connector?: Connector) => Promise<void>;
  logout: () => void;
}

export const AuthContext = createContext<AuthContextData>(null);

interface IAuthProvider {
  children?: React.ReactNode;
}

export const AuthProvider: React.FC<IAuthProvider> = (props) => {
  //
  const { children } = props;

  const queryClient = useQueryClient();
  const router = useRouter();
  const toast = useToast();

  const {
    isOpen: isOpenWrongNetwork,
    onOpen: onOpenWrongNetwork,
    onClose: onCloseWrongNetwork,
  } = useDisclosure();

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isWaitingForWalletActivation, setIsWaitingForWalletActivation] = useState(false);
  const [redirectTo, setRedirectTo] = useState<string>(null);

  const [token, setAccessToken, removeAccessToken] = useLocalStorage(
    'graviton_access_token'
  );

  const { account, connector, provider, ENSName } = useWeb3React();
  const { signer } = useContracts();

  const { ensAvatar: avatar } = useEnsAvatar(ENSName);

  const { signMessage, isLoading: isWaitingForSignMessage } = useSignMessage();

  const { mutateAsync: authenticate } = useMutation({
    mutationFn: async (address: string | undefined) => {
      const { message, uuid } = await generateChallenges();

      const signature = await signMessage({ message });

      const { accessToken } = await displayUserAuthenticate({
        uuid,
        signature,
        address: address ?? account,
      });

      return accessToken;
    },
    onSuccess: (accessToken) => setAccessToken(accessToken),
    onError: (error: { code?: number }) => {
      if (error?.code === 4001) {
        logout();
        return;
      }

      toast({
        position: 'bottom-right',
        render: ({ onClose }) => (
          <Toast
            status="error"
            title="Graviton authentication error"
            onClose={onClose}
          />
        ),
      });
    },
  });

  const {
    data: users,
    isLoading: isUsersLoading,
    refetch: fetchUsers,
  } = useQuery({
    retry: false,
    cacheTime: 0,
    queryKey: ['app-users', token],
    initialData: [],
    queryFn: async () => {
      const usersResults = await Promise.allSettled([
        getDisplayUser(),
        getTorrentUser(),
      ]);

      return usersResults.map((res) =>
        res.status === 'fulfilled' ? res.value : null
      );
    },
    onSuccess: (users) => {
      if (!isAuthenticated) {
        return;
      }
      if (!users?.[0]) {
        toast({
          position: 'bottom-right',
          render: ({ onClose }) => (
            <Toast
              status="error"
              title="Fetch NFT Display user error :("
              onClose={onClose}
            />
          ),
        });
      }

      if (!users?.[1]) {
        toast({
          position: 'bottom-right',
          render: ({ onClose }) => (
            <Toast
              status="error"
              title="Fetch NFT Torrent user error :("
              onClose={onClose}
            />
          ),
        });
      }
    },
  });

  const login = async (connector?: Connector) => {
    if (connector) {
      await connector.activate(NETWORK_CHAIN_ID ?? 5);
      await authenticate(undefined);
    }
    await handleAuthenticated();
  };

  const logout = (): void => {
    setIsAuthenticated(false);

    removeAccessToken();

    if (connector?.deactivate) {
      void connector.deactivate();
    } else {
      void connector.resetState();
    }

    router.push('/');
  };

  const handleChangeWalletAddress = async (accounts) => {
    if (!accounts?.length) {
      // logout when disconnect wallet manually
      logout();
      return;
    }

    if (!token || !account || accounts[0] === account) {
      return;
    }

    setIsAuthenticated(false);
    await authenticate(accounts[0]);
    await handleAuthenticated();
    queryClient.clear();
  };

  const handleChangeChain = useCallback(
    (chainId: string) => {
      if (+chainId !== NETWORK_CHAIN_ID) {
        onOpenWrongNetwork();
      } else {
        onCloseWrongNetwork();
      }
    },
    [onOpenWrongNetwork, onCloseWrongNetwork]
  );

  const handleSwitchNetwork = useCallback(async () => {
    try {
      await window.ethereum?.request({
        method: 'wallet_switchEthereumChain',
        params: [
          {
            chainId: `0x${NETWORK_CHAIN_ID.toString(16)}`,
          },
        ],
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  const handleCloseWrongNetwork = useCallback(() => {
    logout();
    onCloseWrongNetwork();
  }, [logout, onCloseWrongNetwork]);

  const handleAuthenticated = async () => {
    await fetchUsers();

    setIsAuthenticated(true);

    if (redirectTo) {
      await router.push(redirectTo);
      setRedirectTo(null);
    } else if (router.pathname === '/') {
      await router.replace('/display/nfts');
    }
  };

  const generateChallenges = async () => {
    //
    const challengeMessage = getChallengeMessage(account);

    const { uuid } = await displaySetChallenge(challengeMessage);

    return {
      uuid: uuid,
      message: challengeMessage,
    };
  };

  useMount(async () => {
    if (token) {
      await connector?.connectEagerly();
      const accounts = (await connector?.provider.request({ method: 'eth_accounts' })) as string[];

      if (!accounts?.length) {
        setIsWaitingForWalletActivation(true);
        await connector.activate(NETWORK_CHAIN_ID ?? 5);
        setIsWaitingForWalletActivation(false);
      }

      await login();
      handleChangeChain(
        (connector.provider as unknown as ProviderConnectInfo).chainId
      );
    } else {
      if (!['/', '/404'].includes(router.pathname)) {
        // save user route when user is not authenticated
        setRedirectTo(router.pathname);
      }

      // redirect to homepage when user is not authenticated
      if (router.pathname !== '/') {
        await router.push('/');
        return;
      }
    }
  });

  useEffect(() => {
    window.ethereum?.on('accountsChanged', handleChangeWalletAddress);
    window.ethereum?.on('chainChanged', handleChangeChain);

    return () => {
      window.ethereum?.removeListener(
        'accountsChanged',
        handleChangeWalletAddress
      );
      window.ethereum?.removeListener('chainChanged', handleChangeChain);
    };
  }, [handleChangeWalletAddress, handleChangeChain]);

  const contextValue: AuthContextData = {
    user: {
      display: isAuthenticated ? users?.[0] : undefined,
      torrent: isAuthenticated ? users?.[1] : undefined,
    },
    provider: provider,
    address: isAuthenticated ? account : '',
    signer,
    isAuthenticated: isAuthenticated,
    isLoading: isUsersLoading,
    isWaitingForSignMessage: isWaitingForSignMessage,
    isWaitingForWalletActivation: isWaitingForWalletActivation,
    avatar,
    login,
    logout,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
      <WrongNetwork
        isOpened={isOpenWrongNetwork}
        onSwitchNetworkClick={handleSwitchNetwork}
        onClose={handleCloseWrongNetwork}
      />
    </AuthContext.Provider>
  );
};
