import { Web3Provider } from '@ethersproject/providers';
import { utils } from 'ethers';

export const signMessage = async (
  provider: Web3Provider,
  challenge: string,
  address: string
) => {
  //
  const data = utils.toUtf8Bytes(challenge);
  const hexiflied = utils.hexlify(data);

  const signature = await provider.send('personal_sign', [
    hexiflied,
    address.toLowerCase(),
  ]);

  return signature;
};
