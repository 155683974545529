import { useAuth, useContracts } from '@/hooks';
import { utils } from 'ethers';

function useSupernovaContract() {
  //
  const contracts = useContracts();
  const { address } = useAuth();

  const Contract = contracts.UniverseXYZSupernovaFacet;

  const deposit = async (amount: string, gasPrice: string) => {
    //
    const formatedAmount = utils.parseUnits(amount);
    const formatedGasPrice = utils.parseUnits(gasPrice, 'gwei');

    return await Contract.deposit(formatedAmount, {
      from: address,
      gasPrice: formatedGasPrice,
    });
  };

  const withdraw = async (amount: string, gasPrice: string) => {
    //
    const formatedAmount = utils.parseUnits(amount);
    const formatedGasPrice = utils.parseUnits(gasPrice, 'gwei');

    return await Contract.withdraw(formatedAmount, {
      from: address,
      gasPrice: formatedGasPrice,
    });
  };

  return {
    deposit,
    withdraw,
  };
}

export { useSupernovaContract };
