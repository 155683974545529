import { useAuth } from '@/hooks/use-auth';
import { useEffect, useState } from 'react';
import { configs } from '@/configs';

export const useAddress = (onlyOriginal = false): string | undefined => {
  const auth = useAuth();

  const [address, setAddress] = useState<string>();

  useEffect(() => {
    const address = (
      !onlyOriginal &&
      typeof window !== 'undefined' &&
      window?.['ownerAddress'] &&
      ['dev', 'alpha'].includes(configs.environment)
    )
      ? window['ownerAddress']
      : auth.address;

    setAddress(address);
  }, [address]);

  return address;
};