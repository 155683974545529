import {
  Box,
  BoxProps,
  Link,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import cn from 'classnames';

import { useProPlan } from '@/context';

import s from './ProPlanAlert.module.sass';

interface IPropPlanAlert extends BoxProps {
  text: string;
}

export const ProPlanAlert: React.FC<IPropPlanAlert> = (props) => {
  const { text, ...rest } = props;

  const { getProPlan } = useProPlan();

  return (
    <>
      <Box className={cn(s.Wrapper)} {...rest}>
        <Text>{text}</Text>
        <Link
          href={'#'}
          className={cn(s.Link)}
          onClick={getProPlan}
        >Get Pro Plan</Link>
      </Box>
    </>
  );
};