import { AspectRatio } from '@chakra-ui/layout';
import { Box, Center, Text, useClipboard } from '@chakra-ui/react';
import cn from 'classnames';
import { useCallback, useState } from 'react';

import { Icon, Tooltip } from '@/components/ui';
import { Playlist } from '@/types';

import { PlaylistCardAsset, PlaylistCardMenu } from './components';

import s from './playlist-card.module.sass';

interface PlaylistCardProps {
  playlist: Playlist;
  showPlayButton?: boolean;
  onEdit: (playlist: Playlist) => void;
  onDelete: (playlist: Playlist) => void;
}

export const PlaylistCard = (props: PlaylistCardProps) => {
  //
  const { playlist, showPlayButton = true, onEdit, onDelete } = props;
  const { title, displayCode, assets } = playlist;

  const [assetLoaded, setAssetLoaded] = useState(false);

  const { hasCopied, onCopy } = useClipboard(displayCode);

  const handleOnClick = useCallback(() => {
    window.open(
      `https://display.graviton.xyz/play/${displayCode}`,
      '_blank'
    )
  }, []);

  return (
    <>
      <Box className={cn(s.Wrapper)}>
        <Box className={cn(s.Card, assetLoaded && s.Card__innerShadow)}>
          <AspectRatio ratio={1}>
            <PlaylistCardAsset
              asset={assets[0]}
              playlistTitle={title}
              onLoaded={() => setAssetLoaded(true)}
            />
          </AspectRatio>

          <Box className={cn(s.Amount)}>
            <Icon icon={'layers'} />
            <Text fontSize={'12px'} lineHeight={'16px'}>
              {assets.length}
            </Text>
          </Box>

          {showPlayButton && (
            <Center className={cn(s.Play)} data-play-button onClick={handleOnClick}>
              <Icon icon={'playlistPlay'} />
            </Center>
          )}

          <Box className={cn(s.Content, assetLoaded && s.Content__loaded)}>
            <Box className={s.Title}>{title}</Box>

            <Box className={s.Code}>
              <Text>{displayCode}</Text>
              <Tooltip label={hasCopied ? 'Copied' : 'Copy'}>
                <Box className={s.CopyIcon} onClick={onCopy}>
                  <Icon icon={'copy'} viewBox={'0 0 24 24'} boxSize={'16px'} />
                </Box>
              </Tooltip>
            </Box>

            <PlaylistCardMenu
              buttonProps={{
                className: cn(
                  s.MenuButton,
                  assetLoaded && s.MenuButton__loaded
                ),
              }}
              onEdit={() => onEdit(playlist)}
              onDelete={() => onDelete(playlist)}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};
