import { Heading } from '@chakra-ui/layout';
import { Box, Flex, Text } from '@chakra-ui/react';
import React, { useCallback, useState } from 'react';

import { ReservoirAPI } from '@/api/reservoir';
import {
  NFTDetailsModalHeader,
  NFTDetailsModalStickyBar,
  NFTProcessing,
} from '@/components/common';
import { Button } from '@/components/ui';
import { useSendTransaction } from '@/hooks';
import { IOrder, NFT } from '@/types';

interface INFTMarketplaceCancelListingProps {
  NFT: NFT;
  order?: IOrder;
  onSuccessCancelListing: () => void;
  onClose: () => void;
}

type State = 'confirm' | 'processing' | 'success' | 'error';

export const NFTMarketplaceCancelListing: React.FC<
  INFTMarketplaceCancelListingProps
> = (props) => {
  const { NFT, order, onSuccessCancelListing, onClose } = props;

  const { sendTransactionAsync } = useSendTransaction();

  const [state, setState] = useState<State>('confirm');
  const [tx, setTx] = useState<string>();

  const handleCancelListing = useCallback(async () => {
    if (!order) {
      return;
    }

    try {
      setState('processing');

      const { data, from, to } =
        await ReservoirAPI.prepareReservoirCancelOfferData(
          order.id,
          order.maker
        );

      const tx = await sendTransactionAsync({
        data,
        from,
        to,
      });

      setTx(tx.hash);

      const receipt = await tx.wait();

      if (receipt.status === 1) {
        setState('success');
      }

      onSuccessCancelListing();
    } catch (e: unknown) {
      setState('error');
    }
  }, [order, onSuccessCancelListing]);

  const NFTName = NFT.metadata?.name ?? NFT.tokenId;

  return (
    <>
      <NFTDetailsModalHeader>
        <Heading fontSize={'24px'}>Cancel listing</Heading>
      </NFTDetailsModalHeader>

      {state === 'confirm' && (
        <NFTProcessing
          state={'confirm'}
          title={'Are you sure you want to cancel your listing?'}
          renderDescription={() => (
            <Text>
              Canceling your listing will unpublish this listing from the tNFT
              Marketplace and requires a transaction to make sure it will never
              be fulfilable.
            </Text>
          )}
          renderFooter={() => (
            <NFTDetailsModalStickyBar>
              <Flex alignItems={'center'} gap={'16px'}>
                <Button variant={'secondary'} w={'100%'} onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  variant={'primary'}
                  w={'100%'}
                  onClick={handleCancelListing}
                >
                  Cancel listing
                </Button>
              </Flex>
            </NFTDetailsModalStickyBar>
          )}
          onClose={onClose}
        />
      )}

      {state === 'processing' && (
        <NFTProcessing
          state={'processing'}
          title={'Canceling your listing...'}
          tx={tx}
          renderDescription={() => (
            <Text>
              Canceling your listing of <Box as={'span'}>{NFTName}</Box>. It
              should be confirmed on the blockchain shortly.
            </Text>
          )}
          renderFooter={() => null}
        />
      )}

      {state === 'success' && (
        <>
          <NFTProcessing
            state={'success'}
            title={'Congratulations!'}
            tx={tx}
            renderDescription={() => (
              <Text>
                Your listing of <Box as={'span'}>{NFTName}</Box> was canceled.
              </Text>
            )}
            onClose={onClose}
          />
        </>
      )}

      {state === 'error' && (
        <NFTProcessing
          state="failed"
          tx={tx}
          renderDescription={() => (
            <Text>Please refresh the page and try again.</Text>
          )}
          onClose={onClose}
        />
      )}
    </>
  );
};
