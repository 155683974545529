import React from 'react';

import { Box, BoxProps } from '@chakra-ui/react';

// Icons
import { ReactComponent as AlertSVG } from '@/assets/icons/alert.svg';
import { ReactComponent as alignTopArrowSVG } from '@/assets/icons/align-top-arrow.svg';
import { ReactComponent as AndroidSVG } from '@/assets/icons/android.svg';
import { ReactComponent as AppleSVG } from '@/assets/icons/apple.svg';
import { ReactComponent as ArrowDownSVG } from '@/assets/icons/arrow-down.svg';
import { ReactComponent as ArrowLeftSVG } from '@/assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRightSVG } from '@/assets/icons/arrow-right.svg';
import { ReactComponent as ArrowUpRightSVG } from '@/assets/icons/arrow-up-right.svg';
import { ReactComponent as BundleSVG } from '@/assets/icons/bundle.svg';
import { ReactComponent as CalendarSVG } from '@/assets/icons/calandar.svg';
import { ReactComponent as Check2SVG } from '@/assets/icons/check-2.svg';
import { ReactComponent as CheckSVG } from '@/assets/icons/check.svg';
import { ReactComponent as ChevronDownSVG } from '@/assets/icons/chevron-down.svg';
import { ReactComponent as ChevronUpSVG } from '@/assets/icons/chevron-up.svg';
import { ReactComponent as Cogs2SVG } from '@/assets/icons/cogs-2.svg';
import { ReactComponent as CogsSVG } from '@/assets/icons/cogs.svg';
import { ReactComponent as XYZBlackAndWhiteSVG } from '@/assets/icons/coins/xyz-black-and-white.svg';
import { ReactComponent as CopySmallSVG } from '@/assets/icons/copy-small.svg';
import { ReactComponent as CopySVG } from '@/assets/icons/copy.svg';
import { ReactComponent as DelistSVG } from '@/assets/icons/delist.svg';
import { ReactComponent as DollarSVG } from '@/assets/icons/dollar.svg';
import { ReactComponent as DotsVerticalSVG } from '@/assets/icons/dots-vertical.svg';
import { ReactComponent as DotsSVG } from '@/assets/icons/dots.svg';
import { ReactComponent as DownSmallSVG } from '@/assets/icons/down-small.svg';
import { ReactComponent as DownloadSVG } from '@/assets/icons/download.svg';
import { ReactComponent as DownloadAndroidAppSVG } from '@/assets/icons/downloadAndroidApp.svg';
import { ReactComponent as DownloadAndroidTVAppSVG } from '@/assets/icons/downloadAndroidTVApp.svg';
import { ReactComponent as DownloadAppleTVAppSVG } from '@/assets/icons/downloadAppleTVApp.svg';
import { ReactComponent as DownloadIOSAppSVG } from '@/assets/icons/downloadIOSApp.svg';
import { ReactComponent as DownloadLinuxAppSVG } from '@/assets/icons/downloadLinuxApp.svg';
import { ReactComponent as DownloadMacOSAppSVG } from '@/assets/icons/downloadMacOSApp.svg';
import { ReactComponent as DownloadWindowsAppSVG } from '@/assets/icons/downloadWindowsApp.svg';
import { ReactComponent as ExclamationMarkSVG } from '@/assets/icons/exclamation-mark.svg';
import { ReactComponent as ExternalLinkSVG } from '@/assets/icons/external_link.svg';
import { ReactComponent as ExternalLinkPurpleSVG } from '@/assets/icons/external_link_purple.svg';
import { ReactComponent as FeeSVG } from '@/assets/icons/fee.svg';
import { ReactComponent as FilterSVG } from '@/assets/icons/filter.svg';
import { ReactComponent as GemSVG } from '@/assets/icons/gem.svg';
import { ReactComponent as MagnetGradientSVG } from '@/assets/icons/gradient/magnet-gradient.svg';
import { ReactComponent as GridLargeSVG } from '@/assets/icons/grid-large.svg';
import { ReactComponent as GridMediumSVG } from '@/assets/icons/grid-medium.svg';
import { ReactComponent as GridSmallSVG } from '@/assets/icons/grid-small.svg';
import { ReactComponent as InfoSVG } from '@/assets/icons/info.svg';
import { ReactComponent as LandscapeSVG } from '@/assets/icons/landscape.svg';
import { ReactComponent as LayersSVG } from '@/assets/icons/layers.svg';
import { ReactComponent as LinuxSVG } from '@/assets/icons/linux.svg';
import { ReactComponent as ListSVG } from '@/assets/icons/list.svg';
import { ReactComponent as ListingSVG } from '@/assets/icons/listing.svg';
import { ReactComponent as MagnetSVG } from '@/assets/icons/magnet.svg';
import { ReactComponent as MintSVG } from '@/assets/icons/mint.svg';
import { ReactComponent as PlaylistPlaySVG } from '@/assets/icons/play.svg';
import { ReactComponent as FullscreenOffSVG } from '@/assets/icons/player/fullscreen-off.svg';
import { ReactComponent as FullscreenOnSVG } from '@/assets/icons/player/fullscreen-on.svg';
import { ReactComponent as PauseSVG } from '@/assets/icons/player/pause.svg';
import { ReactComponent as PlaySVG } from '@/assets/icons/player/play.svg';
import { ReactComponent as SoundOffSVG } from '@/assets/icons/player/sound-off.svg';
import { ReactComponent as SoundOnSVG } from '@/assets/icons/player/sound-on.svg';
import { ReactComponent as PlusSVG } from '@/assets/icons/plus.svg';
import { ReactComponent as RefreshSVG } from '@/assets/icons/refresh.svg';
import { ReactComponent as ClaimSVG } from '@/assets/icons/rewards/claim.svg';
import { ReactComponent as StakeSVG } from '@/assets/icons/rewards/stake.svg';
import { ReactComponent as SwapSVG } from '@/assets/icons/rewards/swap.svg';
import { ReactComponent as WithdrawSVG } from '@/assets/icons/rewards/withdraw.svg';
import { ReactComponent as RightSVG } from '@/assets/icons/right.svg';
import { ReactComponent as Rotate270degSVG } from '@/assets/icons/rotate270deg.svg';
import { ReactComponent as Rotate90degSVG } from '@/assets/icons/rotate90deg.svg';
import { ReactComponent as SaleSVG } from '@/assets/icons/sale.svg';
import { ReactComponent as SearchSVG } from '@/assets/icons/search.svg';
import { ReactComponent as TimeSVG } from '@/assets/icons/time.svg';
import { ReactComponent as ToggleCloseSVG } from '@/assets/icons/toggle-close.svg';
import { ReactComponent as ToggleOpenSVG } from '@/assets/icons/toggle-open.svg';
import { ReactComponent as TransferSVG } from '@/assets/icons/transfer.svg';
import { ReactComponent as WindowsSVG } from '@/assets/icons/windows.svg';
// Social
import { ReactComponent as SocialDiscordSVG } from '@/assets/icons/social/social-discord.svg';
import { ReactComponent as SocialEtherscanSVG } from '@/assets/icons/social/social-etherscan.svg';
import { ReactComponent as SocialInstagramSVG } from '@/assets/icons/social/social-instagram.svg';
import { ReactComponent as SocialMediumSVG } from '@/assets/icons/social/social-medium.svg';
import { ReactComponent as SocialTelegramSVG } from '@/assets/icons/social/social-telegram.svg';
import { ReactComponent as SocialTwitterSVG } from '@/assets/icons/social/social-twitter.svg';
import { ReactComponent as SocialWebsiteSVG } from '@/assets/icons/social/social-website.svg';
// Responsive
import { ReactComponent as Category3d16SVG } from '@/assets/icons/responsive/category-3d/16.svg';
import { ReactComponent as Category3d20SVG } from '@/assets/icons/responsive/category-3d/20.svg';
import { ReactComponent as Category3d24SVG } from '@/assets/icons/responsive/category-3d/24.svg';

import { ReactComponent as CategoryApps16SVG } from '@/assets/icons/responsive/category-apps/16.svg';
import { ReactComponent as CategoryApps20SVG } from '@/assets/icons/responsive/category-apps/20.svg';
import { ReactComponent as CategoryApps24SVG } from '@/assets/icons/responsive/category-apps/24.svg';

import { ReactComponent as CategoryAudio16SVG } from '@/assets/icons/responsive/category-audio/16.svg';
import { ReactComponent as CategoryAudio20SVG } from '@/assets/icons/responsive/category-audio/20.svg';
import { ReactComponent as CategoryAudio24SVG } from '@/assets/icons/responsive/category-audio/24.svg';

import { ReactComponent as CategoryGaming16SVG } from '@/assets/icons/responsive/category-gaming/16.svg';
import { ReactComponent as CategoryGaming20SVG } from '@/assets/icons/responsive/category-gaming/20.svg';
import { ReactComponent as CategoryGaming24SVG } from '@/assets/icons/responsive/category-gaming/24.svg';

import { ReactComponent as CategoryImagery16SVG } from '@/assets/icons/responsive/category-imagery/16.svg';
import { ReactComponent as CategoryImagery20SVG } from '@/assets/icons/responsive/category-imagery/20.svg';
import { ReactComponent as CategoryImagery24SVG } from '@/assets/icons/responsive/category-imagery/24.svg';

import { ReactComponent as CategoryOther16SVG } from '@/assets/icons/responsive/category-other/16.svg';
import { ReactComponent as CategoryOther20SVG } from '@/assets/icons/responsive/category-other/20.svg';
import { ReactComponent as CategoryOther24SVG } from '@/assets/icons/responsive/category-other/24.svg';

import { ReactComponent as CategoryVideo16SVG } from '@/assets/icons/responsive/category-video/16.svg';
import { ReactComponent as CategoryVideo20SVG } from '@/assets/icons/responsive/category-video/20.svg';
import { ReactComponent as CategoryVideo24SVG } from '@/assets/icons/responsive/category-video/24.svg';

import { ReactComponent as Copy16SVG } from '@/assets/icons/responsive/copy/16.svg';
import { ReactComponent as Copy20SVG } from '@/assets/icons/responsive/copy/20.svg';
import { ReactComponent as Copy24SVG } from '@/assets/icons/responsive/copy/24.svg';

import { ReactComponent as Close16SVG } from '@/assets/icons/responsive/close/16.svg';
import { ReactComponent as Close20SVG } from '@/assets/icons/responsive/close/20.svg';
import { ReactComponent as Close24SVG } from '@/assets/icons/responsive/close/24.svg';

import { ReactComponent as Edit16SVG } from '@/assets/icons/responsive/edit/16.svg';
import { ReactComponent as Edit20SVG } from '@/assets/icons/responsive/edit/20.svg';
import { ReactComponent as Edit24SVG } from '@/assets/icons/responsive/edit/24.svg';

import { ReactComponent as GridLarge16SVG } from '@/assets/icons/responsive/grid-large/16.svg';
import { ReactComponent as GridLarge20SVG } from '@/assets/icons/responsive/grid-large/20.svg';
import { ReactComponent as GridLarge24SVG } from '@/assets/icons/responsive/grid-large/24.svg';

import { ReactComponent as GridMedium16SVG } from '@/assets/icons/responsive/grid-medium/16.svg';
import { ReactComponent as GridMedium20SVG } from '@/assets/icons/responsive/grid-medium/20.svg';
import { ReactComponent as GridMedium24SVG } from '@/assets/icons/responsive/grid-medium/24.svg';

import { ReactComponent as GridSmall16SVG } from '@/assets/icons/responsive/grid-small/16.svg';
import { ReactComponent as GridSmall20SVG } from '@/assets/icons/responsive/grid-small/20.svg';
import { ReactComponent as GridSmall24SVG } from '@/assets/icons/responsive/grid-small/24.svg';

import { ReactComponent as List16SVG } from '@/assets/icons/responsive/list/16.svg';
import { ReactComponent as List20SVG } from '@/assets/icons/responsive/list/20.svg';
import { ReactComponent as List24SVG } from '@/assets/icons/responsive/list/24.svg';

import { ReactComponent as ArrowDownRight16SVG } from '@/assets/icons/responsive/arrow-down-right/16.svg';
import { ReactComponent as ArrowDownRight20SVG } from '@/assets/icons/responsive/arrow-down-right/20.svg';
import { ReactComponent as ArrowDownRight24SVG } from '@/assets/icons/responsive/arrow-down-right/24.svg';

import { ReactComponent as ArrowLeft16SVG } from '@/assets/icons/responsive/arrow-left/16.svg';
import { ReactComponent as ArrowLeft20SVG } from '@/assets/icons/responsive/arrow-left/20.svg';
import { ReactComponent as ArrowLeft24SVG } from '@/assets/icons/responsive/arrow-left/24.svg';

import { ReactComponent as AlignTopArrow16SVG } from '@/assets/icons/responsive/align-top-arrow/16.svg';
import { ReactComponent as AlignTopArrow20SVG } from '@/assets/icons/responsive/align-top-arrow/20.svg';
import { ReactComponent as AlignTopArrow24SVG } from '@/assets/icons/responsive/align-top-arrow/24.svg';

import { ReactComponent as ArrowDown16SVG } from '@/assets/icons/responsive/arrow-down/16.svg';
import { ReactComponent as ArrowDown20SVG } from '@/assets/icons/responsive/arrow-down/20.svg';
import { ReactComponent as ArrowDown24SVG } from '@/assets/icons/responsive/arrow-down/24.svg';

import { ReactComponent as ArrowDownLeft16SVG } from '@/assets/icons/responsive/arrow-down-left/16.svg';
import { ReactComponent as ArrowDownLeft20SVG } from '@/assets/icons/responsive/arrow-down-left/20.svg';
import { ReactComponent as ArrowDownLeft24SVG } from '@/assets/icons/responsive/arrow-down-left/24.svg';

import { ReactComponent as ArrowRight16SVG } from '@/assets/icons/responsive/arrow-right/16.svg';
import { ReactComponent as ArrowRight20SVG } from '@/assets/icons/responsive/arrow-right/20.svg';
import { ReactComponent as ArrowRight24SVG } from '@/assets/icons/responsive/arrow-right/24.svg';

import { ReactComponent as ArrowUp16SVG } from '@/assets/icons/responsive/arrow-up/16.svg';
import { ReactComponent as ArrowUp20SVG } from '@/assets/icons/responsive/arrow-up/20.svg';
import { ReactComponent as ArrowUp24SVG } from '@/assets/icons/responsive/arrow-up/24.svg';

import { ReactComponent as ArrowUpLeft16SVG } from '@/assets/icons/responsive/arrow-up-left/16.svg';
import { ReactComponent as ArrowUpLeft20SVG } from '@/assets/icons/responsive/arrow-up-left/20.svg';
import { ReactComponent as ArrowUpLeft24SVG } from '@/assets/icons/responsive/arrow-up-left/24.svg';

import { ReactComponent as ArrowUpRight16SVG } from '@/assets/icons/responsive/arrow-up-right/16.svg';
import { ReactComponent as ArrowUpRight20SVG } from '@/assets/icons/responsive/arrow-up-right/20.svg';
import { ReactComponent as ArrowUpRight24SVG } from '@/assets/icons/responsive/arrow-up-right/24.svg';

import { ReactComponent as ChevronDown16SVG } from '@/assets/icons/responsive/chevron-down/16.svg';
import { ReactComponent as ChevronDown20SVG } from '@/assets/icons/responsive/chevron-down/20.svg';
import { ReactComponent as ChevronDown24SVG } from '@/assets/icons/responsive/chevron-down/24.svg';

import { ReactComponent as ChevronLeft16SVG } from '@/assets/icons/responsive/chevron-left/16.svg';
import { ReactComponent as ChevronLeft20SVG } from '@/assets/icons/responsive/chevron-left/20.svg';
import { ReactComponent as ChevronLeft24SVG } from '@/assets/icons/responsive/chevron-left/24.svg';

import { ReactComponent as ChevronRight16SVG } from '@/assets/icons/responsive/chevron-right/16.svg';
import { ReactComponent as ChevronRight20SVG } from '@/assets/icons/responsive/chevron-right/20.svg';
import { ReactComponent as ChevronRight24SVG } from '@/assets/icons/responsive/chevron-right/24.svg';

import { ReactComponent as ChevronUp16SVG } from '@/assets/icons/responsive/chevron-up/16.svg';
import { ReactComponent as ChevronUp20SVG } from '@/assets/icons/responsive/chevron-up/20.svg';
import { ReactComponent as ChevronUp24SVG } from '@/assets/icons/responsive/chevron-up/24.svg';

import { ReactComponent as TransactionMint16SVG } from '@/assets/icons/responsive/transaction-mint/16.svg';
import { ReactComponent as TransactionMint20SVG } from '@/assets/icons/responsive/transaction-mint/20.svg';
import { ReactComponent as TransactionMint24SVG } from '@/assets/icons/responsive/transaction-mint/24.svg';

import { ReactComponent as TransactionSale16SVG } from '@/assets/icons/responsive/transaction-sale/16.svg';
import { ReactComponent as TransactionSale20SVG } from '@/assets/icons/responsive/transaction-sale/20.svg';
import { ReactComponent as TransactionSale24SVG } from '@/assets/icons/responsive/transaction-sale/24.svg';

import { ReactComponent as TransactionTransfer16SVG } from '@/assets/icons/responsive/transaction-transfer/16.svg';
import { ReactComponent as TransactionTransfer20SVG } from '@/assets/icons/responsive/transaction-transfer/20.svg';
import { ReactComponent as TransactionTransfer24SVG } from '@/assets/icons/responsive/transaction-transfer/24.svg';

import { ReactComponent as TransactionList16SVG } from '@/assets/icons/responsive/transaction-list/16.svg';
import { ReactComponent as TransactionList20SVG } from '@/assets/icons/responsive/transaction-list/20.svg';
import { ReactComponent as TransactionList24SVG } from '@/assets/icons/responsive/transaction-list/24.svg';

import { ReactComponent as TransactionDelist16SVG } from '@/assets/icons/responsive/transaction-delist/16.svg';
import { ReactComponent as TransactionDelist20SVG } from '@/assets/icons/responsive/transaction-delist/20.svg';
import { ReactComponent as TransactionDelist24SVG } from '@/assets/icons/responsive/transaction-delist/24.svg';

import { ReactComponent as ExternalLink16SVG } from '@/assets/icons/responsive/external-link/16.svg';
import { ReactComponent as ExternalLink20SVG } from '@/assets/icons/responsive/external-link/20.svg';
import { ReactComponent as ExternalLink24SVG } from '@/assets/icons/responsive/external-link/24.svg';

import { ReactComponent as Magnet16SVG } from '@/assets/icons/responsive/magnet/16.svg';
import { ReactComponent as Magnet20SVG } from '@/assets/icons/responsive/magnet/20.svg';
import { ReactComponent as Magnet24SVG } from '@/assets/icons/responsive/magnet/24.svg';

export type IconNames =
  | 'externalLink'
  | 'check'
  | 'dots'
  | 'dots-vertical'
  | 'bundle'
  | 'arrowLeft'
  | 'arrowDown'
  | 'arrowUpRight'
  | 'cogs'
  | 'magnet'
  | 'magnet-gradient'
  | 'exclamationMark'
  | 'externalLinkPurple'
  | 'downSmall'
  | 'check2'
  | 'filter'
  | 'search'
  | 'download'
  | 'apple'
  | 'android'
  | 'linux'
  | 'windows'
  | 'downloadAndroidApp'
  | 'downloadAndroidTVApp'
  | 'downloadAppleTVApp'
  | 'downloadIOSApp'
  | 'downloadLinuxApp'
  | 'downloadMacOSApp'
  | 'downloadWindowsApp'
  | 'plus'
  | 'grid-large'
  | 'grid-medium'
  | 'grid-small'
  | 'list'
  | 'copy'
  | 'copySmall'
  | 'layers'
  | 'playlistPlay'
  | 'play'
  | 'pause'
  | 'sound-on'
  | 'sound-off'
  | 'fullscreen-on'
  | 'fullscreen-off'
  | 'refresh'
  | 'gem'
  | 'info'
  | 'right'
  | 'xyz-black-and-white'
  | 'stake'
  | 'claim'
  | 'swap'
  | 'withdraw'
  | 'landscape'
  | 'rotate90deg'
  | 'rotate270deg'
  | 'dollar'
  | 'toggle-open'
  | 'toggle-close'
  | 'cogs2'
  | 'alert'
  | 'fee'
  | 'delist'
  | 'listing'
  | 'mint'
  | 'sale'
  | 'transfer'
  | 'calendar'
  | 'time'
  | 'chevronUp'
  | 'chevronDown'
  | 'align-top-arrow'
  | 'arrow-right'
  | 'social-website'
  | 'social-twitter'
  | 'social-discord'
  | 'social-instagram'
  | 'social-medium'
  | 'social-telegram'
  | 'social-etherscan';

export type ResponsiveIconNames =
  | 'category-audio'
  | 'category-apps'
  | 'category-imagery'
  | 'category-3d'
  | 'category-video'
  | 'category-gaming'
  | 'category-other'
  | 'copy'
  | 'grid-large'
  | 'grid-medium'
  | 'grid-small'
  | 'list'
  | 'arrow-down-right'
  | 'arrow-left'
  | 'align-top-arrow'
  | 'arrow-down'
  | 'arrow-down-left'
  | 'arrow-right'
  | 'arrow-up'
  | 'arrow-up-left'
  | 'arrow-up-right'
  | 'chevron-down'
  | 'chevron-left'
  | 'chevron-right'
  | 'chevron-up'
  | 'transaction-mint'
  | 'transaction-list'
  | 'transaction-delist'
  | 'transaction-sale'
  | 'transaction-transfer'
  | 'external-link'
  | 'close'
  | 'magnet'
  | 'edit';

export type ResponsivesIconSize = 'sm' | 'md' | 'lg';

const ICONS: Record<IconNames, React.FC> = {
  externalLink: ExternalLinkSVG,
  check: CheckSVG,
  bundle: BundleSVG,
  dots: DotsSVG,
  'dots-vertical': DotsVerticalSVG,
  arrowLeft: ArrowLeftSVG,
  arrowDown: ArrowDownSVG,
  arrowUpRight: ArrowUpRightSVG,
  cogs: CogsSVG,
  magnet: MagnetSVG,
  'magnet-gradient': MagnetGradientSVG,
  exclamationMark: ExclamationMarkSVG,
  externalLinkPurple: ExternalLinkPurpleSVG,
  downSmall: DownSmallSVG,
  check2: Check2SVG,
  filter: FilterSVG,
  search: SearchSVG,
  download: DownloadSVG,
  apple: AppleSVG,
  android: AndroidSVG,
  linux: LinuxSVG,
  windows: WindowsSVG,
  downloadAndroidApp: DownloadAndroidAppSVG,
  downloadAndroidTVApp: DownloadAndroidTVAppSVG,
  downloadAppleTVApp: DownloadAppleTVAppSVG,
  downloadIOSApp: DownloadIOSAppSVG,
  downloadLinuxApp: DownloadLinuxAppSVG,
  downloadMacOSApp: DownloadMacOSAppSVG,
  downloadWindowsApp: DownloadWindowsAppSVG,
  plus: PlusSVG,
  'grid-small': GridSmallSVG,
  'grid-medium': GridMediumSVG,
  'grid-large': GridLargeSVG,
  list: ListSVG,
  copy: CopySVG,
  copySmall: CopySmallSVG,
  layers: LayersSVG,
  playlistPlay: PlaylistPlaySVG,
  play: PlaySVG,
  pause: PauseSVG,
  'sound-on': SoundOnSVG,
  'sound-off': SoundOffSVG,
  'fullscreen-on': FullscreenOnSVG,
  'fullscreen-off': FullscreenOffSVG,
  refresh: RefreshSVG,
  gem: GemSVG,
  info: InfoSVG,
  right: RightSVG,
  'xyz-black-and-white': XYZBlackAndWhiteSVG,
  stake: StakeSVG,
  claim: ClaimSVG,
  swap: SwapSVG,
  withdraw: WithdrawSVG,
  landscape: LandscapeSVG,
  rotate90deg: Rotate90degSVG,
  rotate270deg: Rotate270degSVG,
  dollar: DollarSVG,
  'toggle-open': ToggleOpenSVG,
  'toggle-close': ToggleCloseSVG,
  cogs2: Cogs2SVG,
  alert: AlertSVG,
  chevronUp: ChevronUpSVG,
  chevronDown: ChevronDownSVG,
  'align-top-arrow': alignTopArrowSVG,
  'arrow-right': ArrowRightSVG,
  fee: FeeSVG,
  delist: DelistSVG,
  listing: ListingSVG,
  mint: MintSVG,
  sale: SaleSVG,
  calendar: CalendarSVG,
  time: TimeSVG,
  transfer: TransferSVG,
  'social-website': SocialWebsiteSVG,
  'social-twitter': SocialTwitterSVG,
  'social-discord': SocialDiscordSVG,
  'social-instagram': SocialInstagramSVG,
  'social-medium': SocialMediumSVG,
  'social-telegram': SocialTelegramSVG,
  'social-etherscan': SocialEtherscanSVG,
};

const RESPONSIVE_ICONS: Record<
  ResponsiveIconNames,
  Record<ResponsivesIconSize, React.FC>
> = {
  'category-audio': {
    lg: CategoryAudio24SVG,
    md: CategoryAudio20SVG,
    sm: CategoryAudio16SVG,
  },
  'category-apps': {
    lg: CategoryApps24SVG,
    md: CategoryApps20SVG,
    sm: CategoryApps16SVG,
  },
  'category-imagery': {
    lg: CategoryImagery24SVG,
    md: CategoryImagery20SVG,
    sm: CategoryImagery16SVG,
  },
  'category-3d': {
    lg: Category3d24SVG,
    md: Category3d20SVG,
    sm: Category3d16SVG,
  },
  'category-video': {
    lg: CategoryVideo24SVG,
    md: CategoryVideo20SVG,
    sm: CategoryVideo16SVG,
  },
  'category-gaming': {
    lg: CategoryGaming24SVG,
    md: CategoryGaming20SVG,
    sm: CategoryGaming16SVG,
  },
  'category-other': {
    lg: CategoryOther24SVG,
    md: CategoryOther20SVG,
    sm: CategoryOther16SVG,
  },
  edit: {
    lg: Edit24SVG,
    md: Edit20SVG,
    sm: Edit16SVG,
  },
  copy: {
    lg: Copy24SVG,
    md: Copy20SVG,
    sm: Copy16SVG,
  },
  close: {
    lg: Close24SVG,
    md: Close20SVG,
    sm: Close16SVG,
  },
  'grid-large': {
    lg: GridLarge24SVG,
    md: GridLarge20SVG,
    sm: GridLarge16SVG,
  },
  'grid-medium': {
    lg: GridMedium24SVG,
    md: GridMedium20SVG,
    sm: GridMedium16SVG,
  },
  'grid-small': {
    lg: GridSmall24SVG,
    md: GridSmall20SVG,
    sm: GridSmall16SVG,
  },
  list: {
    lg: List24SVG,
    md: List20SVG,
    sm: List16SVG,
  },
  'arrow-down-right': {
    lg: ArrowDownRight24SVG,
    md: ArrowDownRight20SVG,
    sm: ArrowDownRight16SVG,
  },
  'arrow-left': {
    lg: ArrowLeft24SVG,
    md: ArrowLeft20SVG,
    sm: ArrowLeft16SVG,
  },
  'align-top-arrow': {
    lg: AlignTopArrow24SVG,
    md: AlignTopArrow20SVG,
    sm: AlignTopArrow16SVG,
  },
  'arrow-down': {
    lg: ArrowDown24SVG,
    md: ArrowDown20SVG,
    sm: ArrowDown16SVG,
  },
  'arrow-down-left': {
    lg: ArrowDownLeft24SVG,
    md: ArrowDownLeft20SVG,
    sm: ArrowDownLeft16SVG,
  },
  'arrow-right': {
    lg: ArrowRight24SVG,
    md: ArrowRight20SVG,
    sm: ArrowRight16SVG,
  },
  'arrow-up': {
    lg: ArrowUp24SVG,
    md: ArrowUp20SVG,
    sm: ArrowUp16SVG,
  },
  'arrow-up-left': {
    lg: ArrowUpLeft24SVG,
    md: ArrowUpLeft20SVG,
    sm: ArrowUpLeft16SVG,
  },
  'arrow-up-right': {
    lg: ArrowUpRight24SVG,
    md: ArrowUpRight20SVG,
    sm: ArrowUpRight16SVG,
  },
  'chevron-down': {
    lg: ChevronDown24SVG,
    md: ChevronDown20SVG,
    sm: ChevronDown16SVG,
  },
  'chevron-left': {
    lg: ChevronLeft24SVG,
    md: ChevronLeft20SVG,
    sm: ChevronLeft16SVG,
  },
  'chevron-right': {
    lg: ChevronRight24SVG,
    md: ChevronRight20SVG,
    sm: ChevronRight16SVG,
  },
  'chevron-up': {
    lg: ChevronUp24SVG,
    md: ChevronUp20SVG,
    sm: ChevronUp16SVG,
  },
  'transaction-mint': {
    lg: TransactionMint24SVG,
    md: TransactionMint20SVG,
    sm: TransactionMint16SVG,
  },
  'transaction-sale': {
    lg: TransactionSale24SVG,
    md: TransactionSale20SVG,
    sm: TransactionSale16SVG,
  },
  'transaction-transfer': {
    lg: TransactionTransfer24SVG,
    md: TransactionTransfer20SVG,
    sm: TransactionTransfer16SVG,
  },
  'transaction-list': {
    lg: TransactionList24SVG,
    md: TransactionList20SVG,
    sm: TransactionList16SVG,
  },
  'transaction-delist': {
    lg: TransactionDelist24SVG,
    md: TransactionDelist20SVG,
    sm: TransactionDelist16SVG,
  },
  'external-link': {
    lg: ExternalLink24SVG,
    md: ExternalLink20SVG,
    sm: ExternalLink16SVG,
  },
  magnet: {
    lg: Magnet24SVG,
    md: Magnet20SVG,
    sm: Magnet16SVG,
  },
};

type ResponsiveIconObject = {
  name: ResponsiveIconNames;
  size?: ResponsivesIconSize;
};

export type IconType = IconNames | ResponsiveIconObject;

export interface IconProps extends BoxProps {
  icon: IconType;
  size?: ResponsivesIconSize;
  viewBox?: string;
}

export const Icon: React.FC<IconProps> = (props) => {
  //
  const { icon, size, ...rest } = props;

  const isResponsive = icon instanceof Object;

  const renderIcon: React.FC = isResponsive
    ? RESPONSIVE_ICONS[icon.name][icon.size || size || 'lg']
    : ICONS[icon];

  const width = renderIcon?.({})?.props?.width;
  const height = renderIcon?.({})?.props?.height;

  return (
    <Box
      as={renderIcon}
      viewBox={width && height ? `0 0 ${width} ${height}` : undefined}
      {...rest}
    />
  );
};
