import {
  Box,
  Flex,
  Link,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  StackDivider,
  Text,
  useClipboard,
  VStack,
} from '@chakra-ui/react';
import React from 'react';

import { Icon, IconButton } from '@/components/ui';
import { Tooltip } from '@/components/ui/tooltip';
import { NFT } from '@/types';
import { formatAddress } from '@/utils';

import s from './tnft-see-more-details.module.sass';

export interface ITNFTSeeMoreDetailsProps {
  NFT: NFT;
}

export const TNFTSeeMoreDetails: React.FC<ITNFTSeeMoreDetailsProps> = (
  props
) => {
  const { NFT } = props;

  return (
    <Popover placement={'bottom-end'}>
      <PopoverTrigger>
        <IconButton
          aria-label="See more details"
          icon={<Icon icon="dots" />}
          size="sm"
          variant="naked"
          colorScheme="gray"
        />
      </PopoverTrigger>
      <PopoverContent className={s.PopoverContent}>
        <PopoverBody>
          <VStack
            align={'stretch'}
            divider={<StackDivider className={s.StackDivider} />}
            spacing={'8px'}
          >
            <TNFTSeeMoreDetail
              label={'Contract Address'}
              value={formatAddress(NFT.contractAddress)}
              copyValue={NFT.contractAddress}
            />
            <TNFTSeeMoreDetail
              label={'Token ID'}
              value={NFT.tokenId}
              copyValue={NFT.tokenId}
            />
            <TNFTSeeMoreDetail
              label={'Magnet Link'}
              value={NFT.metadata?.external_url}
              copyValue={NFT.metadata?.external_url}
            />
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

interface ITNFTSeeMoreDetailProps {
  label: string;
  value: string;
  copyValue?: string;
}

const TNFTSeeMoreDetail: React.FC<ITNFTSeeMoreDetailProps> = (props) => {
  const { label, value, copyValue } = props;

  const { hasCopied, onCopy } = useClipboard(copyValue ?? '');

  return (
    <Box>
      <Text className={s.Label}>{label}</Text>
      {!copyValue ? (
        <Text className={s.Value}>{value}</Text>
      ) : (
        <Flex>
          <Tooltip label={hasCopied ? 'Copied' : 'Copy'}>
            <Link className={s.Value} onClick={() => onCopy()} noOfLines={1}>
              {value}
            </Link>
          </Tooltip>
        </Flex>
      )}
    </Box>
  );
};
