import { Box, BoxProps, Icon } from '@chakra-ui/react';

export const ArrowTopIcon = (props: BoxProps) => {
  //
  return (
    <Box {...props}>
      <Icon
        width="100%"
        height="100%"
        viewBox="0 0 20 20"
        fill="none"
        display="block"
      >
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M9.5 14C9.5 14.2761 9.72386 14.5 10 14.5C10.2761 14.5 10.5 14.2761 10.5 14V7.20711L13.6464 10.3536C13.8417 10.5488 14.1583 10.5488 14.3536 10.3536C14.5488 10.1583 14.5488 9.84171 14.3536 9.64645L10.3536 5.64645C10.3056 5.59851 10.2504 5.56234 10.1914 5.53794C10.1333 5.51385 10.0697 5.5004 10.003 5.50001L10 5.5L9.997 5.50001C9.87004 5.50077 9.74332 5.54958 9.64645 5.64645L5.64645 9.64645C5.45118 9.84171 5.45118 10.1583 5.64645 10.3536C5.84171 10.5488 6.15829 10.5488 6.35355 10.3536L9.5 7.20711V14Z"
          fill="black"
        />
      </Icon>
    </Box>
  );
};
