import { useTheme } from '@/hooks';
import ContentLoader, { IContentLoaderProps } from 'react-content-loader';

export const Skeleton = (props: IContentLoaderProps) => {
  //
  const { speed = 2, width, height, viewBox, children, ...rest } = props;

  const { isLight } = useTheme();

  const backgroundColor = isLight ? '#e6e6e6' : '#1d1832';
  const foregroundColor = isLight ? '#f3f3f3' : '#272043';

  return (
    <ContentLoader
      speed={speed}
      width={width}
      height={height}
      viewBox={viewBox}
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
      {...rest}
    >
      {children}
    </ContentLoader>
  );
};
