import { useQuery } from '@tanstack/react-query';
import { AlchemyAPI } from '@/api/alchemy';

export const useNFTOwner = (address: string | undefined, tokenId: string | number | undefined) => {
  const { data, ...rest } = useQuery({
    enabled: !!address && !!tokenId,
    queryKey: ['nft-owners', address, tokenId],
    queryFn: () => AlchemyAPI.nft.getOwnersForNft(address, tokenId),
  });

  return {
    owners: data?.owners ?? [],
    ...rest,
  };
};