import {
  Textarea as TextareaChakra,
  TextareaProps as TextareaChakraProps,
  useStyleConfig,
} from '@chakra-ui/react';
import React from 'react';

export const Textarea = React.forwardRef<
  HTMLTextAreaElement,
  TextareaChakraProps
>((props, ref) => {
  //
  const { placeholder, variant, size, ...rest } = props;

  const styles = useStyleConfig('Textarea', { variant, size });

  return (
    <TextareaChakra
      size={size}
      ref={ref}
      __css={styles}
      placeholder={placeholder}
      {...rest}
    />
  );
});

Textarea.displayName = 'Textarea';
