import { ReservoirAPI, TorrentAPI } from '@/utils/axios';

type Params = {
  continuation: string | null;
  limit?: number;
  sort?: 'floorAskPrice';
  /** @desription 1 - Sort ascending. -1 - Sort descending. */
  sortOrder?: 1 | -1;
};

export type Test = {
  _id: string;
};

export const fetchMarketplaceTnfts = async (values) => {
  //
  const { filters, limit, contractAddress } = values;

  const attributes = filters?.properties
    ? normalizePropertiesQuery(filters?.properties)
    : undefined;

  const params = {
    limit: limit ?? 20,
    continuation: values?.params?.pageParam,
    contractAddress: contractAddress || filters?.collection?.contractAddress,
    sort: filters?.sorting.field,
    sortOrder: filters?.sorting.order,
    query: filters?.search.length > 0 ? filters?.search : undefined,
    minPrice:
      filters?.priceRange?.min.length > 0
        ? filters?.priceRange?.min
        : undefined,
    maxPrice:
      filters?.priceRange?.max.length > 0
        ? filters?.priceRange?.min
        : undefined,
    ...attributes,
  };

  const { data } = await TorrentAPI.get('/marketplace/tnfts', { params });

  return data;
};

export const fetchMarketplaceTNFT = async (token: string) => {
  //
  const params = {
    tokens: [token],
  };

  const { data } = await TorrentAPI.get('/marketplace/tnfts', { params });

  return data;
};

export const fetchMarketplaceTCollections = async (params) => {
  //
  const { data } = await TorrentAPI.get('/marketplace/t-collections', {
    params: {
      query:
        params?.filters?.search?.length > 0
          ? params?.filters?.search
          : undefined,
      sortBy: params?.filters?.sorting,
      continuation: params?.params?.pageParam,
      limit: 20,
    },
  });

  return data;
};

export const fetchMarketplaceTCollectionByAddress = async (address) => {
  //
  const { data } = await TorrentAPI.get('/marketplace/t-collections', {
    params: {
      contractAddress: address,
      limit: 1,
    },
  });

  return data;
};

export const fetchMarketplaceTCollectionActivity = async ({
  params,
  address,
  types,
}: {
  params: any;
  address: string;
  types: string[];
}) => {
  //
  const { data } = await ReservoirAPI.get('/collections/activity/v5', {
    params: {
      continuation: params?.pageParam,
      collection: address,
      limit: 10,
      includeMetadata: true,
      types: types,
    },
  });

  return data;
};

export const fetchMarketplaceCollectionProperties = async (address: string) => {
  //
  const { data } = await ReservoirAPI.get(
    `/collections/${address}/attributes/all/v2`,
    {
      params: {},
    }
  );

  return data;
};

type OrderStatuses =
  | 'active'
  | 'inactive'
  | 'expired'
  | 'cancelled'
  | 'filled'
  | 'any';

// Available when filtering by maker, otherwise only valid orders will be returned

export const fetchCollectionOrders = async (
  contracts: string[],
  status: OrderStatuses
) => {
  //
  const { data } = await ReservoirAPI.get(`/orders/asks/v4`, {
    params: {
      contracts: contracts,
      status: status,
    },
  });

  return data;
};

const normalizePropertiesQuery = (properties) => {
  //
  const normalizedProperties = {};

  for (const [key, value] of Object.entries(properties)) {
    normalizedProperties[`attributes[${key}]`] = value[0];
  }

  return normalizedProperties;
};
