import { NETWORK_CHAIN_ID } from './NETWORK_CHAIN_ID';

export const ETHERSCAN_LINKS = {
  1: {
    address: 'https://etherscan.io/address',
    hash: 'https://etherscan.io/tx',
  },
  4: {
    address: 'https://rinkeby.etherscan.io/address',
    hash: 'https://rinkeby.etherscan.io/tx',
  },
  5: {
    address: 'https://goerli.etherscan.io/address',
    hash: 'https://goerli.etherscan.io/tx',
  },
};

export const ETHERSCAN_LINK = ETHERSCAN_LINKS[NETWORK_CHAIN_ID];
