import { InfoTooltip } from '@/components/common';
import { InfoTooltipProps } from '@/components/common/info-tooltip';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';

type ChildrenType = 'input' | 'select' | 'switch' | 'elastic-switch';

interface IFormItemProps {
  children: React.ReactNode;
  name: string;
  type: ChildrenType;
  label?: string;
  description?: string;
  required?: boolean;
  showErrorMessage?: boolean;
  maxLength?: number;
  tooltip?: string | (() => React.ReactNode);
  variant?: string;
  descriptionColor?: string;
}

export const FormItem: React.FC<IFormItemProps> = (props) => {
  //
  const {
    children,
    type,
    name,
    label = null,
    description,
    variant,
    showErrorMessage = true,
    required = false,
    tooltip = null,
    maxLength = null,
    descriptionColor,
    //
  } = props;

  const { control } = useFormContext();

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const { onChange, onBlur, value } = field;

  const formItemId = `${name}-form-field`;

  const internalOnChange = (props, ...value) => {
    // send data to react hook form

    switch (type) {
      //
      default:
        // update form state
        onChange(...value);
        // call child onChange if it exist
        props.onChange && props.onChange(...value);
        break;
    }
  };

  const childrenWithProps = React.Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a typescript error too.
    if (React.isValidElement(child)) {
      const newProps: any = {
        id: formItemId,
        onChange: (...rest) => internalOnChange(child?.props, ...rest),
        onBlur,
        'aria-invalid': Boolean(error),
      };

      switch (type) {
        case 'switch':
          newProps.isChecked = value;
          break;
        default:
          newProps.value = value;
      }

      return React.cloneElement(child, newProps);
    }
    return child;
  });

  const styles = useMultiStyleConfig('FormItem', { variant });

  return (
    <FormControl isInvalid={Boolean(error)} sx={styles.wrapper}>
      {label && (
        <FormLabel id={`${name}-label`} htmlFor={formItemId} sx={styles.label}>
          <Flex align="center">
            <Box data-label>{label}</Box>
            {required && <RequiredMark />}
            {tooltip &&
              (typeof tooltip === 'string' ? (
                <LabelTooltip tooltip={tooltip} />
              ) : (
                tooltip()
              ))}
            {maxLength && (
              <ValueLengthCounter maxLength={maxLength} value={field.value} />
            )}
          </Flex>
        </FormLabel>
      )}
      {childrenWithProps}
      {!Boolean(error) ? (
        <>
          {description && (
            <FormHelperText sx={styles.description} color={descriptionColor}>
              {description}
            </FormHelperText>
          )}
        </>
      ) : (
        <>
          {showErrorMessage && (
            <FormErrorMessage sx={styles.error}>
              {error?.message}
            </FormErrorMessage>
          )}
        </>
      )}
    </FormControl>
  );
};

const RequiredMark = () => (
  <Box ml="2px" role="presentation" aria-hidden="true" color="red">
    *
  </Box>
);

interface ILabelTooltipProps extends InfoTooltipProps {
  tooltip: string;
}

export const LabelTooltip: React.FC<ILabelTooltipProps> = ({
  tooltip,
  ...rest
}) => (
  <Box ml="6px">
    <InfoTooltip label={tooltip} {...rest} />
  </Box>
);

interface IValueLengthCounterProps {
  value: string;
  maxLength: number;
}

const ValueLengthCounter: React.FC<IValueLengthCounterProps> = (props) => {
  const { value, maxLength } = props;

  const color = value?.length > maxLength ? 'red' : '#999';

  return (
    <Box ml="auto" fontSize="11px" fontWeight="400" color={color}>
      {value?.length} / {maxLength}
    </Box>
  );
};
