import { FC } from 'react';

import { coinbaseWallet } from '@/connectors/coinbaseWallet';
import { IConnectorButton } from '@/components/modules/home';

import { ConnectWalletItem } from '../connect-wallet-item';
import { Logo } from './components';

export const Coinbase: FC<IConnectorButton> = ({ onConnect }) => {

  const handleConnect = () => {
    onConnect(coinbaseWallet, 'COINBASE WALLET');
  };

  return (
    <>
      <ConnectWalletItem onClick={() => handleConnect()}>
        <Logo />
      </ConnectWalletItem>
    </>
  );
};
