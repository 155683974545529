import { useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';

import { getAllPlaylists } from '@/api/playlists';
import { INFTModalProps, NFTModal } from '@/components/common';
import { Playlist } from '@/types';

import {
  NFTModalAddToPlaylistSidebar,
  NFTModalCreateNewPlaylistSidebar,
  NFTModalDetailsSidebar,
} from './components';

type INFTDetailsModalProps = Omit<INFTModalProps, 'children'>;

export const NFTDetailsModal: React.FC<INFTDetailsModalProps> = (props) => {
  const { NFT, isOpen } = props;

  type IState = 'details' | 'addToPlaylist' | 'createNewPlaylist';

  const [state, setState] = useState<IState>('details');

  const queryClient = useQueryClient();

  const { data: allPlaylists } = useQuery<Playlist[]>(
    ['allPlaylists'],
    getAllPlaylists,
    {
      enabled: isOpen && !queryClient.getQueryData(['allPlaylists']),
    }
  );

  useEffect(() => setState('details'), [isOpen]);

  return (
    <NFTModal {...props}>
      {state === 'details' && (
        <NFTModalDetailsSidebar
          NFT={NFT}
          playlists={allPlaylists}
          onAddToPlaylist={() => setState('addToPlaylist')}
        />
      )}

      {(state === 'addToPlaylist' || state === 'createNewPlaylist') && (
        <NFTModalAddToPlaylistSidebar
          visible={state === 'addToPlaylist'}
          NFT={NFT}
          playlists={allPlaylists}
          onAdded={() => setState('details')}
          onCancel={() => setState('details')}
          onAddNewPlaylist={() => setState('createNewPlaylist')}
        />
      )}

      {state === 'createNewPlaylist' && (
        <NFTModalCreateNewPlaylistSidebar
          NFT={NFT}
          onCreated={() => setState('details')}
          onBack={() => setState('addToPlaylist')}
        />
      )}
    </NFTModal>
  );
};
