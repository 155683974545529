import { alertAnatomy as parts } from '@chakra-ui/anatomy';
import {
  createMultiStyleConfigHelpers,
  cssVar,
  StyleFunctionProps,
} from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const $fg = cssVar('alert-fg');
const $bg = cssVar('alert-bg');
const $borderColor = cssVar('alert-border');

const getColorSchemeColorName = (props: StyleFunctionProps) => {
  const { colorScheme: cs } = props;

  switch (cs) {
    case 'blue':
    default:
      return 'blueTransparent';
  }
}

const variantSubtle = definePartsStyle((props) => {
  const colorName = getColorSchemeColorName(props);

  return {
    container: {
      alignItems: 'flex-start',
      bg: $bg.reference,
      color: $fg.reference,
      border: '1px solid',
      borderColor: $borderColor.reference,
      borderRadius: '12px',
      fontWeight: '400',
      fontSize: '14px',
      gap: '16px',
      lineHeight: '20px',
      padding: '16px',

      [$fg.variable]: `colors.${colorName}.100`,
      [$bg.variable]: `colors.${colorName}.4`,
      [$borderColor.variable]: `colors.${colorName}.40`,
      _dark: {
        [$fg.variable]: `colors.${colorName}.100`,
        [$bg.variable]: `colors.${colorName}.8`,
      },
    },
  }
});

const variants = {
  subtle: variantSubtle,
}

export const Alert = defineMultiStyleConfig({
  variants,
  defaultProps: {
    variant: 'subtle',
    colorScheme: 'blue',
  },
})