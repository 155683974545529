import { Icon, IconProps } from '@/components/ui';
import { ISelectOption } from '@/components/ui/select';
import { Box } from '@chakra-ui/layout';
import React from 'react';

type IRotationOptionProps = Pick<ISelectOption, 'title'> & {
  showCheckmark: boolean;
  icon: IconProps['icon'];
};

export const RotationOption: React.FC<IRotationOptionProps> = (props) => {
  const { title, showCheckmark, icon } = props;

  return (
    <Box
      alignItems={'center'}
      display="inline-flex"
      gap={'8px'}
      lineHeight={'20px'}
      textAlign={'left'}
      w="100%"
    >
      <Icon icon={icon} />
      <Box flex={1}>{title}</Box>
      {showCheckmark && (
        <Box flexShrink={0}>
          <Icon icon="check2" />
        </Box>
      )}
    </Box>
  );
};
