import { mode } from '@chakra-ui/theme-tools';

export const Switch = {
  // style object for base or default style
  baseStyle: (props) => ({
    thumb: {
      background: mode('white', 'white')(props),
      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',

      _hover: {
        boxShadow: '0px 0px 0px 4px rgba(0, 0, 0, 0.16)',
      },
      _checked: {
        background: 'white',
      },
    },

    track: {
      background: mode('blackTransparent.10', 'whiteTransparent.20')(props),
      padding: '3px',
      transition: 'background 200ms linear',

      _hover: {
        // background: mode('blackTransparent.20', 'whiteTransparent.20')(props),
      },

      _checked: {
        background: mode('purpleGradient', 'purpleGradient')(props),
      },

      _focus: {
        boxShadow: '0px 0px 0px 4px rgba(188, 94, 190, 0.16)',
      },
    },
  }),
  // styles for different sizes ("sm", "md", "lg")
  sizes: {
    md: {
      track: {
        borderRadius: '8px',
        width: '34px',
        height: '16px',
      },
      thumb: {
        borderRadius: '4px',
        width: '16px',
        height: '16px',

        _checked: {
          transform: 'translateX(16px)',
        },
      },
    },
  },
  // styles for different visual variants ("outline", "solid")
  variants: {},
  // default values for `size` and `variant`
  defaultProps: {},
};
