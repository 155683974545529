import { Select } from '@/components/ui';
import { HStack, Text } from '@chakra-ui/react';

type Props = {
  current: number;
  pageSizes: number[];
  onSizeChange: (size: number) => void;
};

export const SizeSelector = ({ current, onSizeChange, pageSizes }: Props) => {
  const options = pageSizes.map((size) => ({
    title: ` ${size}`,
    value: size,
  }));
  return (
    <HStack pl="10px">
      <Text fontSize={'14px'}>Items per page</Text>
      <Select
        size="sm"
        options={options}
        defaultValue={current}
        value={current}
        placement={'top'}
        onChange={onSizeChange}
      />
    </HStack>
  );
};
