import { Web3ReactHooks } from '@web3-react/core';
import { Connector } from '@web3-react/types';

import { hooks as metaMaskHooks, metaMask } from '@/connectors/metaMask';
import { hooks as walletConnectHooks, walletConnect } from '@/connectors/walletConnect';
import { hooks as coinbaseWalletHooks, coinbaseWallet } from '@/connectors/coinbaseWallet';

export const connectors: [Connector, Web3ReactHooks][] = [
  [metaMask, metaMaskHooks],
  [walletConnect, walletConnectHooks],
  [coinbaseWallet, coinbaseWalletHooks],
];
