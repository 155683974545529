import { Icon, IconProps } from '@chakra-ui/react';

export const HandleIcon = (props: IconProps) => {
  //
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="60px"
      height="56px"
      viewBox="0 0 60 56"
      fill="none"
      display="block"
      {...props}
    >
      <path
        d="M18 10C18 12.2091 16.2091 14 14 14C11.7909 14 10 12.2091 10 10C10 7.79086 11.7909 6 14 6C16.2091 6 18 7.79086 18 10Z"
        fill="black"
      />
      <path
        d="M34 10C34 12.2091 32.2091 14 30 14C27.7909 14 26 12.2091 26 10C26 7.79086 27.7909 6 30 6C32.2091 6 34 7.79086 34 10Z"
        fill="black"
      />
      <path
        d="M50 10C50 12.2091 48.2091 14 46 14C43.7909 14 42 12.2091 42 10C42 7.79086 43.7909 6 46 6C48.2091 6 50 7.79086 50 10Z"
        fill="black"
      />
      <path
        d="M18 28C18 30.2091 16.2091 32 14 32C11.7909 32 10 30.2091 10 28C10 25.7909 11.7909 24 14 24C16.2091 24 18 25.7909 18 28Z"
        fill="black"
      />
      <path
        d="M34 28C34 30.2091 32.2091 32 30 32C27.7909 32 26 30.2091 26 28C26 25.7909 27.7909 24 30 24C32.2091 24 34 25.7909 34 28Z"
        fill="black"
      />
      <path
        d="M50 28C50 30.2091 48.2091 32 46 32C43.7909 32 42 30.2091 42 28C42 25.7909 43.7909 24 46 24C48.2091 24 50 25.7909 50 28Z"
        fill="black"
      />
      <path
        d="M18 46C18 48.2091 16.2091 50 14 50C11.7909 50 10 48.2091 10 46C10 43.7909 11.7909 42 14 42C16.2091 42 18 43.7909 18 46Z"
        fill="black"
      />
      <path
        d="M34 46C34 48.2091 32.2091 50 30 50C27.7909 50 26 48.2091 26 46C26 43.7909 27.7909 42 30 42C32.2091 42 34 43.7909 34 46Z"
        fill="black"
      />
      <path
        d="M50 46C50 48.2091 48.2091 50 46 50C43.7909 50 42 48.2091 42 46C42 43.7909 43.7909 42 46 42C48.2091 42 50 43.7909 50 46Z"
        fill="black"
      />
    </Icon>
  );
};
