import { Flex, HStack, VStack } from '@chakra-ui/react';
import React from 'react';

import { NFTDetailsModalStickyBar } from '@/components/common';
import { Skeleton } from '@/components/ui';

export const NFTMarketplaceSidebarStickybarSkeleton: React.FC = () => {
  return (
    <NFTDetailsModalStickyBar>
      <Flex alignItems={'center'} justifyContent={'space-between'}>
        <VStack align={'stretch'} spacing={'4px'}>
          <HStack spacing={'4px'}>
            <Skeleton width={20} height={20} viewBox="0 0 20 20">
              <rect width="20" height="20" rx="10" />
            </Skeleton>

            <Skeleton width={40} height={20} viewBox="0 0 40 20">
              <rect width="40" height="20" rx="8" />
            </Skeleton>

            <Skeleton width={48} height={16} viewBox="0 0 48 16">
              <rect width="48" height="16" rx="8" />
            </Skeleton>
          </HStack>

          <Skeleton width={154} height={16} viewBox="0 0 154 16">
            <rect width="154" height="16" rx="8" />
          </Skeleton>
        </VStack>

        <Skeleton width={90} height={40} viewBox="0 0 90 40">
          <rect width="90" height="40" rx="8" />
        </Skeleton>
      </Flex>
    </NFTDetailsModalStickyBar>
  );
};