import { Link, Text } from '@chakra-ui/layout';
import { Box, TextProps } from '@chakra-ui/react';
import React, { useState } from 'react';

import { Icon } from '@/components/ui';

import s from './text-read-more-less.module.sass';

export interface ITextReadMoreLessProps extends TextProps {
  numberOfLines?: number;
  readMoreLabel?: string;
  readLessLabel?: string;
  renderButton?: (
    expanded: boolean,
    toggleExpanded: () => void
  ) => React.ReactNode;
}

export const TextReadMoreLess: React.FC<ITextReadMoreLessProps> = (props) => {
  const {
    numberOfLines = 2,
    readMoreLabel = 'Read more',
    readLessLabel = 'Read less',
    renderButton = (expanded, toggleExpanded) => (
      <Link className={s.ExpandLink} onClick={toggleExpanded}>
        <Box as={'span'}>{!expanded ? readMoreLabel : readLessLabel}</Box>
        <Icon
          icon={'arrowDown'}
          transform={expanded ? 'rotate(180deg)' : undefined}
        />
      </Link>
    ),
    ...rest
  } = props;

  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <Text as={'div'} noOfLines={!expanded ? numberOfLines : undefined} {...rest} />
      {renderButton?.(expanded, () => setExpanded(!expanded))}
    </>
  );
};
