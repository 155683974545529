export function getFileType(type: string, name?: string) {
  //
  // const lastIndex = name.lastIndexOf(".");
  // const extension = name.substring(lastIndex + 1);

  const isVideo = type?.startsWith('video');
  const isAudio = type?.startsWith('audio');
  const isImage = type?.startsWith('image');
  const isUnknown =
    type?.length === 0 || type?.startsWith('application') || type === 'unknown';

  return { isVideo, isAudio, isImage, isUnknown };
}
