import { Icon, IconProps } from '@chakra-ui/react';

export const InfoIcon = (props: IconProps) => {
  //
  return (
    <>
      <Icon
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 34 34"
        fill="none"
        display="block"
        {...props}
      >
        <path
          d="M17.6545 9.69792C18.0687 9.69792 18.4045 10.0337 18.4045 10.4479V11.7583C18.4045 12.1725 18.0687 12.5083 17.6545 12.5083C17.2403 12.5083 16.9045 12.1725 16.9045 11.7583V10.4479C16.9045 10.0337 17.2403 9.69792 17.6545 9.69792Z"
          fill="black"
        />
        <path
          d="M15.0336 14.9399L17.6548 14.9395C17.8537 14.9395 18.0445 15.0185 18.1852 15.1592C18.3259 15.2998 18.4049 15.4906 18.4049 15.6895V23.552C18.4049 23.9662 18.0691 24.302 17.6549 24.302C17.2407 24.302 16.9049 23.9662 16.9049 23.552V16.4396L15.0338 16.4399C14.6196 16.4399 14.2837 16.1042 14.2837 15.69C14.2836 15.2757 14.6194 14.9399 15.0336 14.9399Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.14597 17C3.14597 9.34856 9.34869 3.14583 17.0001 3.14583C24.6516 3.14583 30.8543 9.34856 30.8543 17C30.8543 24.6514 24.6516 30.8542 17.0001 30.8542C9.34869 30.8542 3.14597 24.6514 3.14597 17ZM17.0001 4.64583C10.1771 4.64583 4.64597 10.177 4.64597 17C4.64597 23.823 10.1771 29.3542 17.0001 29.3542C23.8231 29.3542 29.3543 23.823 29.3543 17C29.3543 10.177 23.8231 4.64583 17.0001 4.64583Z"
          fill="black"
        />
      </Icon>
    </>
  );
};
