import ReactDOM from 'react-dom';
import screenfull from 'screenfull';

import { useCallback, useContext, useEffect, useState } from 'react';

import { PlayerContext } from '../context';

interface Props {
  player: any;
  wrapper?: any;
  light?: boolean;
}

const initialValues = {
  playing: false,
  paused: false,
  muted: false,
  played: 0,
  seeking: false,
  duration: 0,
  buffer: false,
  loaded: false,
  mounted: false,
  fullscreen: false,
};

const usePlayerState = (props: Props) => {
  //
  const { player, wrapper, light = false } = props;

  const context = useContext(PlayerContext);

  const [playing, setPlaying] = useState(initialValues.playing);
  const [paused, setPaused] = useState(initialValues.paused);
  const [muted, setMuted] = useState(initialValues.muted);
  const [played, setPlayed] = useState(initialValues.played);
  const [seeking, setSeeking] = useState(initialValues.seeking);
  const [duration, setDuration] = useState(initialValues.duration);
  const [buffer, setBuffer] = useState(initialValues.buffer);
  const [loaded, setLoaded] = useState(initialValues.loaded);
  const [mounted, setMounted] = useState(initialValues.mounted);
  const [fullscreen, setFullscreen] = useState(initialValues.fullscreen);

  useEffect(() => {
    if (light) {
      setMounted(false);
    } else {
      setMounted(true);
    }
  }, [light]);

  useEffect(() => {
    if (screenfull.isEnabled) {
      screenfull.on('change', onFullscreenChange);
    }

    return () => {
      screenfull.off('change', onFullscreenChange);
    };
  });

  const onFullscreenChange = useCallback(() => {
    //
    setFullscreen((state) => !state);
  }, []);

  const handlePlay = useCallback(() => {
    //
    setPlaying(true);
    setPaused(false);
  }, []);

  const handlePause = useCallback(() => {
    //
    setPlaying(false);
    setPaused(true);
  }, []);

  const togglePlaying = useCallback(() => {
    //
    setPlaying((state) => !state);
    setPaused((state) => !state);
  }, []);

  const handleMute = useCallback(() => {
    //
    setMuted(true);
  }, []);

  const handleUnmute = useCallback(() => {
    //
    setMuted(false);
  }, []);

  const toggleSound = useCallback(() => {
    //
    setMuted((state) => !state);
  }, []);

  const onPlayedChange = useCallback((value) => {
    // We only want to update time slider if we are not currently seeking
    if (!seeking) {
      setPlayed(value.played);
    }
  }, []);

  const onSeekingStart = useCallback(() => {
    //
    setSeeking(true);
    setPlaying(false);
  }, []);

  const onSeekingEnd = useCallback((played: number) => {
    //
    setSeeking(false);
    player.current.seekTo(played);
    setPlayed(played);
    setPlaying(true);
  }, []);

  const onDuration = useCallback((duration) => {
    //
    setDuration(duration);
  }, []);

  const onEnded = useCallback(() => {
    //
    setPlaying(false);
  }, []);

  const onBuffer = useCallback(() => {
    //
    setBuffer(true);
  }, []);

  const onBufferEnd = useCallback(() => {
    //
    setBuffer(false);
  }, []);

  const onLoaded = useCallback(() => {
    //
    setLoaded(true);
  }, []);

  const handleFullscreenOn = useCallback(() => {
    //
    if (screenfull.isEnabled) {
      const PlayerNode: any = ReactDOM.findDOMNode(wrapper.current); //eslint-disable-line react/no-find-dom-node
      screenfull.request(PlayerNode);
    }
  }, []);

  const handleFullscreenOff = useCallback(() => {
    //
    if (screenfull.isEnabled) {
      screenfull.exit();
    }
  }, []);

  const toggleFullscreen = useCallback(() => {
    //
    if (screenfull.isEnabled) {
      if (screenfull.isFullscreen) {
        handleFullscreenOff();
      } else {
        handleFullscreenOn();
      }
    }
  }, []);

  const handleResetStates = () => {
    //
    setPlaying(initialValues.playing);
    setPaused(initialValues.paused);
    setMuted(initialValues.muted);
    setPlayed(initialValues.played);
    setSeeking(initialValues.seeking);
    setDuration(initialValues.duration);
    setBuffer(initialValues.buffer);
    setLoaded(initialValues.loaded);
    setMounted(initialValues.mounted);
    setFullscreen(initialValues.fullscreen);
  };

  const onClickPreview = () => {
    //
    player.current.handleClickPreview();
    setPlaying(true);
    setMounted(true);

    if (context) {
      context.updateList(player, handleResetStates);
    }
  };

  return {
    // states
    context,
    playing,
    paused,
    muted,
    played,
    seeking,
    duration,
    buffer,
    loaded,
    mounted,
    fullscreen,
    // handlers
    handlePlay,
    handlePause,
    handleMute,
    handleUnmute,
    handleFullscreenOn,
    handleFullscreenOff,
    // toggls
    togglePlaying,
    toggleSound,
    toggleFullscreen,
    // events
    onPlayedChange,
    onSeekingStart,
    onSeekingEnd,
    onDuration,
    onEnded,
    onBuffer,
    onBufferEnd,
    onLoaded,
    onClickPreview,
  };
};

export { usePlayerState };
