import React, { useCallback } from 'react';

import { Box, BoxProps, HStack } from '@chakra-ui/react';

import cn from 'classnames';
import s from './elastic-switch.module.sass';

type IElasticSwitchSize = 'sm' | 'md' | 'lg';

type Value = number | string | boolean;

export interface IElasticSwitchItem<T = Value> {
  value: T;
  label?: string;
  isDisabled?: boolean;
  icon?: React.ReactNode;
  renderLabel?: () => React.ReactNode;
}

interface IElasticSwitchProps extends Omit<BoxProps, 'onChange'> {
  radioBoxProps?: BoxProps;
  size?: IElasticSwitchSize;
  items: IElasticSwitchItem[];
  value?: Value;
  onChange?: (_: Value) => void;
  isDisabled?: boolean;
  listProps?: BoxProps;
}

const WRAPPER_SIZE_STYLES: Record<IElasticSwitchSize, string> = {
  sm: s['Size--sm'],
  md: s['Size--md'],
  lg: s['Size--lg'],
};

const ITEM_SIZE_STYLES: Record<IElasticSwitchSize, string> = {
  sm: s.Item__sm,
  md: s.Item__md,
  lg: s.Item__lg,
};

export const ElasticSwitch: React.FC<IElasticSwitchProps> = (props) => {
  //
  const {
    radioBoxProps = {},
    size = 'md',
    items,
    value,
    isDisabled,
    onChange,
    className,
    listProps = {},
    ...rest
  } = props;

  const { className: classNameListProps, ...restListProps } = listProps;

  console.log('classNameListProps', classNameListProps);
  console.log('restListProps', restListProps);

  const handleItemSelect = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>, value: Value) => {
      onChange && onChange(value);
    },
    [onChange]
  );

  return (
    <Box
      className={cn(
        s.Wrapper,
        WRAPPER_SIZE_STYLES[size],
        isDisabled && s.isDisabled,
        className
      )}
      display={'inline-block'}
      {...rest}
    >
      <HStack
        className={cn(s.List, classNameListProps)}
        spacing="4px"
        role="radiogroup"
        aria-orientation="horizontal"
        aria-labelledby="Elastic Switch"
        {...restListProps}
      >
        {items.map((item: IElasticSwitchItem, index: number) => {
          //
          const isSelected = value === item.value;

          const accessibility = {
            role: 'radio',
            'aria-checked': isSelected,
            'aria-labelledby': item.label,
            'aria-disabled': item.isDisabled ?? null,
            tabIndex: isSelected ? 0 : -1,
          };

          return (
            <Box
              key={index}
              {...radioBoxProps}
              className={cn(
                s.Item,
                ITEM_SIZE_STYLES[size],
                isSelected && s['Item--Active'],
                item.isDisabled && s['Item--Disabled'],
                !item.label && s['Item--WithoutLabel']
              )}
              onClick={(e) => handleItemSelect(e, item.value)}
              {...accessibility}
            >
              <HStack display="inline-flex" h="100%" spacing={0}>
                {item.renderLabel ? (
                  item.renderLabel()
                ) : (
                  <>
                    {item.icon && <Box className={s.Icon}>{item.icon}</Box>}
                    {item.label && (
                      <Box className={s.Title} id={item.label}>
                        {item.label}
                      </Box>
                    )}
                  </>
                )}
              </HStack>
            </Box>
          );
        })}
      </HStack>
    </Box>
  );
};
