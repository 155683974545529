import React from 'react';
import { Box, BoxProps, Icon } from '@chakra-ui/react';

export const WebsiteIcon = (props: BoxProps) => {
  //
  return (
    <Box {...props}>
      <Icon
        width="100%"
        height="100%"
        viewBox="0 0 20 20"
        fill="none"
        display="block"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.99971 16.8571C10.0866 16.8571 10.265 16.8209 10.5315 16.5578C10.8029 16.29 11.0997 15.8457 11.3737 15.2066C11.6521 14.5567 11.8838 13.7597 12.0438 12.8571H7.95566C8.11573 13.7598 8.34736 14.5567 8.6258 15.2066C8.89978 15.8457 9.19657 16.29 9.46802 16.5578C9.73447 16.8209 9.91292 16.8571 9.99977 16.8571H9.99971ZM7.79817 11.7142C7.74345 11.1679 7.71399 10.5941 7.71399 9.99993C7.71399 9.40581 7.74345 8.83193 7.79817 8.28565H12.2011C12.2558 8.83193 12.2853 9.40581 12.2853 9.99993C12.2853 10.5941 12.2558 11.1679 12.2011 11.7142H7.79817ZM13.2032 12.8571C12.9551 14.3708 12.5156 15.6638 11.9536 16.5746C13.8587 16.0093 15.4174 14.6387 16.2351 12.8571H13.2032ZM16.6409 11.7142H13.3495C13.4012 11.1619 13.4283 10.5883 13.4283 9.99993C13.4283 9.41153 13.4012 8.83794 13.3495 8.28565H16.6409C16.7819 8.8336 16.8569 9.40797 16.8569 9.99993C16.8569 10.5919 16.7819 11.1663 16.6409 11.7142ZM6.65009 11.7142H3.35866C3.21759 11.1663 3.14259 10.5919 3.14259 9.99993C3.14259 9.40797 3.21759 8.8336 3.35866 8.28565H6.65009C6.59831 8.83794 6.57113 9.41153 6.57113 9.99993C6.57113 10.5883 6.5983 11.1619 6.65009 11.7142ZM3.76438 12.8571H6.79628C7.04437 14.3708 7.48402 15.6638 8.04591 16.5746C6.1408 16.0093 4.58207 14.6387 3.76444 12.8571H3.76438ZM7.95572 7.14279H12.0439C11.8838 6.2401 11.6522 5.44317 11.3737 4.79331C11.0998 4.15415 10.803 3.70988 10.5315 3.44202C10.2651 3.17901 10.0866 3.14279 9.99977 3.14279C9.91292 3.14279 9.73447 3.17901 9.46802 3.44202C9.19659 3.70988 8.89979 4.15415 8.6258 4.79331C8.34735 5.44317 8.11572 6.24013 7.95566 7.14279H7.95572ZM13.2034 7.14279H16.2354C15.4177 5.36127 13.8589 3.9905 11.9539 3.42524C12.5159 4.3361 12.9555 5.62893 13.2036 7.14279H13.2034ZM8.04617 3.42524C7.48431 4.3361 7.04464 5.62893 6.79654 7.14279H3.76464C4.58224 5.36127 6.14103 3.9905 8.04611 3.42524H8.04617ZM10 2C14.4183 2 18 5.58171 18 10C18 14.4183 14.4183 18 10 18C5.58171 18 2 14.4183 2 10C2 5.58171 5.58171 2 10 2V2Z"
          fill="currentColor"
        />
      </Icon>
    </Box>
  );
};
