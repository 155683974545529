import { SimpleGrid, Text } from '@chakra-ui/layout';
import { Box } from '@chakra-ui/react';
import React from 'react';

import { NFTAttribute } from '@/types';

import s from './nft-properties.module.sass';

interface INFTPropertiesProps {
  attributes: NFTAttribute[];
}

export const NFTProperties: React.FC<INFTPropertiesProps> = (props) => {
  const { attributes } = props;
  return (
    <SimpleGrid columns={2} spacing={'8px'}>
      {attributes?.map(({ trait_type, value }, i) => (
        <Box className={s.Property} key={i}>
          <Text>{trait_type}</Text>
          <Text>{value || 'None'}</Text>
        </Box>
      ))}
    </SimpleGrid>
  );
}