import { Icon, IconProps } from '@chakra-ui/react';

export const RightFromBracketIcon = (props: IconProps) => {
  //
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      fill="none"
      display="block"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.4724 1.84999L6.5001 1.84999H7.5001C7.85908 1.84999 8.1501 2.14101 8.1501 2.49999C8.1501 2.85898 7.85908 3.14999 7.5001 3.14999H6.5001C5.78931 3.14999 5.29531 3.1505 4.91103 3.18189C4.53435 3.21267 4.3207 3.26986 4.16022 3.35163C3.81212 3.52899 3.5291 3.81201 3.35174 4.16011L2.77744 3.86749L3.35174 4.16011C3.26996 4.32059 3.21278 4.53424 3.182 4.91093C3.1506 5.2952 3.1501 5.7892 3.1501 6.49999V13.5C3.1501 14.2108 3.1506 14.7048 3.182 15.0891C3.21278 15.4657 3.26996 15.6794 3.35174 15.8399C3.5291 16.188 3.81212 16.471 4.16022 16.6484C4.3207 16.7301 4.53435 16.7873 4.91103 16.8181C5.29531 16.8495 5.78931 16.85 6.5001 16.85H7.5001C7.85908 16.85 8.1501 17.141 8.1501 17.5C8.1501 17.859 7.85908 18.15 7.5001 18.15H6.5001H6.4724C5.79584 18.15 5.24862 18.15 4.80517 18.1138C4.3481 18.0764 3.94432 17.9974 3.57003 17.8067C2.97732 17.5047 2.49543 17.0228 2.19343 16.4301C2.00272 16.0558 1.92366 15.652 1.88632 15.1949C1.85009 14.7515 1.85009 14.2042 1.8501 13.5277L1.8501 13.5V6.49999L1.8501 6.47229C1.85009 5.79573 1.85009 5.24852 1.88632 4.80506C1.92366 4.34799 2.00272 3.94421 2.19343 3.56992C2.49543 2.97721 2.97732 2.49532 3.57003 2.19332C3.94432 2.00261 4.3481 1.92356 4.80517 1.88621C5.24862 1.84998 5.79584 1.84998 6.4724 1.84999ZM12.8738 5.3737C13.1277 5.11986 13.5392 5.11986 13.7931 5.37371L17.9597 9.54037C18.2136 9.79421 18.2136 10.2058 17.9597 10.4596L13.7931 14.6263C13.5392 14.8801 13.1277 14.8801 12.8738 14.6263C12.62 14.3724 12.62 13.9609 12.8738 13.707L15.9309 10.65H7.5001C7.14111 10.65 6.8501 10.359 6.8501 9.99999C6.8501 9.64101 7.14111 9.34999 7.5001 9.34999H15.9309L12.8738 6.29294C12.62 6.0391 12.62 5.62755 12.8738 5.3737Z"
        fill="currentColor"
      />
    </Icon>
  );
};
