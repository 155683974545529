import { useEffect, useRef, useState } from 'react';
import { useScroll } from 'react-use';

import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';

import { ArrowBottomIcon, ArrowTopIcon } from './icons';

import { Spinner } from '@/components/ui';
import { Box, Stack } from '@chakra-ui/react';
import { EmptyState } from './components';

import cn from 'classnames';
import styles from './table.module.sass';

interface TableProps {
  data: any;
  columns: any;
  isLoading?: boolean;
  manualSorting?: boolean;
  onSortingChange?(sorting: SortingState): void;
  sort?: SortingState;
  layout?: 'auto' | 'fixed';
  minWidth?: string | number;
}

const MIN_COLUMN_WIDTH = 120;

export const Table = (props: TableProps) => {
  //
  const {
    columns,
    data,
    isLoading,
    manualSorting = true,
    sort,
    onSortingChange,
    layout = 'auto',
    minWidth = '100%',
  } = props;

  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnPinning, setColumnPinning] = useState({});

  // ====================== Scroll ======================

  //const [isTableHasScroll, setIsTableHasScroll] = useState(false);

  const scrollRef = useRef(null);
  const { x } = useScroll(scrollRef);
  //const { width: windowWidth } = useWindowSize();

  const isScrolledHorizontally = x > 0;

  // const isTableHasScroll =
  //   scrollRef.current?.scrollWidth > scrollRef.current?.offsetWidth;

  // useEffect(() => {
  //   if (scrollRef?.current) {
  //     const isElementHasScroll =
  //       scrollRef.current?.scrollWidth > scrollRef.current?.offsetWidth;
  //     setIsTableHasScroll(isElementHasScroll);
  //   }
  // }, [windowWidth, scrollRef]);

  // ====================== Scroll ======================

  useEffect(() => {
    onSortingChange && onSortingChange(sorting);
  }, [sorting]);

  const isEmpty = data?.length === 0;
  const isLayoutFixed = layout === 'fixed';

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
      columnPinning,
    },
    defaultColumn: {
      size: 0,
      minSize: MIN_COLUMN_WIDTH,
      maxSize: 0,
    },
    onSortingChange: setSorting,
    onColumnPinningChange: setColumnPinning,
    getSortedRowModel: getSortedRowModel(),
    manualSorting: manualSorting,
    enableSortingRemoval: false,
    // debugTable: true,
    // debugHeaders: true,
    // debugColumns: true,
  });

  useEffect(() => {
    if (sort.length) {
      table.setSorting(sort);
    }
  }, [sort, table]);

  return (
    <Box
      className={cn(
        styles.Wrapper,
        isScrolledHorizontally && styles['Wrapper--Scrolled-Horizontally']
      )}
    >
      <Box className={cn(styles.TableWrapper)} ref={scrollRef}>
        {isLoading && (
          <Box className={styles.SpinnerWrapper}>
            <Box className={styles.Spinner}>
              <Spinner size="md" />
            </Box>
          </Box>
        )}

        <table
          className={cn(
            styles.Table,
            isLoading && styles.isLoading,
            isLayoutFixed && styles['Table--LayoutFixed']
          )}
          style={minWidth ? { minWidth: minWidth } : undefined}
        >
          <thead className={styles.Thead}>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  //
                  const isSorted = header.column.getIsSorted();
                  const isCanSort = header.column.getCanSort();
                  const isSizeSet = header.column.columnDef.size !== 0;
                  const isMaxSizeSet = header.column.columnDef.maxSize !== 0;
                  // const isMinSizeSet =
                  //   header.column.columnDef.minSize !== MIN_COLUMN_WIDTH;
                  const columnAlign =
                    (header.column.columnDef as any)?.align ?? 'left';
                  const isReverse = isCanSort && columnAlign === 'right';
                  const isFixed = (header.column.columnDef as any)?.fixed;

                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      className={cn(
                        styles.Th,
                        isSorted && styles['Th--Column-Is-Sorted'],
                        isCanSort && styles['Th--Column-Can-Sort'],
                        isFixed && styles['Th--Column-Fixed']
                        //
                      )}
                      style={{
                        width: isSizeSet
                          ? header.column.columnDef.size
                          : undefined,
                        maxWidth: isMaxSizeSet
                          ? header.column.columnDef.maxSize
                          : undefined,
                      }}
                    >
                      {header.isPlaceholder ? null : (
                        <div onClick={header.column.getToggleSortingHandler()}>
                          <Stack
                            spacing={0}
                            justify={columnAlign}
                            direction={isReverse ? 'row-reverse' : 'row'}
                          >
                            <Box>
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                            </Box>

                            {header.column.getCanSort() && (
                              <Box className={styles['Th-Sort-Icon']}>
                                {header.column.getIsSorted() && (
                                  <>
                                    {{
                                      asc: <ArrowTopIcon />,
                                      desc: <ArrowBottomIcon />,
                                    }[header.column.getIsSorted() as string] ??
                                      null}
                                  </>
                                )}
                              </Box>
                            )}
                          </Stack>
                        </div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            <>
              {isEmpty ? (
                <>
                  <tr>
                    <td colSpan={table.getAllColumns().length}>
                      <EmptyState />
                    </td>
                  </tr>
                </>
              ) : (
                <>
                  {table.getRowModel().rows.map((row) => {
                    return (
                      <tr key={row.id} className={styles.Tr}>
                        {row.getVisibleCells().map((cell) => {
                          //
                          // const isMinSizeSet =
                          //   cell.column.columnDef.minSize !== MIN_COLUMN_WIDTH;
                          const columnAlign =
                            (cell.column.columnDef as any)?.align ?? 'left';
                          const isFixed = (cell.column.columnDef as any)?.fixed;
                          const isTruncated =
                            (cell.column.columnDef as any)?.truncate ?? false;

                          return (
                            <td
                              key={cell.id}
                              className={cn(
                                styles.Td,
                                isFixed && styles['Th--Column-Fixed'],
                                isTruncated && styles['Td--Truncate']
                              )}
                              align={columnAlign}
                              // style={{
                              //   minWidth: isMinSizeSet
                              //     ? cell.column.columnDef.minSize
                              //     : MIN_COLUMN_WIDTH,
                              // }}
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </>
              )}
            </>
          </tbody>
        </table>
      </Box>
    </Box>
  );
};
