import { MenuIcon, XMarkIcon } from '@/components/icons';
import { IconButton } from '@/components/ui';
import { useToggleMenu } from '@/hooks';

export const ToggleMenu = () => {
  const { isOpen, onOpen, onClose } = useToggleMenu();

  const icon = isOpen ? <XMarkIcon width="20px" height="20px" /> : <MenuIcon />;
  return (
    <IconButton
      aria-label={isOpen ? 'Close' : 'Open'}
      onClick={isOpen ? onClose : onOpen}
      icon={icon}
    />
  );
};
