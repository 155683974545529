// Mainnet
import { GravitonTorrentERC721Core as MainnetGravitonTorrentERC721Core } from './abi/mainnet/GravitonTorrentERC721Core';
import { GravitonTorrentERC721Factory as MainnetGravitonTorrentERC721Factory } from './abi/mainnet/GravitonTorrentERC721Factory';
import { UniverseXYZMerkleRewardsDistributor as MainnetXYZMerkleRewardsDistributor } from './abi/mainnet/UniverseXYZMerkleRewardsDistributor';
import { UniverseXYZSupernovaFacet as MainnetUniverseXYZSupernovaFacet } from './abi/mainnet/UniverseXYZSupernovaFacet';
import { UniverseXYZToken as MainnetUniverseXYZToken } from './abi/mainnet/UniverseXYZToken';
// Rinkeby
import { GravitonTorrentERC721Core as RinkebyGravitonTorrentERC721Core } from './abi/rinkeby/GravitonTorrentERC721Core';
import { GravitonTorrentERC721Factory as RinkebyGravitonTorrentERC721Factory } from './abi/rinkeby/GravitonTorrentERC721Factory';
// Goerli
import { GravitonTorrentERC721Core as GoerliGravitonTorrentERC721Core } from './abi/goerli/GravitonTorrentERC721Core';
import { GravitonTorrentERC721Factory as GoerliGravitonTorrentERC721Factory } from './abi/goerli/GravitonTorrentERC721Factory';
import { UniverseXYZMerkleRewardsDistributor as GoerliXYZMerkleRewardsDistributor } from './abi/goerli/UniverseXYZMerkleRewardsDistributor';
import { UniverseXYZSupernovaFacet as GoerliUniverseXYZSupernovaFacet } from './abi/goerli/UniverseXYZSupernovaFacet';
import { UniverseXYZToken as GoerliUniverseXYZToken } from './abi/goerli/UniverseXYZToken';

export const Contracts = {
  1: {
    name: 'mainnet',
    chainId: '1',
    contracts: {
      GravitonTorrentERC721Core: MainnetGravitonTorrentERC721Core,
      GravitonTorrentERC721Factory: MainnetGravitonTorrentERC721Factory,
      UniverseXYZSupernovaFacet: MainnetUniverseXYZSupernovaFacet,
      UniverseXYZToken: MainnetUniverseXYZToken,
      UniverseXYZMerkleRewardsDistributor: MainnetXYZMerkleRewardsDistributor,
    },
  },
  4: {
    name: 'rinkeby',
    chainId: '4',
    contracts: {
      GravitonTorrentERC721Core: RinkebyGravitonTorrentERC721Core,
      GravitonTorrentERC721Factory: RinkebyGravitonTorrentERC721Factory,
    },
  },
  5: {
    name: 'goerli',
    chainId: '5',
    contracts: {
      GravitonTorrentERC721Core: GoerliGravitonTorrentERC721Core,
      GravitonTorrentERC721Factory: GoerliGravitonTorrentERC721Factory,
      UniverseXYZSupernovaFacet: GoerliUniverseXYZSupernovaFacet,
      UniverseXYZToken: GoerliUniverseXYZToken,
      UniverseXYZMerkleRewardsDistributor: GoerliXYZMerkleRewardsDistributor,
    },
  },
};

export * from './hooks';
