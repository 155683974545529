import { Input } from '../input/input';

const baseStyle = (props) => ({
  field: {
    ...Input.baseStyle(props).field,
    outline: 'none',
  },
});

const variants = {
  ghost: (props) => ({
    field: {
      ...Input.variants.ghost(props).field,
    },
  }),
};

const sizes = {
  sm: {
    field: {
      ...Input.sizes.sm.field,
    },
  },
  md: {
    field: {
      ...Input.sizes.md.field,
    },
  },
  lg: {
    field: {
      ...Input.sizes.lg.field,
      paddingRight: '24px',
    },
  },
};

const defaultProps = {
  size: 'md',
  variant: 'ghost',
};

export const InputNumber = {
  parts: ['field'],
  baseStyle,
  sizes,
  variants,
  defaultProps,
};
