import { useTheme } from '@/hooks';
import { Box } from '@chakra-ui/react';
import cn from 'classnames';
import React from 'react';
import styles from './slide.module.sass';

interface Props {
  children: React.ReactNode;
  visible: boolean;
}

const SlideFC = (
  { children, visible }: Props,
  ref?: React.LegacyRef<HTMLDivElement>
) => {
  const { isDark } = useTheme();
  return (
    <Box
      ref={ref}
      className={cn(
        styles.SlideIn,
        visible && styles.Show,
        isDark ? styles.Dark : styles.Light
      )}
    >
      <Box className={styles.SlideInContent}></Box>
      {children}
    </Box>
  );
};

export const Slide = React.forwardRef(SlideFC);
