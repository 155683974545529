import { Layout } from '@/components/layouts/';
import { connectors } from '@/connectors/connectors';
import { AppContextProvider, AuthProvider, ProPlanProvider, SelectedNftsProvider, ToggleMenuProvider } from '@/context';
import { theme } from '@/theme/theme';
import { trackPage } from '@/utils/amplitude';
import { ChakraProvider } from '@chakra-ui/react';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { createTheme, ThemeProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Web3ReactProvider } from '@web3-react/core';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useCallback, useEffect } from 'react';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { configs } from '@/configs';

const emotionCache = createCache({
  key: 'emotion-css-cache',
  prepend: true,
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  },
});

const MUITheme = createTheme({});

export const App = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();

  const handleRouteChangeComplete = useCallback(() => {
    trackPage(document.title);
  }, []);

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, []);

  return (
    <>
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${configs.googleAnalyticsMeasurementId}`}
      />

      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${configs.googleAnalyticsMeasurementId}', {
            page_path: window.location.pathname,
          });
        `}
      </Script>

      <Web3ReactProvider connectors={connectors}>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={MUITheme}>
            <CacheProvider value={emotionCache}>
              <ChakraProvider theme={theme}>
                <AuthProvider>
                  <AppContextProvider>
                    <ProPlanProvider>
                      <SelectedNftsProvider>
                        <ToggleMenuProvider>
                          <Head>
                            <link
                              rel="apple-touch-icon"
                              sizes="180x180"
                              href="/favicon/apple-touch-icon.png"
                            />
                            <link
                              rel="icon"
                              type="image/png"
                              sizes="32x32"
                              href="/favicon/favicon-32x32.png"
                            />
                            <link
                              rel="icon"
                              type="image/png"
                              sizes="16x16"
                              href="/favicon/favicon-16x16.png"
                            />
                            <link
                              rel="manifest"
                              href="/favicon/site.webmanifest"
                            />
                          </Head>
                          <Layout>
                            <NestedLayout
                              Component={Component}
                              pageProps={pageProps}
                            />
                          </Layout>
                        </ToggleMenuProvider>
                      </SelectedNftsProvider>
                    </ProPlanProvider>
                  </AppContextProvider>
                </AuthProvider>
              </ChakraProvider>
            </CacheProvider>
          </ThemeProvider>
          {configs.environment === 'dev' && (
            <ReactQueryDevtools initialIsOpen={false} />
          )}
        </QueryClientProvider>
      </Web3ReactProvider>
    </>
  );
};

const NestedLayout = ({ Component, pageProps }) => {
  if (Component.getLayout) {
    return Component.getLayout(<Component {...pageProps} />);
  } else {
    return <Component {...pageProps} />;
  }
};
