import {
  Button as ButtonChakra,
  ButtonProps as ButtonChakraProps,
  useStyleConfig,
} from '@chakra-ui/react';
import React from 'react';

export const Button = React.forwardRef<HTMLButtonElement, ButtonChakraProps>(
  (props, ref) => {
    //
    const { children, variant, size, colorScheme, ...rest } = props;

    const styles = useStyleConfig('Button', { variant, size, colorScheme });

    return (
      <ButtonChakra ref={ref} __css={styles} {...rest}>
        {children}
      </ButtonChakra>
    );
  }
);

Button.displayName = 'Button';
