export function formatNumber(
  amount: number | null | undefined,
  maximumFractionDigits = 2
) {
  if (!amount) {
    return '-';
  }

  const { format } = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: maximumFractionDigits,
  });

  return format(amount);
}
