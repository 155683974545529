import { PaginationInfo, PaginationQueryParams } from '@/types';
import { Box, BoxProps } from '@chakra-ui/react';
import cn from 'classnames';
import { PaginatedControls } from './pagination-controls';
import s from './pagination.module.sass';

interface Props<T> {
  pagerBoxProps?: BoxProps;
  /** Page size options to show in the dropdown, default [12, 24, 36] */
  /**
   * @optional
   * @default [12, 24, 36]
   */
  pageSizes?: number[];
  pageParams: PaginationQueryParams;
  setPaginationParams: (params: Partial<PaginationQueryParams>) => void;
  paginationInfo?: PaginationInfo;
  fixed?: boolean;
  hideSingle?: boolean;
}

export function Pagination<T>({
  pageSizes = [12, 24, 36],
  pagerBoxProps = {},
  pageParams,
  paginationInfo,
  setPaginationParams,
  fixed,
  hideSingle,
}: Props<T>) {
  if (hideSingle && paginationInfo?.lastPage <= 1) return;

  const { className, ...restWrapperProps } = pagerBoxProps;

  return (
    <Box
      className={cn(
        s.Wrapper,
        fixed ? s.Wrapper_fixed : s.Wrapper_static,
        className
      )}
      {...restWrapperProps}
    >
      <PaginatedControls
        onParamsChange={setPaginationParams}
        pageParams={pageParams}
        pageSizes={pageSizes}
        pagination={paginationInfo}
      />
    </Box>
  );
}
