import {
  NFTDetailsModalStickyBar,
  useNFTModalContext,
} from '@/components/common';
import { Button } from '@/components/ui';
import { Spinner } from '@/components/ui/spinner';
import { Box, Center, Heading, Image, Link, VStack } from '@chakra-ui/react';

import { formatAddress } from '@/utils';

import { ETHERSCAN_LINK } from '@/constants';

import FailedImage from '@/assets/images/failed.png';
import QuestionImage from '@/assets/images/question.png';
import SuccessImage from '@/assets/images/success-2.png';

import s from './nft-processing.module.sass';
import { configs } from '@/configs';

type INFTProcessingState = 'processing' | 'success' | 'confirm' | 'failed';

interface INFTProcessingProps {
  state: INFTProcessingState;
  title?: string;
  tx?: string;
  renderDescription?: () => React.ReactNode;
  renderFooter?: () => React.ReactNode;
  onClose?: () => void;
}

const defaultTitles: Record<INFTProcessingState, string> = {
  processing: 'Processing',
  success: 'Success',
  confirm: 'Confirm',
  failed: 'Transaction failed',
};

const statusIcons: Record<INFTProcessingState, React.ReactNode> = {
  processing: (
    <Box textAlign={'center'}>
      <Spinner size={'lg'} />
    </Box>
  ),
  success: (
    <Image src={SuccessImage.src} alt={'Success'} boxSize={'64px'} m={'auto'} />
  ),
  confirm: (
    <Image
      src={QuestionImage.src}
      alt={'Question'}
      boxSize={'64px'}
      m={'auto'}
    />
  ),
  failed: (
    <Image src={FailedImage.src} alt={'Failed'} boxSize={'64px'} m={'auto'} />
  ),
};

export const NFTProcessing: React.FC<INFTProcessingProps> = (props) => {
  const { state, title, tx, renderDescription, renderFooter, onClose } = props;

  const { contentHeight } = useNFTModalContext();

  return (
    <>
      <Center h={`${contentHeight}px`} textAlign={'center'}>
        <VStack align={'stretch'} spacing={'16px'}>
          {statusIcons[state]}
          <VStack align={'stretch'} spacing={'8px'}>
            <Heading className={s.Title}>
              {title || defaultTitles[state]}
            </Heading>
            {!renderDescription ? null : (
              <Box className={s.Description}>{renderDescription()}</Box>
            )}
          </VStack>
          {!tx ? null : (
            <VStack align={'stretch'} spacing={'4px'}>
              <Heading className={s.TxTitle}>Transaction ID</Heading>
              <Link
                className={s.TxLink}
                href={`${ETHERSCAN_LINK.hash}/${tx}`}
                isExternal
              >
                {formatAddress(tx)}
              </Link>
            </VStack>
          )}
        </VStack>
      </Center>
      {renderFooter !== undefined ? (
        renderFooter()
      ) : (
        <NFTDetailsModalStickyBar>
          <Button variant={'secondary'} w={'100%'} onClick={onClose}>
            Close
          </Button>
        </NFTDetailsModalStickyBar>
      )}
    </>
  );
};
