import { Sidebar } from '@/components/common';
import { MENUS_MAP } from '@/constants/SIDEBAR_MENUS';
import { useIsMobile } from '@/hooks';
import { Box } from '@chakra-ui/react';
import cn from 'classnames';
import styles from '../layout.module.sass';

interface Props {
  page: React.ReactNode;
  menu: 'display' | 'torrent' | 'storage';
}

export const LayoutWithSidebar = (props: Props) => {
  //
  const { page, menu } = props;
  const isMobile = useIsMobile();

  return (
    <Box className={styles.Wrapper}>
      <Sidebar items={MENUS_MAP[menu]} />
      <Box as="main" className={cn(styles.Content, isMobile && styles.Mobile)}>
        {page}
      </Box>
    </Box>
  );
};
