import { ISelectOption } from '@/components/ui/select';

export const ROTATION_VALUES: ISelectOption[] = [
  {
    title: 'Landscape',
    value: 'horizontal',
    icon: 'landscape',
  },
  {
    title: 'Portrait',
    value: 'clockwise-90',
    icon: 'rotate90deg',
  },
  {
    title: 'Portrait',
    value: 'clockwise-270',
    icon: 'rotate270deg',
  },
];
