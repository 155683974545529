import { useIsMobile, useTheme } from '@/hooks';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useState } from 'react';

import { Tooltip } from '@/components/ui';
import { Box, Flex, HStack, Link, VStack } from '@chakra-ui/react';

import { ProPlanBanner } from '../components';
import { MobileAppsLinks } from './components';

import { configs } from '@/configs';
import cn from 'classnames';
import styles from '../sidebar.module.sass';

export interface Props {
  items: MenuItem[];
  className?: string;
}

interface MenuItem {
  title: string;
  href: string;
  icon: React.ReactNode;
  disabled?: boolean;
}

const menuItemHeight = 48;
const menuItemGap = 2;

export const SidebarLayout = (props: Props) => {
  //
  const { items, className } = props;

  const router = useRouter();
  const { asPath } = router;

  const { themeClass } = useTheme();
  const isMobile = useIsMobile();

  const isActive = (href) => {
    return asPath.startsWith(href);
  };

  const [translateY, setTranslateY] = useState(0);

  useEffect(() => {
    if (asPath) {
      const index = items.findIndex((item) => isActive(item.href));
      setTranslateY(calculateTranslateY(index));
    }
  }, [asPath]);

  const calculateTranslateY = useCallback((index: number): number => {
    if (index === 0) {
      return 0;
    }

    return menuItemHeight * index + menuItemGap * index;
  }, []);

  const currentApp = asPath.split('/')?.[1];

  return (
    <Box
      as="aside"
      className={cn(styles.Sidebar, styles[themeClass], className)}
    >
      <Box as="nav" className={styles.Menu}>
        {translateY < 0 ? null : (
          <Box
            className={styles.Handle}
            transform={`translateY(${translateY}px)`}
          />
        )}
        <VStack as={'ul'} align={'stretch'} spacing={`${menuItemGap}px`}>
          {items.map((item, index) => (
            <li
              key={index}
              className={cn(
                styles.Item,
                isActive(item.href) && styles.isActive,
                item.disabled && styles.isDisabled
              )}
              onClick={() => (!item.disabled ? router.push(item.href) : void 0)}
            >
              {item.disabled ? (
                <Tooltip
                  label="Coming soon"
                  placement={isMobile ? 'top' : 'right'}
                  gutter={28}
                >
                  <HStack spacing="10px">
                    {item.icon}
                    <VStack spacing={0} align="left">
                      <Box>{item.title}</Box>
                      <Box className={styles.DisabledComment}>
                        Under Construction
                      </Box>
                    </VStack>
                  </HStack>
                </Tooltip>
              ) : (
                <HStack spacing="10px">
                  {item.icon}
                  <span>{item.title}</span>
                </HStack>
              )}
            </li>
          ))}
        </VStack>
      </Box>
      <Flex
        flexDir={'column'}
        pos={'absolute'}
        gap={'20px'}
        bottom={'24px'}
        left={'24px'}
        width={'calc(100% - 48px)'}
      >
        {configs.featuresFlags.enableProPlanSubscription && <ProPlanBanner />}

        {(currentApp === 'display' || currentApp === 'torrent') && (
          <MobileAppsLinks application={currentApp} />
        )}
        <Flex flexDir={'column'} gap={'4px'}>
          <NextLink href={'/terms-of-services'} passHref>
            <Link
              className={styles.footerLinks}
            >
              Terms of service
            </Link>
          </NextLink>
          <NextLink href={'/privacy'} passHref>
            <Link
              className={styles.footerLinks}
            >
              Privacy policy
            </Link>
          </NextLink>
        </Flex>
      </Flex>
    </Box>
  );
};
