import React from 'react';

import { DataNotFound } from '@/components/common';
import { Button, Icon } from '@/components/ui';
import { Box } from '@chakra-ui/react';

import { useRouter } from 'next/router';

// import cn from 'classnames';
import styles from './empty-state.module.sass';

interface Props {
  icon?: React.ReactNode;
  title?: string;
  description?: string;
  renderButtons?: React.ReactNode;
}

export const EmptyState = (props: Props) => {
  //
  const { title, description, icon, renderButtons } = props;

  const router = useRouter();

  return (
    <Box className={styles.Wrapper}>
      <DataNotFound
        title="No torrents found"
        description="Click the buttons below to mint on Graviton."
      >
        <Button
          size="lg"
          variant="secondary"
          onClick={() => router.push(`/torrent/mint/tnft`)}
        >
          Mint tNFT
        </Button>
      </DataNotFound>
    </Box>
  );
};
