import { Logo } from '@/components/common';
import { useAuth, useIsMobile, useTheme } from '@/hooks';
import {
  Flex,
  HStack,
  Spacer,
} from '@chakra-ui/react';
import { ThemeSwitcher, WalletDropdown } from './components';

import cn from 'classnames';
import { ToggleMenu } from './components/toggle-menu';
import styles from './header.module.sass';
import { AppSwitcher } from '@/components/common/header/components/switcher';

interface Props {
  className?: string;
}

export const Header = (props: Props) => {
  //
  const { className } = props;
  const { themeClass } = useTheme();
  const { isAuthenticated } = useAuth();

  const isMobile = useIsMobile();

  return (
    <Flex
      as="header"
      className={cn(
        styles.Header,
        styles[themeClass],
        className,
        isMobile && styles.Mobile
      )}
    >
      <Flex gap={'16px'} alignItems={'center'}>
        <Logo className={styles.Logo} />
        {isAuthenticated && <AppSwitcher />}
      </Flex>
      <Spacer />

      <HStack>
        {isAuthenticated && !isMobile && <WalletDropdown compact={isMobile} />}
        <ThemeSwitcher />
        {isAuthenticated && isMobile && <ToggleMenu />}
      </HStack>
    </Flex>
  );
};
