import { mode } from '@chakra-ui/theme-tools';

export const naked = (props) => {
  //
  const { colorScheme } = props;

  const baseStyles = {
    fontWeight: 400,
    border: 0,
    borderRadius: '10px',
    background: 'transparent',
    backgroundColor: 'transparent',
    boxShadow: mode(
      '0px 0px 0px 4px rgba(0 0 0 / 0)',
      '0px 0px 0px 4px rgba(255 255 255 / 0)'
    )(props),
    transition: 'all 200ms linear',

    _hover: {
      backgroundColor: mode('blackTransparent.5', 'whiteTransparent.5')(props),
    },

    _active: {
      backgroundColor: mode(
        'blackTransparent.10',
        'whiteTransparent.10'
      )(props),
    },

    _focus: {
      boxShadow: mode(
        '0px 0px 0px 4px rgba(0 0 0 / 10%)',
        '0px 0px 0px 4px rgba(255 255 255 / 10%)'
      )(props),
    },

    _expanded: {
      backgroundColor: mode('blackTransparent.5', 'whiteTransparent.5')(props),
    },
  };

  const colorSchemeStyles = {
    default: {
      color: mode('black', 'white')(props),
    },
    gray: {
      color: mode('blackTransparent.40', 'whiteTransparent.40')(props),
    },
  };

  const styles = colorSchemeStyles[colorScheme] || colorSchemeStyles.default;

  return {
    ...baseStyles,
    ...styles,
  };
};
