import { Icon, IconProps } from '@chakra-ui/react';

export const ChevronRightIcon = (props: IconProps) => {
  return (
    <>
      <Icon
        xmlns="http://www.w3.org/2000/svg"
        width="12px"
        height="12px"
        viewBox="0 0 12 12"
        fill="none"
        display="block"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.2807 0.970157C3.9878 0.677264 3.51293 0.677264 3.22004 0.970157C2.92714 1.26305 2.92714 1.73792 3.22004 2.03082L4.2807 0.970157ZM8.25012 6.00024L8.78043 6.5306C8.92109 6.38995 9.00012 6.19918 9.00012 6.00026C9.00013 5.80134 8.92111 5.61057 8.78045 5.46991L8.25012 6.00024ZM3.21969 9.96965C2.92679 10.2625 2.92677 10.7374 3.21965 11.0303C3.51253 11.3232 3.9874 11.3232 4.28031 11.0304L3.21969 9.96965ZM3.22004 2.03082L7.71979 6.53057L8.78045 5.46991L4.2807 0.970157L3.22004 2.03082ZM7.71981 5.46989L3.21969 9.96965L4.28031 11.0304L8.78043 6.5306L7.71981 5.46989Z"
          fill="black"
        />
      </Icon>
    </>
  );
};
