import { Button, Modal } from '@/components/ui';
import { Box, HStack } from '@chakra-ui/react';

// import cn from 'classnames';
import styles from './confirm-modal.module.sass';

interface Props {
  isOpen: boolean;
  onClose(): void;
  confirmText?: string;
  cancelText?: string;
  onConfirm?(): void;
  onCancel?(): void;
  title?: string | React.ReactNode;
  description?: string | React.ReactNode;
}

export const ConfirmModal = (props: Props) => {
  //
  const {
    isOpen,
    onClose,
    title = 'Are you sure?',
    description,
    confirmText = 'Apply',
    cancelText = 'Cancel',
    onConfirm,
    onCancel,
  } = props;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered={true}
      isClosable={true}
    >
      <Box className={styles.Wrapper}>
        <Box className={styles.Title}>{title}</Box>
        {description && <Box className={styles.Description}>{description}</Box>}

        <HStack spacing="25px" mt="30px">
          <Button variant="secondary" size="lg" onClick={() => onCancel()}>
            {cancelText}
          </Button>
          <Button variant="primary" size="lg" onClick={() => onConfirm()}>
            {confirmText}
          </Button>
        </HStack>
      </Box>
    </Modal>
  );
};
