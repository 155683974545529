import { useQuery } from '@tanstack/react-query';

interface IUseWaitForTransactionOptions {
  tx?: {
    hash: string;
    wait: () => Promise<any>;
  };
  onSuccess?: (result: any) => void;
  onError?: (error: unknown) => void;
}

export const useWaitForTransaction = (
  options: IUseWaitForTransactionOptions
) => {
  const { tx, onSuccess, onError } = options;

  return useQuery({
    enabled: !!tx,
    queryKey: ['tx', tx?.hash],
    queryFn: () => tx?.wait(),
    onSuccess,
    onError,
  });
};
