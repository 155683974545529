import { HStack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

import {
  closestCenter,
  defaultDropAnimationSideEffects,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  DropAnimation,
  KeyboardCoordinateGetter,
  KeyboardSensor,
  MeasuringStrategy,
  MouseSensor,
  PointerActivationConstraint,
  TouchSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core';

import {
  AnimateLayoutChanges,
  arrayMove as reorderItems,
  defaultAnimateLayoutChanges,
  horizontalListSortingStrategy,
  NewIndexGetter,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
} from '@dnd-kit/sortable';

import {
  restrictToHorizontalAxis,
} from '@dnd-kit/modifiers';

import { Item } from '../selected-nfts-item';

import { createPortal } from 'react-dom';
import styles from './selected-nfts-list.module.sass';

export interface SelectedNftsListProps {
  activationConstraint?: PointerActivationConstraint;
  coordinateGetter?: KeyboardCoordinateGetter;
  getNewIndex?: NewIndexGetter;
  items: any;
  style?: React.CSSProperties;
  useDragOverlay?: boolean;
  onChange?(items: any): void;
}

const dropAnimationConfig: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: '0.5',
      },
    },
  }),
};

export const SelectedNftsList = (props: SelectedNftsListProps) => {
  //
  const {
    activationConstraint,
    coordinateGetter = sortableKeyboardCoordinates,
    useDragOverlay = false,
    items: initialItems,
    onChange,
  } = props;

  const [items, setItems] = useState([]);
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);

  useEffect(() => {
    setItems(initialItems);
  }, [initialItems]);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint,
    }),
    useSensor(TouchSensor, {
      activationConstraint,
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter,
    })
  );

  const getIndex = (id: UniqueIdentifier) =>
    items.findIndex((item) => item.id === id);
  const activeIndex = activeId ? getIndex(activeId) : -1;

  const handleRemove = (id: string): void => {
    const filteredItems = items.filter((item) => item.id !== id);
    setItems(filteredItems);
    onChange && onChange(filteredItems);
  };

  const onDragStart = ({ active }: DragStartEvent) => {
    if (!active) {
      return;
    }

    setActiveId(active.id);
  };

  const onDragEnd = ({ over }: DragEndEvent) => {
    setActiveId(null);

    if (over) {
      const overIndex = getIndex(over.id);
      if (activeIndex !== overIndex) {
        const reorderedItems = reorderItems(items, activeIndex, overIndex);
        setItems(reorderedItems);
        onChange && onChange(reorderedItems);
      }
    }
  };

  const animateLayoutChanges: AnimateLayoutChanges = (args) =>
    args.isSorting || args.wasDragging
      ? defaultAnimateLayoutChanges(args)
      : true;

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onDragCancel={() => setActiveId(null)}
      measuring={{ droppable: { strategy: MeasuringStrategy.Always } }}
      modifiers={[restrictToHorizontalAxis]}
    >
      <SortableContext
        items={items.map((item) => item.id)}
        strategy={horizontalListSortingStrategy}
      >
        <HStack spacing="20px">
          {items.map((item) => (
            <SortableItem
              key={item.id}
              id={item.id}
              item={item}
              onRemove={handleRemove}
              animateLayoutChanges={animateLayoutChanges}
              useDragOverlay={useDragOverlay}
            />
          ))}
        </HStack>
      </SortableContext>

      {useDragOverlay
        ? createPortal(
            <DragOverlay
              adjustScale={false}
              dropAnimation={dropAnimationConfig}
            >
              {activeId ? (
                <Item
                  key={items[activeIndex].id}
                  id={items[activeIndex].id}
                  item={items[activeIndex]}
                  onRemove={handleRemove}
                  className={styles.DragOverlay}
                />
              ) : null}
            </DragOverlay>,
            document.body
          )
        : null}
    </DndContext>
  );
};

interface SortableItemProps {
  animateLayoutChanges?: AnimateLayoutChanges;
  id: string;
  onRemove?(id: string): void;
  item: any;
  useDragOverlay?: boolean;
}

export function SortableItem({
  animateLayoutChanges,
  id,
  item,
  onRemove,
  useDragOverlay,
}: SortableItemProps) {
  const {
    attributes,
    isDragging,
    isSorting,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id,
    animateLayoutChanges,
  });

  return (
    <Item
      ref={setNodeRef}
      item={item}
      dragging={isDragging}
      sorting={isSorting}
      onRemove={() => onRemove(id)}
      transform={transform}
      transition={transition}
      listeners={listeners}
      dragOverlay={useDragOverlay}
      {...attributes}
    />
  );
}
