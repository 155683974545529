import { HStack, Image, Text } from '@chakra-ui/react';
import React, { useCallback, useMemo } from 'react';

import { ISelectOption, Select } from '@/components/ui/select';
import { IToken } from '@/types';

export interface ITokenSelectorProps {
  tokens: IToken[];
  value?: IToken;
  onChange?: (value: IToken) => void;
}

export const TokenSelector: React.FC<ITokenSelectorProps> = (props) => {
  const { tokens, value, onChange } = props;

  const renderToken = useCallback((token: IToken) => (
    <HStack spacing={'8px'}>
      <Image src={token.image} boxSize={'20px'} />
      <Text fontSize={'16px'} fontWeight={600}>{token.ticker}</Text>
    </HStack>
  ), []);

  const options = useMemo<ISelectOption[]>(() => tokens.map(((token) => ({
    value: token.ticker,
    title: token.ticker,
    render: () => renderToken(token),
  }))), [tokens, renderToken]);

  return (
    <Select
      placeholder={'Select token'}
      value={value?.ticker}
      options={options}
      onChange={(ticker) => onChange?.(tokens.find((token) => token.ticker === ticker))}
      popoverProps={{
        matchWidth: false,
      }}
      renderSelectedOption={(option) => renderToken(option as unknown as IToken)}
    />
  );
}