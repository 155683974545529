export const FormItem = {
  // The styles all Cards have in common
  parts: ['wrapper', 'label', 'description', 'error'],

  baseStyle: (props) => ({
    //
    wrapper: {
      width: 'auto',
    },

    label: {
      color: 'black',
      fontWeight: '700',
      fontSize: '14px',
      lineHeight: '22px',
      marginBottom: '6px',
      marginRight: '0px',
      _dark: {
        color: 'white',
      },
    },

    error: {
      color: 'red',
    },
  }),

  sizes: {
    //md: {},
    //lg: {},
  },

  variants: {
    // ghost: (props) => ({
    //   wrapper: {},
    // }),
  },
  // The default variant value
  defaultProps: {
    //variant: 'ghost',
    //size: 'md',
  },
};
