export const RoyaltyRegistryABI = [
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'previousOwner',
                type: 'address',
            },
            {
                indexed: true,
                internalType: 'address',
                name: 'newOwner',
                type: 'address',
            },
        ],
        name: 'OwnershipTransferred',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'token',
                type: 'address',
            },
            {
                components: [
                    {
                        internalType: 'address payable',
                        name: 'account',
                        type: 'address',
                    },
                    {
                        internalType: 'uint96',
                        name: 'value',
                        type: 'uint96',
                    },
                ],
                indexed: false,
                internalType: 'struct LibPart.Part[]',
                name: 'royalties',
                type: 'tuple[]',
            },
        ],
        name: 'RoyaltiesSetForContract',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'token',
                type: 'address',
            },
            {
                indexed: true,
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256',
            },
            {
                components: [
                    {
                        internalType: 'address payable',
                        name: 'account',
                        type: 'address',
                    },
                    {
                        internalType: 'uint96',
                        name: 'value',
                        type: 'uint96',
                    },
                ],
                indexed: false,
                internalType: 'struct LibPart.Part[]',
                name: 'royalties',
                type: 'tuple[]',
            },
        ],
        name: 'RoyaltiesSetForToken',
        type: 'event',
    },
    {
        inputs: [],
        name: '__RoyaltiesRegistry_init',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: 'token', type: 'address' },
            {
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256',
            },
        ],
        name: 'getRoyalties',
        outputs: [
            {
                components: [
                    {
                        internalType: 'address payable',
                        name: 'account',
                        type: 'address',
                    },
                    {
                        internalType: 'uint96',
                        name: 'value',
                        type: 'uint96',
                    },
                ],
                internalType: 'struct LibPart.Part[]',
                name: 'nftRoyalties',
                type: 'tuple[]',
            },
            {
                components: [
                    {
                        internalType: 'address payable',
                        name: 'account',
                        type: 'address',
                    },
                    {
                        internalType: 'uint96',
                        name: 'value',
                        type: 'uint96',
                    },
                ],
                internalType: 'struct LibPart.Part[]',
                name: 'collectionRoyalties',
                type: 'tuple[]',
            },
        ],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'owner',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'renounceOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'address', name: '', type: 'address' }],
        name: 'royaltiesByToken',
        outputs: [{ internalType: 'bool', name: 'initialized', type: 'bool' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
        name: 'royaltiesByTokenAndTokenId',
        outputs: [{ internalType: 'bool', name: 'initialized', type: 'bool' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'address', name: '', type: 'address' }],
        name: 'royaltiesProviders',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: 'token', type: 'address' },
            {
                internalType: 'address',
                name: 'provider',
                type: 'address',
            },
        ],
        name: 'setProviderByToken',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: 'token', type: 'address' },
            {
                components: [
                    {
                        internalType: 'address payable',
                        name: 'account',
                        type: 'address',
                    },
                    {
                        internalType: 'uint96',
                        name: 'value',
                        type: 'uint96',
                    },
                ],
                internalType: 'struct LibPart.Part[]',
                name: 'royalties',
                type: 'tuple[]',
            },
        ],
        name: 'setRoyaltiesByToken',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: 'token', type: 'address' },
            {
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256',
            },
            {
                components: [
                    {
                        internalType: 'address payable',
                        name: 'account',
                        type: 'address',
                    },
                    {
                        internalType: 'uint96',
                        name: 'value',
                        type: 'uint96',
                    },
                ],
                internalType: 'struct LibPart.Part[]',
                name: 'royalties',
                type: 'tuple[]',
            },
        ],
        name: 'setRoyaltiesByTokenAndTokenId',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: 'newOwner',
                type: 'address',
            },
        ],
        name: 'transferOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
];