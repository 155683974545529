import { Icon, IconProps } from '@chakra-ui/react';

export const CopyIcon = (props: IconProps) => {
  //
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      fill="none"
      display="block"
      {...props}
    >
      <path
        d="M3.5 4.75C3.5 4.05964 4.05964 3.5 4.75 3.5H11.5C12.0523 3.5 12.5 3.94771 12.5 4.5C12.5 4.91421 12.8358 5.25 13.25 5.25C13.6642 5.25 14 4.91421 14 4.5C14 3.11929 12.8807 2 11.5 2H4.75C3.23122 2 2 3.23122 2 4.75V11.5C2 12.8807 3.11929 14 4.5 14C4.91421 14 5.25 13.6642 5.25 13.25C5.25 12.8358 4.91421 12.5 4.5 12.5C3.94771 12.5 3.5 12.0523 3.5 11.5V4.75Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.75 6C7.23122 6 6 7.23122 6 8.75V15.25C6 16.7688 7.23122 18 8.75 18H15.25C16.7688 18 18 16.7688 18 15.25V8.75C18 7.23122 16.7688 6 15.25 6H8.75ZM7.5 8.75C7.5 8.05964 8.05964 7.5 8.75 7.5H15.25C15.9404 7.5 16.5 8.05964 16.5 8.75V15.25C16.5 15.9404 15.9404 16.5 15.25 16.5H8.75C8.05964 16.5 7.5 15.9404 7.5 15.25V8.75Z"
        fill="black"
      />
    </Icon>
  );
};
