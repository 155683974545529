const colors = {
  //
  black: '#000',
  white: '#fff',
  dark: '#110d21',
  darkBlue: '#150c3a',
  purpleLight: '#b5a5f6',
  red: '#ff4949',
  green: '#2de43f',
  blueDisabled: '#4d6393',
  blue: '#4D66EB',

  darkFlat: {
    100: '#110d21',
    60: '#706e7a',
    40: '#a09ea6',
    20: '#cfcfd3',
    10: '#e8e7e9',
    5: '#f3f3f4',
  },

  whiteFlat: {
    100: '#fff',
    60: '#9e9da3',
    40: '#6d6b75',
    20: '#3d3a47',
    10: '#242130',
    5: '#181625',
  },

  blackTransparent: {
    100: 'rgba(0 0 0 / 100%)',
    60: 'rgba(0 0 0 / 60%)',
    40: 'rgba(0 0 0 / 40%)',
    30: 'rgba(0 0 0 / 30%)',
    20: 'rgba(0 0 0 / 20%)',
    10: 'rgba(0 0 0 / 10%)',
    5: 'rgba(0 0 0 / 5%)',
  },

  whiteTransparent: {
    100: 'rgba(255 255 255 / 100%)',
    80: 'rgba(255 255 255 / 80%)',
    60: 'rgba(255 255 255 / 60%)',
    40: 'rgba(255 255 255 / 40%)',
    30: 'rgba(255 255 255 / 30%)',
    20: 'rgba(255 255 255 / 20%)',
    10: 'rgba(255 255 255 / 10%)',
    5: 'rgba(255 255 255 / 5%)',
  },

  darkTransparent: {
    60: 'rgba(17 13 33 / 60%)',
    40: 'rgba(17 13 33 / 40%)',
    20: 'rgba(17 13 33 / 20%)',
    10: 'rgba(17 13 33 / 10%)',
    5: 'rgba(17 13 33 / 5%)',
  },

  darkBlueTransparent: {
    60: 'rgba(21 12 58 / 60%)',
    40: 'rgba(21 12 58 / 40%)',
    20: 'rgba(21 12 58 / 20%)',
    10: 'rgba(21 12 58 / 10%)',
    5: 'rgba(21 12 58 / 5%)',
  },

  purpleLightTransparent: {
    60: 'rgba(181 165 246 / 60%)',
    40: 'rgba(181 165 246 / 40%)',
    20: 'rgba(181 165 246 / 20%)',
    10: 'rgba(181 165 246 / 10%)',
    5: 'rgba(181 165 246 / 5%)',
  },

  redTransparent: {
    60: 'rgba(255 73 73 / 60%)',
    40: 'rgba(255 73 73 / 40%)',
    20: 'rgba(255 73 73 / 20%)',
    10: 'rgba(255 73 73 / 10%)',
    5: 'rgba(255 73 73 / 5%)',
  },

  greenTransparent: {
    60: 'rgba(45 228 63 / 60%)',
    40: 'rgba(45 228 63 / 40%)',
    20: 'rgba(45 228 63 / 20%)',
    10: 'rgba(45 228 63 / 10%)',
    5: 'rgba(45 228 63 / 5%)',
  },

  blueTransparent: {
    100: 'rgba(77 102 235 / 100%)',
    90: 'rgba(77 102 235 / 90%)',
    80: 'rgba(77 102 235 / 80%)',
    70: 'rgba(77 102 235 / 70%)',
    60: 'rgba(77 102 235 / 60%)',
    50: 'rgba(77 102 235 / 50%)',
    40: 'rgba(77 102 235 / 40%)',
    30: 'rgba(77 102 235 / 30%)',
    20: 'rgba(77 102 235 / 20%)',
    10: 'rgba(77 102 235 / 10%)',
    9: 'rgba(77 102 235 / 9%)',
    8: 'rgba(77 102 235 / 8%)',
    7: 'rgba(77 102 235 / 7%)',
    6: 'rgba(77 102 235 / 6%)',
    5: 'rgba(77 102 235 / 5%)',
    4: 'rgba(77 102 235 / 4%)',
    3: 'rgba(77 102 235 / 3%)',
    2: 'rgba(77 102 235 / 2%)',
    1: 'rgba(77 102 235 / 1%)',
  },

  gradientGreenFrom: '#bceb00',
  gradientGreenTo: '#00eaea',

  purpleGradient:
    'linear-gradient(120deg, #ff7f69 5%, #ff2d83 30%, #6695ff 85%)',
  purpleGradientHover: 'linear-gradient(120deg, #ff2d83 -10%, #6695ff 80%)',
  purpleGradientActive: 'linear-gradient(120deg, #ff2d83 -135%, #6695ff 160%)',
  darkBlueGradient: 'linear-gradient(225deg, #241759 15%, #150C3A 80%)',
};

export default colors;
