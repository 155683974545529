import { FC, useEffect } from 'react';

import { URI_AVAILABLE } from '@web3-react/walletconnect';

import { walletConnect } from '@/connectors/walletConnect';
import { IConnectorButton } from '@/components/modules/home';

import { ConnectWalletItem } from '../connect-wallet-item';
import { Logo } from './components';

export const WalletConnect: FC<IConnectorButton> = ({ onConnect }) => {
  const handleConnect = () => {
    onConnect(walletConnect, 'WALLETCONNET');
  };

  // log URI when available
  useEffect(() => {
    walletConnect.events.on(URI_AVAILABLE, (uri: string) => {
      console.log(`uri: ${uri}`);
    });
  }, []);

  return (
    <>
      <ConnectWalletItem onClick={() => handleConnect()}>
        <Logo />
      </ConnectWalletItem>
    </>
  );
};
