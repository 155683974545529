import React from 'react';

import {
  Box,
  Flex,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
} from '@chakra-ui/react';

import { Icon, IconButton, IconType } from '@/components/ui';

import { useAuth } from '@/hooks';

import type { ICollection } from '@/types';

import cn from 'classnames';
import styles from './collection-card.module.sass';

interface ICollectionCardProps {
  collection: ICollection;
  includeStats?: boolean;
  statsValue?: '1day' | '7day' | '30day';
  menuItems?: IMenuItem[];
  onClick?: () => void;
}

interface IMenuItem {
  title: string;
  icon?: IconType;
  onClick?: () => void;
  render?: () => React.ReactNode;
}

export const CollectionCard: React.FC<ICollectionCardProps> = (props) => {
  //
  const {
    collection,
    menuItems,
    includeStats = false,
    statsValue = '1day',
    onClick,
  } = props;
  const { creator, logoUrl, name, coverUrl } = collection;

  const { address } = useAuth();

  const isShowCount = collection.nftCount > 0;
  const isCreator = creator === address?.toLowerCase();

  return (
    <Box className={cn(styles.Card)} onClick={onClick}>
      {isShowCount && (
        <Box className={cn(styles.Counter)}>
          <Icon icon="bundle" />
          <Box as="span">{collection.nftCount}</Box>
        </Box>
      )}

      <Box className={styles.CardBanner}>
        {coverUrl && (
          <Image
            src={coverUrl}
            alt={name}
            height={'128px'}
            objectFit={'cover'}
            w={'100%'}
          />
        )}
      </Box>

      <Box className={styles.Content}>
        {logoUrl ? (
          <Image className={styles.Image} src={logoUrl} alt={name} />
        ) : (
          <Box className={styles.Image} h={68} w={68} />
        )}
        <Flex alignItems="center">
          <Box className={styles.Title}>{name}</Box>

          <Spacer />
          {isCreator && menuItems?.length > 0 && (
            <Menu placement="bottom-end" isLazy>
              <MenuButton
                as={IconButton}
                icon={<Icon icon="dots" />}
                className={styles.MenuButton}
                aria-label="Actions"
                variant="naked"
                size="sm"
                onClick={(e) => e.stopPropagation()}
              />

              <MenuList className={styles.MenuList}>
                {menuItems.map((item, index) => (
                  <MenuItem
                    key={index}
                    onClick={(e) => {
                      e.stopPropagation();
                      item.onClick && item.onClick();
                    }}
                    className={styles.MenuItem}
                  >
                    <HStack spacing="8px">
                      {item.icon && <Icon icon={item.icon} />}
                      <Box>{item.title}</Box>
                    </HStack>
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          )}
        </Flex>

        {includeStats && (
          <Box className={styles.StatsWrapper}>
            <HStack spacing="10px" justifyContent="space-between">
              <Box className={styles.StatsItem}>
                <Box className={styles.StatsItem__Value}>
                  {collection?.stats?.volume[statsValue]
                    ? `${collection?.stats?.volume[statsValue]} ETH`
                    : '-'}
                </Box>
                <Box className={styles.StatsItem__Title}>volume</Box>
              </Box>
              <Box className={styles.StatsItem}>
                <Box className={styles.StatsItem__Value}>
                  {collection?.stats.floorAsk.price?.amount.decimal ?? `-`}{' '}
                  {collection?.stats.floorAsk.price?.currency.symbol}
                </Box>
                <Box className={styles.StatsItem__Title}>floor</Box>
              </Box>
              <Box className={styles.StatsItem}>
                <Box className={styles.StatsItem__Value}>
                  {collection?.stats?.tokenCount}
                </Box>
                <Box className={styles.StatsItem__Title}>items</Box>
              </Box>
              <Box className={styles.StatsItem}>
                <Box className={styles.StatsItem__Value}>
                  {collection?.ownerCount ?? `-`}
                </Box>
                <Box className={styles.StatsItem__Title}>owners</Box>
              </Box>
            </HStack>
          </Box>
        )}
      </Box>
    </Box>
  );
};
