import { IAlchemyNft, NFT } from '@/types';

export const transformAlchemyToUniverseNftData = (
  alchemyNftData: IAlchemyNft
): NFT => ({
  ...alchemyNftData,
  _id: `${alchemyNftData.contract.address}:${alchemyNftData.tokenId}`,
  tokenId: alchemyNftData.tokenId,
  contractAddress: alchemyNftData.contract.address,
  tokenType: alchemyNftData.tokenType,
  metadata: {
    ...alchemyNftData.rawMetadata,
  },
  collection: {
    name: alchemyNftData.contract.name,
    contractAddress: alchemyNftData.contract.address,
    tokenType: alchemyNftData.contract.tokenType,
    symbol: alchemyNftData.contract.symbol,
    owner: alchemyNftData.contract.contractDeployer,
  },
});
