import { mode } from '@chakra-ui/theme-tools';
import { naked } from '../button/variants/naked';
import { secondary } from '../button/variants/secondary';

export const IconButton = {
  //
  baseStyle: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    verticalAlign: 'middle',
    whiteSpace: 'nowrap',
    userSelect: 'none',
    appearance: 'none',

    svg: {
      maxWidth: '24px',
      maxHeight: '24px',
    },

    _disabled: {
      cursor: 'not-allowed',
      opacity: 0.4,
    },
  },

  sizes: {
    sm: {
      height: '32px',
      minWidth: '32px',

      svg: {
        maxWidth: '16px',
        maxHeight: '16px',
      },
    },

    md: {
      height: '40px',
      minWidth: '40px',
    },

    lg: {
      height: '48px',
      minWidth: '48px',
    },
  },

  variants: {
    ghost: (props) => ({
      border: '1px solid',
      borderRadius: '10px',
      borderColor: mode('blackTransparent.20', 'whiteTransparent.20')(props),
      transition: 'border-color 200ms linear',

      path: {
        fill: mode('black', 'white')(props),
      },

      _hover: {
        borderColor: mode('blackTransparent.40', 'whiteTransparent.40')(props),
      },
    }),

    secondary: secondary,
    naked: naked,
  },
  // The default variant value
  defaultProps: {
    variant: 'ghost',
    size: 'md',
    colorScheme: 'default',
  },
};
