import { XMarkIcon } from '@/components/icons';
import { Box } from '@chakra-ui/react';
import React from 'react';

import { useTheme } from '@/hooks';

import cn from 'classnames';
import styles from './close-button.module.sass';

interface Props {
  className?: string;
  onClick?(): void;
}

export const CloseButton = React.forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    //
    const { className, onClick } = props;

    const { themeClass } = useTheme();

    return (
      <Box
        ref={ref}
        className={cn(styles.Wrapper, styles[themeClass], className)}
        onClick={onClick}
      >
        <XMarkIcon className={styles.Icon} />
      </Box>
    );
  }
);
