import { Box, Image, Text, Button } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import { useProPlan } from '@/context';
import { useAuth } from '@/hooks';

import s from './ProPlanBanner.module.sass';

export const ProPlanBanner: React.FC = () => {
  const [isProPlan, setIsProPlan] = useState(false);

  const auth = useAuth();

  const { getProPlan } = useProPlan();

  useEffect(() => setIsProPlan(!!auth?.user?.display?.proPlan), [auth?.user?.display?.proPlan]);

  return (
    <Box className={cn(s.ProPlanWrapper)}>
      <Box className={cn(s.Shadow)} />
      {isProPlan ? (
        <>
          <Image src={'/assets/icons/party.png'} />
          <Text>Congrats!<br/>You’re currently on the Pro Plan.</Text>
        </>
      ) : (
        <>
          <Image src={'/assets/icons/rocket.png'} />
          <Text>Upgrade to Pro for more features.</Text>
          <Button variant={'primary'} size={'sm'} mt={'14px'} w={'100%'} onClick={(e) => {
            if (!e.shiftKey) {
              getProPlan();
            } else {
              setIsProPlan(true);
              setTimeout(() => setIsProPlan(false), 5000);
            }
          }}>Get Pro Plan</Button>
        </>
      )}
    </Box>
  );
};