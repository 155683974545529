import { useTheme } from '@/hooks';
import { Box, HStack } from '@chakra-ui/react';
import Link from 'next/link';
import { Icon, Text } from './components';

import cn from 'classnames';
import styles from './logo.module.sass';

interface LogoProps {
  full?: boolean;
  iconShadow?: boolean;
  className?: string;
  variant?: 'gradient' | 'white';
}

export const Logo = (props: LogoProps) => {
  //
  const {
    full = false,
    iconShadow = true,
    className,
    variant = 'gradient',
  } = props;

  const { themeClass } = useTheme();

  return (
    <Box
      className={cn(
        styles.Logo,
        styles[themeClass],
        variant === 'gradient' && styles['Logo--Gradient'],
        variant === 'white' && styles['Logo--White'],
        className
      )}
    >
      <HStack as="a" h="100%">
        <Box
          className={cn(styles.Icon, iconShadow && styles['Icon--Shaddow'])}
        >
          <Icon />
        </Box>
        {full && <Text />}
      </HStack>
    </Box>
  );
};
