import { popoverAnatomy as parts } from '@chakra-ui/anatomy';
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from '@chakra-ui/styled-system';
import { cssVar, mode } from '@chakra-ui/theme-tools';

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys);

const $popperBg = cssVar('popper-bg');
const $arrowBg = cssVar('popper-arrow-bg');

const inverseVariant = (props) => {
  const bg = mode('dark', 'white')(props);

  return definePartsStyle({
    content: {
      [$popperBg.variable]: `colors.${bg}`,
      [$arrowBg.variable]: $popperBg.reference,
      bg: $popperBg.reference,
      borderColor: mode('dark', 'white')(props),
      borderRadius: '8px',
      color: mode('white', 'black')(props),
      outline: '0 !important',
    },
  });
};

const baseStyleContent = defineStyle({
  [$popperBg.variable]: `colors.white`,
  [$arrowBg.variable]: $popperBg.reference,
  bg: $popperBg.reference,
  border: '1px solid',
  borderColor: 'blackTransparent.20',
  borderRadius: '10px',
  boxShadow: '0 0 25px rgba(61, 30, 98, 0.15)',
  outline: '0 !important',

  _dark: {
    [$popperBg.variable]: `colors.dark`,
    [$arrowBg.variable]: $popperBg.reference,
    borderColor: 'whiteTransparent.20',
  },
});

const baseStyleBody = defineStyle({
  padding: '16px',
  outline: '0 !important',
});

const baseStylePoper = defineStyle({
  zIndex: '15',
});

const baseStyle = definePartsStyle({
  popper: baseStylePoper,
  content: baseStyleContent,
  body: baseStyleBody,
});

export const Popover = defineMultiStyleConfig({
  baseStyle,
  variants: {
    inverse: inverseVariant,
  },
});
