import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { tNFT } from '@/modules/torrent/types';
import { NFTMarketplaceModal } from '@/modules/display/pages/my-nfts-pages/components';

type IAppName = 'display' | 'torrent' | 'storage';

interface IOpenTNFTDetailsOptions {
  tNFT: tNFT | string;
  onNFTChanged?: (NFT: tNFT) => void;
}

interface ICtx {
  lastSelectedApp: IAppName;
  openTNFTDetails: (options: IOpenTNFTDetailsOptions) => void;
}

export const AppContext = createContext<ICtx>({
  lastSelectedApp: 'display',
  openTNFTDetails: () => void 0,
});

export const useApp = () => useContext(AppContext);

export const AppContextProvider = (props) => {
  const [app, setApp] = useState<IAppName>();
  const [selectedNFT, setSelectedNFT] = useState<tNFT | string>();
  const [handleNFTChanged, setHandleNFTChanged] = useState<any>();
  const router = useRouter();

  const handleChangeRoute = useCallback((path: string) => {
    if (!app && (!path.includes('/display/') || !path.includes('/torrent/') || !path.includes('/storage/'))) {
      setApp('display');
      return;
    }

    if (path.includes('/display/')) {
      return setApp('display');
    }

    if (path.includes('/torrent/')) {
      return setApp('torrent');
    }

    if (path.includes('/storage/')) {
      return setApp('storage');
    }
  }, [app]);

  useEffect(() => {
    router.events.on('routeChangeComplete', handleChangeRoute);

    return () => {
      router.events.off('routeChangeComplete', handleChangeRoute);
    };
  }, [handleChangeRoute, router.asPath, router.events]);

  const ctx: ICtx = {
    lastSelectedApp: app,
    openTNFTDetails: (options) => {
      setSelectedNFT(options.tNFT);
      options.onNFTChanged && setHandleNFTChanged(() => options.onNFTChanged);
    },
  };

  return (
    <AppContext.Provider value={ctx}>
      {props.children}

      <NFTMarketplaceModal
        NFT={selectedNFT}
        isOpen={!!selectedNFT}
        onNFTChanged={handleNFTChanged}
        onClose={() => {
          setSelectedNFT(undefined);
          setHandleNFTChanged(undefined);
        }}
      />
    </AppContext.Provider>
  )
};