import { useDisclosure } from '@chakra-ui/react';
import React, { createContext, useCallback, useContext } from 'react';

import { ProPlanModal } from '@/components/modules/home';
import { configs } from '@/configs';

type IProPlanContext = {
  getProPlan: () => void;
};

const ProPlanContext = createContext<IProPlanContext>({
  getProPlan: () => void 0,
});

type IUseProPlan = () => IProPlanContext;

export const useProPlan: IUseProPlan = () => useContext(ProPlanContext);

type IProPlanProviderProps = {
  children: React.ReactNode;
}

export const ProPlanProvider: React.FC<IProPlanProviderProps> = (props) => {

  const { isOpen, onOpen, onClose } = useDisclosure();

  const getProPlan = useCallback(() => onOpen(), []);

  const value: IProPlanContext = {
    getProPlan,
  };

  return (
    <>
      <ProPlanContext.Provider value={value} {...props} />
      {configs.featuresFlags.enableProPlanSubscription && (
        <ProPlanModal isOpen={isOpen} onClose={onClose} />
      )}
    </>
  );
};