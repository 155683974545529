import { Box, HStack, Text } from '@chakra-ui/react';

import cn from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar';
import styles from './playlist-selected-nfts.module.sass';

//import { useHorizontalScroll } from '@/hooks';
import { useSelectedNfts } from '@/hooks';
import { SelectedNftsList } from './components';

export const PlaylistSelectedNFTs = (props) => {
  //
  const {} = props;

  //const scrollRef = useHorizontalScroll();

  const { assets, setAssets, maxSelectedAssets } = useSelectedNfts();

  const onChange = (items) => {
    //
    setAssets(items);
  };

  return (
    <Box className={cn(styles.Wrapper)}>
      <HStack spacing="5px">
        <Box className={styles.Headline}>Selected NFTs</Box>
        <Box className={styles.Counter}>{assets.length}</Box>
      </HStack>
      <PerfectScrollbar>
        <Box className={styles.List}>
          {assets.length ? (
            <SelectedNftsList items={assets} onChange={onChange} />
          ) : (
            <Box alignItems={'center'} display={'flex'} gap={'20px'}>
              <Box className={cn(styles.Placeholder)} />
              <Box className={cn(styles.Placeholder)} />
              <Text fontSize={'14px'} maxW={'124px'} opacity={0.4}>
                Click on any NFT below to select it.
              </Text>
            </Box>
          )}
        </Box>
      </PerfectScrollbar>
    </Box>
  );
};
