import { ghost } from './variants/ghost';
import { naked } from './variants/naked';
import { primary } from './variants/primary';
import { secondary } from './variants/secondary';

export const Button = {
  // The styles all Cards have in common

  baseStyle: (props) => ({
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    verticalAlign: 'middle',
    whiteSpace: 'nowrap',
    userSelect: 'none',
    appearance: 'none',
    textAlign: 'center',
  }),
  sizes: {
    sm: {},

    md: {
      fontSize: '14px',
      height: '40px',
      padding: '0 24px',
      minWidth: 'fit-content',
    },

    lg: {
      fontSize: '16px',
      height: '48px',
      padding: '0 40px',
      minWidth: 'fit-content',
    },
  },
  // Variants
  variants: {
    ghost: ghost,
    primary: primary,
    secondary: secondary,
    naked: naked,
  },
  // The default props
  defaultProps: {
    variant: 'ghost',
    size: 'md',
    colorScheme: 'default',
  },
};
