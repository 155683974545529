import React, { useCallback, useEffect, useState } from 'react';

import { Icon } from '@/components/ui';
import { Box, HStack, useColorMode } from '@chakra-ui/react';

import { Tab } from '@headlessui/react';
import { useScroll, useWindowSize } from 'react-use';

import cn from 'classnames';
import styles from './tabs.module.sass';

interface TabsProps {
  items: Tab[];
  defaultActiveTab?: TabId;
  tabListClassName?: string;
  sticky?: boolean;
  onChange?: ({ index, id }: { index: number; id: TabId }) => void;
}

type TabId = string | string[];

interface Tab {
  id: TabId;
  title: string;
  ticker?: string | number;
  disabled?: boolean;
  component: React.ReactNode;
  icon?: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

export const Tabs = (props: TabsProps) => {
  //
  const {
    items = [],
    sticky = true,
    tabListClassName,
    onChange,
    defaultActiveTab,
  } = props;

  const [selectedIndex, setSelectedIndex] = useState(0);

  const { colorMode } = useColorMode();

  const isDark = colorMode === 'dark';

  const listRef = React.useRef(null);
  const wrapperRef = React.useRef(null);

  const [isLeftArrowVisible, setIsLeftArrowVisible] = useState(false);
  const [isRightArrowVisible, setIsRightArrowVisible] = useState(false);

  const { x } = useScroll(listRef);
  const { width } = useWindowSize();

  useEffect(() => {
    //
    if (defaultActiveTab) {
      const index = items.findIndex((item) => item.id === defaultActiveTab);
      setSelectedIndex(index);
    }
  }, [defaultActiveTab]);

  const onInternalChange = (index: number) => {
    //
    setSelectedIndex(index);
    onChange &&
      onChange({
        index: index,
        id: items[index].id,
      });
  };

  // TODO: refactor this to slider
  useEffect(() => {
    //
    if (!wrapperRef.current && !listRef.current) {
      return;
    }

    const list = listRef.current;
    const wrapper = listRef.current;

    if (x + wrapper.clientWidth < list.scrollWidth - 40) {
      setIsRightArrowVisible(true);
    } else {
      setIsRightArrowVisible(false);
    }

    if (x > 40) {
      setIsLeftArrowVisible(true);
    } else {
      setIsLeftArrowVisible(false);
    }
  }, [listRef, wrapperRef, x, width]);

  const handleClickLeftArrow = useCallback(() => {
    //
    const el = listRef.current;

    if (el) {
      el.scrollTo({
        top: 0,
        left: x - 150,
        behavior: 'smooth',
      });
    }
  }, [x]);

  const handleClickRightArrow = useCallback(() => {
    //
    const el = listRef.current;

    if (el) {
      el.scrollTo({
        top: 0,
        left: x + 150,
        behavior: 'smooth',
      });
    }
  }, [x]);

  return (
    <Box className={styles.Wrapper}>
      <Tab.Group selectedIndex={selectedIndex} onChange={onInternalChange}>
        <Box
          ref={wrapperRef}
          className={cn(styles.TablistWrapper, tabListClassName)}
        >
          <Tab.List
            className={cn(
              styles.Tablist,
              sticky && styles.isSticky,
              isDark && styles.Dark
            )}
          >
            {isLeftArrowVisible && (
              <Box
                className={cn(styles.Arrow, styles.ArrowLeft)}
                onClick={handleClickLeftArrow}
              >
                <Icon icon={{ name: 'chevron-left', size: 'md' }} />
              </Box>
            )}

            <Box ref={listRef} className={styles.Scrollable}>
              {items.map((tab, index) => (
                <Tab
                  key={index}
                  className={({ selected }) =>
                    cn(
                      styles.Tab,
                      selected && styles['Tab--Selected'],
                      tab.disabled && styles['Tab--Disabled']
                    )
                  }
                  onClick={tab.onClick}
                >
                  <HStack spacing="8px">
                    {tab.icon && <Box className={styles.Icon}>{tab.icon}</Box>}

                    <Box className={styles.Title}>{tab.title}</Box>

                    {tab.ticker && (
                      <Box className={styles.Ticker}>{tab.ticker}</Box>
                    )}
                  </HStack>
                </Tab>
              ))}
            </Box>

            {isRightArrowVisible && (
              <Box
                className={cn(styles.Arrow, styles.ArrowRight)}
                onClick={handleClickRightArrow}
              >
                <Icon icon={{ name: 'chevron-right', size: 'md' }} />
              </Box>
            )}
          </Tab.List>
        </Box>
        <Tab.Panels>
          {items.map((item, index) => (
            <Tab.Panel
              key={index}
              className={({ selected }) =>
                cn(styles.Panel, selected && styles['Panel--Selected'])
              }
            >
              {item.component}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </Box>
  );
};
