import {
  Box,
  BoxProps,
  Flex,
  Heading,
  Image,
  keyframes,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import cn from 'classnames';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  createSubscriptionOrder,
  getSubscriptionCurrencies,
  getSubscriptionOrder,
  getSubscriptionPlanEstimate,
  getSubscriptionPlans,
} from '@/api/playlists';
import SuccessImage from '@/assets/images/success.png';
import { Button } from '@/components/ui';
import { useAuth } from '@/hooks';
import { ICurrency, ISubscriptionPlan } from '@/types';

import s from './ProPlanModal.module.sass';

const animationKeyframes = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

interface IPropPlanModal extends BoxProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ProPlanModal: React.FC<IPropPlanModal> = (props) => {
  const { isOpen, onClose } = props;

  const { isAuthenticated } = useAuth();

  type IStep = 'purchase' | 'loading' | 'success';

  const [step, setStep] = useState<IStep>('purchase');

  const [selectedPlanId, setSelectedPlanId] = useState<string>();
  const [selectedCurrencyCode, setSelectedCurrencyCode] = useState<string>();

  const { data: plans } = useQuery(
    ['subscriptions', 'plans'],
    getSubscriptionPlans,
    {
      enabled: isAuthenticated,
      retry: false,
      onSuccess: (plans: ISubscriptionPlan[]) => {
        plans?.length && setSelectedPlanId(plans[0]._id);
      },
    }
  );

  const { data: currencies } = useQuery(
    ['subscriptions', 'currencies'],
    getSubscriptionCurrencies,
    {
      enabled: isAuthenticated,
      retry: false,
      onSuccess: (currencies: ICurrency[]) => {
        currencies?.length && setSelectedCurrencyCode(currencies[0].code);
      },
    }
  );

  const { data: estimateData } = useQuery(
    [
      'subscriptions',
      'plans',
      selectedPlanId,
      selectedCurrencyCode,
      'estimate',
    ],
    () =>
      getSubscriptionPlanEstimate(selectedPlanId ?? '', selectedCurrencyCode),
    {
      enabled: isOpen && !!selectedPlanId && !!selectedCurrencyCode,
      retry: false,
    }
  );

  const { data: order, mutate: createOrder } = useMutation(
    (data: { planId: string; currencyCode: string }) =>
      createSubscriptionOrder(data.planId, data.currencyCode)
  );

  const { data: orderStatus } = useQuery(
    ['subscription', 'order', order?._id],
    () => getSubscriptionOrder(order?._id),
    {
      enabled: !!order?._id,
      retry: true,
      refetchInterval: (order) =>
        order?.paymentStatus !== 'finished' ? 2000 : undefined,
      onSuccess: (order) =>
        order?.paymentStatus === 'finished' && setStep('success'),
    }
  );

  const selectedPlan = useMemo(() => {
    if (!selectedPlanId || !plans.length) {
      return null;
    }

    return plans.find((plan) => plan._id === selectedPlanId);
  }, [selectedPlanId, plans]);

  const handlePurchase = useCallback(() => {
    setStep('loading');
    createOrder({
      planId: selectedPlanId,
      currencyCode: selectedCurrencyCode,
    });
  }, [selectedPlanId, selectedCurrencyCode]);

  useEffect(() => {
    setStep('purchase');
    if (plans?.length) {
      setSelectedPlanId(plans[0]._id);
    }
    if (currencies?.length) {
      setSelectedCurrencyCode(currencies[0].code);
    }
  }, [isOpen, plans, currencies]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay className={cn(s.ModalOverlay)} />
      <ModalContent className={cn(s.ModalContent)}>
        <ModalCloseButton className={cn(s.ModalCloseButton)} />
        <ModalBody className={cn(s.ModalBody)}>
          {step === 'purchase' && (
            <>
              <Heading fontSize={'24px'} mb={'24px'}>
                Subscribe to the Pro Plan
              </Heading>
              <List spacing={'14px'} mb={'30px'}>
                <ListItem>🚀 Add unlimited NFTs </ListItem>
                <ListItem>
                  🚀 Additional style options for your playlists
                </ListItem>
                <ListItem>
                  🚀 Removal of the ‘Powered by Universe’ logo on your display
                </ListItem>
              </List>

              <Heading fontSize={'16px'} mb={'16px'}>
                Please select your plan
              </Heading>
              <RadioGroup
                mb={'30px'}
                value={selectedPlanId}
                onChange={setSelectedPlanId}
              >
                <Stack direction="row">
                  {plans?.map((plan, i) => (
                    <Radio key={i} value={plan._id}>
                      {plan.description}
                    </Radio>
                  ))}
                </Stack>
              </RadioGroup>

              <Heading fontSize={'16px'} mb={'16px'}>
                Please select currency
              </Heading>
              <RadioGroup
                mb={'30px'}
                value={selectedCurrencyCode}
                onChange={setSelectedCurrencyCode}
              >
                <Stack direction="row">
                  {currencies?.map((currency, i) => (
                    <Radio key={i} value={currency.code}>
                      {currency.code}
                    </Radio>
                  ))}
                </Stack>
              </RadioGroup>

              <Flex alignItems={'center'} gap={'14px'}>
                <Text maxW={'340px'}>
                  Purchase subscription to access all features Pro Plan.
                </Text>
                <Text
                  fontSize={'24px'}
                  fontWeight={'bold'}
                  flex={1}
                  textAlign={'right'}
                >
                  {`${selectedCurrencyCode} ${
                    estimateData?.estimate?.toFixed() ?? ''
                  }`}
                </Text>
                <Button
                  variant={'primary'}
                  size={'lg'}
                  onClick={handlePurchase}
                >
                  Purchase
                </Button>
              </Flex>
            </>
          )}

          {step === 'loading' && (
            <>
              <Image
                src={'/assets/icons/spinner.png'}
                boxSize={'80px'}
                m={'auto'}
                mb={'40px'}
                animation={`${animationKeyframes} 2s linear infinite`}
              />
              <Box textAlign={'center'}>
                <Heading fontSize={'30px'} mb={'16px'}>
                  Loading!
                </Heading>
                {order && (
                  <Text>
                    Please pay{' '}
                    <strong>
                      {estimateData?.estimate?.toFixed() ?? ''}{' '}
                      {selectedCurrencyCode}
                    </strong>{' '}
                    to the wallet: <strong>{order.payAddress}</strong>
                  </Text>
                )}
              </Box>
            </>
          )}

          {step === 'success' && (
            <Box>
              <Image
                src={SuccessImage.src}
                boxSize={'128px'}
                m={'auto'}
                mb={'32px'}
              />
              <Box textAlign={'center'}>
                <Heading fontSize={'30px'} mb={'16px'}>
                  You’re on the Pro Plan now!
                </Heading>
                <Text>
                  Your Pro Plan is active until{' '}
                  {dayjs()
                    .add(selectedPlan.duration, 'months')
                    .format('DD.MM.YYYY')}
                </Text>
              </Box>
            </Box>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
