import {
  Switch as SwitchChakra,
  SwitchProps as SwitchChakraProps,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import React from 'react';

export const Switch = React.forwardRef<HTMLInputElement, SwitchChakraProps>(
  (props, ref) => {
    //
    const { variant, size, ...rest } = props;

    const styles = useMultiStyleConfig('Switch', { variant, size });

    return <SwitchChakra size={size} ref={ref} __css={styles} {...rest} />;
  }
);

Switch.displayName = 'Switch';
