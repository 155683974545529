import { IconProps } from '@/components/ui';
import type { TorrentCategories } from '@/types';

type TorrentCategoriesArray = {
  value: TorrentCategories;
  title: string;
  icon: IconProps['icon'];
};

export const TORRENT_CATEGORIES: TorrentCategoriesArray[] = [
  {
    value: 'imagery',
    title: 'Imagery',
    icon: {
      name: 'category-imagery',
      size: 'md',
    },
  },
  {
    value: 'video',
    title: 'Video',
    icon: {
      name: 'category-video',
      size: 'md',
    },
  },
  {
    value: 'audio',
    title: 'Audio',
    icon: {
      name: 'category-audio',
      size: 'md',
    },
  },
  {
    value: '3d',
    title: '3D',
    icon: {
      name: 'category-3d',
      size: 'md',
    },
  },
  {
    value: 'apps',
    title: 'Apps',
    icon: {
      name: 'category-apps',
      size: 'md',
    },
  },

  {
    value: 'gaming',
    title: 'Gaming',
    icon: {
      name: 'category-gaming',
      size: 'md',
    },
  },
  {
    value: 'other',
    title: 'Other',
    icon: {
      name: 'category-other',
      size: 'md',
    },
  },
];
