import dayjs from 'dayjs';

export const getDateRangeLabel = (startDate: Date, endDate: Date) => {
  if (!startDate || !endDate) {
    return null;
  }

  const start = dayjs(startDate);
  const end = dayjs(endDate);

  const diffYears = end.diff(start, 'years');

  if (diffYears > 1) {
    return `${diffYears} years`;
  } else if (diffYears === 1) {
    return `${diffYears} year`;
  }

  const diffMonths = end.diff(start, 'months');

  if (diffMonths > 1) {
    return `${diffMonths} months`;
  } else if (diffMonths === 1) {
    return `${diffMonths} month`;
  }

  const diffDays = end.diff(start, 'days');

  if (diffDays > 1) {
    return `${diffDays} days`;
  } else if (diffDays === 1) {
    return `${diffDays} day`;
  }

  const diffHours = end.diff(start, 'hours');

  if (diffHours > 1) {
    return `${diffHours} hours`;
  } else if (diffHours === 1) {
    return `${diffHours} hour`;
  }

  const diffMinutes = end.diff(start, 'minutes');

  if (diffMinutes > 1) {
    return `${diffMinutes} minutes`;
  } else if (diffMinutes === 1) {
    return `${diffMinutes} minute`;
  }
};