import { useCallback } from 'react';
import { useColorMode } from '@chakra-ui/react';
import { trackToggleTheme } from '@/utils/amplitude';

function useTheme() {
  const { colorMode, toggleColorMode } = useColorMode();

  const isDark = colorMode === 'dark';
  const isLight = colorMode === 'light';
  const themeClass = colorMode.charAt(0).toUpperCase() + colorMode.slice(1);

  const toggle = useCallback(() => {
    toggleColorMode();
    trackToggleTheme(isDark ? 'light' : 'dark');
  }, [isDark, toggleColorMode]);

  return {
    colorMode,
    themeClass,
    isDark,
    isLight,
    toggleColorMode: toggle,
  };
}

export { useTheme };
