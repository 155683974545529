import React, { useEffect, useState } from 'react';

import { Box } from '@chakra-ui/react';

import { useNftCardContext } from '../../context';
import { useNftAsset } from '../../hooks';

import {
  NftAssetAudio,
  NftAssetBadges,
  NftAssetError,
  NftAssetImage,
  NftAssetVideo,
} from './components';

import { getFileType } from '@/utils';

import cn from 'classnames';
import styles from './nft-asset.module.sass';

type VideoProps = {
  light?: boolean;
  fit?: 'cover' | 'contain';
  isShowProgressBar?: boolean;
  isShowFullscreenMode?: boolean;
  isHandleClickEvenets?: boolean;
};

type AudioProps = {
  isShowProgressBar?: boolean;
};

export interface INFTAssetProps {
  className?: string;
  nft: any;
  videoProps?: VideoProps;
  audioProps?: AudioProps;
  onAssetParsed?(asset: any): void;
  renderVideo?: (asset: any, preview: string, alt: string) => React.ReactNode;
  renderAudio?: (asset: any, preview: string, alt: string) => React.ReactNode;
}

export const NftAsset: React.FC<INFTAssetProps> = (props) => {
  //
  const {
    className,
    nft,
    videoProps,
    audioProps,
    onAssetParsed,
    renderVideo = (asset, preview, alt) => (
      <NftAssetVideo
        asset={asset}
        alt={alt}
        preview={preview}
        onError={() => setLoadingError(true)}
        {...videoProps}
      />
    ),
    renderAudio = (asset, preview, alt) => (
      <NftAssetAudio
        asset={asset}
        preview={preview}
        alt={alt}
        onError={() => setLoadingError(true)}
        {...audioProps}
      />
    ),
  } = props;

  const { asset, preview, alt } = useNftAsset(nft);

  const [loadingError, setLoadingError] = useState(false);

  const { isVideo, isAudio, isImage, isUnknown } = getFileType(asset.type);
  const { isPlaying } = useNftCardContext();

  const isShowBadges = isVideo || isAudio;

  useEffect(() => {
    if (asset) {
      onAssetParsed &&
        onAssetParsed({
          ...asset,
          preview: preview || undefined,
        });
    }
  }, [asset, preview]);

  return (
    <Box
      className={cn(styles.Wrapper, isPlaying && styles.isPlaying, className)}
    >
      {!loadingError && (
        <>
          {isImage && (
            <NftAssetImage
              asset={asset}
              alt={alt}
              onError={() => setLoadingError(true)}
            />
          )}

          {isVideo && renderVideo?.(asset, preview, alt)}
          {isAudio && renderAudio?.(asset, preview, alt)}
        </>
      )}

      {(isUnknown || loadingError) && <NftAssetError />}

      {isShowBadges && <NftAssetBadges type={asset.type} />}
    </Box>
  );
};
