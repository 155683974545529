import React, { useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Box, Flex, Heading, HStack, Text, VStack } from '@chakra-ui/react';

import {
  NFTDetailsModalHeader,
  NFTDetailsModalStickyBar,
  NFTFees,
  NFTProcessing,
  TokenSelector,
} from '@/components/common';

import { Button, Form, FormItem, InputNumber } from '@/components/ui';

import { DurationDropdown } from './components';

import { ReservoirAPI } from '@/api/reservoir';

import {
  useAuth,
  useContractRead,
  useContractWrite,
  useWaitForTransaction,
} from '@/hooks';

import { ETH_TOKEN, NETWORK_CHAIN_ID } from '@/constants';
import { Contracts } from '@/contracts';
import { configs } from '@/configs';
import { MARKETPLACE_TOKENS } from './../../constants';

import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import dayjs from 'dayjs';
import s from './nft-marketplace-listing.module.sass';

const { contracts } = Contracts[NETWORK_CHAIN_ID];

import type { tNFT } from '@/modules/torrent/types';
import type { IToken } from '@/types';

interface INFTMarketplaceListingProps {
  NFT: tNFT;
  onSuccessListing: () => void;
  onClose: () => void;
}

type State = 'listing' | 'processing' | 'success' | 'error';

export const NFTMarketplaceListing: React.FC<INFTMarketplaceListingProps> = (
  props
) => {
  const { NFT, onSuccessListing, onClose } = props;

  const { address, signer } = useAuth();

  const { data: isCollectionApproved, refetch: refetchCollectionApproved } =
    useContractRead({
      address: NFT.contractAddress,
      abi: contracts.GravitonTorrentERC721Core.abi,
      functionName: 'isApprovedForAll',
      args: [address, configs.marketplaceContractAddress],
    });

  const {
    data: approveCollectionTx,
    isLoading: isLoadingCollectionApproval,
    writeAsync: approveCollection,
  } = useContractWrite({
    address: NFT.contractAddress,
    abi: contracts.GravitonTorrentERC721Core.abi,
    functionName: 'setApprovalForAll',
    args: [configs.marketplaceContractAddress, true],
  });

  const { isFetching: isLoadingCollectionApprovalTx } = useWaitForTransaction({
    tx: approveCollectionTx,
    onSuccess: () => refetchCollectionApproved(),
  });

  const [state, setState] = useState<State>('listing');

  type IFormValues = {
    price: number;
    token: IToken;
    startDate: Date;
    endDate: Date;
  };

  const form = useForm<IFormValues>({
    mode: 'onChange',
    defaultValues: {
      token: ETH_TOKEN,
      startDate: dayjs().toDate(),
      endDate: dayjs().add(1, 'day').toDate(),
    },
    resolver: zodResolver(
      z.object({
        token: z.any(),
        price: z.string().refine((val) => parseFloat(val) > 0, {
          message: 'Expected positive number',
        }),
        startDate: z.any(),
        endDate: z.any(),
      })
    ),
  });

  const handleListing = useCallback(
    async (values: IFormValues) => {
      try {
        setState('processing');

        const order = await ReservoirAPI.createListing(
          signer,
          address,
          NFT,
          values.price.toString(),
          values.token,
          [], // validSaleSplit ? [[saleSplitAddress, saleSplitFee]] : [], // TODO: royalties
          Math.floor(values.startDate.getTime() / 1000),
          Math.floor(values.endDate.getTime() / 1000)
        );

        setState('success');

        onSuccessListing();
      } catch (e: unknown) {
        console.error(e);
        setState('error');
      }
    },
    [NFT, address, signer, onSuccessListing]
  );

  const NFTName = NFT.metadata?.name ?? NFT.tokenId;

  const price = form.watch('price') || 0;
  const token = form.watch('token');

  return (
    <>
      <NFTDetailsModalHeader>
        <Heading fontSize={'24px'}>List item for sale</Heading>
      </NFTDetailsModalHeader>

      {state === 'listing' && (
        <VStack align={'stretch'} spacing={'16px'}>
          <FormProvider {...form}>
            <Form onSubmit={form.handleSubmit(handleListing)}>
              <HStack alignItems={'flex-start'} spacing={'8px'}>
                <Box flex={1}>
                  <FormItem
                    type="input"
                    name="price"
                    label="Price"
                    showErrorMessage={true}
                  >
                    <InputNumber showStepper={false} placeholder={'0.5'} />
                  </FormItem>
                </Box>
                <Box pt={'28px'}>
                  <TokenSelector
                    tokens={MARKETPLACE_TOKENS}
                    value={form.watch('token')}
                    onChange={(token) => form.setValue('token', token)}
                  />
                </Box>
              </HStack>
            </Form>
          </FormProvider>

          <VStack align={'stretch'} spacing={'6px'}>
            <Heading className={s.Label}>Duration</Heading>
            <Box>
              <DurationDropdown
                startDate={form.watch('startDate')}
                endDate={form.watch('endDate')}
                onChange={(startDate, endDate) => {
                  form.setValue('startDate', startDate);
                  form.setValue('endDate', endDate);
                }}
              />
            </Box>
          </VStack>

          <VStack align={'stretch'} spacing={'8px'}>
            <Heading className={s.Label}>Fees</Heading>
            <NFTFees
              contractAddress={NFT?.contractAddress}
              tokenId={NFT?.tokenId}
            />
          </VStack>

          <NFTDetailsModalStickyBar>
            <Flex alignItems={'center'} gap={'16px'}>
              <Button variant={'secondary'} w={'100%'} onClick={onClose}>
                Cancel
              </Button>
              {!isCollectionApproved ? (
                <Button
                  variant={'primary'}
                  w={'100%'}
                  isDisabled={!form.formState.isValid}
                  isLoading={
                    isLoadingCollectionApproval || isLoadingCollectionApprovalTx
                  }
                  onClick={() => approveCollection(undefined)}
                >
                  Approve
                </Button>
              ) : (
                <Button
                  variant={'primary'}
                  w={'100%'}
                  isDisabled={!form.formState.isValid}
                  onClick={() => form.handleSubmit(handleListing)()}
                >
                  Confirm listing
                </Button>
              )}
            </Flex>
          </NFTDetailsModalStickyBar>
        </VStack>
      )}

      {state === 'processing' && (
        <NFTProcessing
          state={'processing'}
          title={'Your listing is processing...'}
          tx={approveCollectionTx?.hash}
          renderDescription={() => (
            <Text>
              Your listing of <Box as={'span'}>{NFTName}</Box> is processing. It
              should be confirmed on the blockchain shortly.
            </Text>
          )}
          renderFooter={() => null}
        />
      )}

      {state === 'success' && (
        <>
          <NFTProcessing
            state={'success'}
            title={'Congratulations!'}
            tx={approveCollectionTx?.hash}
            renderDescription={() => (
              <Text>
                You have successfully listed <Box as={'span'}>{NFTName}</Box>{' '}
                for{' '}
                <Box as={'span'}>
                  {price} {token.ticker}
                </Box>
                . Listing ends on{' '}
                <Box as={'span'}>
                  {!form.watch('endDate')
                    ? null
                    : dayjs(form.watch('endDate')).format(
                        'MMM DD, YYYY, HH:mm'
                      )}
                </Box>
                .
              </Text>
            )}
            onClose={onClose}
          />
        </>
      )}

      {state === 'error' && (
        <NFTProcessing
          state="failed"
          renderDescription={() => (
            <Text>Please refresh the page and try again.</Text>
          )}
          onClose={onClose}
        />
      )}
    </>
  );
};
