import ReactPlayer from 'react-player';

import { Spinner } from '@/components/ui';
import { Box, Flex, HStack } from '@chakra-ui/react';
import { Fullscreen, Mute, Play, ProgressBar, Timer } from '../controls';

import { useDoubleClick } from '@/hooks';
import { useEffect, useRef } from 'react';
import { useIdle, useKeyPress } from 'react-use';
import { usePlayerState } from '../hooks';

import cn from 'classnames';
import styles from './player-video.module.sass';

interface Props {
  url: string;
  hideTimer?: boolean;
  lightMode?: boolean;
}

export const PlayerVideo = (props: Props) => {
  //
  const { url, hideTimer = false, lightMode = true } = props;

  const player = useRef(null);
  const wrapper = useRef(null);
  const box = useRef(null);

  const isIdle = useIdle(3000);

  const {
    played,
    playing,
    muted,
    seeking,
    duration,
    buffer,
    loaded,
    fullscreen,
    togglePlaying,
    toggleSound,
    toggleFullscreen,
    onPlayedChange,
    onSeekingStart,
    onSeekingEnd,
    onDuration,
    onEnded,
    onLoaded,
    onBuffer,
    onBufferEnd,
  } = usePlayerState({ player, wrapper });

  // Fullscreen mouse double click
  useDoubleClick({
    onSingleClick: () => {
      togglePlaying();
    },
    onDoubleClick: () => {
      toggleFullscreen();
    },
    ref: box,
    latency: 250,
  });

  // Space button click - play / pause in fullscreen mode
  const [isPressed] = useKeyPress(' ');

  useEffect(() => {
    if (isPressed && fullscreen) {
      togglePlaying();
    }
  }, [isPressed]);

  return (
    <Box
      className={cn(
        styles.Wrapper,
        fullscreen && styles.isFullscreen,
        fullscreen && isIdle && styles.isIdle
      )}
      ref={wrapper}
    >
      {(!loaded || buffer) && (
        <Box className={styles.Loader}>
          <Spinner size="md" />
        </Box>
      )}

      <Flex
        className={cn(styles.Controls, loaded && styles.isLoaded)}
        align="center"
      >
        <HStack spacing="8px" width="100%">
          <Play
            playing={playing}
            onClick={togglePlaying}
            isLightMode={lightMode}
          />

          <ProgressBar
            played={played}
            seeking={seeking}
            onSeekingStart={onSeekingStart}
            onSeekingEnd={onSeekingEnd}
            isLightMode={lightMode}
            isWrap={true}
          />

          {!hideTimer && (
            <Box>
              <Timer
                duration={duration}
                played={played}
                isLightMode={lightMode}
              />
            </Box>
          )}

          <Mute muted={muted} onClick={toggleSound} isLightMode={lightMode} />

          <Fullscreen
            fullscreen={fullscreen}
            isLightMode={lightMode}
            onClick={toggleFullscreen}
          />
        </HStack>
      </Flex>

      <Box className={styles.VideoWrapper} ref={box}>
        <ReactPlayer
          ref={player}
          className={styles.Video}
          playing={playing}
          muted={muted}
          controls={false}
          url={url}
          onProgress={onPlayedChange}
          onBuffer={onBuffer}
          onBufferEnd={onBufferEnd}
          onReady={onLoaded}
          onEnded={onEnded}
          onDuration={onDuration}
          width="100%"
          height="100%"
        />
      </Box>
    </Box>
  );
};
