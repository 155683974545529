import { Contract } from 'ethers';
import { useMutation } from '@tanstack/react-query';

import { useAuth } from '@/hooks';

interface IUsePrepareContractWriteOptions  {
  address: string;
  abi: any;
  functionName: string;
  args?: any[];
  onSuccess?: (result: any) => void;
}

export const useContractWrite = (options: IUsePrepareContractWriteOptions) => {
  const { address, abi, functionName, args = [], onSuccess, } = options;

  const { signer } = useAuth();

  const { mutate, mutateAsync, ...rest } = useMutation({
    mutationFn: async (dynamicArgs?: any[] | undefined) => {
      if (!signer) {
        throw new Error('Signer not found');
      }

      const contract = new Contract(address, abi, signer);

      const gasLimit = await contract.estimateGas[functionName](...(dynamicArgs ? dynamicArgs : args));

      const tx = await contract[functionName](...(dynamicArgs ? dynamicArgs : args), { gasLimit });

      return tx;
    },
    onSuccess,
  });

  return {
    write: mutate,
    writeAsync: mutateAsync,
    ...rest,
  };
};