import React, { useCallback, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';

import {
  Fullscreen,
  Mute,
  Play,
  ProgressBar,
  usePlayerState,
} from '@/components/common';

import { AspectRatio, Box, Flex } from '@chakra-ui/react';

import { NftImageLoading } from '../../../nft-image-loading';
import { NftAssetImage } from '../nft-asset-image';

import { useDoubleClick } from '@/hooks';
import { useIdle, useKeyPress } from 'react-use';
import { useNftCardContext } from '../../../../context';

import cn from 'classnames';
import styles from './../../nft-asset.module.sass';

type ImageLoadingType = 'skeleton' | 'spinner';
interface INFTAssetVideoProps {
  asset: any;
  preview?: string;
  alt?: string;
  light?: boolean;
  imageLoadingType?: ImageLoadingType;
  fit?: 'cover' | 'contain';
  isShowProgressBar?: boolean;
  isShowFullscreenMode?: boolean;
  isHandleClickEvenets?: boolean;
  onError?: () => void;
  onLoaded?: () => void;
}

export const NftAssetVideo: React.FC<INFTAssetVideoProps> = (props) => {
  //
  const {
    asset,
    preview,
    alt,
    light = true,
    fit = 'cover',
    isShowProgressBar = false,
    isShowFullscreenMode = false,
    isHandleClickEvenets = false,
    onError,
    imageLoadingType,
  } = props;

  const player = useRef(null);
  const wrapper = useRef(null);
  const box = useRef(null);

  const isIdle = useIdle(3000);

  const {
    fullscreen,
    playing,
    played,
    seeking,
    paused,
    muted,
    loaded,
    mounted,
    buffer,
    togglePlaying,
    toggleSound,
    toggleFullscreen,
    onEnded,
    onLoaded,
    onBuffer,
    onBufferEnd,
    onPlayedChange,
    onSeekingStart,
    onSeekingEnd,
    onClickPreview,
  } = usePlayerState({ player, light: light, wrapper });

  const { setIsPlaying } = useNftCardContext();

  // Fullscreen mouse double click
  useDoubleClick({
    onSingleClick: () => {
      togglePlaying();
    },
    onDoubleClick: () => {
      toggleFullscreen();
    },
    ref: box,
    latency: 250,
    enabled: isHandleClickEvenets,
  });

  // Space button click - play / pause in fullscreen mode
  const [isPressed] = useKeyPress(' ');

  useEffect(() => {
    if (isPressed && fullscreen) {
      togglePlaying();
    }
  }, [isPressed]);

  const handleTogglePlaying = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      togglePlaying();
    },
    [togglePlaying]
  );

  const handleToggleSound = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      toggleSound();
    },
    [toggleSound]
  );

  const handleClickPreview = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      onClickPreview();
    },
    [onClickPreview]
  );

  useEffect(() => {
    //
    setIsPlaying(playing);
  }, [playing]);

  const isShowPreview = !playing && !paused && light;
  const isBuffering = buffer && !paused;
  const isLoadingIfLightModeTrue = isBuffering || (!loaded && mounted && light);
  const isLoadingIfLightModeFalse =
    isBuffering || (!loaded && mounted && !light);

  const isVideoFitContain = fit === 'contain';

  return (
    <>
      <Box
        className={cn(
          styles.Wrapper,
          isVideoFitContain && styles['Video--Contain'],
          fullscreen && styles['Video--Fullscreen'],
          fullscreen && isIdle && styles['Video--Idle']
        )}
        ref={wrapper}
      >
        {light ? (
          <>{isLoadingIfLightModeTrue && <NftImageLoading />}</>
        ) : (
          <>{isLoadingIfLightModeFalse && <NftImageLoading />}</>
        )}

        {loaded ? (
          <Flex
            className={cn(
              styles.Controls,
              loaded && styles.isLoaded,
              playing && styles.isPlaying,
              paused && styles.isPaused
            )}
            align="center"
            justify="space-between"
            data-class="controls"
            gap="8px"
          >
            <Play
              playing={playing}
              onClick={handleTogglePlaying}
              isLightMode={true}
            />

            {isShowProgressBar && (
              <ProgressBar
                played={played}
                seeking={seeking}
                onSeekingStart={onSeekingStart}
                onSeekingEnd={onSeekingEnd}
                isLightMode={true}
                isWrap={true}
              />
            )}

            <Mute
              muted={muted}
              onClick={handleToggleSound}
              isLightMode={true}
            />

            {isShowFullscreenMode && (
              <Fullscreen
                fullscreen={fullscreen}
                isLightMode={true}
                onClick={toggleFullscreen}
              />
            )}
          </Flex>
        ) : (
          <Box className={styles.Play} data-class="play">
            <Play
              playing={playing}
              onClick={(e) =>
                light ? handleClickPreview(e) : handleTogglePlaying(e)
              }
              isLightMode={true}
            />
          </Box>
        )}

        <Box>
          {isShowPreview && (
            <Box className={styles.Preview}>
              <NftAssetImage
                asset={{ type: 'image', url: preview }}
                alt={alt}
                imageLoadingType={imageLoadingType}
              />
            </Box>
          )}

          <Box className={styles.Player} ref={box}>
            <AspectRatio ratio={1}>
              <ReactPlayer
                ref={player}
                url={asset.url}
                width="100%"
                height="100%"
                muted={muted}
                playing={playing}
                loop={true}
                onProgress={onPlayedChange}
                onReady={onLoaded}
                onEnded={onEnded}
                onError={onError}
                onBuffer={onBuffer}
                onBufferEnd={onBufferEnd}
                light={light ? preview : false}
                playIcon={<></>}
                style={isShowPreview ? { display: 'none' } : null}
              />
            </AspectRatio>
          </Box>
        </Box>
      </Box>
    </>
  );
};
