import {
  Box,
  Button,
  LinkBox,
  LinkOverlay,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';
import cn from 'classnames';
import React from 'react';

import { Icon, Tooltip } from '@/components/ui';
import { NFT_TORRENT_DESKTOP_APP_URL } from '@/constants';

import { trackInitiateGetMobileApp } from '@/utils/amplitude';
import s from './mobiles-apps-links.module.sass';

interface IMobileAppLinksProps {
  application: 'display' | 'torrent';
}

export const MobileAppsLinks: React.FC<IMobileAppLinksProps> = (props) => {
  const { application } = props;

  type IApplicationLinks = Record<
    IMobileAppLinksProps['application'],
    {
      icon: React.ReactNode;
      groups: Array<{
        name?: string;
        links: Array<{
          link: string | null;
          icon: React.ReactNode;
        }>;
      }>;
    }
  >;

  const applicationLinks: IApplicationLinks = {
    display: {
      icon: (
        <>
          <Icon icon={'apple'} />
          <Icon icon={'android'} />
        </>
      ),
      groups: [
        {
          name: 'Mobile apps',
          links: [
            {
              link: 'https://play.google.com/store/apps/details?id=xyz.Graviton.NFT_Display',
              icon: <Icon icon={'downloadAndroidApp'} />,
            },
            {
              link: null,
              icon: <Icon icon={'downloadIOSApp'} />,
            },
          ],
        },
        {
          name: 'TV apps',
          links: [
            {
              link: 'https://play.google.com/store/apps/details?id=xyz.graviton.nftdisplaytv',
              icon: <Icon icon={'downloadAndroidTVApp'} />,
            },
            {
              link: 'https://apps.apple.com/us/app/nft-display/id1620554581',
              icon: <Icon icon={'downloadAppleTVApp'} />,
            },
          ],
        },
      ],
    },
    torrent: {
      icon: (
        <>
          <Icon icon={'apple'} />
          <Icon icon={'linux'} />
          <Icon icon={'windows'} />
        </>
      ),
      groups: [
        {
          links: [
            {
              link: NFT_TORRENT_DESKTOP_APP_URL,
              icon: <Icon icon={'downloadWindowsApp'} />,
            },
            {
              link: NFT_TORRENT_DESKTOP_APP_URL,
              icon: <Icon icon={'downloadMacOSApp'} />,
            },
            {
              link: NFT_TORRENT_DESKTOP_APP_URL,
              icon: <Icon icon={'downloadLinuxApp'} />,
            },
          ],
        },
      ],
    },
  };

  const handleClickGetApp = () => {
    trackInitiateGetMobileApp(application);
  };

  return (
    <Popover matchWidth={true}>
      <PopoverTrigger>
        <Button className={cn(s.Wrapper)} onClick={handleClickGetApp}>
          <Box display={'flex'} flexDir={'row'} justifyContent={'center'}>
            {applicationLinks[application].icon}
            <Text ml={'8px'}>Get the app</Text>
          </Box>
        </Button>
      </PopoverTrigger>
      <PopoverContent className={cn(s.PopoverContent)}>
        <PopoverBody padding={'16px'}>
          <Box display={'flex'} flexDir={'column'} gap={'16px'}>
            {applicationLinks[application].groups.map(({ name, links }, i) => (
              <Box key={i}>
                {!!name && (
                  <Text fontSize={'12px'} mb={'8px'} opacity={0.6}>
                    {name}
                  </Text>
                )}
                <Box display={'flex'} flexDir={'column'} gap={'4px'}>
                  {links.map(({ link, icon }, j) => (
                    <LinkBox key={j} className={cn(s.Link, !link && s.Link_disabled)}>
                      <Box>
                        <Tooltip label={'Coming soon'} isDisabled={!!link}>
                          <LinkOverlay href={link || undefined} target={'_blank'}>
                            {icon}
                          </LinkOverlay>
                        </Tooltip>
                      </Box>
                    </LinkBox>
                  ))}
                </Box>
              </Box>
            ))}
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
