import React, { useCallback, useMemo } from 'react';

import {
  Alert,
  Box,
  Center,
  Heading,
  HStack,
  Image,
  Input,
  VStack,
} from '@chakra-ui/react';

import {
  Button,
  Form,
  FormItem,
  Icon,
  InputNumber,
  Select,
} from '@/components/ui';
import { LabelTooltip } from '@/components/ui/form-item';

import {
  ElasticSwitch,
  NFTDetailsModalHeader,
  NFTDetailsModalStickyBar,
} from '@/components/common';

import { PoweredByUniverseTooltip } from '@/components/modules/display/playlists';

import { useCreatePlaylist } from '@/api/playlists';
import { useNftAsset } from '@/components/common/nft-card/hooks';
import { useQueryClient } from '@tanstack/react-query';
import { FormProvider, useForm } from 'react-hook-form';

import {
  DELAY_TYPES,
  EFFECTS,
  playlistDefaultValues,
  ROTATION_VALUES,
} from '@/modules/display/constants';

import { playlistValidationSchema } from '@/modules/display/validations';
import { NFT } from '@/types';

import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import SuccessImage from '@/assets/images/success.png';
import { ISelectOption } from '@/components/ui/select';
import { RotationOption } from '@/modules/display/components';

type IPlaylistSubmitData = z.infer<typeof playlistValidationSchema>;

interface INFTModalCreateNewPlaylistSidebarProps {
  NFT: NFT;
  onCreated: () => void;
  onBack: () => void;
}

export const NFTModalCreateNewPlaylistSidebar: React.FC<
  INFTModalCreateNewPlaylistSidebarProps
> = (props) => {
  const { NFT, onCreated, onBack } = props;

  const form = useForm({
    mode: 'onChange',
    defaultValues: playlistDefaultValues,
    resolver: zodResolver(playlistValidationSchema),
  });

  const queryClient = useQueryClient();

  const { asset, preview } = useNftAsset(NFT);

  const { handleSubmit, formState } = form;

  const { isLoading, isSuccess, mutateAsync } = useCreatePlaylist();

  const handleSubmitForm = useCallback(
    async (data: IPlaylistSubmitData) => {
      try {
        await mutateAsync({
          title: data.title,
          effect: data.effect,
          orientation: data.orientation,
          showPoweredBy: data.showPoweredBy,
          delayType: parseInt(data.delayType),
          delay: parseFloat(data.delay),
          assets: [
            {
              id: `${NFT.contractAddress}#${NFT.tokenId}`,
              previewUrl: asset.url,
              url: preview,
            },
          ],
        });
        queryClient.refetchQueries(['allPlaylists']);
      } catch (e: unknown) {}
    },
    [NFT, asset, preview]
  );

  const rotationOptions = useMemo(() => {
    return ROTATION_VALUES.map<ISelectOption>(({ icon, ...rest }) => ({
      ...rest,
      render: (showCheckmark) => (
        <RotationOption
          icon={icon}
          title={rest.title}
          showCheckmark={showCheckmark}
        />
      ),
    }));
  }, []);

  return !isSuccess ? (
    <FormProvider {...form}>
      <Form onSubmit={handleSubmit(handleSubmitForm)}>
        <NFTDetailsModalHeader>
          <Heading fontSize={'24px'}>Create Playlist</Heading>
        </NFTDetailsModalHeader>

        <VStack spacing={'16px'}>
          <Alert>
            <Icon icon={'info'} boxSize={'20px'} />
            <Box as={'span'} flex={1}>
              After you create a playlist the{' '}
              <strong>{NFT?.metadata?.name ?? NFT.tokenId}</strong> item will be
              automatically added to it.
            </Box>
          </Alert>

          <Box w={'100%'}>
            <FormItem
              type="input"
              name="title"
              label="Name"
              showErrorMessage={formState.errors?.title?.type === 'too_big'}
            >
              <Input name="title" placeholder="Playlist name" />
            </FormItem>
          </Box>

          <Box w={'100%'}>
            <FormItem
              name="effect"
              label="Effect"
              type="select"
              showErrorMessage={false}
            >
              <Select options={EFFECTS} />
            </FormItem>
          </Box>

          <Box w={'100%'}>
            <FormItem
              name="delayType"
              label="Delay Type"
              type="select"
              showErrorMessage={false}
            >
              <Select options={DELAY_TYPES} />
            </FormItem>
          </Box>

          <Box w={'100%'}>
            <FormItem
              name="delay"
              label="Delay"
              type="input"
              showErrorMessage={false}
            >
              <InputNumber
                step={0.5}
                precision={1}
                min={1}
                max={5}
                defaultValue={1}
              />
            </FormItem>
          </Box>

          <Box w={'100%'}>
            <FormItem
              name="orientation"
              label="Rotation"
              type="select"
              showErrorMessage={false}
              tooltip={() => (
                <LabelTooltip
                  tooltip={
                    'This is for TV orientation. Phones will use device orientation'
                  }
                  maxW={'200px'}
                  fontSize={'12px'}
                  lineHeight={'16px'}
                />
              )}
            >
              <Select options={rotationOptions} />
            </FormItem>
          </Box>

          <Box w={'100%'}>
            <FormItem
              name="showPoweredBy"
              label="Powered By Universe"
              type="elastic-switch"
              tooltip={() => <PoweredByUniverseTooltip />}
              showErrorMessage={false}
            >
              <ElasticSwitch
                items={[
                  { value: false, label: 'Hide' },
                  { value: true, label: 'Show' },
                ]}
                display={['block']}
              />
            </FormItem>
          </Box>
        </VStack>

        <NFTDetailsModalStickyBar>
          <HStack spacing={'12px'}>
            <Button
              variant={'secondary'}
              w={'100%'}
              disabled={isLoading}
              onClick={onBack}
            >
              Back
            </Button>
            <Button
              variant={'primary'}
              w={'100%'}
              disabled={!formState.isValid}
              onClick={() => handleSubmit(handleSubmitForm)()}
              isLoading={isLoading}
            >
              Create
            </Button>
          </HStack>
        </NFTDetailsModalStickyBar>
      </Form>
    </FormProvider>
  ) : (
    <>
      <Center h={'calc(100vh - 100px)'} textAlign={'center'}>
        <VStack spacing={'20px'}>
          <Image src={SuccessImage.src} boxSize={'64px'} />
          <Box>
            <Heading fontSize={'18px'} maxW={'280px'} mb={'6px'}>
              Your playlist was successfully created.
            </Heading>
          </Box>
        </VStack>
      </Center>
      <NFTDetailsModalStickyBar>
        <Button variant={'secondary'} w={'100%'} onClick={onCreated}>
          Close
        </Button>
      </NFTDetailsModalStickyBar>
    </>
  );
};
