import ReactPlayer from 'react-player';

import { Box, HStack } from '@chakra-ui/react';

import { useRef } from 'react';
import { Mute, Play, ProgressBar, Timer } from '../controls';
import { usePlayerState } from '../hooks';

import styles from './player-audio.module.sass';

interface Props {
  url: string;
  hideTimer?: boolean;
}

export const PlayerAudio = (props: Props) => {
  //
  const { url, hideTimer = false } = props;

  const player = useRef(null);

  const {
    played,
    playing,
    muted,
    seeking,
    duration,
    togglePlaying,
    toggleSound,
    onPlayedChange,
    onSeekingStart,
    onSeekingEnd,
    onDuration,
    onEnded,
  } = usePlayerState({ player });

  return (
    <Box className={styles.Wrapper}>
      <Box className={styles.Player}>
        <HStack spacing="8px" width="100%">
          <Play playing={playing} onClick={togglePlaying} />
          <ProgressBar
            played={played}
            seeking={seeking}
            onSeekingStart={onSeekingStart}
            onSeekingEnd={onSeekingEnd}
          />
          <Mute muted={muted} onClick={toggleSound} />
        </HStack>
        {!hideTimer && (
          <Box className={styles.Timer}>
            <Timer duration={duration} played={played} />
          </Box>
        )}
      </Box>

      <ReactPlayer
        ref={player}
        playing={playing}
        muted={muted}
        url={url}
        onProgress={onPlayedChange}
        onEnded={onEnded}
        onDuration={onDuration}
        style={{ display: 'none', width: '0px', height: '0px' }}
      />
    </Box>
  );
};
