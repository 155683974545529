import { AspectRatio, Box, Text } from '@chakra-ui/react';
import cn from 'classnames';
import React from 'react';

import { Icon } from '@/components/ui';
import s from './nft-asset-error.module.sass';

export const NftAssetError: React.FC = () => {
  return (
    <AspectRatio ratio={1}>
      <Box className={cn(s.NftAssetError)}>
        <Icon icon={'cogs'} className={cn(s.NftAssetError_icon)} />
        <Text className={cn(s.NftAssetError_text)}>
          Visual cannot be loaded.
          <br />
          Check back later.
        </Text>
        {/*<Button variant={'secondary'} className={cn(s.NftAssetError_button)}>Refresh</Button>*/}
      </Box>
    </AspectRatio>
  );
};
