export const GravitonTorrentERC721Factory = {
  address: '0x0966016C5E13cFa073f3f5100f84Ae2732b7bFa3',
  abi: [
    {
      inputs: [{ internalType: 'address', name: '_signer', type: 'address' }],
      stateMutability: 'nonpayable',
      type: 'constructor',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'string',
          name: 'tokenName',
          type: 'string',
        },
        {
          indexed: false,
          internalType: 'string',
          name: 'tokenSymbol',
          type: 'string',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'contractAddress',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'owner',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'time',
          type: 'uint256',
        },
      ],
      name: 'LogGravitonTorrentERC721ContractDeployed',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'previousOwner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'newOwner',
          type: 'address',
        },
      ],
      name: 'OwnershipTransferred',
      type: 'event',
    },
    {
      inputs: [
        { internalType: 'string', name: 'tokenName', type: 'string' },
        { internalType: 'string', name: 'tokenSymbol', type: 'string' },
        {
          internalType: 'address payable[]',
          name: '_feeRecipients',
          type: 'address[]',
        },
        { internalType: 'uint96[]', name: '_feeValues', type: 'uint96[]' },
      ],
      name: 'deployGravitonTorrentERC721',
      outputs: [
        {
          internalType: 'address',
          name: 'gravitonTERC721Contract',
          type: 'address',
        },
      ],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      name: 'deployedContracts',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'getDeployedContractsCount',
      outputs: [{ internalType: 'uint256', name: 'count', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'lastDeployedContractAddress',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'owner',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'renounceOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
      name: 'transferOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
  ],
};
