import {
  Box,
  Image,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';
import cn from 'classnames';
import React from 'react';

import UniverseLogo from '@/assets/images/universe-white.png';
import { InfoIcon } from '@/components/icons';

import s from './PoweredByUniverseTooltip.module.sass';

export const PoweredByUniverseTooltip: React.FC = () => {
  return (
    <Popover variant={'inverse'} trigger={'hover'} placement={'top'}>
      <PopoverTrigger>
        <InfoIcon className={cn(s.InfoTooltip)} ml={'6px'} />
      </PopoverTrigger>
      <PopoverContent w={'fit-content'}>
        <PopoverArrow />
        <PopoverBody padding={'12px 16px'}>
          <Box className={cn(s.PoweredByWrapper)}>
            <Text
              color={'whiteTransparent.60'}
              fontSize={'10px'}
              fontWeight={400}
            >
              powered by:
            </Text>
            <Image src={UniverseLogo.src} h={'24px'} />
          </Box>
          <Text
            fontSize={'12px'}
            fontWeight={'normal'}
            lineHeight={'16px'}
            textAlign={'center'}
            maxW={'155px'}
          >
            Show a label in the bottom of your NFT slideshow
          </Text>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
