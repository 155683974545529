import { useTheme } from '@/hooks';
import { Box, BoxProps, Center } from '@chakra-ui/react';

import { ReactComponent as PlaylistDarkImage } from './images/playlist/playlist-dark.svg';
import { ReactComponent as PlaylistLightImage } from './images/playlist/playlist-light.svg';

import { ReactComponent as NftDarkImage } from './images/nft/nft-dark.svg';
import { ReactComponent as NftLightImage } from './images/nft/nft-light.svg';

import { ReactComponent as CollectionDarkImage } from './images/collection/collection-dark.svg';
import { ReactComponent as CollectionLightImage } from './images/collection/collection-light.svg';

import { ReactComponent as ActivityDarkImage } from './images/activity/activity-dark.svg';
import { ReactComponent as ActivityLightImage } from './images/activity/activity-light.svg';

// import cn from 'classnames';
import styles from './empty.module.sass';

type ImageType = 'playlist' | 'nft' | 'collection' | 'activity';

type MapImages = {
  [key in ImageType]: {
    images: {
      light: React.ReactNode;
      dark: React.ReactNode;
    };
  };
};

interface Props extends BoxProps {
  //
  type: ImageType;
  text?: string;
}

const MAP_IMAGES: MapImages = {
  playlist: {
    images: {
      light: <PlaylistLightImage />,
      dark: <PlaylistDarkImage />,
    },
  },
  nft: {
    images: {
      light: <NftLightImage />,
      dark: <NftDarkImage />,
    },
  },
  collection: {
    images: {
      light: <CollectionLightImage />,
      dark: <CollectionDarkImage />,
    },
  },
  activity: {
    images: {
      light: <ActivityLightImage />,
      dark: <ActivityDarkImage />,
    },
  },
};

export const Empty = (props: Props) => {
  //
  const { type, text = 'Not Found', ...rest } = props;

  const { colorMode } = useTheme();

  return (
    <Center>
      <Box className={styles.Wrapper} {...rest}>
        <Box className={styles.Image}>{MAP_IMAGES[type].images[colorMode]}</Box>
        <Box className={styles.Text}>{text}</Box>
      </Box>
    </Center>
  );
};
