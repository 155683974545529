import React from 'react';
import Image from 'next/image';

import { IConnectorButton } from '@/components/modules/home';
import { metaMask } from '@/connectors/metaMask';
import { useTheme } from '@/hooks';

import { ConnectWalletItem } from '../connect-wallet-item';

import metaMaskDark from './images/metamask_dark.svg';
import metaMaskLight from './images/metamask_light.svg';

export const MetaMask: React.FC<IConnectorButton> = ({ onConnect }) => {
  const { isLight } = useTheme();

  const handleConnect = () => {
    onConnect(metaMask, 'METAMASK');
  };

  return (
    <ConnectWalletItem onClick={() => handleConnect()}>
      <Image
        src={isLight ? metaMaskLight : metaMaskDark}
        alt="Metamask"
        width={140}
        height={38}
      />
    </ConnectWalletItem>
  );
};
