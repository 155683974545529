import { Input } from '../input/input';

// export const Textarea = {
//   baseStyle: (props) => ({
//     ...Input.baseStyle.field,
//   }),
//   sizes: {
//     xs: Input.sizes.xs.field ?? {},
//     sm: Input.sizes.sm.field ?? {},
//     md: Input.sizes.md.field ?? {},
//     lg: Input.sizes.lg.field ?? {},
//   },
//   // variants
//   variants: {
//     ghost: (props) => ({
//       field: {
//         border: '1px solid',
//         borderRadius: '10px',
//         backgroundColor: mode('white', 'dark')(props),
//         borderColor: mode('blackTransparent.20', 'whiteTransparent.20')(props),
//         transition: 'border-color 200ms linear',

//         _hover: {
//           borderColor: mode(
//             'blackTransparent.40',
//             'whiteTransparent.40'
//           )(props),
//         },

//         _invalid: {
//           borderColor: 'red',
//         },
//       },
//     }),
//   },
//   // The default variant value
//   defaultProps: {
//     variant: 'ghost',
//     size: 'md',
//   },
// };

const baseStyle = {
  ...Input.baseStyle.field,
  paddingTop: '12px',
  paddingBottom: '12px',
  // minHeight: '80px',
  // lineHeight: 'short',
  // verticalAlign: 'top',
};

const variants = {
  ghost: (props) => Input.variants.ghost(props).field,
};

const sizes = {
  sm: Input.sizes.sm.field,
  md: Input.sizes.md.field,
  lg: Input.sizes.lg.field,
};

const defaultProps = {
  size: 'md',
  variant: 'ghost',
};

export const Textarea = {
  baseStyle,
  sizes,
  variants,
  defaultProps,
};
