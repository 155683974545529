import { Box, HStack, Image } from '@chakra-ui/react';

import styles from './file.module.sass';

interface Props {
  file: any;
}

// TODO: need refactoring

export const File = (props: Props) => {
  //
  const { file } = props;

  const isFbx = file.name.includes('.fbx');
  const isPdf = file.name.includes('.pdf');

  const renderIcon = {
    fbx: () => <Image src="/assets/icons/files/fbx.svg" alt={file.name} />,
    pdf: () => <Image src="/assets/icons/files/pdf.svg" alt={file.name} />,
  };

  return (
    <HStack spacing="12px" className={styles.Wrapper}>
      {isFbx && <Box className={styles.Icon}>{renderIcon.fbx()}</Box>}
      {isPdf && <Box className={styles.Icon}>{renderIcon.pdf()}</Box>}
      <Box className={styles.Name}>{file.name}</Box>
    </HStack>
  );
};
