import { Flex } from '@chakra-ui/react';
import React, { forwardRef } from 'react';

export interface Props {
  children: React.ReactNode;
  columns?: number;
  style?: React.CSSProperties;
  horizontal?: boolean;
}

export const List = forwardRef<HTMLDivElement, Props>((props, ref) => {
  //
  const { children } = props;

  return (
    <Flex minWidth="max-content" ref={ref}>
      {children}
    </Flex>
  );
});

List.displayName = 'List';
