import { z } from 'zod';

export const playlistValidationSchema = z.object({
  title: z
    .string()
    .min(1, { message: 'Required' })
    .max(100, 'Name must be 100 characters or less'),
  effect: z.string().min(1, { message: 'Required' }),
  delayType: z.string().min(1, { message: 'Required' }),
  delay: z.string().min(1, { message: 'Required' }),
  orientation: z.string().min(1, { message: 'Required' }),
  showPoweredBy: z.boolean(),
});
