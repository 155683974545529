import {
  Tooltip as ChakraTooltip,
  TooltipProps,
  useStyleConfig,
} from '@chakra-ui/react';

export const Tooltip = (props: TooltipProps) => {
  const {
    variant,
    children,
    placement = 'top',
    hasArrow = true,
    gutter = 16,
    ...rest
  } = props;

  const styles = useStyleConfig('Tooltip', { variant });

  return (
    <ChakraTooltip
      __css={styles}
      hasArrow={hasArrow}
      gutter={gutter}
      closeOnClick={false}
      closeOnScroll={true}
      placement={placement}
      openDelay={200}
      variant={variant}
      {...rest}
    >
      {children}
    </ChakraTooltip>
  );
};
