import { AvatarResolver } from '@ensdomains/ens-avatar';
import { useQuery } from '@tanstack/react-query';
import { useWeb3React } from '@web3-react/core';

export const useEnsAvatar = (ensName: string) => {
  const { provider } = useWeb3React();

  const { data: ensAvatar } = useQuery({
    queryKey: ['ens-avatar', ensName],
    queryFn: async () => {
      if (!ensName) {
        return null;
      }

      try {
        const avt = new AvatarResolver(provider as any);
        return await avt.getAvatar(ensName, {});
      } catch (e) {
        console.error(e);
        return null;
      }
    },
  });

  return { ensAvatar };
};
