import { Heading } from '@chakra-ui/layout';
import { Box, Flex, Input, Text, VStack } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { utils } from 'ethers';
import React, { useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';

import {
  NFTDetailsModalHeader,
  NFTDetailsModalStickyBar,
  NFTProcessing,
} from '@/components/common';
import { Button, FormItem } from '@/components/ui';
import { Form } from '@/components/ui/form';
import { NETWORK_CHAIN_ID } from '@/constants';
import { Contracts } from '@/contracts';
import { useAuth, useContractWrite } from '@/hooks';
import { NFT, NFTType } from '@/types';
import { formatAddress } from '@/utils';

import s from './nft-marketplace-transfer.module.sass';

const { contracts } = Contracts[NETWORK_CHAIN_ID];

interface INFTMarketplaceTransferProps {
  NFT: NFT;
  onSuccessTransfer: () => void;
  onClose: () => void;
}

type State = 'transfer' | 'processing' | 'success' | 'error';

export const NFTMarketplaceTransfer: React.FC<INFTMarketplaceTransferProps> = (
  props
) => {
  const { NFT, onSuccessTransfer, onClose } = props;

  const { address } = useAuth();

  const [state, setState] = useState<State>('transfer');
  const [tx, setTx] = useState<string>();

  const { writeAsync } = useContractWrite({
    address: NFT.contractAddress,
    abi: contracts.GravitonTorrentERC721Core.abi,
    functionName:
      NFT.tokenType === NFTType.ERC1155
        ? 'safeTransferFrom'
        : 'safeTransferFrom(address,address,uint256)',
  });

  type IFormValue = {
    to: string;
  };

  const form = useForm<IFormValue>({
    mode: 'onChange',
    defaultValues: {
      to: '',
    },
    resolver: zodResolver(
      z.object({
        to: z
          .string()
          .min(1, { message: 'Address is required' })
          .refine((value) => utils.isAddress(value), {
            message: 'The wallet address you have entered is not valid',
          }),
      })
    ),
  });

  const handleTransfer = useCallback(
    async (value: IFormValue) => {
      try {
        setState('processing');

        const tx = await writeAsync(
          NFT.tokenType === NFTType.ERC1155
            ? [address, value.to, NFT.tokenId, 1, 0x0]
            : [address, value.to, NFT.tokenId]
        );

        setTx(tx.hash);

        await tx.wait();

        setState('success');

        onSuccessTransfer();
      } catch (e) {
        setState('error');
        console.error(e);
      }
    },
    [onSuccessTransfer]
  );

  const NFTName = NFT.metadata?.name ?? NFT.tokenId;

  return (
    <>
      <NFTDetailsModalHeader>
        <Heading fontSize={'24px'}>Transfer item</Heading>
      </NFTDetailsModalHeader>

      {state === 'transfer' && (
        <>
          <VStack align={'stretch'} spacing={'16px'}>
            <Text className={s.Hint}>
              You can transfer tNFTs from your address to another
            </Text>

            <FormProvider {...form}>
              <Form onSubmit={form.handleSubmit(handleTransfer)}>
                <FormItem
                  type="input"
                  name="to"
                  label="Receiver address"
                  showErrorMessage={true}
                >
                  <Input name="to" placeholder="e.g. 0x3v042b..." />
                </FormItem>
              </Form>
            </FormProvider>
          </VStack>
          <NFTDetailsModalStickyBar>
            <Flex alignItems={'center'} gap={'16px'}>
              <Button variant={'secondary'} w={'100%'} onClick={onClose}>
                Cancel
              </Button>
              <Button
                variant={'primary'}
                w={'100%'}
                isDisabled={!form.formState.isValid}
                onClick={() => form.handleSubmit(handleTransfer)()}
              >
                Transfer
              </Button>
            </Flex>
          </NFTDetailsModalStickyBar>
        </>
      )}

      {state === 'processing' && (
        <NFTProcessing
          state={'processing'}
          title={'Your transfer is processing...'}
          tx={tx}
          renderDescription={() => (
            <Text>
              Your transfer of <Box as={'span'}>{NFTName}</Box> is processing.
              It should be confirmed on the blockchain shortly.
            </Text>
          )}
          renderFooter={() => null}
        />
      )}

      {state === 'success' && (
        <>
          <NFTProcessing
            state={'success'}
            title={'Congratulations!'}
            tx={tx}
            renderDescription={() => (
              <Text>
                You have successfully transferred{' '}
                <Box as={'span'}>{NFTName}</Box> to{' '}
                <Box as={'span'}>{formatAddress(form.getValues().to)}</Box>.
              </Text>
            )}
            onClose={onClose}
          />
        </>
      )}

      {state === 'error' && (
        <NFTProcessing
          state="failed"
          tx={tx}
          renderDescription={() => (
            <Text>Please refresh the page and try again.</Text>
          )}
          onClose={onClose}
        />
      )}
    </>
  );
};
