import { InputProps } from '@chakra-ui/input';
import { Box, InputGroup, InputLeftElement } from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { useDebounce } from 'react-use';
import dayjs from 'dayjs';

import { Icon } from '@/components/ui';
import { Input } from '@/components/ui/input';

import s from './date-input.module.sass';

interface IDateInputProps extends Omit<InputProps, 'value' | 'onChange'> {
  value: Date;
  onChange: (value: Date) => void;
  onTimeChanged?: () => void;
}

export const DateInput: React.FC<IDateInputProps> = (props) => {
  const { value, onChange, onTimeChanged, ...rest } = props;

  const [dateString, setDateString] = useState<string>(dayjs(value).format('MM.DD.YYYY'));
  const [timeString, setTimeString] = useState<string>(dayjs(value).format('HH:mm'));

  const handleChange = useCallback((forcePush) => {
    const date = dayjs(`${dateString} ${timeString}`, 'MM.DD.YYYY HH:mm');

    (date.isValid() || forcePush) && onChange(date.toDate());
  }, [dateString, timeString, onChange]);

  useDebounce(
    () => handleChange(false),
    500,
    [dateString, timeString],
  );

  useEffect(() => {
    const date = dayjs(value);

    if (date.isValid()) {
      setDateString(date.format('MM.DD.YYYY'));
      setTimeString(date.format('HH:mm'));
    }
  }, [value]);

  return (
    <Box className={s.Wrapper}>
      <InputMask
        mask="99.99.9999"
        maskChar={' '}
        alwaysShowMask={true}
        value={dateString}
        onChange={(e) => setDateString(e.target.value)}
        onBlur={() => handleChange(true)}
      >
        {(dateInputProps) => (
          <Input
            {...dateInputProps}
            placeholder={'MM.DD.YYYY'}
            fontSize={'12px'}
            {...rest}
          />
        )}
      </InputMask>

      <InputMask
        mask="99:99"
        maskChar={' '}
        alwaysShowMask={true}
        value={timeString}
        onChange={(e) => {
          setTimeString(e.target.value);
          onTimeChanged?.();
        }}
        onBlur={() => handleChange(true)}
      >
        {(timeInputProps) => (
          <InputGroup className={s.TimeInputGroup}>
            <Input
              {...timeInputProps}
              placeholder={'HH:mm'}
              className={s.TimeInput}
              isDisabled={props.isDisabled}
            />
            <InputLeftElement boxSize={'32px'}>
              <Icon icon={'time'} className={s.TimeIcon} />
            </InputLeftElement>
          </InputGroup>
        )}
      </InputMask>
    </Box>
  );
};