import { playlistValidationSchema } from '@/modules/display/validations';
import { z } from 'zod';

type SubmitData = z.infer<typeof playlistValidationSchema>;

export const playlistDefaultValues: SubmitData = {
  title: '',
  effect: 'Fader',
  delayType: '1',
  delay: '1',
  orientation: 'horizontal',
  showPoweredBy: true,
};
