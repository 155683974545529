import {
  Modal as ChakraModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/modal';
import { Portal } from '@chakra-ui/portal';
import { Box, BoxProps, useBreakpointValue } from '@chakra-ui/react';
import { Slide } from '@chakra-ui/transition';
import cn from 'classnames';
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useUpdate, useWindowSize } from 'react-use';

import { NftAsset, NftCardProvider } from '@/components/common/nft-card';
import { Icon } from '@/components/ui';
import { Button } from '@/components/ui/button';
import { NFT } from '@/types';

import { NFTSkeletonAsset } from './components/nft-skeleton-asset';
import s from './nft-modal.module.sass';

interface INFTModalContextValue {
  headerRef: React.MutableRefObject<HTMLDivElement>;
  stickyBarRef: React.MutableRefObject<HTMLDivElement>;
  contentHeight: number;
}

const NFTModalContext = createContext<INFTModalContextValue>({
  headerRef: null,
  stickyBarRef: null,
  contentHeight: 0,
});

export const useNFTModalContext = () => useContext(NFTModalContext);

export interface INFTModalProps {
  NFT?: NFT;
  isOpen: boolean;
  closeOnEsc?: boolean;
  children: React.ReactNode;
  onClose: () => void;
  onEsc?: () => void;
}

export const NFTModal = (props: INFTModalProps) => {
  const { NFT, isOpen, closeOnEsc = true, children, onClose, onEsc } = props;

  const windowSize = useWindowSize();

  const headerRef = useRef<HTMLDivElement>();
  const stickyBarRef = useRef<HTMLDivElement>();

  const [headerHeight, setHeaderHeight] = useState(0);
  const [stickyBarHeight, setStickyBarHeight] = useState(0);
  const [isSidebarOpened, setSidebarOpened] = useState(true);

  const showSidebar = useBreakpointValue([true, null, isSidebarOpened]);

  const update = useUpdate();

  useEffect(() => {
    if (isOpen) {
      update();
    }
  }, [isOpen, update, children]);

  useEffect(() => {
    if (!headerRef.current) return;
    const resizeObserver = new ResizeObserver((entries) => {
      setHeaderHeight(entries?.[0].contentRect.height ?? 0);
    });
    resizeObserver.observe(headerRef.current);
    return () => resizeObserver.disconnect();
  }, [headerRef?.current]);

  useEffect(() => {
    if (!stickyBarRef.current) return;
    const resizeObserver = new ResizeObserver((entries) => {
      setStickyBarHeight(entries?.[0].contentRect.height ?? 0);
    });
    resizeObserver.observe(stickyBarRef.current);
    return () => resizeObserver.disconnect();
  }, [stickyBarRef?.current]);

  const contentMinHeight = windowSize.height - headerHeight - stickyBarHeight;

  const contextValue: INFTModalContextValue = {
    headerRef,
    stickyBarRef,
    contentHeight: contentMinHeight - 48,
  };

  return (
    <NFTModalContext.Provider value={contextValue}>
      <NftCardProvider>
        <ChakraModal
          size={'full'}
          isOpen={isOpen}
          scrollBehavior={'inside'}
          closeOnEsc={closeOnEsc}
          onClose={onClose}
          onEsc={onEsc}
        >
          <ModalOverlay />
          <ModalContent className={s.ModalContent}>
            <ModalCloseButton className={s.ModalCloseButton} />
            <ModalBody className={s.ModalBody}>
              <Box
                className={cn(
                  s.AssetWrapper,
                  isSidebarOpened && s.AssetWrapper__opened
                )}
              >
                <Button
                  className={s.ModalCollapse}
                  onClick={() => setSidebarOpened(!isSidebarOpened)}
                >
                  <Icon
                    icon={'right'}
                    transition={'200ms'}
                    transform={!isSidebarOpened ? 'rotate(180deg)' : undefined}
                  />
                </Button>
                <Box className={cn(s.AssetWrapper_Container)}>
                  {!NFT ? <NFTSkeletonAsset /> : (
                    <NftAsset
                      nft={NFT}
                      videoProps={{
                        light: false,
                        fit: 'contain',
                        isShowProgressBar: true,
                        isShowFullscreenMode: true,
                        isHandleClickEvenets: true,
                      }}
                      audioProps={{
                        isShowProgressBar: true,
                      }}
                    />
                  )}
                </Box>
              </Box>
              <Slide
                direction="right"
                in={showSidebar}
                className={cn(
                  s.SidebarWrapper,
                  !showSidebar && s.SidebarWrapper__closed
                )}
              >
                <Box ref={headerRef} className={s.HeaderWrapper} />

                <Box p={'24px'} minH={`${contentMinHeight}px`}>
                  {children}
                </Box>

                <Box ref={stickyBarRef} className={s.StickyBarWrapper} />
              </Slide>
            </ModalBody>
          </ModalContent>
        </ChakraModal>
      </NftCardProvider>
    </NFTModalContext.Provider>
  );
};

interface INFTDetailsModalHeaderProps extends BoxProps {
  children: React.ReactNode;
}

export const NFTDetailsModalHeader: React.FC<INFTDetailsModalHeaderProps> = (
  props
) => {
  const { className, ...rest } = props;
  const { headerRef } = useContext(NFTModalContext);

  return (
    <Portal containerRef={headerRef}>
      <Box className={cn(s.Header, className)} {...rest} />
    </Portal>
  );
};

interface INFTDetailsModalStickyBarProps extends BoxProps {
  children: React.ReactNode;
}

export const NFTDetailsModalStickyBar: React.FC<
  INFTDetailsModalStickyBarProps
> = (props) => {
  const { className, ...rest } = props;
  const { stickyBarRef } = useContext(NFTModalContext);

  return (
    <Portal containerRef={stickyBarRef}>
      <Box className={cn(s.StickyBar, className)} {...rest} />
    </Portal>
  );
};
