import { Box } from '@chakra-ui/react';
import { useToggle } from 'react-use';

import cn from 'classnames';
import styles from './timer.module.sass';

interface Props {
  duration: number;
  played: number;
  isLightMode?: boolean;
}

//type ShowTime = 'elapsed' | 'remaining';

export const Timer = (props: Props) => {
  //
  const { duration = 0, played = 0, isLightMode = false } = props;

  const [isElapsed, toggle] = useToggle(true);

  const format = (seconds) => {
    //
    const date = new Date(seconds * 1000);
    const hh = date.getUTCHours();
    const mm = date.getUTCMinutes();
    const ss = pad(date.getUTCSeconds());

    if (hh) {
      return `${hh}:${pad(mm)}:${ss}`;
    }

    return `${mm}:${ss}`;
  };

  const pad = (string) => {
    //
    return ('0' + string).slice(-2);
  };

  //elapsed = duration * played;
  //remaining = duration * (1 - played);

  const time = isElapsed ? duration * played : duration * (1 - played);

  return (
    <Box
      className={cn(styles.Wrapper, isLightMode && styles.isLightMode)}
      onClick={() => toggle()}
    >{`${!isElapsed ? '-' : ''}${format(time)}`}</Box>
  );
};
