import { Box, Center, Text, useColorMode } from '@chakra-ui/react';
import cn from 'classnames';
import React from 'react';
import Lottie from 'react-lottie';

import PreloaderAnimationDark from '@/assets/animations/preloader_graviton_dark_mode.json';
import PreloaderAnimationLight from '@/assets/animations/preloader_graviton_light_mode.json';

import s from './FullPageLoader.module.scss';

interface IFullPageLoaderProps {
  children?: React.ReactNode;
}

export const FullPageLoader: React.FC<IFullPageLoaderProps> = (props) => {
  const { children } = props;

  const { colorMode } = useColorMode();

  return (
    <Center h="100vh" pos={'relative'} overflow={'hidden'} flexDir={'column'}>
      <Box
        className={cn(s.bubble, s.bubblePink, s.bubble1)}
        boxSize={['900px', null, '1200px']}
      />
      <Box
        className={cn(s.bubble, s.bubbleBlue, s.bubble2)}
        boxSize={['900px', null, '1200px']}
      />
      <Box
        className={cn(s.bubble, s.bubblePink, s.bubble3)}
        boxSize={'900px'}
      />
      <Box
        className={cn(s.bubble, s.bubbleBlue, s.bubble4)}
        boxSize={'900px'}
      />

      <Box boxSize={['128px', null, '280px']}>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData:
              colorMode === 'dark'
                ? PreloaderAnimationDark
                : PreloaderAnimationLight,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
            },
          }}
          isClickToPauseDisabled={true}
        />
      </Box>
      {children}
    </Center>
  );
};
