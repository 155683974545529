export const getNftPreviewImage = (nft) => {
  return [
    nft?.previewUrl, // new api from alchemy
    nft?.nft?.nft?.metadata?.image,
    nft?.metadata?.image_original_url,
    nft?.metadata?.image_preview_url,
    nft?.metadata?.image_url,
    nft?.metadata?.image_thumbnail_url,
  ].find((url) => !!url);
};
