import { useTheme } from '@/hooks';
import {
  Spinner as ChakraSpinner,
  SpinnerProps as ChakraSpinnerProps,
} from '@chakra-ui/react';

export interface SpinnerProps extends ChakraSpinnerProps {
  size?: Size;
}

type Size = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';

type SizeObject = {
  thickness: string;
  boxSize: string;
};

export const Spinner = (props: SpinnerProps) => {
  //
  const { size = 'xs' } = props;

  const { isDark } = useTheme();

  const sizes: Record<Size, SizeObject> = {
    xxs: {
      thickness: '1.5px',
      boxSize: '15px',
    },
    xs: {
      thickness: '3px',
      boxSize: '25px',
    },
    sm: {
      thickness: '4px',
      boxSize: '35px',
    },
    md: {
      thickness: '5px',
      boxSize: '45px',
    },
    lg: {
      thickness: '6px',
      boxSize: '64px',
    },
    xl: {
      thickness: '7px',
      boxSize: '80px',
    },
    '2xl': {
      thickness: '8px',
      boxSize: '90px',
    },
  };

  const emptyColor = isDark ? '#382c58' : '#dcd8f8';

  return (
    <ChakraSpinner
      speed="1.5s"
      emptyColor={emptyColor}
      color="#ff2d83"
      {...sizes[size]}
    />
  );
};
