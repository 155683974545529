export enum TokenTicker {
  ETH = 'ETH',
  WETH = 'WETH',
  USDT = 'USDT',
  USDC = 'USDC',
  BUSD = 'BUSD',
  DAI = 'DAI',
  UNI = 'UNI',
  LINK = 'LINK',
  WBTC = 'WBTC',
  MATIC = 'MATIC',
  APE = 'APE',
  AAVE = 'AAVE',
  SNX = 'SNX',
  CRV = 'CRV',
  LPT = 'LPT',
  XYZ = 'XYZ',
  ASTO = 'ASTO',
  ILV = 'ILV',
  BOND = 'BOND',
  LEAG = 'LEAG',
  SAND = 'SAND',
  MANA = 'MANA',
  SYLO = 'SYLO',
  AXS = 'AXS',
  ENTR = 'ENTR',
  FDT = 'FDT',
  // dev
  CHARLES = 'CHARLES',
}

export interface IToken<T extends TokenTicker = TokenTicker> {
  ticker: T;
  address: string;
  image: string;
  name: string;
  decimals: number;
  coinGeckoId: string;
}