import { useCallback, useMemo, useState } from 'react';

import { Image as ChakraImage } from '@chakra-ui/react';
import NextImage from 'next/future/image';

import { imageRemotePatterns } from '@/configs/image-remote-patterns';
import { RemotePattern } from 'next/dist/shared/lib/image-config';
import { matchRemotePattern } from 'next/dist/shared/lib/match-remote-pattern';

interface Props {
  //
  src: string;
  alt: string;
  width: number;
  height: number;
  onLoaded?(): void;
  onError?(): void;
}

export const Image = (props: Props) => {
  //
  const { src, alt, width, height, onLoaded, onError } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const handleLoadingComplete = useCallback(() => {
    setIsLoading(false);
    onLoaded?.();
  }, [onLoaded]);

  const handleLoadingError = useCallback(() => {
    setIsError(true);
    setIsLoading(false);
    onError?.();
  }, [onError]);

  const isWhiteListURL = useMemo(() => {
    if (src) {
      try {
        return imageRemotePatterns.some((pattern) =>
          matchRemotePattern(pattern as RemotePattern, new URL(src))
        );
      } catch (e: unknown) {}
    }

    return false;
  }, [src]);

  return (
    <>
      {isWhiteListURL ? (
        <NextImage
          src={src}
          alt={alt}
          width={width}
          height={height}
          onLoadingComplete={handleLoadingComplete}
          onError={handleLoadingError}
        />
      ) : (
        <ChakraImage
          src={src}
          alt={alt}
          width={width}
          height={height}
          onError={handleLoadingError}
          onLoad={handleLoadingComplete}
        />
      )}
    </>
  );
};
