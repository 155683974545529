import { Image } from '@chakra-ui/image';
import { Heading, Text, VStack } from '@chakra-ui/layout';
import {
  Flex,
  HStack,
  Link,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { Tooltip } from '@chakra-ui/tooltip';
import { useInfiniteQuery } from '@tanstack/react-query';
import BigNumber from 'bignumber.js';
import dayjs from 'dayjs';
import { utils } from 'ethers';
import React, { useEffect, useMemo, useState } from 'react';

import { IReservoirActivityType, ReservoirAPI } from '@/api/reservoir';
import {
  NFTBasicInfo,
  NFTDetails,
  NFTDetailsItem,
  NFTDetailsModalStickyBar,
  NFTFees,
  NFTHistory,
  NFTHistoryType,
  NFTProperties,
} from '@/components/common';
import { Button, Icon } from '@/components/ui';
import { ETH_TOKEN } from '@/constants';
import { useAuth, useTokensUSDPrice } from '@/hooks';
import { useNFTOwner } from '@/hooks/use-nft-owner';
import { tNFT } from '@/modules/torrent/types';
import { IOrder, IToken, TokenTicker } from '@/types';
import { formatAddress } from '@/utils';

import { MARKETPLACE_TOKENS_ADDRESS_MAP } from './../../constants';
import { NFTMarketplaceSidebarStickybarSkeleton } from './../nft-marketplace-sidebar-stickybar-skeleton';
import s from './nft-marketplace-details-view.module.sass';

interface INFTMarketplaceDetailsViewProps {
  NFT: tNFT;
  order?: IOrder;
  isLoadingOrder: boolean;
  onBuy: () => void;
  onCancelListing: () => void;
  onTransfer: () => void;
  onChangePrice: () => void;
  onSell: () => void;
}

export const NFTMarketplaceDetailsView: React.FC<
  INFTMarketplaceDetailsViewProps
> = (props) => {
  const {
    NFT,
    order,
    isLoadingOrder,
    onBuy,
    onCancelListing,
    onTransfer,
    onChangePrice,
    onSell,
  } = props;

  const { address } = useAuth();

  const { owners } = useNFTOwner(NFT.contractAddress, NFT.tokenId);

  const [orderStatus, setOrderStatus] = useState<'listed' | 'not-listed'>();
  const [userView, setUserView] = useState<'buyer' | 'seller'>();

  const token = useMemo<IToken | undefined>(() => {
    if (!order) {
      return undefined;
    }

    if (order.take.assetType.assetClass === TokenTicker.ETH) {
      return ETH_TOKEN;
    }

    return MARKETPLACE_TOKENS_ADDRESS_MAP[
      order.take?.assetType?.contract?.toLowerCase()
    ];
  }, [order]);

  const [tokenUSD] = useTokensUSDPrice([token?.ticker]);

  const price = useMemo(() => {
    if (!order || !token) {
      return null;
    }

    return new BigNumber(
      utils.formatUnits(order.take.value, token.decimals)
    ).toString();
  }, [order, token]);

  const formattedPrice = useMemo(() => {
    if (!order || !token) {
      return null;
    }

    const priceBN = new BigNumber(
      utils.formatUnits(order.take.value, token.decimals)
    );

    return priceBN.lt(0.01)
      ? '<0.01'
      : utils.formatUnits(order.take.value, token.decimals);
  }, [order, token]);

  const priceUSD = useMemo(() => {
    if (!order) {
      return null;
    }

    const p = new BigNumber(
      utils.formatUnits(order.take.value, token.decimals)
    );
    return p.multipliedBy(tokenUSD).toFixed(2);
  }, [order, tokenUSD]);

  const formattedEndIn = useMemo(() => {
    if (!order) {
      return null;
    }

    return dayjs().to(dayjs(order.end * 1000).toDate());
  }, [order]);

  const {
    data: activities,
    hasNextPage: hasMoreActivities,
    fetchNextPage: fetchNextPageActivities,
    isFetchingNextPage: isFetchingNextPageActivities,
    isLoading: isLoadingActivities,
  } = useInfiniteQuery({
    queryKey: ['nft-activities', NFT?.contractAddress, NFT.tokenId],
    queryFn: async ({ pageParam = undefined }) => {
      return ReservoirAPI.getTokenActivity({
        collectionAddress: NFT?.contractAddress,
        tokenId: NFT.tokenId,
        limit: 10,
        nextCursor: pageParam,
      });
    },
    getNextPageParam: (lastPage) => lastPage.continuation,
  });

  useEffect(() => {
    const isOwner = owners.find(
      (ownerAddress) => ownerAddress?.toLowerCase() === address.toLowerCase()
    );
    setUserView(isOwner ? 'seller' : 'buyer');
    setOrderStatus(!!order ? 'listed' : 'not-listed');
  }, [order, owners]);

  return (
    <>
      <Tooltip hasArrow placement={'left'} label={'Seed'}>
        <Link
          isExternal
          href={NFT?.metadata?.external_url}
          className={s.SeedButton}
        >
          <Icon icon={'magnet'} />
        </Link>
      </Tooltip>

      {!NFT ? null : (
        <NFTBasicInfo
          contractAddress={NFT.contractAddress}
          tokenId={NFT.tokenId}
          name={NFT.metadata?.name}
          description={NFT.metadata?.description}
        />
      )}

      <VStack align={'stretch'} spacing={'24px'}>
        <Tabs isFitted variant={'elastic'}>
          <TabList mb={'24px'}>
            <Tab>Details</Tab>
            <Tab>Properties</Tab>
            <Tab>History</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <NFTDetails mb={'24px'}>
                <NFTDetailsItem
                  label={'Contract Address'}
                  value={formatAddress(NFT?.contractAddress ?? '')}
                  copyData={NFT?.contractAddress}
                />
                <NFTDetailsItem
                  label={'Token ID'}
                  value={NFT?.tokenId}
                  copyData={`${NFT?.tokenId}`}
                />
                <NFTDetailsItem label={'Network'} value={'Ethereum'} />
                <NFTDetailsItem
                  label={'Token Standard'}
                  value={NFT?.tokenType}
                />
                <NFTDetailsItem
                  label={'Magnet link'}
                  value={'magnet:?xt...'}
                  copyData={NFT?.metadata?.external_url}
                />
              </NFTDetails>

              <VStack align={'stretch'} spacing={'8px'}>
                <Heading className={s.FeesTitle}>Fees</Heading>
                <NFTFees
                  contractAddress={NFT?.contractAddress}
                  tokenId={NFT?.tokenId}
                />
              </VStack>
            </TabPanel>
            <TabPanel>
              <NFTProperties attributes={NFT.metadata?.attributes ?? []} />
            </TabPanel>
            <TabPanel>
              <VStack align={'stretch'} spacing={'8px'}>
                {activities?.pages?.map((page, i) =>
                  page.activities.map((activity, j) => {
                    const typeMap: Record<
                      Exclude<IReservoirActivityType, 'bid' | 'bid_cancel'>,
                      NFTHistoryType
                    > = {
                      mint: 'mint',
                      transfer: 'transfer',
                      ask: 'list',
                      ask_cancel: 'delist',
                      sale: 'sale',
                    };

                    return (
                      <NFTHistory
                        key={`${i}-${j}`}
                        type={typeMap[activity.type]}
                        tx={activity.txHash}
                        from={activity.fromAddress}
                        to={activity.toAddress}
                        timestamp={activity.timestamp}
                        price={[activity.price, '']}
                      />
                    );
                  })
                )}
                {!hasMoreActivities ? null : (
                  <Button
                    variant={'secondary'}
                    isLoading={
                      isLoadingActivities || isFetchingNextPageActivities
                    }
                    onClick={() => fetchNextPageActivities()}
                  >
                    Load more
                  </Button>
                )}
              </VStack>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </VStack>

      {isLoadingOrder ? (
        <NFTMarketplaceSidebarStickybarSkeleton />
      ) : (
        <NFTDetailsModalStickyBar>
          {orderStatus === 'listed' ? (
            userView === 'buyer' ? (
              <>
                <Flex alignItems={'center'} gap={'12px'}>
                  <VStack align={'stretch'} spacing={'4px'}>
                    <HStack spacing={'4px'}>
                      {!token ? null : (
                        <>
                          <Image
                            src={token?.image}
                            borderRadius={'full'}
                            boxSize={'20px'}
                          />
                          <Tooltip
                            hasArrow
                            placement={'top'}
                            label={`${price} ${token.ticker}`}
                          >
                            <Text className={s.Price}>{formattedPrice}</Text>
                          </Tooltip>
                          <Text className={s.PriceUSD}>${priceUSD}</Text>
                        </>
                      )}
                    </HStack>
                    <Text className={s.ExpireInLabel}>
                      Ends {formattedEndIn}
                    </Text>
                  </VStack>
                  <Button variant={'primary'} ml={'auto'} onClick={onBuy}>
                    Buy now
                  </Button>
                </Flex>
              </>
            ) : (
              <>
                <VStack align={'stretch'} spacing={'4px'}>
                  <HStack spacing={'4px'}>
                    {!token ? null : (
                      <>
                        <Image
                          src={token?.image}
                          borderRadius={'full'}
                          boxSize={'20px'}
                        />
                        <Tooltip
                          hasArrow
                          placement={'top'}
                          label={`${price} ${token.ticker}`}
                        >
                          <Text className={s.Price}>{formattedPrice}</Text>
                        </Tooltip>
                        <Text className={s.PriceUSD}>${priceUSD}</Text>
                      </>
                    )}
                  </HStack>
                  <Text className={s.ExpireInLabel}>Ends {formattedEndIn}</Text>
                </VStack>
                <Flex alignItems={'center'} gap={'16px'} mt={'16px'}>
                  <Button
                    variant={'secondary'}
                    w={'100%'}
                    onClick={onCancelListing}
                  >
                    Cancel listing
                  </Button>
                  {/*<Button variant={'primary'} w={'100%'} onClick={onChangePrice}>*/}
                  {/*  Change price*/}
                  {/*</Button>*/}
                </Flex>
              </>
            )
          ) : userView === 'buyer' ? (
            <>
              <Text className={s.ExpireInLabel}>
                This item is not Listed for sale
              </Text>
            </>
          ) : (
            <>
              <Flex alignItems={'center'} gap={'16px'}>
                <Button variant={'secondary'} w={'100%'} onClick={onTransfer}>
                  Transfer
                </Button>
                <Button variant={'primary'} w={'100%'} onClick={onSell}>
                  Sell
                </Button>
              </Flex>
            </>
          )}
        </NFTDetailsModalStickyBar>
      )}
    </>
  );
};
