import { useQuery } from '@tanstack/react-query';

import { getCollectionByAddress } from '@/api/playlists';

export const useCollection = (address: string | undefined) => {
  const queryKey = ['collection', address];

  const { data: collection } = useQuery({
    queryKey,
    queryFn: () => getCollectionByAddress(address),
    enabled: !!address,
    retry: false,
  });

  return collection;
};
