import { useReducer } from 'react';

type ObjectStateUpdate<T> = Partial<T>;

export const useObjectState = <T>(initialState: T) => {
  const reducer = (state: T, update: ObjectStateUpdate<T>): T => {
    return { ...state, ...update };
  };

  return useReducer(reducer, initialState);
};
