import {
  Box,
  Button,
  HStack,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverProps,
  PopoverTrigger,
  Spinner,
  useDisclosure,
  useMultiStyleConfig,
  useOutsideClick,
} from '@chakra-ui/react';

import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Icon, IconProps } from '@/components/ui';
import { isEqual } from 'lodash';

type AnyObject = Record<string, unknown>;
type Value = number | string | AnyObject;
type OptionIcon = IconProps['icon'];

export type ISelectOption = {
  title: string;
  value: Value;
  icon?: OptionIcon;
  hidden?: boolean;
  render?(showCheckmark: boolean): React.ReactNode | null;
};

interface ISelectProps {
  options: ISelectOption[];
  defaultValue?: Value | null;
  value?: Value | null;
  variant?: string;
  size?: Size;
  placeholder?: string;
  placement?: PopoverProps['placement'];
  showArrow?: boolean;
  showCheckmark?: boolean;
  dropdownWidth?: string;
  arrowRender?: React.ReactNode;
  onChange?: (value: Value, option: ISelectOption) => void;
  onBlur?(): void;
  isLoading?: boolean;
  renderSelectedOption?(option: ISelectOption): React.ReactNode | null;
  popoverProps?: PopoverProps;
}

type Size = 'sm' | 'md' | 'lg';

export const Select: React.FC<ISelectProps> = (props) => {
  //
  const {
    options,
    defaultValue,
    value,
    placeholder = 'Select option',
    placement,
    showArrow = true,
    showCheckmark = true,
    arrowRender = null,
    dropdownWidth = null,
    variant,
    size,
    onChange,
    onBlur,
    isLoading,
    renderSelectedOption,
    popoverProps = {},
    ...rest
  } = props;

  const ref = useRef(null);

  const { isOpen, onClose, onToggle } = useDisclosure();

  const [internalValue, setInternalValue] = useState<Value | null>(
    defaultValue || null
  );
  const [selectedOption, setSelectedOption] = useState<ISelectOption | null>(
    null
  );

  const styles = useMultiStyleConfig('Select', { variant, size });

  const handleSelect = useCallback(
    (value: Value, option: ISelectOption) => {
      setSelectedOption(option);
      setInternalValue(value);
      onChange && onChange(value, option);
      onClose();
    },
    [onClose, onChange]
  );

  useOutsideClick({
    ref: ref,
    handler: () => onClose(),
  });

  const findOptionByValue = (value: Value) => {
    // if value is object
    if (typeof value === 'object' && value !== null) {
      const findedOption = options.find((option: ISelectOption) =>
        isEqual(option.value, value)
      );
      return findedOption;
    }
    // if value is string or number
    const findedOption = options.find(
      (option: ISelectOption) => option.value === value
    );

    return findedOption;
  };

  useEffect(() => {
    if (defaultValue && options) {
      //
      const findedOption = findOptionByValue(defaultValue);
      //
      if (findedOption) {
        setSelectedOption(findedOption);
        //onChange && onChange(findedOption.value, findedOption);
      }
    }
  }, [defaultValue]);

  useEffect(() => {
    if (value) {
      const findedOption = findOptionByValue(value);
      setInternalValue(value);
      setSelectedOption(findedOption);
    }
  }, [value]);

  return (
    <>
      <Box ref={ref} __css={styles.wrapper}>
        <Popover
          isOpen={isOpen}
          isLazy={true}
          matchWidth={true}
          gutter={4}
          placement={placement}
          {...popoverProps}
        >
          <PopoverTrigger>
            <Button
              onClick={onToggle}
              onBlur={onBlur}
              __css={styles.select}
              disabled={isLoading}
              {...rest}
            >
              {selectedOption ? (
                <>
                  {selectedOption.render ? (
                    selectedOption.render(false)
                  ) : renderSelectedOption ? (
                    renderSelectedOption(selectedOption)
                  ) : (
                    <Box __css={styles.selectedOption}>
                      <HStack spacing="8px">
                        {selectedOption.icon && (
                          <Box>
                            <Icon icon={selectedOption.icon} />
                          </Box>
                        )}
                        <Box>{selectedOption.title}</Box>
                      </HStack>
                    </Box>
                  )}
                </>
              ) : (
                <Box __css={styles.placeholder}>
                  {isLoading ? 'Loading...' : placeholder}
                </Box>
              )}
              {showArrow && (
                <Box __css={styles.arrow} aria-expanded={isOpen}>
                  {isLoading ? (
                    <Spinner size="sm" thickness="1px" color="dark" />
                  ) : (
                    <>{arrowRender ? arrowRender : <Icon icon="chevronUp" />}</>
                  )}
                </Box>
              )}
            </Button>
          </PopoverTrigger>

          <PopoverContent sx={styles.dropdown} style={{ width: dropdownWidth }}>
            <PopoverBody as="ul" sx={styles.list} role="listbox">
              {options.map((option, index) =>
                !!option.hidden ? null : (
                  <Option
                    key={index}
                    value={option.value}
                    title={option.title}
                    icon={option.icon}
                    render={option.render}
                    size={size}
                    onClick={() => handleSelect(option.value, option)}
                    isSelected={option.value === selectedOption?.value}
                    showCheckmark={showCheckmark}
                  />
                )
              )}
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Box>
    </>
  );
};

interface IOptionProps {
  value: Value;
  title: string;
  icon?: OptionIcon;
  className?: string;
  onClick?(): void;
  isSelected: boolean;
  isDisabled?: boolean;
  size?: Size;
  render?(showCheckmark: boolean): React.ReactNode | null;
  showCheckmark?: boolean;
}

const Option: React.FC<IOptionProps> = (props) => {
  //
  const {
    render,
    title,
    icon,
    value,
    isDisabled,
    onClick,
    isSelected,
    size,
    showCheckmark = false,
  } = props;

  const styles = useMultiStyleConfig('Select', { size });

  return (
    <Box
      as="li"
      onClick={onClick}
      __css={styles.option}
      aria-selected={isSelected}
      role="option"
    >
      {render ? (
        render(showCheckmark && isSelected)
      ) : (
        <Box
          display="inline-flex"
          justifyContent="space-between"
          alignItems="center"
          lineHeight="20px"
          w="100%"
        >
          <HStack spacing="8px">
            {icon && (
              <Box>
                <Icon icon={icon} />
              </Box>
            )}
            <Box>{title}</Box>
          </HStack>
          {isSelected && showCheckmark && (
            <Box flexShrink={0}>
              <Icon icon="check2" />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};
