import React from 'react';

import cn from 'classnames';
import styles from './wrapper.module.sass';

interface Props {
  children: React.ReactNode;
  center?: boolean;
  style?: React.CSSProperties;
}

export const Wrapper = (props: Props) => {
  //
  const { children, center, style } = props;

  return (
    <div className={cn(styles.Wrapper, center && styles.center)} style={style}>
      {children}
    </div>
  );
};
