import { Box, Fade, Text } from '@chakra-ui/react';
import cn from 'classnames';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React from 'react';

import { FullPageLoader, Header } from '@/components/common';
import { useAuth, useIsMobile } from '@/hooks';

import styles from '../layout.module.sass';

interface ILayoutProps {
  children: React.ReactNode;
}

export const Layout: React.FC<ILayoutProps> = (props) => {

  const { children } = props;

  const {
    isAuthenticated,
    isLoading,
    isWaitingForSignMessage,
    isWaitingForWalletActivation
  } = useAuth();
  const isMobile = useIsMobile();
  const router = useRouter();

  const isHomePage = router.asPath === '/';
  const showPreloader = isLoading || isWaitingForWalletActivation || (!isAuthenticated && !isHomePage);

  return (
    <>
      <Head>
        <meta
          name="description"
          content="NFT Display - a free-to-use web, TV, and mobile NFT app to showcase your NFTs on any screen."
        />
      </Head>

      <Fade in={showPreloader} unmountOnExit={true}>
        <FullPageLoader>
          {!isWaitingForSignMessage ? null : (
            <Text>Please sign the message in your wallet to log in</Text>
          )}
          {!isWaitingForWalletActivation ? null : (
            <Text>Please activate your wallet</Text>
          )}
        </FullPageLoader>
      </Fade>

      {!showPreloader && (
        <Box className={styles.Wrapper}>
          <Header className={cn(styles.Header, isMobile && styles.Mobile)} />
          <Box className={cn(styles.Page, isMobile && styles.Mobile)}>
            {children}
          </Box>
        </Box>
      )}
    </>
  );
};
