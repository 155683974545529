export const parseTCollection = (tcollection) => {
  //
  return {
    contractAddress: tcollection.contractAddress,
    creator: tcollection.creator,
    tokenType: tcollection.standard,
    name: tcollection.name,
    symbol: tcollection.symbol,
    logoUrl: tcollection?.logo?.url ?? null,
    coverUrl: tcollection?.banner?.url ?? null,
    nftCount: tcollection?.ownedNfts,
    ownerCount: tcollection?.ownerCount ?? null,
    stats: tcollection?.reservoir,
  };
};
