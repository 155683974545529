import { useCallbackRef } from '@chakra-ui/react';
import { useCallback, useEffect, useRef } from 'react';

function useDebouncedCallback<A extends any[]>(
  callback: (...args: A) => void,
  time?: number
) {
  const callbackRef = useCallbackRef(callback);
  const timeoutRef = useRef<number>();

  const clearCurrentTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  useEffect(() => {
    return () => clearCurrentTimeout();
  }, []);

  return useCallback(
    (...args: A) => {
      clearCurrentTimeout();

      timeoutRef.current = Number(
        setTimeout(() => {
          callbackRef(...args);
        }, time)
      );
    },
    [callbackRef, time]
  );
}

export default useDebouncedCallback;
