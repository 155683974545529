import { configs } from '@/configs';
import { NetworkID } from '@/types';

interface BasicChainInformation {
  name: string;
  urls: string[];
}

export const CHAINS: Record<NetworkID, BasicChainInformation> = {
  [NetworkID.MAINNET]: {
    name: 'Mainnet',
    urls: [
      `https://eth-mainnet.g.alchemy.com/v2/${configs.alchemyApiKey}`,
    ],
  },
  [NetworkID.GOERLI]: {
    name: 'Goerli',
    urls: [
      `https://eth-goerli.alchemyapi.io/v2/${configs.alchemyApiKey}`,
    ],
  },
};

export const URLS = Object.keys(CHAINS).reduce((accumulator, chainId) => {
  const validURLs: string[] = CHAINS[Number(chainId)].urls;

  if (validURLs.length) {
    accumulator[Number(chainId)] = validURLs;
  }

  return accumulator;
}, {} as Record<NetworkID, string[]>);
