import { ElasticSwitch } from '@/components/common';
import { Icon } from '@/components/ui';
import { HStack } from '@chakra-ui/react';

import type { IElasticSwitchItem } from '@/components/common/elastic-switch/';
import { useEffect, useState } from 'react';

type Grids = 'grid-small' | 'grid-medium' | 'grid-large' | 'list';

interface Props {
  size?: 'sm' | 'md' | 'lg';
  grids: Grids[];
  value?: Grids;
  onChange?: (value: any) => void;
}

export const GridSwither = (props: Props) => {
  //
  const { value, grids, size = 'md', onChange } = props;

  const [internalValue, setInternalValue] = useState(null);

  useEffect(() => {
    if (!value) {
      setInternalValue(grids[0]);
    }
  }, []);

  useEffect(() => {
    if (value) {
      setInternalValue(value);
    }
  }, [value]);

  const internalOnChange = (value) => {
    //
    setInternalValue(value);

    onChange && onChange(value);
  };

  const values: Record<Grids, IElasticSwitchItem> = {
    'grid-small': {
      value: 'grid-small',
      label: '',
      icon: <Icon icon={{ name: 'grid-small', size: size }} />,
    },
    'grid-medium': {
      value: 'grid-medium',
      label: '',
      icon: <Icon icon={{ name: 'grid-medium', size: size }} />,
    },
    'grid-large': {
      value: 'grid-large',
      label: '',
      icon: <Icon icon={{ name: 'grid-large', size: size }} />,
    },
    list: {
      value: 'list',
      label: '',
      icon: <Icon icon={{ name: 'list', size: size }} />,
    },
  };

  const getItems = (): IElasticSwitchItem[] => {
    //
    const items = [];

    grids.forEach((item) => {
      //
      items.push(values[item]);
    });

    return items;
  };

  return (
    <HStack>
      <ElasticSwitch
        value={internalValue}
        items={getItems()}
        size={size}
        onChange={internalOnChange}
      />
    </HStack>
  );
};
