import {
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
  VStack
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import dayjs, { ManipulateType } from 'dayjs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useForm, FormProvider } from 'react-hook-form';
import { z } from 'zod';

import { Icon } from '@/components/ui';
import { Form } from '@/components/ui/form';
import { ISelectOption, Select } from '@/components/ui/select';

import { DateInput } from './components';
import { getDateRangeLabel } from './helpers';
import s from './duration-dropdown.module.sass';

type DateRangePresetValue = '1-d' | '3-d' | '7-d' | '1-M' | '3-M' | '6-M' | 'custom';

type DateRangePresetOption = Omit<ISelectOption, 'value'> & {
  value: DateRangePresetValue;
};

const dateRangePresetsOptions: DateRangePresetOption[] = [
  { title: '1 day', value: '1-d' },
  { title: '3 days', value: '3-d' },
  { title: '7 days', value: '7-d' },
  { title: '1 month', value: '1-M' },
  { title: '3 months', value: '3-M' },
  { title: '6 months', value: '6-M' },
  { title: '', value: 'custom', hidden: true },
];

interface IDurationDropdownProps {
  startDate: Date;
  endDate: Date;
  onChange(startDate: Date, endDate: Date): void;
}

export const DurationDropdown: React.FC<IDurationDropdownProps> = (props) => {
  const { startDate: initialStartDate, endDate: initialEndDate, onChange } = props;

  const { isOpen, onToggle, onClose } = useDisclosure();

  const [dateRangePreset, setDateRangePreset] = useState<DateRangePresetValue>('custom');

  const [isChangeStartDateTime, setIsChangeStartDateTime] = useState(false);

  const formSchema = z.object({
    startDate: z.date().refine((value) => {
      const date = dayjs(value);
      if (isChangeStartDateTime) {
        return date.isValid() && date.isAfter(dayjs());
      }
      return date.isValid() && date.isAfter(dayjs().set('h', 0).set('minute', 0).set('seconds', 0))
    }, {
      message: 'Start date must be in the future',
    }),
    endDate: z.date().refine((value) => dayjs(value).isAfter(form.watch('startDate')), {
      message: 'End date must be in the future',
    }),
  });

  type IFormValues = {
    startDate: Date;
    endDate: Date
  };

  const form = useForm<IFormValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      startDate: initialStartDate,
      endDate: initialEndDate,
    },
    resolver: zodResolver(formSchema),
  });

  const watchStartDate = form.watch('startDate');
  const watchEndDate = form.watch('endDate');

  const handleChangeDateRangePreset = useCallback((value: DateRangePresetValue) => {
    setDateRangePreset(value);

    if (value === 'custom') {
      return;
    }

    const [rangeAmount, rangeType] = value.split('-');
    form.setValue('startDate', new Date());
    form.setValue('endDate', dayjs().add(Number(rangeAmount), rangeType as ManipulateType).toDate());
  }, []);

  const handleChangeDateRange = useCallback((date) => {
    // const [start, end] = dates;
    // form.setValue('startDate', start);

    // if (dayjs(end).isSame(dayjs(start))) {
    //   form.setValue('endDate', dayjs(end).set('hours', 23).set('minutes', 59).set('seconds', 59).toDate());
    // } else {
    //   form.setValue('endDate', date);
    // }

    form.setValue('endDate', date);

    setDateRangePreset('custom');
  }, []);

  const handleChangeStartDateInput = useCallback((date: Date) => {
    form.setValue('startDate', date);
  }, []);
  const handleChangeEndDateInput = useCallback((date: Date) => {
    form.setValue('endDate', date);
  }, []);

  const handleApply = useCallback((values: IFormValues) => {
    onChange(values.startDate, values.endDate);
    onClose();
  }, [onChange, onClose]);

  const initialDateRangeLabel = useMemo(
    () => getDateRangeLabel(initialStartDate, initialEndDate),
    [initialStartDate, initialEndDate]
  );

  const dateRangeLabel = useMemo(
    () => getDateRangeLabel(watchStartDate, watchEndDate),
    [watchStartDate, watchEndDate]
  );

  useEffect(() => form.setValue('startDate', initialStartDate), [initialEndDate]);
  useEffect(() => form.setValue('endDate', initialEndDate), [initialEndDate]);

  useEffect(() => {
    form.trigger();
  }, [watchStartDate, watchEndDate]);

  useEffect(() => {
    if (!isOpen) {
      setDateRangePreset('custom');
      form.setValue('startDate', initialStartDate);
      form.setValue('endDate', initialEndDate);
    }
  }, [isOpen]);

  return (
    <FormProvider {...form}>
      <Form onSubmit={form.handleSubmit(handleApply)}>
        <Popover
          matchWidth={true}
          isOpen={isOpen}
          onClose={onClose}
          closeOnBlur={true}
        >
          <PopoverTrigger>
            <Button className={s.TriggerButton} onClick={onToggle}>
              <Icon icon={'calendar'} />
              <Box as={'span'}>{initialDateRangeLabel}</Box>
            </Button>
          </PopoverTrigger>
          <PopoverContent className={s.PopoverContent}>
            <PopoverBody className={s.PopoverBody}>
              <VStack align={'stretch'} spacing={'6px'}>
                <Heading className={s.Label}>Date range</Heading>
                <Select
                  options={dateRangePresetsOptions}
                  value={dateRangePreset}
                  onChange={handleChangeDateRangePreset}
                  renderSelectedOption={() => (
                    <Flex gap={'8px'}>
                      <Icon icon={'calendar'} />
                      <Text>{dateRangeLabel ?? 'Select date range'}</Text>
                    </Flex>
                  )}
                />
              </VStack>

              <HStack spacing={'8px'} alignItems={'flex-start'}>
                <FormControl isInvalid={!!form.formState.errors.startDate} className={s.FormControl}>
                  <FormLabel className={s.Label} m={0}>Starting</FormLabel>
                  <DateInput
                    className={s.DateInput}
                    value={watchStartDate}
                    isDisabled={true}
                    onChange={handleChangeStartDateInput}
                    onTimeChanged={() => setIsChangeStartDateTime(true)}
                  />
                  {!form.formState.errors.startDate ? null : (
                    <FormHelperText color={'red'}>
                      {form.formState.errors.startDate.message}
                    </FormHelperText>
                  )}
                </FormControl>

                <Box>
                  <Icon icon={'right'} boxSize={'16px'} mt={'calc(12px + 16px + 6px)'} />
                </Box>

                <FormControl isInvalid={!!form.formState.errors.endDate} className={s.FormControl}>
                  <FormLabel className={s.Label} m={0}>Ending</FormLabel>
                  <DateInput
                    className={s.DateInput}
                    value={watchEndDate}
                    onChange={handleChangeEndDateInput}
                  />
                  {!form.formState.errors.endDate ? null : (
                    <FormHelperText color={'red'}>
                      {form.formState.errors.endDate.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </HStack>

              <Box className={s.DatePicker}>
                <DatePicker
                  minDate={new Date()}
                  selected={dayjs(watchEndDate).isValid() ? watchEndDate : undefined}
                  // startDate={dayjs(watchStartDate).isValid() ? watchStartDate : undefined}
                  // endDate={dayjs(watchEndDate).isValid() ? watchEndDate : undefined}
                  // selectsRange
                  inline
                  renderCustomHeader={(controller) => (
                    <Flex alignItems={'center'} mb={'8px'}>
                      <Button
                        className={s.ChangeMonthButton}
                        isDisabled={controller.prevMonthButtonDisabled}
                        onClick={controller.decreaseMonth}
                      >
                        <Icon icon={'right'} transform={'rotate(-180deg)'} />
                      </Button>
                      <Heading fontSize={'14px'} flex={1}>
                        {dayjs(controller.date).format('MMMM')} {dayjs(controller.date).format('YYYY')}
                      </Heading>
                      <Button
                        className={s.ChangeMonthButton}
                        isDisabled={controller.nextMonthButtonDisabled}
                        onClick={controller.increaseMonth}
                      >
                        <Icon icon={'right'} />
                      </Button>
                    </Flex>
                  )}
                  onChange={handleChangeDateRange}

                />
              </Box>
              <Flex justifyContent={'space-between'}>
                <Button variant={'secondary'} onClick={onClose}>Cancel</Button>
                <Button
                  variant={'primary'}
                  isDisabled={!form.formState.isValid}
                  onClick={form.handleSubmit(handleApply)}
                >Set Date</Button>
              </Flex>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Form>
    </FormProvider>
  );
};