import { tabsAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(parts.keys);

const sizes = {
  sm: definePartsStyle({
    tab: {
      fontSize: '12px',
      fontWeight: 600,
      lineHeight: '16px',
      padding: '8px 16px',
    },
  }),
  md: definePartsStyle({
    tab: {
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '20px',
      padding: '6px 12px',
    },
  }),
  lg: definePartsStyle({
    tab: {
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '24px',
      padding: '4px 8px',
    },
  }),
}

const variantElastic = definePartsStyle(() => {
  return {
    tablist: {
      background: 'white',
      border: '1px solid',
      borderColor: 'blackTransparent.10',
      borderRadius: '10px',
      gap: '4px',
      marginBottom: '16px',
      padding: '4px',

      _dark: {
        background: 'dark',
        borderColor: 'whiteTransparent.10',
      },
    },
    tabpanel: {
      padding: 0,
    },
    tab: {
       border: 0,
       borderRadius: '6px',
       color: 'blackTransparent.60',
       marginBottom: 0,
       transition: '200ms',

       _dark: {
         color: 'whiteTransparent.60',
       },

      _hover: {
        color: 'black',

        _dark: {
          color: 'white',
        },
      },

      _selected: {
        background: 'blackTransparent.10',
        color: 'black',

        _dark: {
          background: 'whiteTransparent.10',
          color: 'white',
        },
      },

      _disabled: {
         background: 'transparent',
         color: 'blackTransparent.20 !important',

        _dark: {
          color: 'whiteTransparent.20 !important',
        },
      }
    },
  }
})

const variants = {
  elastic: variantElastic,
}

export const Tabs = defineMultiStyleConfig({
  sizes,
  variants,
  defaultProps: {
    size: 'md',
  },
})