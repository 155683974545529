import { Icon as ChakraIcon, IconProps } from '@chakra-ui/react';

export const Icon = (props: IconProps) => {
  //
  return (
    <ChakraIcon
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 136 136"
      fill="none"
    >
      <path
        d="M49.272 102.953C51.6356 101.863 53.9992 100.592 55.9992 98.7767C60.5446 94.9636 61.2719 89.8795 57.8174 85.1585C55.8174 82.4349 53.272 80.0744 50.5447 78.4402C44.1812 74.264 36.7267 72.8114 29.2723 72.4482C25.8178 72.993 22.5451 73.1745 19.0906 74.0824C11.4544 75.8982 4.90902 79.7113 0 84.6138V85.8848C0 92.9663 1.63632 99.8661 4.18174 106.04C8.3635 92.6031 22.9087 84.4322 35.8176 89.3348C37.8176 90.2426 39.8176 91.5137 41.454 93.1478C44.1812 95.6899 44.1811 98.5951 41.6357 101.319C40.5448 102.59 39.2722 103.679 37.8176 104.769C30.545 109.671 24.5451 115.663 19.8178 122.745C19.2724 123.289 18.9088 124.016 18.3633 124.742C19.4542 125.65 20.5452 126.376 21.8179 127.284C28.3633 116.389 37.454 108.4 49.272 102.953Z"
        fill="black"
      />
      <path
        d="M57.6354 109.853C62.1808 108.037 66.7262 106.221 71.2715 104.224C78.3624 101.137 84.1805 96.5976 88.544 90.0609C91.9985 84.9768 92.9077 79.5295 91.4532 73.356C89.4532 65.7298 84.726 59.5562 78.7261 54.6537C64.7262 42.6697 48.3628 37.0409 29.8176 36.6777C19.2723 36.8593 9.63604 38.8566 0.727066 42.4881C0.363434 44.8486 0.181641 47.3907 0.181641 49.9327V62.4614C6.72701 58.6484 13.8178 56.2879 21.4541 55.38C39.272 53.2011 55.2718 57.5589 68.7262 69.906C70.5443 71.5402 72.1806 73.356 73.4534 75.5349C77.9987 82.0716 77.4534 89.3346 71.8171 94.9634C69.2717 97.5055 66.1807 99.6844 62.9081 101.5C58.9081 103.861 54.9081 105.676 50.7264 107.492C40.1811 112.576 31.8175 120.021 25.8176 129.826C27.2721 130.734 28.9085 131.46 30.363 132.186C36.5447 121.655 45.6355 114.392 57.6354 109.853Z"
        fill="black"
      />
      <path
        d="M86.1818 0H49.8187C32.9098 0 18.0009 8.35247 8.91016 21.2443C9.4556 21.0627 9.81921 21.0627 10.3647 20.8812C16.5464 19.6101 23.0918 19.247 26.7281 18.8838C55.455 19.4286 78.0001 28.5073 96.7271 46.8465C103.818 53.9279 108.363 62.462 109.272 72.4486C110.182 83.5247 105.818 92.4219 97.6362 99.6849C91.9999 104.951 84.9092 107.856 77.8184 110.398C73.273 112.032 69.0912 113.666 64.5458 115.119C52.9096 118.932 44.0006 125.469 37.4552 134.547C41.2734 135.455 45.4552 136 49.6369 136H86.0001C113.636 136 135.999 113.666 135.999 86.0668V49.9332C136.181 22.3338 113.636 0 86.1818 0Z"
        fill="black"
      />
      <defs>
        <linearGradient
          id="gradient-1"
          x1="0.968756"
          y1="76.8705"
          x2="35.0548"
          y2="131.94"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF7F69" />
          <stop offset="0.299586" stopColor="#FF2D83" />
          <stop offset="1" stopColor="#6695FF" />
        </linearGradient>
        <linearGradient
          id="gradient-2"
          x1="1.66645"
          y1="44.38"
          x2="64.0932"
          y2="133.133"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF7F69" />
          <stop offset="0.299586" stopColor="#FF2D83" />
          <stop offset="1" stopColor="#6695FF" />
        </linearGradient>
        <linearGradient
          id="gradient-3"
          x1="10.9627"
          y1="10.9677"
          x2="100.716"
          y2="134.843"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF7F69" />
          <stop offset="0.299586" stopColor="#FF2D83" />
          <stop offset="1" stopColor="#6695FF" />
        </linearGradient>
      </defs>
    </ChakraIcon>
  );
};
