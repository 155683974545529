import { useWeb3React } from '@web3-react/core';

import { Contracts } from '@/contracts';
import { NETWORK_CHAIN_ID } from '@/constants';
import { Contract } from 'ethers';

function useContracts() {
  //
  const { account: address, provider } = useWeb3React();

  const signer = provider?.getSigner(address).connectUnchecked() as any;

  const { contracts } = Contracts[NETWORK_CHAIN_ID];

  // Minting
  const GravitonTorrentERC721Core = new Contract(
    contracts.GravitonTorrentERC721Core.address,
    contracts.GravitonTorrentERC721Core.abi,
    signer
  );

  const GravitonTorrentERC721Factory = new Contract(
    contracts.GravitonTorrentERC721Factory.address,
    contracts.GravitonTorrentERC721Factory.abi,
    signer
  );

  // XYZ
  const UniverseXYZToken = new Contract(
    contracts.UniverseXYZToken.address,
    contracts.UniverseXYZToken.abi,
    signer
  );

  // Staking

  const UniverseXYZSupernovaFacet = new Contract(
    contracts.UniverseXYZSupernovaFacet.address,
    contracts.UniverseXYZSupernovaFacet.abi,
    signer
  );

  // Claiming
  const UniverseXYZMerkleRewardsDistributor = new Contract(
    contracts.UniverseXYZMerkleRewardsDistributor.address,
    contracts.UniverseXYZMerkleRewardsDistributor.abi,
    signer
  );

  return {
    signer,
    GravitonTorrentERC721Core,
    GravitonTorrentERC721Factory,
    UniverseXYZToken,
    UniverseXYZSupernovaFacet,
    UniverseXYZMerkleRewardsDistributor,
  };
}

export { useContracts };
