import { Icon, IconProps } from '@chakra-ui/react';

export const ErrorIcon = (props: IconProps) => {
  //
  return (
    <>
      <Icon
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 34 34"
        fill="none"
        display="block"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.2317 6.46473L30.5383 24.5031C30.5543 24.5311 30.5685 24.5602 30.5808 24.59C30.7389 24.9745 30.827 25.3816 30.8499 25.7895L30.8501 25.7929C30.955 27.832 29.387 29.5679 27.3494 29.6744C27.3364 29.6751 27.3233 29.6755 27.3102 29.6755H6.79407C6.77681 29.6755 6.75968 29.6749 6.74272 29.6737C6.71181 29.6758 6.68042 29.6761 6.64869 29.6743C6.23997 29.6514 5.83635 29.5632 5.45277 29.4087L5.45005 29.4076C3.56116 28.6381 2.65099 26.4842 3.41883 24.5931C3.43138 24.5622 3.44598 24.5321 3.46253 24.5031L13.7691 6.46489C14.1049 5.85949 14.6049 5.35966 15.2121 5.0256C16.9969 4.03725 19.2424 4.68259 20.2317 6.46473ZM6.74215 28.1772C6.7593 28.1761 6.77661 28.1755 6.79407 28.1755H27.2892C28.492 28.1028 29.4132 27.0757 29.3521 25.8716C29.3389 25.6383 29.291 25.4117 29.21 25.2019L18.9217 7.19565C18.3345 6.13427 16.9991 5.75017 15.9381 6.3382L15.9359 6.33946C15.5762 6.53717 15.2788 6.83429 15.0792 7.19532L15.0741 7.20467L4.79159 25.2006C4.36662 26.313 4.90685 27.5656 6.01432 28.0177C6.24242 28.1095 6.4844 28.1627 6.73261 28.1766C6.73579 28.1768 6.73897 28.177 6.74215 28.1772Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.9857 13.8359C17.3999 13.8359 17.7357 14.1717 17.7357 14.5859V18.9776C17.7357 19.3918 17.3999 19.7276 16.9857 19.7276C16.5715 19.7276 16.2357 19.3918 16.2357 18.9776V14.5859C16.2357 14.1717 16.5715 13.8359 16.9857 13.8359Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.2305 23.3753C16.2305 22.9611 16.5663 22.6253 16.9805 22.6253H16.9947C17.4089 22.6253 17.7447 22.9611 17.7447 23.3753C17.7447 23.7895 17.4089 24.1253 16.9947 24.1253H16.9805C16.5663 24.1253 16.2305 23.7895 16.2305 23.3753Z"
          fill="black"
        />
      </Icon>
    </>
  );
};
