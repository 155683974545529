import { Icon, IconType, Toast } from '@/components/ui';
import { useClipboard } from '@/hooks';
import {
  Box,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useToast,
} from '@chakra-ui/react';

interface Props {
  magnetLink: string;
}

type Item = {
  label: string;
  icon: IconType;
  onClick: (e: React.MouseEvent) => void;
};

export const MarketplaceMenu = (props: Props) => {
  //
  const { magnetLink } = props;

  const toast = useToast();
  const { handleCopy } = useClipboard();

  const items: Item[] = [
    {
      label: 'Seed',
      icon: { name: 'magnet', size: 'sm' },
      onClick: (e) => {
        e.stopPropagation();
        window.open(magnetLink, '_blank');
      },
    },
    {
      label: 'Copy magnet link',
      icon: { name: 'copy', size: 'sm' },
      onClick: (e) => {
        //
        e.stopPropagation();

        handleCopy(magnetLink);

        toast({
          position: 'bottom-right',
          duration: 3000,
          render: ({ onClose }) => (
            <Toast
              status="success"
              title="Success"
              description="Magnet link copied to clipboard"
              onClose={onClose}
            />
          ),
        });
      },
    },
  ];

  return (
    <Menu placement="bottom-end" flip={false}>
      <MenuButton
        as={IconButton}
        icon={<Icon icon="dots" opacity={0.4} />}
        variant="naked"
        size="sm"
        onClick={(e) => e.stopPropagation()}
      />
      <MenuList zIndex={2}>
        {items.map((item, index) => (
          <MenuItem key={index} onClick={item.onClick}>
            <HStack spacing="8px">
              <Box>
                <Icon icon={item.icon} />
              </Box>
              <Box>{item.label}</Box>
            </HStack>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};
