import { Box, Flex, HStack, Text, useBreakpointValue } from '@chakra-ui/react';

import {
  Button,
  Form,
  FormItem,
  Icon,
  Input,
  InputNumber,
  Select,
} from '@/components/ui';

import { ElasticSwitch } from '@/components/common';
import { LabelTooltip } from '@/components/ui/form-item';

import { playlistValidationSchema } from '@/modules/display/validations';
import { PoweredByUniverseTooltip } from './components';

import { useAuth, useSelectedNfts } from '@/hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Global } from '@emotion/react';
import { SwipeableDrawer } from '@mui/material';

import {
  DELAY_TYPES,
  EFFECTS,
  playlistDefaultValues,
  ROTATION_VALUES,
} from '@/modules/display/constants';
import type { Playlist } from '@/types';

import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import cn from 'classnames';

import { configs } from '@/configs';
import { ISelectOption } from '@/components/ui/select';
import { RotationOption } from '@/modules/display/components';
import s from './playlist-panel.module.sass';

type SubmitData = z.infer<typeof playlistValidationSchema>;

interface Props {
  playlist?: Playlist;
  isLoading?: boolean;
  type: 'create' | 'edit';
  onSubmit?(data): void;
}

export const PlaylistPanel = (props: Props) => {
  //
  const { type, playlist, isLoading, onSubmit } = props;

  const auth = useAuth();
  const { assets, maxSelectedAssets } = useSelectedNfts();

  const isCreate = type === 'create';

  const methods = useForm({
    mode: 'onChange',
    defaultValues: playlistDefaultValues,
    resolver: zodResolver(playlistValidationSchema),
  });

  const {
    handleSubmit,
    reset,
    setValue,
    trigger,
    formState: { errors, isDirty, isValid },
  } = methods;

  const isDisabled = useMemo(() => {
    if (assets.length === 0) {
      return true;
    }

    if (configs.featuresFlags.enableProPlanSubscription) {
      if (assets.length > maxSelectedAssets && !auth?.user?.display?.proPlan) {
        return true;
      }
    }

    return !isValid;
  }, [assets, maxSelectedAssets, auth, isValid]);

  const handleSubmitForm = useCallback(
    (data: SubmitData) => {
      const { title, effect, delayType, delay, orientation, showPoweredBy } =
        data;

      const playlist = {
        title: title,
        effect: effect,
        orientation: orientation,
        delayType: parseInt(delayType),
        delay: parseFloat(delay),
        assets: assets,
        showPoweredBy: showPoweredBy,
      };

      onSubmit && onSubmit(playlist);
    },
    [assets, onSubmit]
  );

  const handleResetForm = useCallback(() => {
    reset();
  }, [reset]);

  const [showToolbar, setShowToolbar] = useState(true);
  const toolbarMarginLeft = useBreakpointValue(['0px', null, s.sidebarWidth]);
  const isToolbarCompact = useBreakpointValue([true, null, null, false]);

  useEffect(() => setShowToolbar(!isToolbarCompact), [isToolbarCompact]);

  useEffect(() => {
    if (playlist) {
      setValue('title', playlist.title);
      setValue('effect', playlist.effect);
      setValue('delayType', `${playlist.delayType}`);
      setValue('delay', `${playlist.delay}`);
      setValue('orientation', `${playlist.orientation}`);
      setValue('showPoweredBy', playlist.showPoweredBy);

      trigger();
    }
  }, [playlist, setValue, trigger]);

  const rotationOptions = useMemo(() => {
    return ROTATION_VALUES.map<ISelectOption>(({ icon, ...rest }) => ({
      ...rest,
      render: (showCheckmark) => (
        <RotationOption
          icon={icon}
          title={rest.title}
          showCheckmark={showCheckmark}
        />
      ),
    }));
  }, []);

  const renderButtons = () => (
    <HStack spacing="10px">
      {isCreate && (
        <Button
          onClick={() => handleResetForm()}
          disabled={!isDirty}
          className={cn(s.Button, s.Button_reset)}
        >
          Reset
        </Button>
      )}
      <Button
        variant="primary"
        type="submit"
        disabled={isDisabled}
        className={cn(s.Button)}
        isLoading={isLoading}
      >
        {isCreate ? 'Create' : 'Update'}
      </Button>
    </HStack>
  );

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(handleSubmitForm)}>
        <Global
          styles={{
            '.MuiDrawer-root > .MuiPaper-root': {
              border: 'none',
              overflow: 'visible',
              marginLeft: `${toolbarMarginLeft}`,
              width: `calc(100% - ${toolbarMarginLeft})`,
            },
          }}
        />
        <SwipeableDrawer
          variant={isToolbarCompact ? 'temporary' : 'persistent'}
          anchor={'bottom'}
          open={showToolbar}
          onClose={() => setShowToolbar(false)}
          onOpen={() => setShowToolbar(true)}
          swipeAreaWidth={88}
          disableSwipeToOpen={false}
          SwipeAreaProps={{
            sx: {
              width: `calc(100% - ${toolbarMarginLeft})`,
              marginLeft: toolbarMarginLeft,
            },
          }}
          BackdropProps={{
            invisible: true,
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <Box className={cn(s.Wrapper, s.DesktopWrapper)}>
            <Flex
              flexDir={{ base: 'column', lg: 'row' }}
              gap={'10px'}
              w={'100%'}
              flexWrap={'wrap'}
            >
              <Box
                w={'100%'}
                maxW={{ base: '100%', lg: 'calc(100% - 170px)', xxl: '240px' }}
              >
                <FormItem
                  type="input"
                  name="title"
                  label="Name"
                  showErrorMessage={errors?.title?.type === 'too_big'}
                >
                  <Input name="title" placeholder="Playlist name" />
                </FormItem>
              </Box>

              <Box className={cn(s.FieldWrapper)}>
                <FormItem
                  name="effect"
                  label="Effect"
                  type="select"
                  showErrorMessage={false}
                >
                  <Select options={EFFECTS} />
                </FormItem>
              </Box>

              <Box className={cn(s.FieldWrapper)}>
                <FormItem
                  name="delayType"
                  label="Delay Type"
                  type="select"
                  showErrorMessage={false}
                >
                  <Select options={DELAY_TYPES} />
                </FormItem>
              </Box>

              <Box className={cn(s.FieldWrapper)}>
                <FormItem
                  name="orientation"
                  label="Rotation"
                  type="select"
                  showErrorMessage={false}
                  tooltip={() => (
                    <LabelTooltip
                      tooltip={
                        'This is for TV orientation. Phones will use device orientation'
                      }
                      maxW={'200px'}
                      fontSize={'12px'}
                      lineHeight={'16px'}
                    />
                  )}
                >
                  <Select options={rotationOptions} />
                </FormItem>
              </Box>

              <Box className={cn(s.FieldWrapper, s.FieldWrapper_delay)}>
                <FormItem
                  name="delay"
                  label="Delay"
                  type="input"
                  showErrorMessage={false}
                >
                  <InputNumber
                    step={0.5}
                    precision={1}
                    min={1}
                    max={5}
                    defaultValue={1}
                  />
                </FormItem>
              </Box>

              <Box className={cn(s.FieldWrapper)} minW={'150px'}>
                <FormItem
                  name="showPoweredBy"
                  label="Powered By Universe"
                  type="elastic-switch"
                  tooltip={() => <PoweredByUniverseTooltip />}
                  showErrorMessage={false}
                >
                  <ElasticSwitch
                    items={[
                      { value: false, label: 'Hide' },
                      { value: true, label: 'Show' },
                    ]}
                    display={['block', null, null, 'inline-block']}
                  />
                </FormItem>
              </Box>

              <Box
                mt={'28px'}
                w={['100%', null, null, 'fit-content']}
                ml={'auto'}
              >
                {renderButtons()}
              </Box>
            </Flex>
          </Box>
        </SwipeableDrawer>
        <Box
          className={cn(s.Wrapper, s.MobileWrapper)}
          marginLeft={`${toolbarMarginLeft}`}
          width={`calc(100% - ${toolbarMarginLeft})`}
        >
          <Box
            className={cn(s.TapToEditWrapper)}
            onClick={() => setShowToolbar(true)}
          >
            <Text fontSize={'12px'} fontWeight={600} mb={'18px'}>
              Tap to edit
              <Icon
                icon={'downSmall'}
                display={'inline-block'}
                ml={'6px'}
                pos={'relative'}
                top={'2px'}
              />
            </Text>
            <Box className={cn(s.TapToEditWrapper_line)} />
          </Box>
          <Box display={['block', null, null, 'none']}>{renderButtons()}</Box>
        </Box>
      </Form>
    </FormProvider>
  );
};
