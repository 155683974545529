import { Spinner, SpinnerProps } from '@/components/ui';
import { Box } from '@chakra-ui/react';

import styles from './playlist-card-asset-loading.module.sass';

export const PlaylistCardAssetLoading = (props: SpinnerProps) => {
  //
  const { size = 'md' } = props;

  return (
    <Box className={styles.Wrapper}>
      <Spinner size={size} {...props} />
    </Box>
  );
};
