import {
  Box,
  Popover,
  PopoverContent,
  PopoverFooter,
  PopoverProps,
  PopoverTrigger,
  Portal,
  useMultiStyleConfig,
} from '@chakra-ui/react';

interface DropdownProps {
  children: React.ReactNode;
  content?: React.ReactNode;
  footer?: React.ReactNode;
  popoverProps?: PopoverProps;
  variant?: string;
  size?: string;
  isOpen?: boolean;
}

export const Dropdown = (props: DropdownProps) => {
  //
  const { children, content, footer, popoverProps, variant, size } = props;

  const styles = useMultiStyleConfig('Dropdown', { variant, size });

  return (
    <Box __css={styles.wrapper}>
      <Popover isLazy={true} gutter={4} {...popoverProps}>
        <PopoverTrigger>{children}</PopoverTrigger>
        <Portal>
          <PopoverContent sx={styles.content}>
            <Box>{content}</Box>

            {footer && (
              <PopoverFooter sx={styles.footer}>{footer}</PopoverFooter>
            )}
          </PopoverContent>
        </Portal>
      </Popover>
    </Box>
  );
};
