/* eslint-disable react/display-name */
import { XMarkIcon } from '@/components/icons';
import { HandleIcon } from '@/components/icons/playlist';
import { Box, Center, Image, useColorMode } from '@chakra-ui/react';
import type { DraggableSyntheticListeners } from '@dnd-kit/core';
import type { Transform } from '@dnd-kit/utilities';
import { CSS } from '@dnd-kit/utilities';
import React, { useEffect, useState } from 'react';

import cn from 'classnames';
import styles from './selected-nfts-item.module.sass';
import { Spinner } from '@/components/ui/spinner';

export interface Props {
  dragOverlay?: boolean;
  dragging?: boolean;
  handle?: boolean;
  height?: number;
  fadeIn?: boolean;
  transform?: Transform | null | any;
  listeners?: DraggableSyntheticListeners;
  sorting?: boolean;
  style?: React.CSSProperties;
  transition?: string;
  wrapperStyle?: React.CSSProperties;
  onRemove?(id: string): void;
  item: any;
  id?: string;
  className?: string;
}

export const Item = React.memo(React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    listeners,
    dragging,
    sorting,
    transition,
    transform,
    item,
    onRemove,
    dragOverlay,
  } = props;

  const { colorMode } = useColorMode();

  useEffect(() => {
    if (!dragging) {
      return;
    }

    document.body.classList.add('grabbing');

    return () => document.body.classList.remove('grabbing');
  }, [dragging]);

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  return (
    <div
      ref={ref}
      className={cn(
        styles.Wrapper,
        sorting && styles.isSorting,
        dragging && styles.isDragging,
        colorMode === 'dark' && styles.Dark
      )}
      style={style}
    >
      <SelectedNftsItem
        item={item}
        listeners={listeners}
        isDragging={dragging}
        onRemove={onRemove}
      />
    </div>
  );
}));

const SelectedNftsItem = (props) => {
  //
  const { item, listeners, isDragging, onRemove } = props;

  const { id, url } = item;

  const [isLoaded, setIsLoaded] = useState(false);

  const handleDelete = (id: string) => {
    onRemove && onRemove(id);
  };

  const renders = {
    mp4: (url: string) => <video src={url} onLoadedData={() => setIsLoaded(true)} />,
    default: (url: string) => <Image src={url} alt="Selected NFT" onLoad={() => setIsLoaded(true)} />,
  };

  const urlParts = url.split('.');
  const lastPart = urlParts[urlParts.length - 1];

  return (
    <Box className={cn(styles.Wrapper, isDragging && styles.isDragging)}>
      <Box className={styles.Image} display={isLoaded ? 'block' : 'none'}>
        {renders[lastPart]?.(url) ?? renders.default(url)}
      </Box>

      {!isLoaded && (
        <Center h={'100%'}>
          <Spinner size={'md'} />
        </Center>
      )}

      <Box className={styles.Handle} {...listeners}>
        <HandleIcon />
      </Box>
      <Box className={styles.Delete} onClick={() => handleDelete(id)}>
        <XMarkIcon />
      </Box>
    </Box>
  );
};
