import React from 'react';

interface Props {
  children: React.ReactNode;
  onSubmit(event: React.FormEvent): void;
  submitOnEnter?: boolean;
}

export const Form = (props: Props) => {
  //
  const { children, submitOnEnter = false, onSubmit } = props;

  const onKeyDown = (event) => {
    //
    if (submitOnEnter) return;

    const keyCode = event.keyCode ? event.keyCode : event.which;

    if (keyCode === 13) {
      event.preventDefault();
    }
  };

  return (
    <>
      <form onSubmit={onSubmit} onKeyDown={(e) => onKeyDown(e)}>
        {children}
      </form>
    </>
  );
};
