import { useAuth, useContracts } from '@/hooks';
import { BigNumber, constants } from 'ethers';

function useUniverceContracts() {
  //
  const { address } = useAuth();
  const contracts = useContracts();

  const approveXYZToken = async (value: boolean): Promise<void> => {
    //
    const amount = value ? constants.MaxUint256 : constants.Zero;
    const spender = contracts.UniverseXYZSupernovaFacet.address;

    const transaction = await contracts.UniverseXYZToken.approve(
      spender,
      amount
    );

    const receipt = await transaction.wait();

    if (receipt.status === 1) return Promise.resolve();

    return Promise.reject();
  };

  const getAllowance = async () =>
    //   {
    //   ownerAddress,
    //   spenderAddress,
    // }: {
    //   ownerAddress?: string;
    //   spenderAddress?: string;
    // } = {}
    {
      //
      // const owner = ownerAddress ?? address;
      // const spender =
      //   spenderAddress ?? contracts.UniverseXYZSupernovaFacet.address; // Supernova contract for "Staking"

      // if (!owner || !spender) {
      //   return;
      // }

      return await contracts.UniverseXYZToken.allowance(
        address,
        contracts.UniverseXYZSupernovaFacet.address
      );
    };

  const getWalletBalance = async (): Promise<BigNumber> => {
    //
    const balance: BigNumber = await contracts.UniverseXYZToken.balanceOf(
      address
    );

    return balance;
  };

  const claimReward = async ({
    amount,
    merkleProof,
  }: {
    amount: string; // no need to convert to BigNumber
    merkleProof: string[];
  }) => {
    //
    return await contracts.UniverseXYZMerkleRewardsDistributor.claim(
      amount,
      merkleProof
    );
  };

  const getClaimStatus = async () => {
    //
    return await contracts.UniverseXYZMerkleRewardsDistributor.claimsDisabled();
  };

  return {
    // UniverseXYZToken
    getAllowance,
    getWalletBalance,
    approveXYZToken,
    // MerkleRewardsDistributor
    getClaimStatus,
    claimReward,
  };
}

export { useUniverceContracts };
