import { Icon, IconProps } from '@chakra-ui/react';

export const DarkThemeIcon = (props: IconProps) => {
  //
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.25603 1.20706C7.4473 1.39833 7.50012 1.68806 7.38866 1.93452C6.931 2.94652 6.6759 4.07032 6.6759 5.25546C6.6759 9.71162 10.2883 13.3241 14.7445 13.3241C15.9296 13.3241 17.0534 13.069 18.0654 12.6113C18.3119 12.4998 18.6016 12.5526 18.7929 12.7439C18.9842 12.9352 19.037 13.2249 18.9255 13.4714C17.4561 16.7205 14.1856 18.9833 10.3852 18.9833C5.21106 18.9833 1.0166 14.7889 1.0166 9.61476C1.0166 5.81432 3.27946 2.5438 6.52857 1.07443C6.77504 0.96297 7.06476 1.01579 7.25603 1.20706ZM5.6255 3.09875C3.61881 4.56708 2.3166 6.93931 2.3166 9.61476C2.3166 14.0709 5.92903 17.6833 10.3852 17.6833C13.0606 17.6833 15.4329 16.3811 16.9012 14.3745C16.2084 14.5377 15.4863 14.6241 14.7445 14.6241C9.57036 14.6241 5.3759 10.4296 5.3759 5.25546C5.3759 4.51366 5.46223 3.79151 5.6255 3.09875Z"
        fill="currentColor"
      />
    </Icon>
  );
};
