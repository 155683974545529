import { useAuth } from '@/hooks/use-auth';
import { useMutation } from '@tanstack/react-query';

import { TransactionRequest } from '@ethersproject/abstract-provider';

interface IUseSendTransaction {
  onSuccess?: (result: any) => void;
}

export const useSendTransaction = (options: IUseSendTransaction = {}) => {
  const { onSuccess } = options;

  const { signer } = useAuth();

  const { mutate, mutateAsync, ...rest } = useMutation({
    mutationFn: async (txRequest: TransactionRequest) => {
      if (!signer) {
        throw new Error('Signer not found');
      }

      const tx = await signer.sendTransaction(txRequest);

      return tx;
    },
    onSuccess,
  });

  return {
    sendTransaction: mutate,
    sendTransactionAsync: mutateAsync,
    ...rest,
  };
};