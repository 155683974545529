import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  BoxProps,
  Flex,
  StackDivider,
  Text,
  VStack
} from '@chakra-ui/react';
import cn from 'classnames';
import React, { useMemo } from 'react';

import { Icon } from '@/components/ui';
import { configs } from '@/configs';
import { RoyaltyRegistryABI, MarketplaceABI } from '@/contracts/abi';
import { useContractRead } from '@/hooks';
import { formatAddress, toFixed } from '@/utils';

import s from './nft-fees.module.sass';

export interface INFTFeesProps {
  contractAddress: string;
  tokenId: string | number;
}

export const NFTFees: React.FC<INFTFeesProps> = (props) => {
  const { contractAddress, tokenId } = props;

  const { data: feesRawData } = useContractRead({
    enabled: !!contractAddress && !!tokenId,
    address: configs.royaltyRegistryContractAddress,
    abi: RoyaltyRegistryABI,
    functionName: 'getRoyalties',
    getFunction: (contract) => contract.callStatic.getRoyalties,
    args: [contractAddress, tokenId],
  });

  const { data: daoFeeRawData } = useContractRead({
    address: configs.marketplaceContractAddress,
    abi: MarketplaceABI,
    functionName: 'daoFee',
    args: [],
  });

  type IFess = {
    graviton: number;
    seeder?: number;
    creator?: number;
    collection: Array<[string, number]>;
    other: Array<[string, number]>;
    universeProtocol: number;
  }

  const fees = useMemo<IFess>(() => {
    const creatorFee = (feesRawData?.nftRoyalties?.[0]?.value?.toNumber() || 0) / 100 / 100;
    const seederFee = (feesRawData
      ?.nftRoyalties
      ?.find((NFTRoyalty) => NFTRoyalty?.account?.toLowerCase() === configs.seederContractAddress.toLowerCase())
      ?.value
      ?.toNumber() ?? 0) / 100 / 100;

    const collectionFees = feesRawData?.collectionRoyalties?.map((collectionRoyalty) => ([
      collectionRoyalty?.account,
      ((collectionRoyalty?.value?.toNumber() ?? 0) / 100 / 100),
    ]));

    const otherFees = feesRawData?.nftRoyalties?.reduce((acc, NFTRoyalty, i) => {
      if (i === 0) {
        return acc;
      }

      if (NFTRoyalty?.account?.toLowerCase() === configs.seederContractAddress.toLowerCase()) {
        return acc;
      }

      acc.push([NFTRoyalty?.account, ((NFTRoyalty?.value?.toNumber() ?? 0) / 100 / 100)]);

      return acc;
    }, []);

    const totalNFTsFees = creatorFee + seederFee +
      (collectionFees?.reduce((acc, collectionFee) => acc + collectionFee[1], 0) ?? 0) +
      (otherFees?.reduce((acc, otherFee) => acc + otherFee[1], 0) ?? 0);

    const daoFee = (daoFeeRawData?.toNumber() ?? 0) / 100 / 100;
    const calculatedDaoFee = (daoFee) * (1 - totalNFTsFees);

    return {
      graviton: 0,
      creator: creatorFee,
      seeder: seederFee,
      collection: collectionFees,
      other: otherFees,
      universeProtocol: calculatedDaoFee,
    };
  }, [feesRawData, daoFeeRawData]);

  const [totalOtherFees, totalCollectionFees] = useMemo(() => {
    const totalOtherFees = fees?.other?.reduce((acc, fee) => acc + fee[1], 0);
    const totalCollectionFees = fees?.collection?.reduce((acc, fee) => acc + fee[1], 0);

    return [totalOtherFees, totalCollectionFees];
  }, [fees]);

  return (
    <VStack
      align={'stretch'}
      className={cn(s.Wrapper)}
      divider={<StackDivider className={s.StackDivider} />}
      spacing={'8px'}
    >
      <NFTFee label={'Graviton'} value={fees.graviton} />
      {!fees.seeder ? null : (
        <NFTFee label={'Seeder'} value={fees.seeder} />
      )}
      {!fees.creator ? null : (
        <NFTFee label={'Creator'} value={fees.creator} />
      )}
      {!fees.collection?.length ? null : (
        <NFTFee label={'Collection'} value={totalCollectionFees}>
          {fees.collection.map(([address, value], i) => (
            <NFTSubFee key={i} address={formatAddress(address)} value={value} />
          ))}
        </NFTFee>
      )}
      {!fees.other?.length ? null : (
        <NFTFee label={'Other'} value={totalOtherFees}>
          {fees.other.map(([address, value], i) => (
            <NFTSubFee key={i} address={formatAddress(address)} value={value} />
          ))}
        </NFTFee>
      )}
      <NFTFee
        icon={<Icon icon={'fee'} />}
        label={'Universe protocol'}
        value={fees.universeProtocol}
      />
    </VStack>
  );
};

export interface INFTFeeProps extends BoxProps {
  icon?: React.ReactNode;
  label: string;
  value: number | string;
  children?: React.ReactNode;
}

export const NFTFee: React.FC<INFTFeeProps> = (props) => {
  const { icon, label, value, children, ...rest } = props;

  const renderFee = () => (
    <Flex alignItems={'center'} gap={'4px'} flex={1} {...rest}>
      {icon}
      <Text className={s.FeeLabel}>{label}</Text>
      <Text className={s.FeeValue}>{typeof value === 'number' ? `${toFixed(value * 100)}%` : value}</Text>
    </Flex>
  );

  return (
    <>
      {!children ? renderFee() : (
        <Accordion allowToggle={true}>
          <AccordionItem className={s.AccordionItem}>
            {({ isExpanded }) => (
              <>
                <AccordionButton className={s.AccordionButton}>
                  {renderFee()}
                  <Icon
                    icon={'chevronUp'}
                    className={s.AccordionButton_icon}
                    transform={!isExpanded ? 'rotate(180deg)' : undefined}
                  />
                </AccordionButton>
                <AccordionPanel className={s.AccordionPanel}>
                  <VStack
                    align={'stretch'}
                    divider={<StackDivider className={s.StackDivider} />}
                    spacing={'6px'}
                  >
                    {children}
                  </VStack>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        </Accordion>
      )}
    </>

  );
}

export interface INFTSubFeeProps {
  address: string;
  value: number;
}

export const NFTSubFee: React.FC<INFTSubFeeProps> = (props) => {
  const { address, value } = props;
  return (
    <Flex alignItems={'center'}>
      <Text className={s.SubFeeLabel}>{address}</Text>
      <Text className={s.SubFeeValue}>{value * 100}%</Text>
    </Flex>
  );
}