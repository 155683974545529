export const getFileFromUrl = async ({
  url,
  name,
  defaultType = 'image/jpeg',
}: {
  url: string;
  name: string;
  defaultType: string;
}) => {
  //
  const response = await fetch(url);
  const data = await response.blob();
  return new File([data], name, {
    type: data.type || defaultType,
  });
};
