import { mode } from '@chakra-ui/theme-tools';

export const Modal = {
  // The styles all Cards have in common
  parts: [
    'overlay',
    'closeButton',
    'dialog',
    'dialogContainer',
    'body',
    'header',
    'footer',
  ],

  baseStyle: (props) => ({
    header: {
      padding: '20px',
    },

    overlay: {
      backgroundColor: 'rgba(17, 13, 33, 0.4)',
      backdropFilter: 'blur(10px)',
    },

    closeButton: {
      position: 'absolute',
      top: '16px',
      right: '16px',
    },
  }),

  sizes: {
    //md: {},
    //lg: {},
  },

  variants: {
    gradient: (props) => ({
      dialog: {
        backgroundColor: mode('white', 'dark')(props),
        border: '1px solid',
        borderColor: mode('whiteTransparent.10', 'whiteTransparent.20')(props),
        borderRadius: '20px',
        boxShadow: '0px 4px 73px rgba(0, 0, 0, 0.2)',
        overflow: 'hidden',

        _before: {
          content: "''",
          position: 'absolute',
          left: '-65px',
          bottom: '-450px',
          display: 'block',
          width: '600px',
          height: '600px',
          background:
            'radial-gradient(50% 50% at 50% 50%, rgba(255, 90, 189, 0.3) 0%, rgba(255, 90, 189, 0) 100%)',
          zIndex: '-1',
        },

        _after: {
          content: "''",
          position: 'absolute',
          right: '-225px',
          bottom: '-320px',
          display: 'block',
          width: '600px',
          height: '600px',
          background:
            'radial-gradient(50% 50% at 50% 50%, rgba(89, 138, 255, 0.3) 0%, rgba(89, 138, 255, 0) 100%)',
          zIndex: '-1',
        },
      },
    }),
  },
  // The default variant value
  defaultProps: {
    variant: 'gradient',
    //size: 'md',
  },
};
