import { Heading, SimpleGrid, Text, VStack } from '@chakra-ui/layout';
import { Box } from '@chakra-ui/react';
import React, { useMemo } from 'react';

import {
  NFTBasicInfo,
  NFTDetails,
  NFTDetailsItem,
  NFTDetailsModalStickyBar, NFTProperties,
} from '@/components/common';
import { Button } from '@/components/ui';
import { NFT, Playlist } from '@/types';
import { formatAddress } from '@/utils';

import s from './nft-modal-details-sidebar.module.sass';

interface INFTModalDetailsSidebarProps {
  NFT: NFT;
  playlists: Playlist[];
  onAddToPlaylist: () => void;
}

export const NFTModalDetailsSidebar: React.FC<INFTModalDetailsSidebarProps> = (
  props
) => {
  const { NFT, playlists, onAddToPlaylist } = props;

  const NFTInPlaylistAmount = useMemo(() => {
    return (playlists ?? []).reduce((acc, playlist) => {
      for (const asset of playlist?.assets ?? []) {
        try {
          const [contractAddress, tokenId] = asset.id.split('#');

          if (
            contractAddress.toLowerCase() ===
              NFT.contractAddress?.toLowerCase() &&
            tokenId.toLowerCase() === `${NFT.tokenId}`.toLowerCase()
          ) {
            return ++acc;
          }
        } catch (e: unknown) {}
      }
      return acc;
    }, 0);
  }, [NFT, playlists]);

  return (
    <>
      {/*Hidden for future*/}
      {/*<Tooltip hasArrow placement={'left'} label={'See on Gem'} fontSize={'12px'}>*/}
      {/*  <Link className={s.Gem} href={'https://www.gem.xyz/'} target={'_blank'}>*/}
      {/*    <Icon icon={'gem'} />*/}
      {/*  </Link>*/}
      {/*</Tooltip>*/}

      {!NFT ? null : (
        <NFTBasicInfo
          contractAddress={NFT.contractAddress}
          tokenId={NFT.tokenId}
          name={NFT.metadata?.name}
          description={NFT.metadata?.description}
        />
      )}

      <VStack spacing={'24px'}>
        <Box w={'100%'}>
          <Heading fontSize={'16px'} mb={'12px'}>
            NFT Details
          </Heading>
          <NFTDetails>
            <NFTDetailsItem
              label={'Contract Address'}
              value={formatAddress(NFT?.contractAddress ?? '')}
              copyData={NFT?.contractAddress}
            />
            <NFTDetailsItem label={'Token ID'} value={NFT?.tokenId} copyData={NFT?.tokenId} />
            <NFTDetailsItem label={'Network'} value={'Ethereum'} />
            <NFTDetailsItem label={'Token Standard'} value={NFT?.tokenType} />
          </NFTDetails>
        </Box>

        {NFT?.metadata?.attributes?.length && (
          <Box w={'100%'}>
            <Heading fontSize={'16px'} mb={'12px'}>
              Properties
            </Heading>
            <NFTProperties attributes={NFT.metadata?.attributes ?? []} />
          </Box>
        )}
      </VStack>

      <NFTDetailsModalStickyBar pt={'16px !important'}>
        <VStack spacing={'12px'}>
          {!!NFTInPlaylistAmount && (
            <Text className={s.AmountInPlaylist}>
              This NFT is currently in{' '}
              <strong>{NFTInPlaylistAmount} playlists</strong>
            </Text>
          )}
          <Button variant={'primary'} w={'100%'} onClick={onAddToPlaylist}>
            Add to Playlist
          </Button>
        </VStack>
      </NFTDetailsModalStickyBar>
    </>
  );
};
