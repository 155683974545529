import { Heading, Link, VStack } from '@chakra-ui/layout';
import {
  Box,
  Center,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useMemo, useState } from 'react';
import { useDebounce, useList } from 'react-use';

import { batchUpdatePlaylists } from '@/api/playlists';
import SuccessImage from '@/assets/images/success.png';
import {
  NFTDetailsModalHeader,
  NFTDetailsModalStickyBar,
} from '@/components/common';
import { useNftAsset } from '@/components/common/nft-card/hooks';
import { Button, Checkbox, Icon } from '@/components/ui';
import { NFT, Playlist } from '@/types';

import s from './nft-modal-add-to-playlist-sidebar.module.sass';

interface INFTModalAddToPlaylistSidebarProps {
  visible?: boolean;
  NFT: NFT;
  playlists: Playlist[];
  onAdded: () => void;
  onCancel: () => void;
  onAddNewPlaylist: () => void;
}

export const NFTModalAddToPlaylistSidebar: React.FC<
  INFTModalAddToPlaylistSidebarProps
> = (props) => {
  const { visible = true, NFT, onAdded, onCancel, onAddNewPlaylist } = props;

  const queryClient = useQueryClient();

  const { asset, preview } = useNftAsset(NFT);

  const [playlists, playlistsFns] = useList(
    props.playlists?.map((playlist) => {
      const isDisabled = !!playlist.assets.find((asset) => {
        return (
          asset.id.toLowerCase() ===
          `${NFT.contractAddress}#${NFT.tokenId}`.toLowerCase()
        );
      });

      return {
        ...playlist,
        isChecked: isDisabled || false,
        isDisabled: isDisabled,
      };
    }) ?? []
  );

  const { isLoading, isSuccess, mutateAsync } =
    useMutation(batchUpdatePlaylists);

  const [search, setSearch] = useState<string>('');
  const [debouncedSearch, setDebouncedSearch] = useState('');

  useDebounce(() => setDebouncedSearch(search), 500, [search]);

  const toggleAllPlaylists = useCallback(
    (newValue: boolean) => {
      playlists.forEach((playlist, i) => {
        if (!playlist.isDisabled) {
          playlistsFns.updateAt(i, {
            ...playlist,
            isChecked: newValue,
          });
        }
      });
    },
    [playlists, playlistsFns]
  );

  const handleSave = useCallback(async () => {
    try {
      await mutateAsync(
        playlists
          .filter((playlist) => playlist.isChecked && !playlist.isDisabled)
          .map(({ isChecked, isDisabled, ...playlist }) => ({
            ...playlist,
            assets: [
              ...playlist.assets,
              {
                id: `${NFT.contractAddress}#${NFT.tokenId}`,
                previewUrl: asset.url,
                url: preview,
              },
            ],
          }))
      );
      queryClient.refetchQueries(['allPlaylists']);
    } catch (e: unknown) {}
  }, [playlists]);

  const filteredPlaylists = useMemo(
    () =>
      playlists.filter((playlist) =>
        playlist.title.toLowerCase().includes(debouncedSearch.toLowerCase())
      ),
    [playlists, debouncedSearch]
  );

  const isAllPlaylistsChecked = useMemo(() => {
    return playlists.every((playlist) => playlist.isChecked);
  }, [playlists]);

  const selectedAmount = useMemo(() => {
    return playlists.reduce(
      (acc, playlist) => (playlist.isChecked ? ++acc : acc),
      0
    );
  }, [playlists]);

  const selectedNewAmount = useMemo(() => {
    return playlists.reduce(
      (acc, playlist) =>
        playlist.isChecked && !playlist.isDisabled ? ++acc : acc,
      0
    );
  }, [playlists]);

  return !visible ? null : !isSuccess ? (
    <>
      <NFTDetailsModalHeader>
        <HStack spacing={'8px'}>
          <Heading fontSize={'24px'}>Add to Playlist</Heading>
          <Box className={s.PlaylistsAmount}>
            <Box as={'span'}>{selectedAmount}</Box> / {playlists.length}
          </Box>
          <Link className={s.CreateNewPlaylist} onClick={onAddNewPlaylist}>
            <Icon icon={'plus'} boxSize={'16px'} />
            Create Playlist
          </Link>
        </HStack>
      </NFTDetailsModalHeader>

      <InputGroup size={'md'} mb={'16px'}>
        <InputLeftElement pointerEvents="none">
          <Icon icon={'search'} opacity={0.4} />
        </InputLeftElement>
        <Input
          placeholder={'Find a playlist'}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </InputGroup>

      <VStack spacing={0}>
        <Checkbox
          className={s.PlaylistItem}
          fontSize={'14px'}
          isChecked={isAllPlaylistsChecked}
          onChange={() => toggleAllPlaylists(!isAllPlaylistsChecked)}
        >
          <Box as={'span'}>Select all</Box>
          <Box as={'span'} />
        </Checkbox>
        {filteredPlaylists.map((playlist, i) => (
          <Checkbox
            key={i}
            className={s.PlaylistItem}
            isDisabled={playlist.isDisabled}
            isChecked={playlist.isChecked}
            onChange={() =>
              playlistsFns.update((p) => p === playlist, {
                ...playlist,
                isChecked: !playlist.isChecked,
              })
            }
          >
            <Box as={'span'}>{playlist.title}</Box>
            <Box as={'span'}>{playlist.assets.length}</Box>
          </Checkbox>
        ))}
      </VStack>

      <NFTDetailsModalStickyBar>
        <HStack spacing={'12px'}>
          <Button variant={'secondary'} w={'100%'} onClick={onCancel}>
            Cancel
          </Button>
          <Button
            variant={'primary'}
            w={'100%'}
            disabled={!selectedNewAmount}
            isLoading={isLoading}
            onClick={handleSave}
          >
            Save
          </Button>
        </HStack>
      </NFTDetailsModalStickyBar>
    </>
  ) : (
    <>
      <Center h={'calc(100vh - 100px)'} textAlign={'center'}>
        <VStack spacing={'20px'}>
          <Image src={SuccessImage.src} boxSize={'64px'} />
          <Box>
            <Heading fontSize={'18px'} maxW={'280px'} mb={'6px'}>
              Your NFT was added to <strong>{selectedNewAmount}</strong>{' '}
              {selectedNewAmount > 1 ? 'playlists' : 'playlist'}.
            </Heading>
          </Box>
        </VStack>
      </Center>
      <NFTDetailsModalStickyBar>
        <Button variant={'secondary'} w={'100%'} onClick={onAdded}>
          Close
        </Button>
      </NFTDetailsModalStickyBar>
    </>
  );
};
