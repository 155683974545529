import { NetworkID } from '@/types';

interface IConfigs {
  environment: 'dev' | 'alpha' | 'prod';
  networkChainId: NetworkID;
  passwordProtect: boolean;
  passwordProtectPassword: string;
  alchemyApiKey: string;
  sentryDsn: string;
  reservoirApiKey: string;
  amplitudeApiKey: string;
  googleAnalyticsMeasurementId: string;
  royaltyRegistryContractAddress: string;
  seederContractAddress: string;
  marketplaceContractAddress: string;
  NFTDisplayApiUrl: string;
  NFTTorrentApiUrl: string;
  NFTEmbedUrl: string;
  etherscanAddressUrl: string;
  etherscanTxUrl: string;
  reservoirApiUrl: string;
  featuresFlags: {
    enableNFTDisplay: boolean;
    enableNFTTorrent: boolean;
    enableNFTStorage: boolean;
    enableProPlanSubscription: boolean;
  };
}

const configsMap: Record<NetworkID, IConfigs> = {
  [NetworkID.MAINNET]: {
    environment: process.env.ENVIRONMENT as IConfigs['environment'],
    networkChainId: Number(process.env.NETWORK_CHAIN_ID) as NetworkID,
    passwordProtect: process.env.PASSWORD_PROTECT === 'true',
    passwordProtectPassword: process.env.PASSWORD_PROTECT_PASSWORD,
    alchemyApiKey: process.env.ALCHEMY_API_KEY,
    sentryDsn: process.env.SENTRY_DSN,
    reservoirApiKey: process.env.RESERVOIR_API_KEY,
    amplitudeApiKey: process.env.AMPLITUDE_API_KEY,
    googleAnalyticsMeasurementId: process.env.GOOGLE_ANALYTICS_MEASUREMENT_ID,
    royaltyRegistryContractAddress: '0x2A9647901684caaeC6f026eEc0b1355195313cB5',
    seederContractAddress: '0x5cf95cBA4B398de28aFA683804Ef7CB0D56e8F25',
    marketplaceContractAddress: '0xB720279a6fc982721771427aA3B6B3a135f81dE3',
    NFTDisplayApiUrl: 'https://nft-display-backend.graviton.xyz',
    NFTTorrentApiUrl: 'https://prod.nft-torrent-backend.graviton.xyz',
    NFTEmbedUrl: 'https://nftembed.org',
    etherscanAddressUrl: 'https://etherscan.io/address/',
    etherscanTxUrl: 'https://etherscan.io/tx/',
    reservoirApiUrl: 'https://api.reservoir.tools',
    featuresFlags: {
      enableNFTDisplay: true,
      enableNFTTorrent: true,
      enableNFTStorage: false,
      enableProPlanSubscription: false,
    },
  },
  [NetworkID.GOERLI]: {
    environment: process.env.ENVIRONMENT as IConfigs['environment'],
    networkChainId: Number(process.env.NETWORK_CHAIN_ID) as NetworkID,
    passwordProtect: process.env.PASSWORD_PROTECT === 'true',
    passwordProtectPassword: process.env.PASSWORD_PROTECT_PASSWORD,
    alchemyApiKey: process.env.ALCHEMY_API_KEY,
    sentryDsn: process.env.SENTRY_DSN,
    reservoirApiKey: process.env.RESERVOIR_API_KEY,
    amplitudeApiKey: process.env.AMPLITUDE_API_KEY,
    googleAnalyticsMeasurementId: process.env.GOOGLE_ANALYTICS_MEASUREMENT_ID,
    royaltyRegistryContractAddress: '0xAE6088Ff813067db204752946256405ab63Eb93B',
    seederContractAddress: '0xba40FA88814A0a5608264712ce7C353cc29f3BeE',
    marketplaceContractAddress: '0xdE8C8fF3835c5E5cAe88b5b403ef56b9243381B1',
    NFTDisplayApiUrl: 'https://dev.nft-display-backend.graviton.xyz',
    NFTTorrentApiUrl: 'https://dev.nft-torrent-backend.graviton.xyz',
    NFTEmbedUrl: 'https://dev.nftembed.org',
    etherscanAddressUrl: 'https://goerli.etherscan.io/address/',
    etherscanTxUrl: 'https://goerli.etherscan.io/tx/',
    reservoirApiUrl: 'https://api-goerli.reservoir.tools',
    featuresFlags: {
      enableNFTDisplay: true,
      enableNFTTorrent: true,
      enableNFTStorage: false,
      enableProPlanSubscription: false,
    },
  },
};

export const configs: IConfigs = configsMap[Number(process.env.NETWORK_CHAIN_ID)];