import { useEffect, useState } from 'react';

import {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputProps,
  NumberInputStepper,
  useMultiStyleConfig,
} from '@chakra-ui/react';

interface InputNumberProps extends NumberInputProps {
  showStepper?: boolean;
  preffix?: string;
  suffix?: string;
  placeholder?: string;
}

export const InputNumber = (props: InputNumberProps) => {
  //
  const {
    value,
    defaultValue,
    showStepper = true,
    allowMouseWheel = true,
    preffix,
    suffix,
    placeholder,
    onChange,
    ...rest
  } = props;

  const [internalValue, setInternalValue] = useState(defaultValue || 0);

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const internalOnChange = (value) => {
    setInternalValue(value);
    onChange && onChange(value, Number(value));
  };

  const parseValue = (value) => {
    //
    if (preffix || suffix) {
      const regx = new RegExp(`\\b${preffix || suffix}\\b`, 'gi');
      return value.replace(regx, '');
    }

    return value;
  };

  const formatValue = (value) => {
    if (Number.isNaN(value)) return '';

    if (preffix) return formatPreffix(value);
    if (suffix) return formatSuffix(value);

    return value;
  };

  const formatPreffix = (val) => `${preffix}` + val;
  const formatSuffix = (val) => val + `${suffix}`;

  const styles = useMultiStyleConfig('InputNumber', props);

  return (
    <NumberInput
      allowMouseWheel={allowMouseWheel}
      value={internalValue}
      onChange={internalOnChange}
      parse={parseValue}
      format={formatValue}
      pattern={'.*'}
      {...rest}
    >
      <NumberInputField sx={styles.field} placeholder={placeholder} />

      {showStepper && (
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      )}
    </NumberInput>
  );
};