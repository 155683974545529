import { Heading, Text } from '@chakra-ui/layout';
import { Alert, Box, Checkbox, Flex, Link, VStack } from '@chakra-ui/react';
import React, { useMemo, useState } from 'react';

import {
  NFTDetailsModalHeader,
  NFTDetailsModalStickyBar,
  NFTProcessing,
} from '@/components/common';
import { NftAsset } from '@/components/common/nft-card';
import { Button, Icon } from '@/components/ui';
import {
  useAuth,
  useCollection,
  useContractRead,
  useContractWrite,
  useSendTransaction,
  useTokensUSDPrice,
  useWaitForTransaction,
} from '@/hooks';
import { IOrder, IToken, NFT, TokenTicker } from '@/types';
import { formatAddress } from '@/utils';

import { ReservoirAPI } from '@/api/reservoir';
import { ETH_TOKEN } from '@/constants';
import { ERC20 as ERC20ABI } from '@/contracts/abi/ERC20';
import { configs } from '@/configs';
import { Tooltip } from '@chakra-ui/tooltip';
import { useMutation, useQuery } from '@tanstack/react-query';
import BigNumber from 'bignumber.js';
import { BigNumber as EthersBigNumber, constants, utils } from 'ethers';

import { ETHERSCAN_LINK } from '@/constants';
import { MARKETPLACE_TOKENS_ADDRESS_MAP } from './../../constants';

import s from './nft-marketplace-buy-view.module.sass';

interface INFTMarketplaceBuyViewProps {
  NFT: NFT;
  order?: IOrder;
  onSuccessBuy: () => void;
  onClose: () => void;
}

type State = 'buy' | 'processing' | 'success' | 'error';

export const NFTMarketplaceBuyView: React.FC<INFTMarketplaceBuyViewProps> = (
  props
) => {
  const { NFT, order, onSuccessBuy, onClose } = props;

  const { address, provider } = useAuth();

  const [state, setState] = useState<State>('buy');
  const [checkBoxChecked, setCheckBoxChecked] = useState(false);
  const [tx, setTx] = useState<string>();

  const collection = useCollection(NFT?.contractAddress);

  const token = useMemo<IToken | undefined>(() => {
    if (!order) {
      return undefined;
    }

    if (order.take.assetType.assetClass === TokenTicker.ETH) {
      return ETH_TOKEN;
    }

    return MARKETPLACE_TOKENS_ADDRESS_MAP[
      order.take?.assetType?.contract?.toLowerCase()
    ];
  }, [order]);

  const [tokenUSD] = useTokensUSDPrice([token?.ticker]);

  const { data: balance, refetch: refetchBalance } = useContractRead({
    enabled: !!token && token.ticker !== TokenTicker.ETH,
    address: token?.address,
    abi: ERC20ABI,
    functionName: 'balanceOf',
    args: [address],
  });

  const { data: allowance, refetch: refetchAllowance } = useContractRead({
    enabled: !!token && token.ticker !== TokenTicker.ETH,
    address: token?.address,
    abi: ERC20ABI,
    functionName: 'allowance',
    args: [address, configs.marketplaceContractAddress],
  });

  const {
    data: approveTx,
    isLoading: isLoadingApprove,
    writeAsync: approve,
  } = useContractWrite({
    address: token?.address,
    abi: ERC20ABI,
    functionName: 'approve',
  });

  const { isFetching: isLoadingApproveTx } = useWaitForTransaction({
    tx: approveTx,
    onSuccess: async () => {
      await refetchBalance();
      await refetchAllowance();
    },
  });

  const { data: hasEnoughBalance } = useQuery({
    enabled: !!order,
    retry: false,
    cacheTime: 0,
    queryKey: ['hasEnoughBalance', balance],
    queryFn: async () => {
      const isETH =
        order?.take.assetType.assetClass === 'ETH' ||
        order?.take.assetType.type === 'ETH';

      if (!balance && !isETH) {
        return false;
      }

      let _balance = balance;

      if (isETH && !!provider) {
        _balance = await provider.getBalance(address);
      }

      const price = order?.take.value;
      const paymentAmount = EthersBigNumber.from(price);
      return paymentAmount.lte(_balance);
    },
  });

  const isTokensApproved = useMemo(() => {
    if (
      order?.take.assetType.assetClass === 'ETH' ||
      order?.take.assetType.type === 'ETH'
    ) {
      return true;
    }

    const price = order?.take.value;

    if (!token?.address || !price || isNaN(Number(price)) || !allowance) {
      return false;
    }

    const paymentAmount = EthersBigNumber.from(price);
    return !paymentAmount.gt(allowance);
  }, [order, token, allowance]);

  const price = useMemo(() => {
    if (!order || !token) {
      return null;
    }

    return new BigNumber(
      utils.formatUnits(order.take.value, token.decimals)
    ).toString();
  }, [order, token]);

  const formattedPrice = useMemo(() => {
    if (!order || !token) {
      return null;
    }

    const priceBN = new BigNumber(
      utils.formatUnits(order.take.value, token.decimals)
    );

    return priceBN.lt(0.01)
      ? '<0.01'
      : utils.formatUnits(order.take.value, token.decimals);
  }, [order, token]);

  const priceUSD = useMemo(() => {
    if (!order || !token) {
      return null;
    }

    const p = new BigNumber(
      utils.formatUnits(order.take.value, token.decimals)
    );
    return p.multipliedBy(tokenUSD).toFixed(2);
  }, [order, tokenUSD]);

  const { sendTransactionAsync } = useSendTransaction();

  const { mutateAsync: handleProceed } = useMutation({
    mutationFn: async () => {
      setState('processing');

      const { data, from, to, value } =
        await ReservoirAPI.prepareReservoirBuyOrderData(
          address,
          order.hash,
          `${1}`,
          order.take.assetType.contract ?? constants.AddressZero,
          'erc721',
          true
        );

      const tx = await sendTransactionAsync({
        data,
        from,
        to,
        value,
        // gasLimit: order.take.assetType.assetClass === TokenTicker.ETH ? 900_000 : undefined,
      });

      setTx(tx.hash);

      const result = await tx.wait();

      if (result.status == 1) {
        setState('success');
        onSuccessBuy();
      }
    },
    onError: () => {
      setState('error');
    },
  });

  const NFTName = NFT.metadata?.name ?? NFT.tokenId;

  return (
    <>
      <NFTDetailsModalHeader>
        <Heading fontSize={'24px'}>Checkout</Heading>
      </NFTDetailsModalHeader>

      {state === 'buy' && (
        <>
          <VStack align={'stretch'} spacing={'12px'}>
            <Box className={s.ColumnsNames}>
              <Text>Item</Text>
              <Text>Total</Text>
            </Box>

            <Box className={s.Item}>
              <NftAsset className={s.Preview} nft={NFT} />
              <Box flex={1}>
                <Text className={s.ItemName}>{NFTName}</Text>
                <Text className={s.ItemCollection}>
                  {collection?.name ?? ''}
                </Text>
              </Box>
              <Box textAlign={'right'}>
                <Tooltip
                  hasArrow
                  placement={'top'}
                  label={`${price} ${token?.ticker}`}
                >
                  <Text className={s.ItemPrice}>
                    {formattedPrice} {token?.ticker}
                  </Text>
                </Tooltip>
                <Text className={s.ItemUSDPrice}>${priceUSD}</Text>
              </Box>
            </Box>
          </VStack>

          <NFTDetailsModalStickyBar>
            <Alert mb={'16px'}>
              <Icon icon={'info'} boxSize={'20px'} />
              <Box as={'span'} flex={1} fontSize={'12px'} lineHeight={'16px'}>
                <Text mb={'8px'}>
                  Always verify on Etherscan to confirm that the contract
                  address is the same address as the project you are trying to
                  buy. Ethereum transactions are irreversible.
                </Text>
                <Text fontWeight={600}>
                  Etherscan:{' '}
                  <Link
                    href={`${ETHERSCAN_LINK.address}/${NFT?.contractAddress}`}
                    isExternal
                  >
                    {formatAddress(NFT?.contractAddress)}
                  </Link>
                </Text>
              </Box>
            </Alert>
            <Checkbox
              isChecked={checkBoxChecked}
              mb={'8px'}
              onChange={() => setCheckBoxChecked((state) => !state)}
            >
              <Box as={'span'} fontSize={'12px'}>
                By checking this box, I acknowledge the information above.
              </Box>
            </Checkbox>
            <Flex alignItems={'center'} gap={'16px'}>
              <Button variant={'secondary'} w={'100%'} onClick={onClose}>
                Cancel
              </Button>
              {!hasEnoughBalance ? (
                <Button variant={'primary'} w={'100%'} isDisabled={true}>
                  Not enough balance
                </Button>
              ) : !isTokensApproved ? (
                <Button
                  variant={'primary'}
                  w={'100%'}
                  isDisabled={
                    !checkBoxChecked || isLoadingApprove || isLoadingApproveTx
                  }
                  isLoading={isLoadingApprove || isLoadingApproveTx}
                  onClick={() =>
                    approve([
                      configs.marketplaceContractAddress,
                      utils.parseUnits(
                        utils.formatUnits(
                          order?.take.value,
                          token?.decimals ?? 18
                        ),
                        token?.decimals
                      ),
                    ])
                  }
                >
                  Approve
                </Button>
              ) : (
                <Button
                  variant={'primary'}
                  w={'100%'}
                  isDisabled={!checkBoxChecked}
                  onClick={() => handleProceed()}
                >
                  Purchase
                </Button>
              )}
            </Flex>
          </NFTDetailsModalStickyBar>
        </>
      )}

      {state === 'processing' && (
        <NFTProcessing
          state={'processing'}
          title={'Your purchase is processing...'}
          tx={tx}
          renderDescription={() => (
            <Text>
              Your purchase of <Box as={'span'}>{NFTName}</Box> is processing.
              It should be confirmed on the blockchain shortly.
            </Text>
          )}
          renderFooter={() => null}
        />
      )}

      {state === 'success' && (
        <>
          <NFTProcessing
            state={'success'}
            title={'Congratulations!'}
            tx={tx}
            renderDescription={() => (
              <Text>
                You have successfully bought the{' '}
                <Box as={'span'}>{NFTName}</Box>.
              </Text>
            )}
            onClose={onClose}
          />
        </>
      )}

      {state === 'error' && (
        <NFTProcessing
          state="failed"
          tx={tx}
          renderDescription={() => (
            <Text>Please refresh the page and try again.</Text>
          )}
          onClose={onClose}
        />
      )}
    </>
  );
};
