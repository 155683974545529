import { Icon } from '@/components/ui';
import { Box, Collapse as CollapseTransition, HStack } from '@chakra-ui/react';

import { useState } from 'react';

// import cn from 'classnames';
import styles from './collapse.module.sass';

type Item = {
  title: string | React.ReactNode;
  content: string | React.ReactNode;
};

interface Props {
  items: Item[];
}

export const Collapse = (props: Props) => {
  //
  const { items } = props;

  const [activeIndex, setActiveIndex] = useState<number>(null);

  const toggleCollapse = (index: number) => {
    //
    if (index === activeIndex) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  return (
    <Box className={styles.Wrapper}>
      {items.map((item, index) => {
        const isOpen = index === activeIndex;
        return (
          <Box
            key={index}
            className={styles.Item}
            onClick={() => toggleCollapse(index)}
          >
            <HStack justify="space-between" spacing="8px">
              <Box className={styles.Title}>{item.title}</Box>
              <Box className={styles.Button}>
                <Icon icon={isOpen ? 'toggle-close' : 'toggle-open'} />
              </Box>
            </HStack>
            <CollapseTransition in={isOpen} animateOpacity>
              <Box className={styles.Content}>{item.content}</Box>
            </CollapseTransition>
          </Box>
        );
      })}
    </Box>
  );
};
