import { AspectRatio } from '@chakra-ui/layout';
import React from 'react';
import { Skeleton } from '@/components/ui';

export const NFTSkeletonAsset: React.FC = () => {
  return (
    <AspectRatio ratio={1} pos={'relative'}>
      <Skeleton width={500} height={500} viewBox="0 0 500 500">
        <rect width="500" height="500" rx="12" />
      </Skeleton>
    </AspectRatio>
  );
};