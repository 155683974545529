module.exports = {
  imageRemotePatterns: [
    {
      protocol: 'https',
      hostname: '**.cloudfront.net',
    },
    {
      protocol: 'https',
      hostname: '**.moviebloc.com',
    },
    {
      hostname: 'arweave.net',
    },
    {
      hostname: 'ipfs.io',
    },
    {
      hostname: '**.pinata.cloud',
    },
    {
      hostname: '**.mypinata.cloud',
    },
    {
      hostname: 'storage.googleapis.com',
    },
    {
      hostname: 'res.cloudinary.com',
    },
    {
      hostname: '**.s3.amazonaws.com',
    },
    {
      hostname: 'ikzttp.mypinata.cloud',
    },
    {
      protocol: 'https',
      hostname: '**.alchemy.com',
    },
  ],
};
