import { Icon, IconProps } from '@chakra-ui/react';

export const PlaylistIcon = (props: IconProps) => {
  //
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.3501 3.0001C7.3501 2.64111 7.64111 2.3501 8.0001 2.3501H18.0001C20.0159 2.3501 21.6501 3.98426 21.6501 6.0001V16.0001C21.6501 16.3591 21.3591 16.6501 21.0001 16.6501C20.6411 16.6501 20.3501 16.3591 20.3501 16.0001V6.0001C20.3501 4.70223 19.298 3.6501 18.0001 3.6501H8.0001C7.64111 3.6501 7.3501 3.35908 7.3501 3.0001ZM2.3501 10.0001C2.3501 7.98426 3.98426 6.3501 6.0001 6.3501H14.0001C16.0159 6.3501 17.6501 7.98426 17.6501 10.0001V18.0001C17.6501 20.0159 16.0159 21.6501 14.0001 21.6501H6.0001C3.98426 21.6501 2.3501 20.0159 2.3501 18.0001V10.0001ZM6.0001 7.6501C4.70223 7.6501 3.6501 8.70223 3.6501 10.0001V18.0001C3.6501 19.298 4.70223 20.3501 6.0001 20.3501H14.0001C15.298 20.3501 16.3501 19.298 16.3501 18.0001V10.0001C16.3501 8.70223 15.298 7.6501 14.0001 7.6501H6.0001ZM8.6501 11.8686C8.6501 11.5891 8.96165 11.4223 9.19424 11.5774L12.3915 13.7089C12.5993 13.8474 12.5993 14.1528 12.3915 14.2913L9.19424 16.4228C8.96165 16.5779 8.6501 16.4111 8.6501 16.1316V11.8686ZM9.91535 10.4957C8.81884 9.76472 7.3501 10.5508 7.3501 11.8686V16.1316C7.3501 17.4494 8.81884 18.2355 9.91535 17.5045L13.1126 15.373C14.0922 14.7199 14.0922 13.2803 13.1126 12.6272L9.91535 10.4957Z"
        fill="black"
      />
      <defs>
        <linearGradient
          id="gradient"
          x1="2.323"
          y1="3.6129"
          x2="21.4124"
          y2="15.5111"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF7F69" />
          <stop offset="0.299586" stopColor="#FF2D83" />
          <stop offset="1" stopColor="#6695FF" />
        </linearGradient>
      </defs>
    </Icon>
  );
};
