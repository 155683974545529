export const parseNftUrl = (url: string | null | undefined): string => {
  if (!url) {
    return undefined;
  }

  if (url.startsWith('ipfs://ipfs')) {
    return url.replace('ipfs://', 'https://ipfs.io/');
  } else if (url.startsWith('ipfs://')) {
    return url.replace('ipfs://', 'https://ipfs.io/ipfs/');
  }

  return url;
};
