import { Skeleton } from '@/components/ui';
import { useTheme } from '@/hooks';
import { AspectRatio, Box, HStack } from '@chakra-ui/react';

import cn from 'classnames';
import styles from './nft-card.module.sass';

interface Props {
  type?: 'display' | 'torrent' | 'marketplace';
}

export const NftCardSkeleton = (props: Props) => {
  //
  const { type = 'display' } = props;

  const { themeClass } = useTheme();

  const isTorrent = type === 'torrent';
  const isMarketplace = type === 'marketplace';

  const ImageSkeleton = () => (
    <Skeleton width={330} height={330} viewBox="0 0 330 330">
      <path d="M0 12C0 5.37259 5.37258 0 12 0H318C324.627 0 330 5.37258 330 12V330H0V12Z" />
    </Skeleton>
  );

  const TitleSkeleton = () => (
    <Skeleton width={150} height={38} viewBox="0 0 150 38">
      <rect width="150" height="18" rx="9" />
      <rect y="25" width="75" height="13" rx="6.5" />
    </Skeleton>
  );

  const TokenSkeleton = () => (
    <Skeleton width={66} height={32} viewBox="0 0 66 32">
      <rect width="66" height="32" rx="8" />
    </Skeleton>
  );

  const ButtonLeft = () => (
    <Skeleton width={40} height={40} viewBox="0 0 40 40">
      <rect width="40" height="40" rx="8" />
    </Skeleton>
  );

  const ButtonSkeleton = () => (
    <Skeleton
      viewBox="0 0 250 40"
      style={{
        display: 'block',
        maxHeight: '40px',
        minHeight: '40px',
        width: '100%',
      }}
      preserveAspectRatio="none"
    >
      <rect width="100%" height="40" rx="8" />
    </Skeleton>
  );

  return (
    <>
      <Box className={cn(styles.WrapperSkeleton, styles[themeClass])}>
        <Box>
          <AspectRatio ratio={1}>
            <ImageSkeleton />
          </AspectRatio>
          <Box p="14px" pt="16px">
            <Box>
              <TitleSkeleton />
            </Box>

            {isTorrent && (
              <Box mt="14px">
                <HStack spacing="8px">
                  <Box>
                    <ButtonLeft />
                  </Box>
                  <Box width="100%">
                    <ButtonSkeleton />
                  </Box>
                </HStack>
              </Box>
            )}
          </Box>

          {isMarketplace && (
            <Box p="8px" pt="0px" mt="-2px">
              <Box width="100%">
                <ButtonSkeleton />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};
