import { Icon, IconProps } from '@chakra-ui/react';

export const XMarkIcon = (props: IconProps) => {
  //
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="14px"
      height="14px"
      viewBox="0 0 14 14"
      fill="none"
      display="block"
      {...props}
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.01678 3.01664C3.21204 2.82138 3.52862 2.82138 3.72388 3.01664L7.00038 6.29314L8.03634 5.25717C8.03975 5.25357 8.04321 5.25 8.04674 5.24647L10.2766 3.01665C10.4718 2.82139 10.7884 2.82139 10.9837 3.01665C11.1789 3.21191 11.1789 3.5285 10.9837 3.72376L9.76099 4.94644C9.75758 4.95004 9.75412 4.95361 9.75059 4.95714L7.70749 7.00024L10.9835 10.2763C11.1788 10.4715 11.1788 10.7881 10.9835 10.9834C10.7882 11.1786 10.4717 11.1786 10.2764 10.9834L7.00038 7.70735L3.72398 10.9837C3.52872 11.179 3.21214 11.179 3.01687 10.9837C2.82161 10.7885 2.82161 10.4719 3.01687 10.2766L6.29327 7.00024L3.01678 3.72375C2.82151 3.52849 2.82151 3.2119 3.01678 3.01664Z"
        fill="black"
      />
    </Icon>
  );
};
