import { useCallback } from 'react';

import { Box, VStack } from '@chakra-ui/react';
import { Connector } from '@web3-react/types';

import { Coinbase, MetaMask, WalletConnect } from './components';

import {
  trackConnectWallet,
  trackInitiateConnectWallet,
} from '@/utils/amplitude';

import cn from 'classnames';
import styles from './connect-wallet.module.sass';
import { useAuth } from '@/hooks';

export type ConnectorName = 'METAMASK' | 'COINBASE WALLET' | 'WALLETCONNET';

export interface IConnectorButton {
  onConnect: (connector: Connector, connectorName: ConnectorName) => void;
}

export const ConnectWallet = () => {
  const { login } = useAuth();

  const handleConnect = useCallback(async (connector: Connector, connectorName: ConnectorName) => {
    try {
      trackInitiateConnectWallet();
      await login(connector);
      trackConnectWallet(connectorName);
    } catch (error: unknown) {
      console.error('Wallet connect failed.', error);
    }
  }, []);

  return (
    <Box className={cn(styles.Wrapper)}>
      <Box className={styles.Title}>Connect Wallet</Box>
      <Box className={styles.Description}>Please pick a wallet to connect:</Box>
      <VStack spacing="15px" className={styles.Wallets}>
        <MetaMask onConnect={handleConnect} />
        <Coinbase onConnect={handleConnect} />
        <WalletConnect onConnect={handleConnect} />
      </VStack>
      <Box className={styles.Text}>
        We do not own your private keys and cannot access your funds without
        your confirmation.
      </Box>
    </Box>
  );
};
