import useDebouncedCallback from '@/hooks/use-debounced-callback';
import { useDisclosure } from '@chakra-ui/react';
import { createContext, useState } from 'react';

type ToggleMenuContextValue = ReturnType<typeof useDisclosure> & {
  isBusy: boolean;
};

export const ToggleMenuContext = createContext<ToggleMenuContextValue>(null);

export const ToggleMenuProvider = ({ children }) => {
  const [isBusy, setIsBusy] = useState(false);
  const resetBusy = useDebouncedCallback(() => setIsBusy(false), 300);
  const {
    isOpen,
    onClose: _onClose,
    onOpen: _onOpen,
    ...value
  } = useDisclosure();

  const updateValue = (value: boolean) => {
    setIsBusy(true);
    value ? _onOpen() : _onClose();
    resetBusy();
  };

  const onClose = () => updateValue(false);
  const onOpen = () => updateValue(true);
  const onToggle = () => updateValue(!isOpen);

  return (
    <ToggleMenuContext.Provider
      value={{ ...value, onClose, onOpen, isOpen, isBusy, onToggle }}
    >
      {children}
    </ToggleMenuContext.Provider>
  );
};
