import { CloseButton } from '@/components/common';
import { Box, HStack, useMultiStyleConfig, VStack } from '@chakra-ui/react';
import { ErrorIcon, InfoIcon, SuccessIcon } from './icons';

interface ToastProps {
  //
  id?: string | number;
  variant?: string;
  status?: StatusName;
  title: string;
  description?: string;
  isClosable?: boolean;
  accentColor?: string;
  width?: string | number;
  onClose?(): void;
  renderIcon?(): React.ReactNode;
}

type StatusName = 'error' | 'success' | 'info';

type StatusInfo = {
  icon: JSX.Element;
  color: string;
};

const STATUS_MAP: Record<StatusName, StatusInfo> = {
  error: {
    icon: <ErrorIcon />,
    color: '#ff4949',
  },
  success: {
    icon: <SuccessIcon />,
    color: '#1fc056',
  },
  info: {
    icon: <InfoIcon />,
    color: '#4d66eb',
  },
};

export const Toast = (props: ToastProps) => {
  //
  const {
    //id,
    variant,
    status = 'info',
    title,
    description,
    isClosable = true,
    accentColor,
    width,
    onClose,
    renderIcon,
  } = props;

  const color = accentColor || STATUS_MAP[status].color;

  const styles = useMultiStyleConfig('Toast', { variant, status, color });

  return (
    <Box __css={styles.wrapper} width={width}>
      <HStack spacing="20px">
        <Box __css={styles.icon}>
          {renderIcon ? renderIcon() : <>{STATUS_MAP[status].icon}</>}
        </Box>
        <VStack spacing="5px" align="left">
          <Box __css={styles.title}>{title}</Box>
          {description && <Box __css={styles.description}>{description}</Box>}
        </VStack>
      </HStack>

      {isClosable && (
        <Box __css={styles.closeButton}>
          <CloseButton onClick={onClose} />
        </Box>
      )}
    </Box>
  );
};
