import { useAuth } from '@/hooks/use-auth';
import { useQuery } from '@tanstack/react-query';
import { Contract } from 'ethers';

interface IUsePrepareContractReadOptions  {
  address: string;
  abi: any;
  functionName: string;
  getFunction?: (contract: Contract, functionName: string) => () => any;
  args: any[];
  enabled?: boolean;
  onSuccess?: (result: any) => void;
}

export const useContractRead = (options: IUsePrepareContractReadOptions) => {
  const { address, abi, functionName, getFunction, args = [], enabled = true, onSuccess } = options;

  const { signer } = useAuth();

  return useQuery({
    enabled: !!signer && enabled,
    queryKey: [address, functionName, args],
    queryFn: async () => {
      const contract = new Contract(address, abi, signer);

      const fn = getFunction ? getFunction(contract, functionName) : contract[functionName];

      const res = await fn(...args);

      return res;
    },
    onSuccess,
  });
};