import { BigNumber, utils } from 'ethers';

type Value = BigNumber | number | string;

export const formatTokenValue = (
  value: Value,
  declimals = 2,
  commify = true
) => {
  //
  const { formatUnits } = utils;

  if (typeof value === 'string' || BigNumber.isBigNumber(value)) {
    //
    const formatedValue = formatUnits(value, 18);
    const parsedValue = parseFloat(formatedValue);

    if (parsedValue === 0) {
      return '0';
    }

    let fixedValue;

    if (parsedValue % 1 === 0) {
      fixedValue = parsedValue.toFixed(0);
    } else {
      fixedValue = parsedValue.toFixed(declimals);
    }

    if (commify) {
      return utils.commify(fixedValue);
    }

    return fixedValue;
  }

  // if (typeof value === 'number') {
  //   const numberToSting = value.toString();
  //   return parseFloat(numberToSting).toFixed(declimals);
  // }

  // if (BigNumber.isBigNumber(value)) {
  //   return;
  // }
};
