import { mode } from '@chakra-ui/theme-tools';

export const Input = {
  baseStyle: (props) => ({
    field: {
      color: 'black',
      position: 'relative',
      width: '100%',
      fontWeight: 400,
      borderRadius: '10px',

      _placeholder: {
        color: mode('#A09EA6', '#6D6B75')(props),
      },

      _dark: {
        color: 'white',
      },
    },
  }),
  sizes: {
    sm: {
      field: {
        fontSize: '12px',
        height: '32px',
        paddingLeft: '12px',
        paddingRight: '12px',

        _placeholder: {
          fontSize: '12px',
        },
      },
    },

    md: {
      field: {
        fontSize: '14px',
        height: '40px',
        px: '14px',

        _placeholder: {
          fontSize: '14px',
        },
      },
    },

    lg: {
      field: {
        fontSize: '16px',
        height: '48px',
        px: '16px',

        _placeholder: {
          fontSize: '16px',
        },
      },
    },

    xl: {
      field: {
        fontSize: '24px',
        lineHeight: '32px',
        height: '64px',
        px: '16px',

        _placeholder: {
          fontSize: '24px',
        },
      },
    },
  },
  // variants
  variants: {
    ghost: (props) => ({
      field: {
        border: '1px solid',
        borderRadius: '10px',
        backgroundColor: mode('transparent', 'transparent')(props),
        borderColor: mode('blackTransparent.20', 'whiteTransparent.20')(props),
        transition: 'border-color 200ms linear, box-shadow 200ms linear',

        _hover: {
          borderColor: mode(
            'blackTransparent.40',
            'whiteTransparent.40'
          )(props),
        },

        _focus: {
          borderColor: mode(
            'blackTransparent.40',
            'whiteTransparent.40'
          )(props),
          boxShadow: mode(
            '0px 0px 0px 4px rgba(0, 0, 0, 0.10)',
            '0px 0px 0px 4px rgba(255, 255, 255, 0.10)'
          )(props),
        },

        _invalid: {
          borderColor: 'red',

          _focus: {
            borderColor: 'red',
          },
        },

        _placeholder: {
          color: mode('#939393', '#777580')(props),
        },
      },
    }),
  },
  // The default variant value
  defaultProps: {
    variant: 'ghost',
    size: 'md',
  },
};
