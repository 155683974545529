import { Icon, IconProps } from '@chakra-ui/react';

export const MenuIcon = (props: IconProps) => {
  return (
    <Icon
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      display="block"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.3501 5.99998C2.3501 5.64099 2.64111 5.34998 3.0001 5.34998H21.0001C21.3591 5.34998 21.6501 5.64099 21.6501 5.99998C21.6501 6.35896 21.3591 6.64998 21.0001 6.64998H3.0001C2.64111 6.64998 2.3501 6.35896 2.3501 5.99998ZM2.3501 12C2.3501 11.641 2.64111 11.35 3.0001 11.35H21.0001C21.3591 11.35 21.6501 11.641 21.6501 12C21.6501 12.359 21.3591 12.65 21.0001 12.65H3.0001C2.64111 12.65 2.3501 12.359 2.3501 12ZM2.3501 18C2.3501 17.641 2.64111 17.35 3.0001 17.35H21.0001C21.3591 17.35 21.6501 17.641 21.6501 18C21.6501 18.359 21.3591 18.65 21.0001 18.65H3.0001C2.64111 18.65 2.3501 18.359 2.3501 18Z"
        fill="currentColor"
      />
    </Icon>
  );
};
