import { Skeleton, Spinner, SpinnerProps } from '@/components/ui';
import { Box } from '@chakra-ui/react';

import styles from './nft-image-loading.module.sass';

interface Props extends SpinnerProps {
  type?: 'spinner' | 'skeleton';
}

export const NftImageLoading = (props: Props) => {
  //
  const { size = 'md', type = 'spinner' } = props;

  return (
    <Box className={styles.Wrapper}>
      {type === 'skeleton' && (
        <Skeleton viewBox="0 0 100 100" preserveAspectRatio="none">
          <rect width="100%" height="100%" />
        </Skeleton>
      )}
      {type === 'spinner' && (
        <Box className={styles.SpinnerWrapper}>
          <Spinner size={size} {...props} />
        </Box>
      )}
    </Box>
  );
};
