import { createContext, useState } from 'react';

// export interface PlayerContextData {

// }

export const PlayerContext = createContext(null);

export const PlayerProvider = (props) => {
  //
  const { multiplePlaing = false, children } = props;

  const [list, setList] = useState(null);

  const updateList = (player, reset) => {
    //
    if (multiplePlaing) {
      //
      setList((state) => {
        if (state) {
          return [...state, { player, reset }];
        } else {
          return [{ player, reset }];
        }
      });
      //
    } else {
      //
      setList((state) => {
        if (state) {
          state.player.current?.showPreview();
          state.reset();
        }
        return { player, reset };
      });
    }
  };

  const resetList = () => {
    if (multiplePlaing) {
      list?.length &&
        list.forEach(({ player, reset }) => {
          player.current?.showPreview();
          reset();
        });
    } else {
      if (list) {
        list.player.current?.showPreview();
        list.reset();
      }
    }
    setList(null);
  };

  const contextValue = {
    multiplePlaing,
    list,
    updateList,
    resetList,
  };

  return (
    <PlayerContext.Provider value={contextValue}>
      {children}
    </PlayerContext.Provider>
  );
};
