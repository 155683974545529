import { Box, HStack } from '@chakra-ui/react';

import { formatNumber } from '@/utils';

import { Icon } from '@/components/ui';
import cn from 'classnames';
import styles from './percentage-change.module.sass';

interface Props {
  value: number | null | undefined;
  positiveColor?: string;
  negativeColor?: string;
  emptyValue?: string | null;
  className?: string;
}

export const PercentageChange = (props: Props) => {
  //
  const {
    value,
    className,
    emptyValue = null,
    positiveColor = '#0eb051',
    negativeColor = '#ff4949',
  } = props;

  const returnValue = emptyValue ? <>{emptyValue}</> : null;

  if (value === undefined || value === null) return returnValue;

  const percentage = (value - 1) * 100;

  if (percentage > 100 || value === 0) {
    return returnValue;
  }

  if (value < 1) {
    return (
      <Box className={cn(styles.Value)} color={negativeColor}>
        <HStack spacing="4px">
          <Icon icon={{ name: 'arrow-down-right', size: 'md' }} />
          <Box>{formatNumber(percentage)}%</Box>
        </HStack>
      </Box>
    );
  }

  if (value > 1) {
    return (
      <Box className={cn(styles.Value, className)} color={positiveColor}>
        <HStack spacing="4px">
          <Icon icon={{ name: 'arrow-up-right', size: 'md' }} />
          <Box>+{formatNumber(percentage)}%</Box>
        </HStack>
      </Box>
    );
  }

  return returnValue;
};
