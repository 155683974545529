import ImageUploading, { ImageType } from 'fork-react-images-uploading';

import { CloseButton, PlayerAudio, PlayerVideo } from '@/components/common';
import { UploadIcon } from '@/components/icons';
import { Spinner, Tooltip } from '@/components/ui';
import { Box, Image } from '@chakra-ui/react';
import { File } from './components';

import { useTheme } from '@/hooks';
import { useEffect, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { getFileType } from '@/utils';

import cn from 'classnames';
import styles from './image-upload.module.sass';

interface Props {
  value?: ImageType[];
  name: string;
  type?: Type;
  width?: string;
  height?: string;
  text?: string | React.ReactNode | null;
  showTooltips?: boolean;
  showIcon?: boolean;
  acceptVideo?: boolean;
  acceptAudio?: boolean;
  accept3d?: boolean;
  acceptPdf?: boolean;
  acceptArchive?: boolean;
  acceptTypes?: string[];
  maxFileSize?: number;
  resolutionWidth?: number;
  resolutionHeight?: number;
  resolutionType?: ResolutionType;
  onChange?(images: ImageType[]): void;
  onError?(message: string): void;
}

type Type = 'area' | 'round';
type ResolutionType = 'absolute' | 'less' | 'more' | 'ratio';

export const ImageUpload = (props: Props) => {
  //
  const {
    value,
    name,
    text = null,
    onChange,
    onError,
    type = 'area',
    showTooltips = false,
    showIcon = true,
    acceptVideo = false,
    acceptAudio = false,
    accept3d = false,
    acceptArchive = false,
    acceptPdf = false,
    acceptTypes,
    maxFileSize = 1, // MB
    resolutionType = 'more',
    resolutionWidth = 0,
    resolutionHeight = 0,
  } = props;

  const { themeClass } = useTheme();

  const [images, setImages] = useState<ImageType[]>([]);

  const methods = useFormContext();

  const { control, setError, clearErrors } = methods;

  const { field } = useController({
    name,
    control,
  });

  const { onChange: formOnChange, onBlur: formOnBlur } = field;

  useEffect(() => {
    //
    if (value) {
      setImages(value);
    }
  }, [value]);

  // TODO: need refactoring

  const internalAcceptTypes = acceptTypes ?? [
    'jpg',
    'jpeg',
    'gif',
    'png',
    'webp',
  ];

  if (acceptVideo) {
    internalAcceptTypes.push('mp4');
    internalAcceptTypes.push('mkv');
  }

  if (acceptAudio) {
    internalAcceptTypes.push('mp3');
    internalAcceptTypes.push('wav');
  }

  if (accept3d) {
    internalAcceptTypes.push('fbx');
  }

  if (acceptArchive) {
    internalAcceptTypes.push('zip');
    internalAcceptTypes.push('rar');
  }

  if (acceptPdf) {
    internalAcceptTypes.push('pdf');
  }

  const isUploaded = images.length !== 0;
  const isArea = type === 'area';
  const isRound = type === 'round';
  const isMedia = acceptVideo || acceptAudio || accept3d;

  const internalOnChange = (imageList: ImageType[]) => {
    //
    setImages(imageList);
    onChange?.(imageList);
    //formOnChange?.(imageList);

    formOnBlur?.();
    clearErrors(name);
  };

  const internalOnError = (errors: any) => {
    //
    let message: string;
    const acceptedTypes = internalAcceptTypes.join(', ');

    if (errors.maxFileSize) {
      message = `Max file size exceeded. Please try again with a file less than ${maxFileSize} MB.`;
    } else if (errors.acceptType) {
      message = `Invalid file format. Acceptable formats include "${acceptedTypes}".`;
    } else if (errors.resolution) {
      message = `Max file resolution exceeded. Please try again with assets smaller than ${resolutionWidth} x ${resolutionHeight}.`;
    } else {
      message = 'Error! Something went wrong.';
      console.error(errors);
    }

    console.log('errors', errors);

    setError?.(name, { type: 'custom', message: message });
    onError?.(message);
  };

  const convertMegabytesToBytes = (value: number): number => {
    //
    const rate = 1048576;

    return value * rate;
  };

  // TODO: Switch validation to Object literals
  // function getErrorTitle(errorKey) {
  //   let errors = {
  //     maxFileSize: 'Max file size 2MB',
  //     acceptType: 'File forma is incorrect',
  //     default: 'Error! Something wrong',
  //   };
  //   let title = errors[errorKey] || errors['default'];
  //   return title;
  // }

  const getFile = (imageList) => {
    //
    const data_url = imageList[0]?.data_url;
    const file = imageList[0]?.file;

    if (data_url?.length > 0) {
      return data_url;
    }

    if (data_url?.length === 0 && file) {
      return URL.createObjectURL(file);
    }

    return null;
  };

  return (
    <Box>
      <ImageUploading
        multiple={false}
        value={images}
        onChange={internalOnChange}
        maxNumber={1}
        dataURLKey="data_url"
        acceptType={internalAcceptTypes}
        maxFileSize={convertMegabytesToBytes(maxFileSize)}
        resolutionType={isMedia ? null : resolutionType}
        resolutionWidth={isMedia ? null : resolutionWidth}
        resolutionHeight={isMedia ? null : resolutionHeight}
        onError={internalOnError}
        inputProps={{ name: name }}
        allowNonImageType={isMedia}
      >
        {({
          imageList,
          onImageUpload,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
          isLoading,
        }) => {
          //
          const { isVideo, isAudio, isImage, isUnknown } = getFileType(
            imageList[0]?.file?.type
          );

          const file = getFile(imageList);

          const isError = file === null;

          return (
            // write your building UI
            <Tooltip
              label="Upload image"
              placement="top"
              isDisabled={!showTooltips || isUploaded || isDragging}
            >
              <Box
                className={cn(
                  styles.Container,
                  styles[themeClass],
                  isArea && styles.Area,
                  isRound && styles.Round,
                  isUploaded && styles.Uploaded,
                  isDragging && styles.Dragging,
                  isLoading && styles.Loading
                )}
              >
                <Box className={styles.Loader}>
                  {isLoading && <Spinner size="md" />}
                </Box>

                <Box
                  className={styles.UploadArea}
                  onClick={onImageUpload}
                  {...dragProps}
                />

                {(showIcon || text) && !isUploaded && !isLoading && (
                  <Box className={styles.Content}>
                    {showIcon && (
                      <UploadIcon
                        className={cn(styles.Icon, styles.IconUpload)}
                      />
                    )}
                    {text && <Box className={styles.Text}>{text}</Box>}
                  </Box>
                )}

                {isUploaded && (
                  <Box className={styles.ImageContainer}>
                    {isError ? (
                      <Box className={styles.Error}>
                        Something went wrong...
                      </Box>
                    ) : (
                      <>
                        {isVideo && (
                          <Box className={styles.Video}>
                            <PlayerVideo url={file} />
                          </Box>
                        )}

                        {isImage && (
                          <Image
                            className={styles.Image}
                            src={file}
                            alt="Image"
                          />
                        )}

                        {isAudio && <PlayerAudio url={file} />}

                        {isUnknown && <File file={imageList[0]?.file} />}
                      </>
                    )}

                    <Tooltip label="Remove image" isDisabled={!showTooltips}>
                      <CloseButton
                        className={styles.Remove}
                        onClick={() => onImageRemove(0)}
                      />
                    </Tooltip>

                    {/* {isImage && (
                      <>
                        <Tooltip
                          label="Replace image"
                          isDisabled={!showTooltips}
                        >
                          <Box
                            className={cn(styles.Overlay)}
                            onClick={() => onImageUpdate(0)}
                          />
                        </Tooltip>

                        <Box className={cn(styles.EditIcon)}>
                          <PencilIcon />
                        </Box>
                      </>
                    )} */}
                  </Box>
                )}
              </Box>
            </Tooltip>
          );
        }}
      </ImageUploading>
    </Box>
  );
};

ImageUpload.displayName = 'ImageUpload';
