import {
  BrowseTorrentsIcon,
  MintTnftIcon,
  MyNftsIcon,
  PlaylistIcon,
  RewardsIcon,
  TnftsMarketplaceIcon,
} from '@/components/icons/menu';

const MENUS = {
  DISPLAY: 'display',
  TORRENT: 'torrent',
  STORAGE: 'storage',
};

const DISPLAY_MENU = [
  {
    title: 'My NFTs',
    href: '/display/nfts',
    icon: <MyNftsIcon />,
  },
  {
    title: 'Playlists',
    href: '/display/playlists',
    icon: <PlaylistIcon />,
  },
];

const TORRENT_MENU = [
  {
    title: 'My tNFTs',
    href: '/torrent/tnfts',
    icon: <MyNftsIcon />,
  },
  {
    title: 'Mint tNFT',
    href: '/torrent/mint',
    icon: <MintTnftIcon />,
  },
  {
    title: 'Royalties',
    href: '/torrent/royalties',
    icon: <RewardsIcon />,
  },
  {
    title: 'Browse Torrents',
    href: '/torrent/browse',
    icon: <BrowseTorrentsIcon />,
  },
  {
    title: 'tNFT Marketplace',
    href: '/torrent/marketplace',
    icon: <TnftsMarketplaceIcon />,
  },
];

const STORAGE_MENU = [
  {
    title: 'My NFTs',
    href: '/storage/nfts',
    icon: <MyNftsIcon />,
  },
];

export const MENUS_MAP = {
  [MENUS.DISPLAY]: DISPLAY_MENU,
  [MENUS.TORRENT]: TORRENT_MENU,
  [MENUS.STORAGE]: STORAGE_MENU,
};
