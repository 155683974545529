//import { mode } from '@chakra-ui/theme-tools';

export const primary = (props) => {
  //const { colorScheme } = props;

  const baseStyles = {
    position: 'relative',
    fontWeight: 600,
    color: 'white',
    borderRadius: '10px',
    background: 'purpleGradient',
    boxShadow:
      '0px 0px 0px 4px rgba(214, 74, 165, 0), inset 0px 6px 8px rgba(255, 255, 255, 0.4)',
    transition: 'all 200ms linear',
    zIndex: 1,

    _before: {
      content: '""',
      borderRadius: 'inherit',
      background: 'purpleGradientHover',
      boxShadow: 'inset 0px 6px 6px rgba(255, 255, 255, 0.45)',
      display: 'block',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      opacity: 0,
      width: '100%',
      zIndex: -1,
      transition: 'opacity 200ms linear',
    },

    _after: {
      content: '""',
      borderRadius: 'inherit',
      background: 'purpleGradientActive',
      boxShadow: 'inset 0px 6px 6px rgba(255, 255, 255, 0.45)',
      display: 'block',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      opacity: 0,
      width: '100%',
      zIndex: -1,
      transition: 'opacity 200ms linear',
    },

    _hover: {
      _before: {
        opacity: 1,
      },

      _disabled: {
        background: 'purpleGradient',

        _before: {
          opacity: 0,
        },
      },
    },

    _active: {
      _before: {
        opacity: 0,
      },

      _after: {
        opacity: 1,
      },

      _disabled: {
        background: 'purpleGradient',

        _before: {
          opacity: 0,
        },

        _after: {
          opacity: 0,
        },
      },
    },

    _focus: {
      boxShadow:
        '0px 0px 0px 4px rgba(214, 74, 165, 0.15), inset 0px 6px 8px rgba(255, 255, 255, 0.4)',
    },

    _disabled: {
      //opacity: 1,
      //background: '#4D6393',
      color: 'whiteTransparent.40',
    },
  };

  //const colorSchemeStyles = {};

  //const styles = colorSchemeStyles[colorScheme] || colorSchemeStyles.default;

  return {
    ...baseStyles,
    //...styles,
  };
};
