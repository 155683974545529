import { mode } from '@chakra-ui/theme-tools';

export const Menu = {
  baseStyle: (props) => ({
    list: {
      background: mode('white', 'dark')(props),
      border: '1px solid',
      borderColor: mode('blackTransparent.10', 'whiteTransparent.10')(props),
      boxShadow: '0px 0px 25px rgba(61, 30, 98, 0.15)',
      borderRadius: '10px',
      padding: '8px',
    },

    item: {
      borderRadius: '6px',
      fontSize: '14px',
      padding: '10px 12px',
      transition: 'background-color 200ms linear',

      _hover: {
        backgroundColor: mode(
          'blackTransparent.5',
          'whiteTransparent.5'
        )(props),
      },

      _focus: {
        backgroundColor: mode(
          'blackTransparent.5',
          'whiteTransparent.5'
        )(props),
      },
    },
  }),
};
