import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useAuth, useTheme } from '@/hooks';

import { Button, Icon, IconButton, Tooltip } from '@/components/ui';
import { Box, Flex, HStack } from '@chakra-ui/react';

import { MarketplaceMenu, NftAsset, TNFTSeeMoreDetails } from './components';
import { NftCardProvider } from './context';

import cn from 'classnames';
import dayjs from 'dayjs';
import styles from './nft-card.module.sass';
import { configs } from '@/configs';

// TODO: add type for NFT, refactor this component to single props
type NFT = any;

type Sizes = 'md' | 'sm';

export type NftCardType = 'display' | 'torrent' | 'marketplace';

export interface NftCardProps {
  nft?: NFT;
  type?: NftCardType;
  size?: Sizes;
  footer?: React.ReactNode;
  selectable?: boolean;
  isSelected?: boolean;
  selectedLabel?: string;
  disabled?: boolean;
  onClick?(NFT: NFT): void;
  onSelect?: (value: NFT) => void;
  onDeselect?: (value: NFT) => void;
}

const today = dayjs();

export const NftCard: React.FC<NftCardProps> = (props) => {
  //
  const {
    nft,
    type = 'display',
    size = 'md',
    selectable,
    isSelected,
    selectedLabel,
    disabled,
    onClick,
    onSelect,
    onDeselect,
  } = props;

  const { metadata } = nft;

  const { address } = useAuth();

  const isDisplay = type === 'display';
  const isTorrent = type === 'torrent';
  const isMarketplace = type === 'marketplace';

  const isOwner = useMemo(
    () => nft?.owner?.toLowerCase() === address?.toLowerCase(),
    [address, nft]
  );

  const isListed = useMemo(() => {
    //
    return nft?.reservoir?.orders.length > 0;
  }, [nft]);

  const listedPrice = useMemo(
    () => nft?.reservoir?.market.floorAsk.price?.amount.decimal,
    [nft]
  );

  const listedCurrency = useMemo(
    () => nft?.reservoir?.market.floorAsk.price?.currency.symbol,
    [nft]
  );

  const listedTimeLeft = useMemo(() => {
    if (nft?.reservoir?.market.floorAsk.validUntil) {
      //
      const validUntil = dayjs.unix(nft?.reservoir?.market.floorAsk.validUntil);
      const fromNow = dayjs(validUntil).fromNow(true);

      return fromNow;
    }
  }, [nft]);

  const lastListedPrice = useMemo(() => {
    if (nft?.reservoir?.market.floorAsk.price?.amount.decimal) {
      //
      return {
        price: nft?.reservoir?.market.floorAsk.price?.amount.decimal,
        currency: nft?.reservoir?.market.floorAsk.price?.currency.symbol,
      };
    }
  }, [nft]);

  const ref = useRef(null);
  const { themeClass } = useTheme();

  const [asset, setAsset] = useState(null);
  const [selected, setSelected] = useState<boolean>(false);

  useEffect(() => {
    setSelected(isSelected);
  }, [isSelected]);

  const onInternalClick = useCallback(() => {
    //
    if (selectable) {
      const value = {
        id: `${nft.contractAddress}#${nft.tokenId}`,
        asset: asset,
      };
      if (selected) {
        onDeselect?.(value);
      } else {
        onSelect?.(value);
      }
      setSelected((state) => !state);
    }
    //
    onClick?.(nft);
  }, [selected, selectable, nft, asset, onSelect, onDeselect, onClick]);

  const onAssetParsed = (asset) => {
    //
    setAsset(asset);
  };

  const isTNFT = useMemo(() => {
    return /^magnet:/.test(metadata?.external_url ?? '');
  }, [metadata]);

  return (
    <NftCardProvider>
      <Box
        ref={ref}
        className={cn(
          styles.Wrapper,
          styles[themeClass],
          selectable && selected && styles.isSelected,
          (selectable || onClick) && styles.isSelecteble,
          disabled && styles.isDisabled,
          isListed && styles.isShowActionButton,
          !isListed && isOwner && styles.isShowActionButton,
          styles[`Size--${size}`]
        )}
        onClick={() => onInternalClick()}
      >
        {selectable && (
          <Box
            className={cn(styles.SelectedLabel, selected && styles.isSelected)}
          >
            {selected && <>{selectedLabel}</>}
          </Box>
        )}

        <NftAsset
          className={styles.Asset}
          nft={nft}
          onAssetParsed={onAssetParsed}
        />

        <Box className={styles.Content}>
          <Box className={styles.TopContent}>
            <HStack spacing="8px" justifyContent="space-between">
              <HStack spacing="6px" className={styles.TitleWrapper}>
                {isDisplay && isTNFT && (
                  <Box>
                    <Icon icon="magnet-gradient" />
                  </Box>
                )}

                <Box className={styles.Title}>
                  {metadata?.name ?? nft.tokenId}
                </Box>
              </HStack>

              {isTorrent && (
                <Box className={styles.ActionButton}>
                  <TNFTSeeMoreDetails NFT={nft} />
                </Box>
              )}

              {isMarketplace && (
                <Box className={styles.ActionButton}>
                  <MarketplaceMenu magnetLink={metadata?.external_url} />
                </Box>
              )}
            </HStack>

            <Box className={styles.Collection}>
              {nft?.collection?.name ?? <>&nbsp;</>}
            </Box>
          </Box>

          <Box className={styles.Footer}>
            {isTorrent && (
              <HStack spacing="8px" p="14px" pt="0px">
                <Tooltip label={'List'}>
                  <IconButton
                    variant="secondary"
                    aria-label="Magnet Link"
                    icon={<Icon icon="dollar" />}
                    onClick={() =>
                      window.open(
                        `${configs.NFTEmbedUrl}/nft/${nft.contractAddress}/${nft.tokenId}/`,
                        '_blank'
                      )
                    }
                  />
                </Tooltip>

                <Tooltip label="Downloads single NFT Torrent magnet link for the single NFT seeding on NFT Torrent client">
                  <Button
                    variant="secondary"
                    size={'md'}
                    w="100%"
                    onClick={() =>
                      window.open(metadata?.external_url, '_blank')
                    }
                    leftIcon={<Icon icon="magnet" />}
                  >
                    Seed
                  </Button>
                </Tooltip>
              </HStack>
            )}

            {isMarketplace && (
              <Box className={styles.Listing}>
                <Flex gap="8px">
                  <Box className={styles.Listing__Wrapper}>
                    <HStack justifyContent="space-between">
                      <Box className={styles.Listing__Status}>
                        {isListed
                          ? `${listedPrice} ${listedCurrency}`
                          : 'Not listed'}
                      </Box>
                      <Box className={styles.Listing__Time}>
                        {isListed ? (
                          `${listedTimeLeft} left`
                        ) : (
                          <>
                            {size === 'md' &&
                              lastListedPrice &&
                              `Last: ${lastListedPrice.price} ${lastListedPrice.currency}`}
                          </>
                        )}
                      </Box>
                    </HStack>
                  </Box>

                  <Box className={styles.Listing__Action}>
                    {isListed && (
                      <Tooltip label="You own this item" isDisabled={!isOwner}>
                        <Button
                          variant="primary"
                          size={size}
                          disabled={isOwner}
                        >
                          Buy
                        </Button>
                      </Tooltip>
                    )}

                    {!isListed && isOwner && (
                      <Button variant="primary" size={size}>
                        List
                      </Button>
                    )}
                  </Box>
                </Flex>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </NftCardProvider>
  );
};
