import { useIsMobile } from '@/hooks';
import { MobileSidebar } from './mobile-sidebar';
import { Props as SidebarProps, SidebarLayout } from './sidebar-layout';
import styles from './sidebar.module.sass';

export const Sidebar = (props: SidebarProps) => {
  const isMobile = useIsMobile();
  return isMobile ? (
    <MobileSidebar {...props} />
  ) : (
    <SidebarLayout {...props} className={styles.Static} />
  );
};
