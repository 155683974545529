import { Icon, IconProps } from '@chakra-ui/react';

export const ChevronLeftIcon = (props: IconProps) => {
  return (
    <>
      <Icon
        xmlns="http://www.w3.org/2000/svg"
        width="12px"
        height="12px"
        viewBox="0 0 12 12"
        fill="none"
        display="block"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.7193 11.0298C8.0122 11.3227 8.48707 11.3227 8.77996 11.0298C9.07286 10.7369 9.07286 10.2621 8.77996 9.96918L7.7193 11.0298ZM3.74988 5.99976L3.21957 5.4694C3.07891 5.61005 2.99988 5.80082 2.99988 5.99974C2.99987 6.19866 3.07889 6.38943 3.21955 6.53009L3.74988 5.99976ZM8.78031 2.03035C9.07321 1.73747 9.07323 1.2626 8.78035 0.969691C8.48747 0.676786 8.0126 0.676767 7.71969 0.969648L8.78031 2.03035ZM8.77996 9.96918L4.28021 5.46943L3.21955 6.53009L7.7193 11.0298L8.77996 9.96918ZM4.28019 6.53011L8.78031 2.03035L7.71969 0.969648L3.21957 5.4694L4.28019 6.53011Z"
          fill="black"
        />
      </Icon>
    </>
  );
};
