import { Icon, IconProps } from '@chakra-ui/react';

export const BrowseTorrentsIcon = (props: IconProps) => {
  //
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1117 4.64998C7.54332 4.64998 4.65059 7.54271 4.65059 11.1111C4.65059 14.6795 7.54332 17.5722 11.1117 17.5722C12.8595 17.5722 14.4452 16.8782 15.6083 15.7508C15.628 15.7238 15.65 15.698 15.6743 15.6737C15.6986 15.6494 15.7244 15.6273 15.7514 15.6077C16.8788 14.4446 17.5728 12.8589 17.5728 11.1111C17.5728 7.54271 14.6801 4.64998 11.1117 4.64998ZM17.0401 16.1202C18.1835 14.7684 18.8728 13.0203 18.8728 11.1111C18.8728 6.82474 15.398 3.34998 11.1117 3.34998C6.82535 3.34998 3.35059 6.82474 3.35059 11.1111C3.35059 15.3974 6.82535 18.8722 11.1117 18.8722C13.0209 18.8722 14.769 18.1828 16.1208 17.0395L19.541 20.4596C19.7948 20.7134 20.2064 20.7134 20.4602 20.4596C20.714 20.2058 20.714 19.7942 20.4602 19.5404L17.0401 16.1202ZM10.4617 6.66664C10.4617 6.30766 10.7527 6.01664 11.1117 6.01664C13.9253 6.01664 16.2061 8.2975 16.2061 11.1111C16.2061 11.4701 15.9151 11.7611 15.5561 11.7611C15.1972 11.7611 14.9061 11.4701 14.9061 11.1111C14.9061 9.01547 13.2073 7.31664 11.1117 7.31664C10.7527 7.31664 10.4617 7.02563 10.4617 6.66664Z"
        fill="currentColor"
      />
    </Icon>
  );
};
