import { configs } from '@/configs';
import { App } from '@/modules';
import colors from '@/theme/colors';
import { withPasswordProtect } from '@storyofams/next-password-protect';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);

import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-datepicker/dist/react-datepicker.css';

import '@/sass/style.sass';

export default configs.passwordProtect
  ? withPasswordProtect(App, {
    loginComponentProps: {
      buttonBackgroundColor: colors.purpleGradient,
      buttonColor: 'white',
      logo: '/assets/icons/logo.png',
    },
  })
  : App;
