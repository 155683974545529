import React, { useCallback, useEffect, useState } from 'react';
import ReactPlayer from 'react-player';

import { IPlaylistAsset } from '@/types';

import { Image } from '@/components/ui';

import { PlaylistCardAssetError, PlaylistCardAssetLoading } from './components';

interface IPlaylistCardAssetProps {
  asset: IPlaylistAsset;
  playlistTitle: string;
  renderLoading?: () => React.ReactNode;
  renderError?: () => React.ReactNode;
  onLoaded?: () => void;
  onLoadError?: () => void;
}

export const PlaylistCardAsset: React.FC<IPlaylistCardAssetProps> = (props) => {
  //
  const {
    asset,
    playlistTitle,
    onLoaded,
    onLoadError,
    renderLoading = () => <PlaylistCardAssetLoading />,
    renderError = () => <PlaylistCardAssetError />,
  } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const isVideo = asset.url.includes('mp4');

  useEffect(() => {
    if (asset.url.length === 0) {
      onLoadingError();
    }
  }, [asset]);

  const onLoadingComplete = useCallback(() => {
    setIsLoading(false);
    onLoaded?.();
  }, [onLoaded]);

  const onLoadingError = useCallback(() => {
    setIsError(true);
    setIsLoading(false);
    onLoadError?.();
  }, [onLoadError]);

  const renders = {
    video: (asset: IPlaylistAsset) => (
      <ReactPlayer
        url={asset.url}
        width="100%"
        height="100%"
        muted={true}
        loop={true}
        playing={false}
        onReady={onLoadingComplete}
        onError={onLoadingError}
      />
    ),
    image: (asset: IPlaylistAsset) => (
      <Image
        width={272}
        height={272}
        src={asset.url}
        alt={playlistTitle}
        onLoaded={onLoadingComplete}
        onError={onLoadError}
      />
    ),
  };

  return (
    <>
      {isLoading && renderLoading()}
      {isError ? (
        renderError()
      ) : (
        <>{isVideo ? renders.video(asset) : renders.image(asset)}</>
      )}
    </>
  );
};
