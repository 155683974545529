import { NETWORK_CHAIN_ID, TOKENS_MAP } from '@/constants';
import { IToken, NetworkID } from '@/types';

const TOKENS_FOR_MAINNET: IToken[] = [
  TOKENS_MAP.ETH,
  TOKENS_MAP.WETH,
  TOKENS_MAP.AAVE,
  TOKENS_MAP.APE,
  TOKENS_MAP.ASTO,
  TOKENS_MAP.BOND,
  TOKENS_MAP.ENTR,
  TOKENS_MAP.FDT,
  TOKENS_MAP.ILV,
  TOKENS_MAP.LEAG,
  TOKENS_MAP.LINK,
  TOKENS_MAP.MANA,
  TOKENS_MAP.SAND,
  TOKENS_MAP.SNX,
  TOKENS_MAP.SYLO,
  TOKENS_MAP.XYZ,
];

const TOKENS_FOR_GOERLI: IToken[] = [
  TOKENS_MAP.ETH,
  TOKENS_MAP.WETH,
  TOKENS_MAP.AAVE,
  TOKENS_MAP.APE,
  TOKENS_MAP.ASTO,
  TOKENS_MAP.BOND,
  TOKENS_MAP.ENTR,
  TOKENS_MAP.FDT,
  TOKENS_MAP.ILV,
  TOKENS_MAP.LEAG,
  TOKENS_MAP.LINK,
  TOKENS_MAP.MANA,
  TOKENS_MAP.SAND,
  TOKENS_MAP.SNX,
  TOKENS_MAP.SYLO,
  TOKENS_MAP.XYZ,
];

const tokensNetworkMap: Record<NetworkID, IToken[]> = {
  [NetworkID.MAINNET]: TOKENS_FOR_MAINNET,
  [NetworkID.GOERLI]: TOKENS_FOR_GOERLI,
};

export const MARKETPLACE_TOKENS = tokensNetworkMap[NETWORK_CHAIN_ID].filter((token) => Boolean(token.address));

export const MARKETPLACE_TOKENS_ADDRESS_MAP = MARKETPLACE_TOKENS.reduce((acc, token) => {
  acc[token.address.toLowerCase()] = token;
  return acc;
}, {} as Record<string, IToken>);