import { Icon } from '@/components/ui';
import { Box, BoxProps, Center } from '@chakra-ui/react';

import cn from 'classnames';
import styles from './error.module.sass';

interface Props extends BoxProps {
  //
  className?: string;
  text?: string;
}

export const Error = (props: Props) => {
  //
  const { text = 'Something went wrong...', className, ...rest } = props;

  return (
    <Center>
      <Box className={cn(styles.Wrapper, className)} {...rest}>
        <Icon icon="exclamationMark" className={styles.Icon} />
        <Box className={styles.Text}>{text}</Box>
      </Box>
    </Center>
  );
};
