import { useContext } from 'react';
import { NftCardContext } from './nft-card-context';

function useNftCardContext() {
  //
  const context = useContext(NftCardContext);

  if (context === undefined || context === null) {
    throw new Error('useNftCardContext must be used within a NftCardProvider');
  }

  return context;
}

export { useNftCardContext };
