import { Icon, IconProps } from '@chakra-ui/react';

export const UploadIcon = (props: IconProps) => {
  //
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="35px"
      height="28px"
      viewBox="0 0 35 28"
      fill="none"
      display="block"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.75794 6.18136C9.02858 3.0102 12.243 -0.00012207 17.0492 -0.00012207C22.2152 -0.00012207 26.6176 3.52344 27.5637 9.23037C28.9371 9.42829 30.3794 9.95054 31.5632 10.8299C33.015 11.9083 34.0984 13.5484 34.0984 15.7384C34.0984 17.8654 33.2038 19.5543 31.7481 20.6868C30.3196 21.7982 28.4146 22.3289 26.4112 22.3289H21.329C20.7767 22.3289 20.329 21.8812 20.329 21.3289C20.329 20.7766 20.7767 20.3289 21.329 20.3289H26.4112C28.0858 20.3289 29.5244 19.8829 30.52 19.1083C31.4884 18.3549 32.0984 17.2486 32.0984 15.7384C32.0984 14.2912 31.4098 13.2073 30.3706 12.4354C29.3068 11.6452 27.8998 11.2096 26.6293 11.1467C26.1367 11.1223 25.7354 10.7424 25.6842 10.2519C25.1415 5.05945 21.4053 1.99988 17.0492 1.99988C12.9356 1.99988 10.3041 4.71481 9.44188 7.4039C9.31975 7.78479 8.9824 8.05627 8.58421 8.0941C4.87593 8.44638 2 10.7348 2 14.2137C2 17.7097 5.02077 20.3289 9.0246 20.3289H12.7694C13.3217 20.3289 13.7694 20.7766 13.7694 21.3289C13.7694 21.8812 13.3217 22.3289 12.7694 22.3289H9.0246C4.20137 22.3289 0 19.0794 0 14.2137C0 9.57212 3.68592 6.7964 7.75794 6.18136ZM16.3439 8.85221C16.7344 8.46169 17.3676 8.46169 17.7581 8.85221L22.0379 13.132C22.4284 13.5225 22.4284 14.1557 22.0379 14.5462C21.6473 14.9367 21.0142 14.9367 20.6237 14.5462L18.0492 11.9718V26.6784C18.0492 27.2307 17.6015 27.6784 17.0492 27.6784C16.4969 27.6784 16.0492 27.2307 16.0492 26.6784V11.9753L13.4783 14.5462C13.0878 14.9367 12.4546 14.9367 12.0641 14.5462C11.6736 14.1557 11.6736 13.5225 12.0641 13.132L16.3439 8.85221Z"
        fill="black"
      />
    </Icon>
  );
};
