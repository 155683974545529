import { configs } from '../../../configs';

export const UniverseXYZMerkleRewardsDistributor = {
  address: configs.seederContractAddress,
  abi: [
    {
      inputs: [
        { internalType: 'address', name: '_adminAddress', type: 'address' },
        { internalType: 'address', name: '_treasuryAddress', type: 'address' },
        { internalType: 'address', name: '_daoAddress', type: 'address' },
        { internalType: 'address', name: 'tokenAddress', type: 'address' },
        { internalType: 'address', name: 'routerAddress', type: 'address' },
      ],
      stateMutability: 'nonpayable',
      type: 'constructor',
    },
    { inputs: [], name: 'ClaimsDisabledUntilNextEpoch', type: 'error' },
    { inputs: [], name: 'IncorrectEpoch', type: 'error' },
    {
      inputs: [
        { internalType: 'uint256', name: 'required', type: 'uint256' },
        { internalType: 'uint256', name: 'actual', type: 'uint256' },
      ],
      name: 'InsufficientEthBalance',
      type: 'error',
    },
    {
      inputs: [
        { internalType: 'address', name: 'token', type: 'address' },
        { internalType: 'uint256', name: 'required', type: 'uint256' },
        { internalType: 'uint256', name: 'actual', type: 'uint256' },
      ],
      name: 'InsufficientTokenBalance',
      type: 'error',
    },
    { inputs: [], name: 'InvalidEpoch', type: 'error' },
    { inputs: [], name: 'InvalidMerkleProof', type: 'error' },
    {
      inputs: [{ internalType: 'string', name: 'msg', type: 'string' }],
      name: 'InvalidRequest',
      type: 'error',
    },
    { inputs: [], name: 'InvalidTreasuryFee', type: 'error' },
    { inputs: [], name: 'MismatchedArrayLengths', type: 'error' },
    { inputs: [], name: 'NoRewardsAvailable', type: 'error' },
    { inputs: [], name: 'NoTokensAttributed', type: 'error' },
    { inputs: [], name: 'OnlyAdmin', type: 'error' },
    { inputs: [], name: 'OnlyDao', type: 'error' },
    { inputs: [], name: 'RewardAlreadyClaimed', type: 'error' },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'from',
          type: 'address',
        },
        { indexed: true, internalType: 'address', name: 'to', type: 'address' },
      ],
      name: 'AdminAddressUpdated',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'epoch',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256',
        },
      ],
      name: 'Claimed',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'from',
          type: 'address',
        },
        { indexed: true, internalType: 'address', name: 'to', type: 'address' },
      ],
      name: 'DaoAddressUpdated',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'addr',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256',
        },
      ],
      name: 'DepositedTreasuryFees',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'endedEpochNum',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'timestamp',
          type: 'uint256',
        },
      ],
      name: 'EpochEnded',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'epochNumber',
          type: 'uint256',
        },
      ],
      name: 'EpochStarted',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'swapAmountOut',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'receivedTokens',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'epoch',
          type: 'uint256',
        },
      ],
      name: 'EthSwapped',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'bytes',
          name: 'newMerkleCDI',
          type: 'bytes',
        },
      ],
      name: 'MerkleProofCIDUpdated',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'from',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'to',
          type: 'uint256',
        },
      ],
      name: 'MinStakeAmountUpdated',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'previousOwner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'newOwner',
          type: 'address',
        },
      ],
      name: 'OwnershipTransferred',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'from',
          type: 'address',
        },
        { indexed: true, internalType: 'address', name: 'to', type: 'address' },
      ],
      name: 'RouterContractUpdated',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'from',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'to',
          type: 'uint256',
        },
      ],
      name: 'SwapTimeoutUpdated',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'swapAmountOut',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'receivedTokens',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'epoch',
          type: 'uint256',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'tokenAddress',
          type: 'address',
        },
      ],
      name: 'TokensSwapped',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'from',
          type: 'address',
        },
        { indexed: true, internalType: 'address', name: 'to', type: 'address' },
      ],
      name: 'TreasuryAddressUpdated',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'epoch',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256',
        },
      ],
      name: 'TreasuryFeeTaken',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'from',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'to',
          type: 'uint256',
        },
      ],
      name: 'TreasuryFeeUpdated',
      type: 'event',
    },
    {
      inputs: [],
      name: 'BPS_MAX',
      outputs: [{ internalType: 'uint16', name: '', type: 'uint16' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'adminAddress',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'betweenEpochs',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'uint256', name: 'amountToClaim', type: 'uint256' },
        { internalType: 'bytes32[]', name: 'merkleProof', type: 'bytes32[]' },
      ],
      name: 'claim',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
      name: 'claimed',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'uint256', name: '', type: 'uint256' },
        { internalType: 'address', name: '', type: 'address' },
      ],
      name: 'claimedTokensPerUser',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'claimsDisabled',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'currentEpoch',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'daoAddress',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'uint256', name: 'epochNumber', type: 'uint256' },
        { internalType: 'uint256', name: 'ethAttributed', type: 'uint256' },
        { internalType: 'address[]', name: 'ethSwapPath', type: 'address[]' },
        { internalType: 'uint256', name: 'ethReturnMin', type: 'uint256' },
        {
          internalType: 'address[]',
          name: 'tokensAttributed',
          type: 'address[]',
        },
        {
          internalType: 'uint256[]',
          name: 'amountsAttributed',
          type: 'uint256[]',
        },
        { internalType: 'address[][]', name: 'swapPaths', type: 'address[][]' },
        { internalType: 'uint256[]', name: 'amountsOutMin', type: 'uint256[]' },
      ],
      name: 'endEpoch',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'merkleRoot',
      outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'minStakeAmount',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'bytes32', name: 'newMerkleRoot', type: 'bytes32' },
        { internalType: 'bytes', name: 'newMerkleCDI', type: 'bytes' },
      ],
      name: 'nextEpoch',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'owner',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'renounceOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'routerContract',
      outputs: [
        {
          internalType: 'contract IUniswapV2Router02',
          name: '',
          type: 'address',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: '_adminAddress', type: 'address' },
      ],
      name: 'setAdminAddress',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: '_daoAddress', type: 'address' },
      ],
      name: 'setDaoAddress',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'uint256', name: '_minStakeAmount', type: 'uint256' },
      ],
      name: 'setMinStakeAmount',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: '_routerContract', type: 'address' },
      ],
      name: 'setRouterContract',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'uint256', name: '_swapTimeout', type: 'uint256' },
      ],
      name: 'setSwapTimeout',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: '_treasuryAddress', type: 'address' },
      ],
      name: 'setTreasuryAddress',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'uint16', name: '_treasuryFeeBps', type: 'uint16' },
      ],
      name: 'setTreasuryFeeBps',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'swapTimeout',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'tokenContract',
      outputs: [{ internalType: 'contract IERC20', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
      name: 'transferOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'treasuryAddress',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'treasuryFeeBps',
      outputs: [{ internalType: 'uint16', name: '', type: 'uint16' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'treasuryFees',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    { stateMutability: 'payable', type: 'receive' },
  ],
};
