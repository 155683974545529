import assert from 'assert';
import { constants } from 'ethers';
import { arrayify } from 'ethers/lib/utils';
import { IOrder } from '@/types';

export const prepareReservoirOrderSignature = async (actionStep: string, data: any, signer: any) => {
  assert(actionStep === 'Authorize listing' || actionStep === 'Authorize offer', 'Wrong actionStep string')

  const listingStep = data.steps.find((step: any) => step.action === actionStep)
  const firstListing = listingStep.items.find((item: any) => item.orderIndex === 0)
  const sign = firstListing.data.sign
  const post = firstListing.data.post

  assert(sign.signatureKind.toLowerCase() === 'eip191' ||
    sign.signatureKind.toLocaleLowerCase() === 'eip712',
    'Wrong signature type'
  )

  // Request user signature
  let signature
  if (sign.signatureKind.toLowerCase() === 'eip191') {
    signature = await signer.signMessage(arrayify(sign.message))
  } else if (sign.signatureKind.toLocaleLowerCase() === 'eip712') {
    signature = await signer._signTypedData(sign.domain, sign.types, sign.value)
  }

  return { signature, post }
}

export const convertReservoirToUniverseOrder = (reservoirOrder: any) => {
  const nftSide = {
    assetType: {
      contract: reservoirOrder.contract.toLowerCase(),
      tokenId: reservoirOrder.tokenSetId.split(':')[2],
    },
    value: reservoirOrder.quantityRemaining,
  };

  const tokenSide = {
    assetType: {
      ...(reservoirOrder.price.currency.contract !== constants.AddressZero && {
        contract: reservoirOrder.price.currency.contract,
      }),
      ...(reservoirOrder.price.currency.contract !== constants.AddressZero
        ? { assetClass: 'ERC20' }
        : { assetClass: 'ETH' }),
    },
    value: reservoirOrder.price.amount.raw,
  };

  const order = {
    id: reservoirOrder.id,
    hash: reservoirOrder.id,
    type: '',
    side: reservoirOrder.side === 'buy' ? 1 : 0,
    maker: reservoirOrder.maker,
    taker: reservoirOrder.taker,
    make: reservoirOrder.side === 'buy' ? tokenSide : nftSide,
    take: reservoirOrder.side === 'buy' ? nftSide : tokenSide,
    start: reservoirOrder.validFrom,
    end: reservoirOrder.validUntil,
    reservoirOrder: reservoirOrder,
    data: {
      dataType: 'ORDER_DATA',
      revenueSplits: reservoirOrder.feeBreakdown.map((fee: any)=> ({
        bps: fee.bps,
        kind: fee.kind,
        recipient: fee.recipient
      }))
    },
    feeBreakdown: reservoirOrder.feeBreakdown,
    feeBps: reservoirOrder.feeBps,
    fill: reservoirOrder.quantityFilled,
    makeStock: reservoirOrder.quantityRemaining,
    makeBalance: reservoirOrder.quantityRemaining,
    signature: reservoirOrder.rawData?.signature,
    source: reservoirOrder.source,
    createdAt: reservoirOrder.createdAt,
    updatedAt: reservoirOrder.createdAt,
    salt: reservoirOrder.rawData?.salt
  } as unknown as IOrder;

  return order;
};