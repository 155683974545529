import { parseNftUrl } from '@/utils';
import { useMemo } from 'react';
import { getNftPreviewImage } from '../utils';

// interface IUseAssetResult {
//   asset: {
//     url: string;
//     type: "image" | "video" | "audio";
//   };
//   preview?: string;
// }

export const useNftAsset = (nft) => {
  return useMemo(() => {
    //
    const previewUrl = getNftPreviewImage(nft);

    const animation_url = nft?.metadata?.animation_url;
    const alternative_image = nft?.alternativeMediaFiles?.find(
      (alternative) => alternative.type === 'image'
    )?.url;
    const image = nft?.metadata?.image;
    const image_original_url = nft?.metadata?.image_original_url;
    const image_preview_url = nft?.metadata?.image_preview_url;
    const image_url = nft?.metadata?.image_url;
    const image_thumbnail_url = nft?.metadata?.image_thumbnail_url;
    const assetUrl = nft?.assetUrl;

    const losslessAudio = nft?.metadata?.losslessAudio;

    let asset;

    const imageUrls = [
      assetUrl,
      previewUrl,
      alternative_image,
      image,
      image_url,
      image_original_url,
      image_preview_url,
      image_thumbnail_url,
    ];

    // regex to check if url contains .mp4 or .mkv
    const videoUrl = [animation_url, ...imageUrls].find(
      (url) => /.mp4$/.test(url) || /.mkv$/.test(url)
    );

    const imageUrl = imageUrls.find((url) => !!url);

    // CHECK IS VIDEO
    if (videoUrl) {
      asset = {
        url: parseNftUrl(videoUrl),
        type: 'video',
      };
      // CHECK IS AUDIO
    } else if (losslessAudio?.length) {
      //
      const audio_url = assetUrl;

      asset = {
        url: parseNftUrl(audio_url),
        type: 'audio',
      };
      // CHECK IS IMAGE
    } else if (imageUrl?.length) {
      //
      asset = {
        url: parseNftUrl(imageUrl),
        type: 'image',
      };
    } else {
      asset = {
        url: '',
        type: 'unknown',
      };
    }

    return {
      asset,
      preview: previewUrl ? parseNftUrl(previewUrl) : undefined,
      alt: nft?.metadata?.name ?? nft?.tokenId,
    };
  }, [nft]);
};
