import { useToggleMenu } from '@/hooks';
import useDebouncedCallback from '@/hooks/use-debounced-callback';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import { useClickAway, useKeyPress } from 'react-use';
import { Slide } from '../../slide';
import { Props as SidebarProps, SidebarLayout } from '../sidebar-layout';
import { WalletDropdown } from '@/components/common/header/components';

export const MobileSidebar = (props: SidebarProps) => {
  const { isOpen, onClose, isBusy } = useToggleMenu();
  const { asPath } = useRouter();
  const ref = useRef();

  const [escapePressed] = useKeyPress('Escape');

  const hideMenuIfNotBusy = useDebouncedCallback(() => {
    if (isOpen && !isBusy) onClose();
  }, 100);

  useEffect(() => {
    if (escapePressed) hideMenuIfNotBusy();
  }, [escapePressed, hideMenuIfNotBusy]);

  useEffect(() => {
    hideMenuIfNotBusy();
  }, [asPath, hideMenuIfNotBusy]);

  useClickAway(ref, () => {
    if (isOpen && !isBusy) hideMenuIfNotBusy();
  });

  return (
    <Slide visible={isOpen} ref={ref}>
      <WalletDropdown compact />
      <SidebarLayout {...props} />
    </Slide>
  );
};
