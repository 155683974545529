import { createContext, useState } from 'react';

export const NftCardContext = createContext(null);

export const NftCardProvider = (props) => {
  //
  const { children } = props;

  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isHovering, setIsHovering] = useState<boolean>(false);

  const contextValue = {
    isPlaying,
    isHovering,
    setIsPlaying,
    setIsHovering,
  };

  return (
    <NftCardContext.Provider value={contextValue}>
      {children}
    </NftCardContext.Provider>
  );
};
