import { Icon, IconProps } from '@chakra-ui/react';

export const AudioIcon = (props: IconProps) => {
  //
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="10px"
      height="12px"
      viewBox="0 0 10 12"
      fill="none"
      display="block"
      {...props}
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M9.74983 1.01254C9.74978 0.94954 9.73502 0.887424 9.70672 0.83114C9.67842 0.774857 9.63736 0.725963 9.58682 0.688357C9.53628 0.650751 9.47765 0.625473 9.41561 0.614538C9.35356 0.603602 9.28983 0.607312 9.22947 0.625372L3.83718 2.24306C3.75385 2.26799 3.68079 2.31912 3.62882 2.38887C3.57685 2.45861 3.54875 2.54325 3.54869 2.63023V7.95619C3.19889 7.71265 2.77694 7.59469 2.35155 7.62151C1.92616 7.64833 1.52238 7.81835 1.20593 8.10391C0.889491 8.38946 0.679025 8.77372 0.608801 9.19413C0.538577 9.61454 0.612731 10.0463 0.81919 10.4192C1.02565 10.7921 1.35225 11.0842 1.74583 11.2478C2.13941 11.4114 2.57679 11.437 2.98676 11.3203C3.39672 11.2037 3.75514 10.9517 4.00365 10.6054C4.25216 10.2591 4.37613 9.83892 4.35538 9.41319C4.35684 9.39904 4.35756 9.38482 4.35754 9.37059V5.08803L8.94098 3.713V6.87827C8.59118 6.63473 8.16924 6.51677 7.74385 6.54359C7.31846 6.57041 6.91467 6.74043 6.59823 7.02599C6.28178 7.31154 6.07132 7.6958 6.00109 8.11621C5.93087 8.53662 6.00502 8.96842 6.21148 9.34132C6.41794 9.71421 6.74455 10.0062 7.13813 10.1699C7.53171 10.3335 7.96908 10.3591 8.37905 10.2424C8.78902 10.1258 9.14743 9.87381 9.39594 9.52751C9.64445 9.18122 9.76842 8.761 9.74767 8.33527C9.74914 8.32094 9.74986 8.30654 9.74983 8.29213V1.01254Z"
        fill="white"
      />
    </Icon>
  );
};
