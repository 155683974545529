import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { fetchMarketplaceTNFT } from '@/api/marketplace';
import { ReservoirAPI } from '@/api/reservoir';
import { INFTModalProps, NFTModal } from '@/components/common';
import { tNFT } from '@/modules/torrent/types';
import { NFT } from '@/types';

import {
  NFTMarketplaceBuyView,
  NFTMarketplaceCancelListing,
  NFTMarketplaceChangePrice,
  NFTMarketplaceDetailsView,
  NFTMarketplaceListing,
  NFTMarketplaceSidebarSkeleton,
  NFTMarketplaceTransfer
} from './components';
import { useNFTOwner } from '@/hooks/use-nft-owner';

export interface INFTMarketplaceModalProps extends Omit<INFTModalProps, 'NFT' | 'children'> {
  NFT: tNFT | string;
  onNFTChanged?: (NFT: tNFT) => void;
}

export const NFTMarketplaceModal: React.FC<INFTMarketplaceModalProps> = (props) => {
  const { NFT: _NFT, onNFTChanged, ...rest } = props;

  const { data: tNFT } = useQuery<tNFT>({
    enabled: typeof _NFT === 'string',
    initialData: typeof _NFT === 'string' ? null : (_NFT as tNFT),
    queryKey: ['tNFT', _NFT],
    queryFn: async () => {
      const response = await fetchMarketplaceTNFT(_NFT as string);
      return response?.data?.[0] ?? null;
    },
  });

  const {
    data: order,
    isLoading: isLoadingOrder,
    refetch: refetchOrder,
  } = useQuery({
    enabled: !!tNFT,
    queryKey: [tNFT?.contractAddress, tNFT?.tokenId, 'orders'],
    queryFn: async () => {
      const orders = await ReservoirAPI.getListingsReservoir(tNFT?.contractAddress, tNFT?.tokenId);
      return orders?.[0] ?? null;
    },
  });

  const { refetch: refetchOwners } = useNFTOwner(tNFT?.contractAddress, tNFT?.tokenId);

  type IState = 'details' | 'buy' | 'cancelListing' | 'transfer' | 'listing'; // changePrice

  const [state, setState] = useState<IState>('details');

  const closeOnEsc = useMemo(() => state === 'details', [state]);

  const handleClickEsc = useCallback(() => {
    setState('details');
  }, []);

  useEffect(() => setState('details'), [props.isOpen]);

  const NFT = tNFT as unknown as NFT;

  const renders: Record<IState, () => React.ReactNode> = {
    details: () => (
      <NFTMarketplaceDetailsView
        NFT={tNFT}
        order={order}
        isLoadingOrder={isLoadingOrder}
        onBuy={() => setState('buy')}
        onCancelListing={() => setState('cancelListing')}
        onTransfer={() => setState('transfer')}
        onChangePrice={() => void 0} // setState('changePrice')
        onSell={() => setState('listing')}
      />
    ),
    transfer: () => (
      <NFTMarketplaceTransfer
        NFT={NFT}
        onSuccessTransfer={() => {
          refetchOwners();
          refetchOrder();
          onNFTChanged?.(tNFT);
        }}
        onClose={() => setState('details')}
      />
    ),
    listing: () => (
      <NFTMarketplaceListing
        NFT={tNFT}
        onSuccessListing={() => {
          refetchOrder();
          onNFTChanged?.(tNFT);
        }}
        onClose={() => setState('details')}
      />
    ),
    cancelListing: () => (
      <NFTMarketplaceCancelListing
        NFT={NFT}
        order={order}
        onSuccessCancelListing={() => {
          refetchOrder();
          onNFTChanged?.(tNFT);
        }}
        onClose={() => setState('details')}
      />
    ),
    buy: () => (
      <NFTMarketplaceBuyView
        NFT={NFT}
        order={order}
        onSuccessBuy={() => {
          refetchOwners();
          refetchOrder();
          onNFTChanged?.(tNFT);
        }}
        onClose={() => setState('details')}
      />
    ),
    // changePrice: () => (
    //   <NFTMarketplaceChangePrice
    //     NFT={NFT}
    //     order={order}
    //     onSuccess={() => {
    //       refetchOrder();
    //       onNFTChanged?.(tNFT);
    //     }}
    //     onClose={() => setState('details')}
    //   />
    // ),
  };

  return (
    <NFTModal
      NFT={NFT}
      closeOnEsc={closeOnEsc}
      onEsc={closeOnEsc ? undefined : handleClickEsc}
      {...rest}
    >
      {!NFT ? <NFTMarketplaceSidebarSkeleton /> : renders[state]()}
    </NFTModal>
  );
};