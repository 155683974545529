import copy from 'copy-to-clipboard';
import { useEffect, useState } from 'react';

function useClipboard() {
  //
  const [value, setValue] = useState<string>();
  const [hasCopied, setHasCopied] = useState<boolean>(false);

  const timeout = 1500;

  const handleCopy = (value: string) => {
    //
    setHasCopied(true); // update state value
    setValue(value); // update state value
    copy(value); // copy value to clipboard
  };

  useEffect(() => {
    let timeoutId: number | null = null;

    if (hasCopied) {
      timeoutId = window.setTimeout(() => {
        setHasCopied(false);
      }, timeout);
    }

    return () => {
      if (timeoutId) {
        window.clearTimeout(timeoutId);
      }
    };
  }, [timeout, hasCopied]);

  return {
    value,
    hasCopied,
    handleCopy,
  };
}

export { useClipboard };
