import { mode } from '@chakra-ui/theme-tools';

export const Select = {
  // The styles all Cards have in common
  parts: [
    'wrapper',
    'select',
    'arrow',
    'placeholder',
    'dropdown',
    'list',
    'option',
    'selectedOption',
  ],

  baseStyle: (props) => ({
    wrapper: {
      position: 'relative',
    },

    select: {
      color: 'black',
      display: 'inline-flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      verticalAlign: 'middle',
      userSelect: 'none',
      appearance: 'none',
      width: '100%',
      fontSize: '14px',
      fontWeight: 400,
      transition: 'border-color 200ms linear, box-shadow 200ms linear',
      outline: 'none',

      _invalid: {
        borderColor: 'red',
      },

      _disabled: {
        cursor: 'not-allowed',
      },

      _dark: {
        color: 'white',
      },
    },

    selectedOption: {
      // text-truncate
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },

    arrow: {
      marginLeft: '8px',
      flexShrink: 0,
      opacity: '40%',
      transform: 'scaleY(-1)',
      transition: 'transform 100ms linear',

      path: {
        fill: mode('dark', 'white')(props),
      },

      _expanded: {
        transform: 'scaleY(1)',
      },
    },

    dropdown: {
      width: '100%',
      minWidth: '100%',
      padding: '8px',
      paddingRight: '4px',
      borderRadius: '10px',
      border: '1px solid',
      borderColor: mode('blackTransparent.10', 'whiteTransparent.10')(props),
      backgroundColor: mode('white', 'dark')(props),
      boxShadow: '0px 0px 25px rgba(61, 30, 98, 0.15)',
    },

    list: {
      padding: 0,
      paddingRight: '4px',
      borderRadius: '6px',
      overflowY: 'auto',
      maxHeight: '276px',

      /* width */
      '::-webkit-scrollbar': {
        width: '8px',
      },

      /* Track */
      '::-webkit-scrollbar-track': {
        background: 'transparent',
        borderRadius: '4px',
      },

      /* Handle */
      '::-webkit-scrollbar-thumb': {
        backgroundColor: mode(
          'blackTransparent.5',
          'whiteTransparent.5'
        )(props),
        backgroundClip: 'padding-box',
        border: '1px solid transparent',
        borderRadius: '9999px',
      },

      /* Handle on hover */
      '::-webkit-scrollbar-thumb:hover': {
        backgroundColor: mode(
          'blackTransparent.20',
          'whiteTransparent.20'
        )(props),
      },
    },

    option: {
      color: 'black',
      listStyle: 'none',
      cursor: 'pointer',
      borderRadius: '6px',
      lineHeight: 0,
      fontWeight: 400,

      _hover: {
        backgroundColor: 'purpleLightTransparent.10',
      },

      _selected: {
        fontWeight: 500,
      },

      _dark: {
        color: 'white',
      },
    },

    placeholder: {
      color: mode('#A09EA6', '#6D6B75')(props),
      // text-truncate
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  }),
  sizes: {
    sm: {
      select: {
        fontSize: '14px',
        fontWeight: 700,
        height: '32px',
        paddingLeft: '10px',
        paddingRight: '10px',
      },

      arrow: {
        width: '14px',
        height: '14px',
      },

      option: {
        fontSize: '12px',
        padding: '6px',
        textAlign: 'center',
        wordWrap: 'normal',
      },
    },
    md: {
      select: {
        fontSize: '14px',
        height: '40px',
        paddingLeft: '14px',
        paddingRight: '14px',
      },

      arrow: {
        width: '16px',
        height: '16px',
      },

      option: {
        fontSize: '14px',
        padding: '10px 12px',
      },
    },

    lg: {
      select: {
        fontSize: '16px',
        height: '48px',
        paddingLeft: '16px',
        paddingRight: '16px',
      },

      arrow: {
        width: '18px',
        height: '18px',
      },

      option: {
        fontSize: '16px',
        padding: '12px 14px',
      },
    },
  },
  // Two variants: rounded and smooth
  variants: {
    ghost: (props) => ({
      select: {
        border: '1px solid',
        borderRadius: '10px',
        borderColor: mode('blackTransparent.20', 'whiteTransparent.20')(props),
        transition: 'border-color 200ms linear, box-shadow 200ms linear',

        _hover: {
          borderColor: mode(
            'blackTransparent.40',
            'whiteTransparent.40'
          )(props),
        },

        _focus: {
          boxShadow: mode(
            '0px 0px 0px 4px rgba(0, 0, 0, 0.10)',
            '0px 0px 0px 4px rgba(255, 255, 255, 0.10)'
          )(props),
        },

        _expanded: {
          borderColor: mode(
            'blackTransparent.40',
            'whiteTransparent.40'
          )(props),
          boxShadow: mode(
            '0px 0px 0px 4px rgba(0, 0, 0, 0.10)',
            '0px 0px 0px 4px rgba(255, 255, 255, 0.10)'
          )(props),
        },
      },
    }),
  },
  // The default variant value
  defaultProps: {
    variant: 'ghost',
    size: 'md',
  },
};
