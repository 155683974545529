import { BigNumberish } from '@ethersproject/bignumber';
import { useQuery } from '@tanstack/react-query';
import ERC20ABI from 'erc-20-abi';
import { Contract, utils } from 'ethers';

import { useAuth } from '@/hooks';

interface IUseBalanceConfigs {
  address: string;
  token?: string;
  formatUnits?: string | BigNumberish;
  cacheTime?: number;
  enabled?: boolean;
}

export const useBalance = (configs: IUseBalanceConfigs): string | undefined => {
  const {
    address,
    token,
    formatUnits,
    cacheTime = 0,
    enabled = true,
  } = configs;

  const { provider } = useAuth();

  const { data: balance } = useQuery<string | undefined>({
    cacheTime,
    enabled,
    queryKey: ['balance', address, 'token', token],
    queryFn: async () => {
      if (!address) {
        return undefined;
      }

      if (!token) {
        return await provider.getBalance(address);
      } else {
        const contract = new Contract(token, ERC20ABI, provider);
        return await contract.balanceOf(address);
      }
    },
  });

  return balance && formatUnits
    ? utils.formatUnits(balance, formatUnits)
    : balance;
};
