import { Box, Center, Heading, Text } from '@chakra-ui/react';
import cn from 'classnames';

import { LayoutWithSidebar } from '@/components/layouts';

import s from './NotFoundPage.module.sass';

export const NotFoundPage = () => {
  return (
    <Center className={cn(s.Wrapper)}>
      <Box>
        <Heading className={cn(s.StatusCode)}>404</Heading>
        <Heading fontSize={'22px'} mb={'10px'}>
          ERROR: Page Not Found
        </Heading>
        <Text fontSize={'14px'}>
          Sorry, the page you’re looking for does not exist.{' '}
        </Text>
      </Box>
    </Center>
  );
};

NotFoundPage.getLayout = (page) => (
  <LayoutWithSidebar page={page} menu="display" />
);
