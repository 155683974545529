import { Icon, IconType } from '@/components/ui';
import { Box, HStack } from '@chakra-ui/react';

import cn from 'classnames';
import styles from './transaction-badge.module.sass';

type TransactionType = 'sale' | 'ask' | 'ask_cancel' | 'transfer' | 'mint';

//'bid', bid_cancel'

type BadgeValue = {
  label: string;
  icon: IconType;
  className: string;
};

interface Props {
  //
  type: TransactionType;
}

const values: Record<TransactionType, BadgeValue> = {
  mint: {
    label: 'Mint',
    icon: { name: 'transaction-mint' },
    className: styles['Badge--Mint'],
  },
  sale: {
    label: 'Sale',
    icon: { name: 'transaction-sale' },
    className: styles['Badge--Sale'],
  },
  transfer: {
    label: 'Transfer',
    icon: { name: 'transaction-transfer' },
    className: styles['Badge--Transfer'],
  },
  ask: {
    label: 'List',
    icon: { name: 'transaction-list' },
    className: styles['Badge--List'],
  },
  ask_cancel: {
    label: 'Delist',
    icon: { name: 'transaction-delist' },
    className: styles['Badge--Delist'],
  },
};

export const TransactionBadge = (props: Props) => {
  //
  const { type } = props;

  //const { label, icon, className } = values[type];

  return (
    <Box className={cn(styles.Wrapper, values[type]?.className)}>
      <HStack>
        <Box className={styles.Icon}>
          <Icon icon={values[type]?.icon} size="sm" />
        </Box>
        <Box className={styles.Title}>{values[type]?.label}</Box>
      </HStack>
    </Box>
  );
};
