import { Center, CenterProps, Image, Text, TextProps, VStack } from '@chakra-ui/react';
import cn from 'classnames';
import React from 'react';

import NoFoundImage from '@/assets/images/no-found.png';

import s from './DataNotFound.module.sass';

interface IDataNotFoundProps extends CenterProps {
  title: string;
  description: string;
  descriptionProps?: TextProps;
  renderImage?: () => React.ReactNode;
}

export const DataNotFound: React.FC<IDataNotFoundProps> = (props) => {
  const { title, description, children, descriptionProps, renderImage, ...rest } = props;

  return (
    <Center {...rest}>
      <VStack spacing={'30px'}>
        {renderImage ? renderImage() : <Image src={NoFoundImage.src} w={'241px'} />}
        <VStack spacing={'10px'}>
          <Text fontSize={'22px'} fontWeight={'bold'}>{title}</Text>
          <Text className={cn(s.Description)} {...descriptionProps}>{description}</Text>
        </VStack>
        {children}
      </VStack>
    </Center>
  );
}