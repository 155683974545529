import { SystemStyleObject } from '@chakra-ui/styled-system';

const checkboxHover: SystemStyleObject = {
  backgroundClip: 'text',
  border: 0,
  position: 'relative',

  _before: {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    padding: '1px',
    background: 'purpleGradient',
    borderRadius: 'inherit',
    WebkitMask:
      'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
    WebkitMaskComposite: 'xor',
    maskComposite: 'exclude',
  },

  _after: {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'purpleGradient',
    borderRadius: 'inherit',
    opacity: '0%',
    zIndex: -1,
    transition: 'opacity 200ms linear',
  },

  _checked: {
    background:
      'linear-gradient(121.93deg, #FF7F69 4.09%, #FF2D83 28.55%, #6695FF 85.73%)',
    border: 0,
    backgroundClip: 'content-box',
    color: 'white',

    _before: {
      display: 'none',
    },
    _after: {
      display: 'none',
    },
  },

  _disabled: {
    background: 'blackTransparent.5',
    borderColor: 'blackTransparent.10',
    color: 'blackTransparent.10',

    _dark: {
      background: 'whiteTransparent.5 !important',
      borderColor: 'whiteTransparent.10 !important',
      color: 'whiteTransparent.10 !important',
    },

    _before: {
      display: 'none',
    },
    _after: {
      display: 'none',
    },
  },
};

export const Checkbox = {
  baseStyle: (props) => ({
    container: {
      _hover: {
        '> span:nth-of-type(1)': {
          ...checkboxHover,
        },
      },
    },
    control: {
      borderColor: 'blackTransparent.10',
      borderWidth: '1px',
      borderRadius: '6px',
      color: 'white',

      _dark: {
        borderColor: 'whiteTransparent.10',
        color: 'white',
      },

      _hover: {
        ...checkboxHover,
      },

      _focus: {
        boxShadow: '0px 0px 0px 4px rgba(188, 94, 190, 0.16)',
      },

      _checked: {
        background:
          'linear-gradient(121.93deg, #FF7F69 4.09%, #FF2D83 28.55%, #6695FF 85.73%)',
        border: 0,
        backgroundClip: 'content-box',
      },

      _disabled: {
        background: 'blackTransparent.5 !important',
        borderColor: 'blackTransparent.10 !important',
        color: 'blackTransparent.10 !important',

        _dark: {
          background: 'whiteTransparent.5 !important',
          borderColor: 'whiteTransparent.10 !important',
          color: 'whiteTransparent.10 !important',
        },
      },
    },
    label: {
      width: '100%',
      overflow: 'hidden',
    },
  }),
  sizes: {
    md: {
      control: {
        boxSize: '20px',
      },
      icon: {
        boxSize: '16px',
      },
    },
  },
};
