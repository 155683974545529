import {
  Box,
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  VStack
} from '@chakra-ui/react';
import React from 'react';

import { Icon } from '@/components/ui';
import { CloseButton } from '@/components/common/close-button';
import { NETWORK_CHAIN_ID } from '@/constants';
import { NetworkID } from '@/types';

import s from './wrong-network.module.sass';

const NETWORK_NAMES: Record<NetworkID, string> = {
  [NetworkID.MAINNET]: 'Ethereum Mainnet',
  [NetworkID.GOERLI]: 'Ethereum Goerli',
};

interface IWrongNetworkProps {
  isOpened: boolean;
  onSwitchNetworkClick: () => void;
  onClose: () => void;
}

export const WrongNetwork: React.FC<IWrongNetworkProps> = (props) => {
  const { isOpened, onSwitchNetworkClick, onClose } = props;

  return (
    <Modal isOpen={isOpened} isCentered closeOnEsc={false} closeOnOverlayClick={false} onClose={onClose}>
      <ModalOverlay />
      <ModalContent className={s.ModalContent}>
        <CloseButton className={s.Close} onClick={onClose} />
        <ModalBody className={s.ModalBody}>
          <VStack align={'stretch'} spacing={'24px'}>
            <Icon icon={'alert'} color={'red'} margin={'auto'} />
            <VStack align={'stretch'} spacing={'16px'}>
              <Heading className={s.Title}>Wrong Network</Heading>
              <Text className={s.Text}>
                Looks like you’re connected to an unsupported network. Please switch your network to{' '}
                <Box as={'span'}>{NETWORK_NAMES[NETWORK_CHAIN_ID]}</Box>
              </Text>
            </VStack>
            <Button className={s.SwitchNetworkButton} variant={'secondary'} onClick={onSwitchNetworkClick}>
              Switch Network
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}