import React from 'react';

import {
  Input as InputChakra,
  InputElementProps,
  InputGroup,
  InputLeftElement,
  InputProps as InputChakraProps,
  InputRightElement,
  useMultiStyleConfig,
} from '@chakra-ui/react';

interface InputProps extends InputChakraProps {
  leftElement?: React.ReactNode;
  leftElementProps?: InputElementProps;
  rightElement?: React.ReactNode;
  rightElementProps?: InputElementProps;
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => {
    //
    const {
      leftElement,
      rightElement,
      leftElementProps,
      rightElementProps,
      variant,
      size,
      ...rest
    } = props;

    const styles = useMultiStyleConfig('Input', { variant, size });

    // TODO: need refactoring - fast solution
    if (!leftElement && !rightElement) {
      return (
        <InputChakra size={size} ref={ref} __css={styles.field} {...rest} />
      );
    }

    return (
      <InputGroup>
        {leftElement && (
          <InputLeftElement h="100%" pointerEvents="none" {...leftElementProps}>
            {leftElement}
          </InputLeftElement>
        )}

        <InputChakra size={size} ref={ref} __css={styles.field} {...rest} />

        {rightElement && (
          <InputRightElement
            h="100%"
            pointerEvents="none"
            {...rightElementProps}
          >
            {rightElement}
          </InputRightElement>
        )}
      </InputGroup>
    );
  }
);

Input.displayName = 'Input';
