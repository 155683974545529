import { Blockies } from '@/components/common';
import { Button, Dropdown, Icon } from '@/components/ui';
import { Box, HStack } from '@chakra-ui/react';
import { DropdownContent } from './components';

import { useAuth } from '@/hooks';
import { useWeb3React } from '@web3-react/core';

import { truncateEthAddress } from '@/utils';

import cn from 'classnames';
import styles from './wallet-dropdown.module.sass';

type Props = {
  compact?: boolean;
};

export const WalletDropdown = ({ compact = false }: Props) => {
  //
  const { ENSName } = useWeb3React();
  const { address: account } = useAuth();

  const address = truncateEthAddress(account, 3);

  const compactStyles = compact
    ? {
        justifyContent: 'space-between',
        w: '100%',
      }
    : {};

  return (
    <>
      <Dropdown
        popoverProps={{
          placement: 'bottom-end',
          flip: false,
        }}
        content={<DropdownContent />}
      >
        <Button
          variant="ghost"
          rightIcon={
            <Icon
              className={styles.Icon}
              icon={{ name: 'chevron-down', size: 'sm' }}
            />
          }
          className={styles.Button}
          {...compactStyles}
        >
          <HStack spacing="10px">
            <Box className={cn(styles.WalletIcon, styles.WalletIconButton)}>
              <Blockies seed={account} size={5} />
            </Box>
            <Box fontSize="14px" lineHeight="20px">
              {ENSName ? ENSName : address}
            </Box>
          </HStack>
        </Button>
      </Dropdown>
    </>
  );
};
