import { Box, Flex, HStack, VStack } from '@chakra-ui/react';
import React from 'react';

import { Skeleton } from '@/components/ui';

import { NFTMarketplaceSidebarStickybarSkeleton } from './../nft-marketplace-sidebar-stickybar-skeleton';

export const NFTMarketplaceSidebarSkeleton: React.FC = () => {
  return (
    <Box>
      <Flex alignItems={'center'} justifyContent={'space-between'} mb={'8px'}>
        <HStack spacing={'12px'}>
          <Skeleton width={32} height={32} viewBox="0 0 32 32">
            <rect width="32" height="32" rx="8" />
          </Skeleton>

          <Skeleton width={186} height={32} viewBox="0 0 186 32">
            <rect width="186" height="32" rx="8" />
          </Skeleton>
        </HStack>

        <Skeleton width={32} height={32} viewBox="0 0 32 32">
          <rect width="32" height="32" rx="8" />
        </Skeleton>
      </Flex>

      <Box mb={'10px'}>
        <Skeleton width={200} height={32} viewBox="0 0 200 32">
          <rect width="200" height="32" rx="8" />
        </Skeleton>
      </Box>

      <Box mb={'26px'}>
        <Skeleton width={160} height={16} viewBox="0 0 160 16">
          <rect width="160" height="16" rx="8" />
        </Skeleton>
      </Box>

      <VStack align={'stretch'} spacing={'12px'} mb={'24px'}>
        <Skeleton width={160} height={22} viewBox="0 0 160 22">
          <rect width="160" height="22" rx="8" />
        </Skeleton>

        <VStack align={'stretch'} spacing={'6px'}>
          <Skeleton width={'100%'} height={16} viewBox="0 0 416 16">
            <rect width="100%" height="16" rx="8" />
          </Skeleton>
          <Skeleton width={'100%'} height={16} viewBox="0 0 416 16">
            <rect width="100%" height="16" rx="8" />
          </Skeleton>
          <Skeleton width={'100%'} height={16} viewBox="0 0 416 16">
            <rect width="100%" height="16" rx="8" />
          </Skeleton>
        </VStack>
      </VStack>

      <VStack align={'stretch'} spacing={'24px'}>
        <Skeleton width={'100%'} height={40} viewBox="0 0 416 40">
          <rect width="100%" height="40" rx="8" />
        </Skeleton>

        <Skeleton width={'100%'} height={192} viewBox="0 0 416 192">
          <rect width="100%" height="192" rx="8" />
        </Skeleton>
      </VStack>

      <NFTMarketplaceSidebarStickybarSkeleton />
    </Box>
  );
}