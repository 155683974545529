import { Icon, IconProps } from '@chakra-ui/react';

export const MintTnftIcon = (props: IconProps) => {
  //
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3936 4.21911C12.3962 3.87526 11.9533 3.73419 11.7565 4.01621L5.62948 12.7997C5.46763 13.0317 5.63364 13.35 5.91654 13.35L9.99972 13.35C10.911 13.35 11.6497 14.0887 11.6497 15V19.7987C11.6497 20.1418 12.0922 20.28 12.2875 19.9979L18.3789 11.1992C18.5396 10.967 18.3735 10.65 18.0911 10.65H14.0073C13.0912 10.65 12.3504 9.90357 12.3574 8.98743L12.3936 4.21911ZM10.6903 3.27245C11.6178 1.94292 13.7059 2.60798 13.6936 4.22898L13.6573 8.9973C13.6559 9.19163 13.813 9.34995 14.0073 9.34995H18.0911C19.4221 9.34995 20.2054 10.8448 19.4478 11.9392L13.3563 20.7379C12.4356 22.0679 10.3497 21.4163 10.3497 19.7987V15C10.3497 14.8067 10.193 14.65 9.99972 14.65L5.91654 14.65C4.58288 14.65 3.80024 13.1498 4.56326 12.056L10.6903 3.27245Z"
        fill="black"
      />
      <defs>
        <linearGradient
          id="gradient"
          x1="2.323"
          y1="3.6129"
          x2="21.4124"
          y2="15.5111"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF7F69" />
          <stop offset="0.299586" stopColor="#FF2D83" />
          <stop offset="1" stopColor="#6695FF" />
        </linearGradient>
      </defs>
    </Icon>
  );
};
