import {
  Box,
  Modal as ChakraModal,
  ModalBody,
  ModalBodyProps,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps as ChakraModalProps,
  useMultiStyleConfig,
} from '@chakra-ui/react';

import { CloseButton } from '@/components/common';

import React from 'react';

// import cn from 'classnames';
// import styles from './.module.sass';

interface ModalProps extends ChakraModalProps {
  //
  title?: string;
  isClosable?: boolean;
  renderHeader?(): React.ReactNode | null;
  renderContent?(): React.ReactNode | null;
  renderCloseIcon?(): React.ReactNode | null;
  children: React.ReactNode;
  width?: number;
  modalBodyProps?: ModalBodyProps;
}

export const Modal = (props: ModalProps) => {
  //
  const {
    isOpen,
    onClose,
    isClosable,
    title,
    renderHeader,
    renderCloseIcon,
    //renderFooter,
    children,
    variant,
    size,
    width,
    modalBodyProps,
    ...rest
  } = props;

  const styles = useMultiStyleConfig('Modal', { variant, size });

  return (
    <Box>
      <ChakraModal
        isOpen={isOpen}
        variant={variant}
        size={size}
        onClose={onClose}
        {...rest}
      >
        <ModalOverlay />
        <ModalContent maxW={width ? `${width}px` : null}>
          {(title || renderHeader) && (
            <ModalHeader>{renderHeader ? renderHeader() : title}</ModalHeader>
          )}

          {isClosable && (
            <Box sx={styles.closeButton}>
              {renderCloseIcon ? (
                renderCloseIcon()
              ) : (
                <CloseButton onClick={() => onClose()} />
              )}
            </Box>
          )}
          <ModalBody {...modalBodyProps}>{children}</ModalBody>

          {/* <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button variant="ghost">Secondary Action</Button>
        </ModalFooter> */}
        </ModalContent>
      </ChakraModal>
    </Box>
  );
};
