import { AspectRatio, Box } from '@chakra-ui/react';
import cn from 'classnames';

import { Skeleton } from '@/components/ui';
import { useTheme } from '@/hooks';
import colors from '@/theme/colors';

import s from './playlist-card-skeleton.module.sass';

export const PlaylistCardSkeleton = () => {

  const { isLight } = useTheme();

  const imageBackgroundColor = isLight ? '#e1e1e1' : '#1d1832';
  const imageForegroundColor = isLight ? '#ECECEC' : '#272043';

  const textColor = isLight ? colors.darkTransparent['5'] : colors.whiteTransparent['5'];

  const ImageSkeleton = () => (
    <Skeleton
      viewBox="0 0 330 330"
      width={330}
      height={330}
      backgroundColor={imageBackgroundColor}
      foregroundColor={imageForegroundColor}
    >
      <rect width="330" height="330" rx="10px" />
    </Skeleton>
  );

  const TitleSkeleton = () => (
    <Skeleton
      viewBox="0 0 200 46"
      className={cn(s.Title)}
      width={200}
      height={46}
      backgroundColor={textColor}
      foregroundColor={textColor}
    >
      <rect width="134" height="20" rx="6" />
      <rect y="30" width="96" height="16" rx="6" />
    </Skeleton>
  );

  const AmountSkeleton = () => (
    <Skeleton
      viewBox="0 0 40 24"
      className={cn(s.Amount)}
      width={40}
      height={24}
      backgroundColor={textColor}
      foregroundColor={textColor}
    >
      <rect width="40" height="24" rx="6" />
    </Skeleton>
  );

  return (
    <>
      <Box className={cn(s.Wrapper)}>
        <AspectRatio ratio={1}>
          <ImageSkeleton />
        </AspectRatio>

        <TitleSkeleton />
        <AmountSkeleton />
      </Box>
    </>
  );
};
