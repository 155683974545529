import {
  IconButton as IconButtonChakra,
  IconButtonProps as IconButtonChakraProps,
  useStyleConfig,
} from '@chakra-ui/react';
import React from 'react';

export const IconButton = React.forwardRef<
  HTMLButtonElement,
  IconButtonChakraProps
>((props, ref) => {
  //
  const { colorScheme, variant, size } = props;

  const styles = useStyleConfig('IconButton', { variant, size, colorScheme });

  return <IconButtonChakra ref={ref} __css={styles} {...props} />;
});

IconButton.displayName = 'IconButton';
