import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

export const VideoIcon = (props: IconProps) => {
  //
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="10px"
      height="8px"
      viewBox="0 0 10 8"
      fill="none"
      display="block"
      {...props}
    >
      <path
        d="M7 6.125C7 6.55598 6.82879 6.9693 6.52405 7.27405C6.2193 7.57879 5.80598 7.75 5.375 7.75H1.625C1.19402 7.75 0.780698 7.57879 0.475951 7.27405C0.171205 6.9693 0 6.55598 0 6.125V1.875C0 1.44402 0.171205 1.0307 0.475951 0.725951C0.780698 0.421205 1.19402 0.25 1.625 0.25H5.375C5.80598 0.25 6.2193 0.421205 6.52405 0.725951C6.82879 1.0307 7 1.44402 7 1.875V6.125ZM9.881 0.9465C9.95787 1.03695 10.0001 1.1518 10 1.2705V6.7295C10 6.82498 9.97273 6.91848 9.92131 6.99893C9.86989 7.07939 9.79651 7.14344 9.70984 7.18351C9.62318 7.22359 9.52685 7.23801 9.43225 7.22507C9.33764 7.21213 9.24872 7.17237 9.176 7.1105L7.5 5.685V2.314L9.176 0.8895C9.22603 0.846935 9.28395 0.814643 9.34646 0.794471C9.40897 0.774298 9.47484 0.76664 9.54031 0.771933C9.60578 0.777226 9.66957 0.795368 9.72802 0.825321C9.78648 0.855274 9.83846 0.896451 9.881 0.9465Z"
        fill="white"
      />
    </Icon>
  );
};
