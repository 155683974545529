import { extendTheme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import colors from './colors';
import * as components from './components';

const config = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

const breakpoints = {
  base: '0px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1440px',
  '2xl': '1820px',
  '3xl': '1920px', //fhd
  '4xl': '2560px', //2k
  '5xl': '3840px', //4k
};

export const theme = extendTheme({
  config,
  colors,
  breakpoints,

  fonts: {
    body: 'Space Grotesk, serif',
    heading: 'Space Grotesk, serif',
  },

  sizes: {
    container: {
      xl: '1140px',
    },
  },

  styles: {
    global: (props) => ({
      // styles for the `body`
      body: {
        position: 'relative',
        color: mode('black', 'white')(props),
        backgroundColor: mode('white', 'dark')(props),
        transition: 'none',
        height: '100%',
      },
    }),
  },
  components: {
    ...components,
  },
});
