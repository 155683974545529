export enum PLATFORM {
  MOBILE_WEB_ANDROID = 'MOBILE_WEB_ANDROID',
  MOBILE_WEB_IOS = 'MOBILE_WEB_IOS',
  DESKTOP_WEB = 'DESKTOP_WEB',
}

export enum BROWSER {
  SAFARI = 'SAFARI',
  FIREFOX = 'FIREFOX',
  CHROME = 'CHROME',
  UNKNOWN = 'UNKNOWN'
}

export const detectBrowser = () => {
  const userAgent = navigator.userAgent;

  if (userAgent.match(/chrome|chromium|crios/i)) {
    return BROWSER.CHROME;
  } else if (userAgent.match(/firefox|fxios/i)) {
    return BROWSER.FIREFOX;
  } else if (userAgent.match(/safari/i)) {
    return BROWSER.SAFARI;
  }
  return BROWSER.UNKNOWN;
}

export const getMobileOperatingSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor;

  if (/android/i.test(userAgent)) {
    return PLATFORM.MOBILE_WEB_ANDROID;
  }

  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return PLATFORM.MOBILE_WEB_IOS;
  }

  return PLATFORM.DESKTOP_WEB;
}