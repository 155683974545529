import ReactMarkdown from 'react-markdown';

import {
  Box,
  BoxProps,
  Heading,
  HStack,
  Image,
  Link,
  Text,
  VStack,
} from '@chakra-ui/react';

import { TextReadMoreLess } from '@/components/common';
import { Icon } from '@/components/ui';

import { useCollection } from '@/hooks';
import { useNFTOwner } from '@/hooks/use-nft-owner';
import { useMemo } from 'react';

import { ETHERSCAN_LINK } from '@/constants';
import { formatAddress } from '@/utils';

import s from './nft-basic-info.module.sass';

export interface INFTBasicInfoProps extends BoxProps {
  contractAddress: string;
  tokenId: string | number;
  name?: string;
  description?: string;
}

export const NFTBasicInfo: React.FC<INFTBasicInfoProps> = (props) => {
  const { contractAddress, tokenId, name, description, ...rest } = props;

  const collection = useCollection(contractAddress);

  const { owners } = useNFTOwner(contractAddress, tokenId);

  const ownerAddress = useMemo(() => {
    return owners.length ? owners[0] : null;
  }, [owners]);

  return (
    <Box {...rest}>
      {!!collection && (
        <HStack className={s.CollectionDetails} spacing={'12px'}>
          {collection?.logoUrl && (
            <Image src={collection.logoUrl} alt={collection.name} />
          )}
          {collection?.name && <Heading>{collection.name}</Heading>}
        </HStack>
      )}

      <VStack align={'stretch'} spacing={'10px'} mb={'26px'}>
        <Heading className={s.NFTName}>{name ?? tokenId}</Heading>
        <Text className={s.OwnedBy}>
          Owned by{' '}
          <Link href={`${ETHERSCAN_LINK.address}/${ownerAddress}`} isExternal>
            {formatAddress(ownerAddress ?? '')}
            <Icon icon={'arrowUpRight'} />
          </Link>
        </Text>
      </VStack>

      {!!description && (
        <Box mb={'24px'}>
          <Heading className={s.DescriptionTitle}>Description</Heading>
          <TextReadMoreLess className={s.DescriptionText}>
            <ReactMarkdown>{description}</ReactMarkdown>
          </TextReadMoreLess>
        </Box>
      )}

      {!!collection?.description && (
        <Box mb={'24px'}>
          <Heading className={s.DescriptionTitle}>About Collection</Heading>
          <TextReadMoreLess className={s.DescriptionText}>
            <ReactMarkdown>{collection.description}</ReactMarkdown>
          </TextReadMoreLess>
        </Box>
      )}
    </Box>
  );
};
