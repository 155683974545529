import { Box, HStack } from '@chakra-ui/react';
import { AudioIcon, VideoIcon } from './components';

import { useNftCardContext } from '@/components/common/nft-card/context';
import { getFileType } from '@/utils';

import PlayerAudioPreviewSmall from '@/assets/animations/player_audio_preview_small.json';
import Lottie from 'react-lottie';

import cn from 'classnames';
import styles from './nft-asset-badges.module.sass';

interface Props {
  className?: string;
  type: string;
}

export const NftAssetBadges = (props: Props) => {
  //
  const { className, type } = props;

  const { isVideo, isAudio } = getFileType(type);

  const { isPlaying } = useNftCardContext();

  return (
    <>
      <Box
        className={cn(styles.Wrapper, isPlaying && styles.isPlaying, className)}
        data-class="badges"
      >
        <HStack>
          <Box className={styles.Item}>
            {isPlaying ? (
              <Box w="20px">
                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: PlayerAudioPreviewSmall,
                    rendererSettings: {
                      preserveAspectRatio: 'xMidYMid slice',
                    },
                  }}
                  isClickToPauseDisabled={true}
                />
              </Box>
            ) : (
              <>
                {isVideo && <VideoIcon className={styles.Icon} />}
                {isAudio && <AudioIcon className={styles.Icon} />}
              </>
            )}
          </Box>
        </HStack>
      </Box>
    </>
  );
};
