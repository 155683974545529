import { Box, BoxProps, Icon } from '@chakra-ui/react';

export const ArrowBottomIcon = (props: BoxProps) => {
  //
  return (
    <Box {...props}>
      <Icon
        width="100%"
        height="100%"
        viewBox="0 0 20 20"
        fill="none"
        display="block"
      >
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M10.5 6C10.5 5.72386 10.2761 5.5 10 5.5C9.72386 5.5 9.5 5.72386 9.5 6V12.7929L6.35355 9.64645C6.15829 9.45118 5.84171 9.45118 5.64645 9.64645C5.45118 9.84171 5.45118 10.1583 5.64645 10.3536L9.64645 14.3536C9.69439 14.4015 9.74964 14.4377 9.80861 14.4621C9.86669 14.4861 9.9303 14.4996 9.997 14.5L10 14.5L10.003 14.5C10.13 14.4992 10.2567 14.4504 10.3536 14.3536L14.3536 10.3536C14.5488 10.1583 14.5488 9.84171 14.3536 9.64645C14.1583 9.45118 13.8417 9.45118 13.6464 9.64645L10.5 12.7929V6Z"
          fill="black"
        />
      </Icon>
    </Box>
  );
};
