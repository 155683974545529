import {
  Box,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import cn from 'classnames';
import styles from './progress-bar.module.sass';

interface Props {
  played?: number;
  seeking?: boolean;
  isLightMode?: boolean;
  isWrap?: boolean;
  onSeekingStart?(value: number): void;
  onSeekingEnd?(value: number): void;
}

export const ProgressBar = (props: Props) => {
  //
  const {
    played,
    seeking = false,
    isLightMode = false,
    isWrap = false,
    onSeekingStart,
    onSeekingEnd,
  } = props;

  const [value, setValue] = useState(0);

  useEffect(() => {
    if (played && !seeking) {
      const fixed = played.toFixed(3);
      const parsed = parseFloat(fixed);
      setValue(parsed);
    }
  }, [played]);

  const onChangeStart = (value) => {
    //
    onSeekingStart && onSeekingStart(value);
  };

  const onChangeEnd = (value) => {
    //
    onSeekingEnd && onSeekingEnd(value);
  };

  const onChange = (value) => {
    //
    setValue(value);
  };

  return (
    <Box
      className={cn(
        styles.Wrapper,
        isLightMode && styles.isLightMode,
        isWrap && styles.isWrap
      )}
    >
      <Slider
        min={0}
        max={1}
        step={0.001}
        defaultValue={0}
        value={value}
        onChange={onChange}
        onChangeStart={onChangeStart}
        onChangeEnd={onChangeEnd}
        focusThumbOnChange={false}
        className={styles.Slider}
      >
        <SliderTrack className={styles.Track}>
          <SliderFilledTrack className={styles.FilledTrack} />
        </SliderTrack>
        <SliderThumb className={styles.Thumb} />
      </Slider>
    </Box>
  );
};
