import { Icon, Tooltip } from '@/components/ui';
import { Box } from '@chakra-ui/react';

import cn from 'classnames';
import styles from '../controls.module.sass';

interface Props {
  fullscreen: boolean;
  isLightMode?: boolean;
  isShowTitle?: boolean;
  onClick?(): void;
}

export const Fullscreen = (props: Props) => {
  //
  const {
    fullscreen = false,
    isShowTitle = true,
    isLightMode = false,
    onClick,
  } = props;

  return (
    <Tooltip
      label={fullscreen ? 'Exit full screen' : 'Full screen'}
      isDisabled={!isShowTitle}
      variant={isLightMode && 'white'}
    >
      <Box
        className={cn(styles.Button, isLightMode && styles.isLightMode)}
        onClick={onClick}
      >
        <Icon
          icon={fullscreen ? 'fullscreen-off' : 'fullscreen-on'}
          className={styles.Icon}
        />
      </Box>
    </Tooltip>
  );
};
