import {
  Box,
  Flex,
  Image,
  Link,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';

import { Icon } from '@/components/ui';

import { useRouter } from 'next/router';
import { useMemo, useRef, useState } from 'react';

import NextLink from 'next/link';

import NftDisplayIcon from '@/assets/images/nft-display-nav-icon.png';
import NftEmbedIcon from '@/assets/images/nft-embed-nav-icon.png';
import NftTorrentIcon from '@/assets/images/nft-torrent-nav-icon.png';
import XeenonIcon from '@/assets/images/xeenon_nav_icon.png';

import cn from 'classnames';
import { useClickAway } from 'react-use';
import s from './app-switcher.module.sass';

export const AppSwitcher = () => {
  //
  const [isOpen, setIsOpen] = useState(false);

  const router = useRouter();
  const refContent = useRef(null);
  const refTrigger = useRef(null);

  useClickAway(refContent, (event) => {
    //
    if (refTrigger.current && !refTrigger.current.contains(event.target)) {
      setIsOpen(false);
    }
  });

  const onClick = () => {
    //
    setIsOpen(false);
  };

  const popoverTitle = useMemo(() => {
    if (router.asPath.includes('/display/')) {
      return 'NFT Display';
    }

    if (router.asPath.includes('/torrent/')) {
      return 'NFT Torrent';
    }

    if (router.asPath.includes('/storage/')) {
      return 'NFT Storage';
    }

    if (
      router.asPath.includes('/privacy') ||
      router.asPath.includes('/terms-of-services')
    ) {
      return 'Documents';
    }
  }, [router.asPath]);

  return (
    <Popover
      isOpen={isOpen}
      placement={'bottom-end'}
      openDelay={0}
      closeDelay={0}
      offset={[-56, 10]}
    >
      <PopoverTrigger>
        <Box
          ref={refTrigger}
          className={cn(s.buttonWrapper, isOpen && s.buttonWrapper__isOpen)}
          onClick={() => setIsOpen((state) => !state)}
        >
          <Flex className={s.button} as={'button'}>
            <Text as={'p'}>{popoverTitle}</Text>
            <Icon icon={'chevronDown'} />
          </Flex>
        </Box>
      </PopoverTrigger>
      <PopoverContent ref={refContent} w={'360px'} className={s.popover}>
        <PopoverBody p={0}>
          <Flex className={s.menuContent}>
            <Text as={'h5'}>Switch to</Text>
            <NextLink href="/display/nfts" passHref>
              <Link onClick={() => onClick()}>
                <Flex className={s.menuContent__item}>
                  <Image
                    src={NftDisplayIcon.src}
                    alt="NFT Display"
                    boxSize={'40px'}
                  />
                  <Flex flexDirection={'column'} gap={'2px'}>
                    <Text className={s.menuContent__item__title}>
                      NFT Display
                    </Text>
                    <Text className={s.menuContent__item__description}>
                      Showcase your NFTs on any screen
                    </Text>
                  </Flex>
                  <Flex className={s.icon}>
                    <Icon icon={'arrow-right'} />
                  </Flex>
                </Flex>
              </Link>
            </NextLink>
            <NextLink href="/torrent/tnfts" passHref>
              <Link onClick={() => onClick()}>
                <Flex className={s.menuContent__item} mb={'16px'}>
                  <Image
                    src={NftTorrentIcon.src}
                    alt="NFT Torrent"
                    boxSize={'40px'}
                  />
                  <Flex flexDirection={'column'} gap={'2px'}>
                    <Text className={s.menuContent__item__title}>
                      NFT Torrent
                    </Text>
                    <Text className={s.menuContent__item__description}>
                      Safely mint, seed, and store NFT files
                    </Text>
                  </Flex>
                  <Flex className={s.icon}>
                    <Icon icon={'arrow-right'} />
                  </Flex>
                </Flex>
              </Link>
            </NextLink>
            <Text as={'h5'}>Discover</Text>
            <Link
              href={'https://www.nftembed.org/'}
              isExternal
              onClick={() => onClick()}
            >
              <Flex className={s.menuContent__item}>
                <Image
                  src={NftEmbedIcon.src}
                  alt="NFT Ember"
                  boxSize={'32px'}
                />
                <Box>
                  <Text
                    className={s.menuContent__item__title}
                    fontSize={'14px!important;'}
                    lineHeight={'20px!important;'}
                  >
                    NFT Embed
                  </Text>
                  <Text className={s.menuContent__item__description}>
                    Display & sell NFTs on any website
                  </Text>
                </Box>
                <Flex className={s.icon}>
                  <Icon icon={'externalLink'} />
                </Flex>
              </Flex>
            </Link>
            <Link
              href="https://xeenon.xyz/"
              isExternal
              onClick={() => onClick()}
            >
              <Flex className={s.menuContent__item}>
                <Image src={XeenonIcon.src} alt="Xeenon" boxSize={'32px'} />
                <Box>
                  <Text
                    className={s.menuContent__item__title}
                    fontSize={'14px!important;'}
                    lineHeight={'20px!important;'}
                  >
                    Xeenon
                  </Text>
                  <Text className={s.menuContent__item__description}>
                    WEB3 media platform
                  </Text>
                </Box>
                <Flex className={s.icon}>
                  <Icon icon={'externalLink'} />
                </Flex>
              </Flex>
            </Link>
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
