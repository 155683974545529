import { Skeleton } from '@/components/ui';
import { Box, Flex, HStack } from '@chakra-ui/react';

import styles from './collection-card-skeleton.module.sass';

interface Props {
  showMenu?: boolean;
  includeStats?: boolean;
}

export const CollectionCardSkeleton = (props: Props) => {
  //

  const { showMenu = false, includeStats = false } = props;

  return (
    <Box className={styles.Wrapper}>
      <Box className={styles.Banner}>
        <Skeleton viewBox="0 0 330 128">
          <rect width="100%" height="128px" />
        </Skeleton>
      </Box>

      <Box className={styles.Content}>
        <Box className={styles.Logo}>
          <Skeleton
            width={styles.logoSize}
            height={styles.logoSize}
            viewBox="0 0 64 64"
          >
            <rect width="100%" height="100%" />
          </Skeleton>
        </Box>

        <Flex justifyContent={'space-between'}>
          <Box className={styles.Title} flex={1}>
            <Skeleton width={130} height={24} viewBox="0 0 130 24">
              <rect width={130} height={24} rx={8} />
            </Skeleton>
          </Box>

          {showMenu && (
            <Box className={styles.Menu}>
              <Skeleton width={24} height={24} viewBox="0 0 24 24">
                <rect width="100%" height="100%" rx={8} />
              </Skeleton>
            </Box>
          )}
        </Flex>

        {includeStats && (
          <Box className={styles.StatsWrapper}>
            <HStack spacing="0px" justifyContent="space-between">
              {Array(4)
                .fill(null)
                .map((_, i) => (
                  <Box key={i}>
                    <Box>
                      <Box mb="8px">
                        <Skeleton width={55} height={16} viewBox="0 0 55 16">
                          <rect width="100%" height="100%" rx={8} />
                        </Skeleton>
                      </Box>
                      <Box>
                        <Skeleton width={30} height={14} viewBox="0 0 30 14">
                          <rect width="100%" height="100%" rx={8} />
                        </Skeleton>
                      </Box>
                    </Box>
                  </Box>
                ))}
            </HStack>
          </Box>
        )}
      </Box>
    </Box>
  );
};
