import { useTheme } from '@/hooks';
import { Box, Center } from '@chakra-ui/react';

import cn from 'classnames';
import styles from './connect-wallet-item.module.sass';

export const ConnectWalletItem = (props) => {
  //
  const { children, onClick } = props;
  const { themeClass } = useTheme();

  return (
    <Box className={cn(styles.Item, styles[themeClass])} onClick={onClick}>
      <Center>{children}</Center>
    </Box>
  );
};
