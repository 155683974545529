import { CenterProps } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import React, { useMemo } from 'react';

import { ReactComponent as NoFoundDark } from '@/assets/images/collections-not-found-dark.svg';
import { ReactComponent as NoFoundLight } from '@/assets/images/collections-not-found-light.svg';
import { Button, Icon } from '@/components/ui';
import { DataNotFound } from '@/components/common';
import { stringToBoolean } from '@/utils';
import { trackInitiateCreateTnftCollection } from '@/utils/amplitude';
import { useTheme } from '@/hooks';
import { configs } from '@/configs';

export interface ICollectionsNotFound extends CenterProps {
  tCollection?: boolean;
}

export const CollectionsNotFound: React.FC<ICollectionsNotFound> = (props) => {
  const { tCollection = false, ...rest } = props;

  const router = useRouter();

  const { isLight } = useTheme();

  const [title, description, buttonText] = useMemo(() => {
    if (tCollection) {
      return [
        'No tNFT Collections Found',
        'Click below to deploy your first tNFT collection',
        'Deploy tNFT Collection',
      ];
    }

    return [
      'No Collections Found',
      'Click below to deploy your first tNFT collection',
      'Deploy Collection',
    ];
  }, [tCollection]);

  return (
    <DataNotFound
      title={title}
      description={configs.featuresFlags.enableNFTTorrent ? description : ''}
      descriptionProps={{ maxW: '545px' }}
      renderImage={() => isLight ? <NoFoundLight /> : <NoFoundDark />}
      {...rest}
    >
      {configs.featuresFlags.enableNFTTorrent && (
        <Button
          variant="secondary"
          size="lg"
          onClick={() => {
            trackInitiateCreateTnftCollection();
            router.push(`/torrent/mint/collection`);
          }}
        >
          {buttonText}
        </Button>
      )}
    </DataNotFound>
  );
}